import { BreadCrumbConfig } from "@kinherit/framework/component.layout/breadcrumbs/types";
import { Lead } from "@kinherit/sdk";

export const LeadMasterListBreadCrumb = {
  text: "Leads",
  route: { name: "LeadMasterList" },
};

export const LeadDetailsBreadCrumb = (params: any) => {
  const lead = Lead.$findOne(params.lead);

  const breadcrumbs: Array<BreadCrumbConfig> = [];

  breadcrumbs.push(LeadMasterListBreadCrumb);

  breadcrumbs.push({
    text: lead?.profile.fullName ?? "",
    route: { name: "LeadDetailsEvents", params },
  });

  return breadcrumbs;
};

export const LeadDetailsNotesBreadCrumb = (params: any) => [
  ...LeadDetailsBreadCrumb(params),
  {
    text: "Notes",
    route: { name: "LeadDetailsNotes", params },
  },
];

export const LeadDetailsChangeLogBreadCrumb = (
  params: Record<string, string>,
) => [
  ...LeadDetailsBreadCrumb(params),
  {
    text: "Changes",
    route: { name: "LeadDetailsChangeLog", params },
  },
];

export const LeadDetailsUploadFormsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...LeadDetailsBreadCrumb(params),
  {
    text: "Upload Forms",
    route: { name: "LeadDetailsUploadForm", params },
  },
];

export const LeadDetailsSortReferBreadCrumb = (
  params: Record<string, string>,
) => [
  ...LeadDetailsBreadCrumb(params),
  {
    text: "SortRefer",
    route: { name: "LeadDetailsSortRefer", params },
  },
];

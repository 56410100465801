// IntroducerFeePayment, IntroducerFeePaymentRun, IntroducerFeePaymentItem

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerFeePayment } from "@kinherit/sdk/model/introducer-fee-payment";

export const CreateIntroducerFeePayment = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/fees/payment",
  method: "post",
  parse: (target: IntroducerFeePayment) => ({
    body: {
      completedAt: target.$data.completedAt,
      introducerFeePaymentRun: target.$data.introducerFeePaymentRun,
      status: target.$data.status,
      xeroContact: target.$data.xeroContact,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DownloadOfficerLetter } from "./download-officer-letter.action";
import { Portal2OfficerNote } from "./notes";
import { RecordPerson } from "./record-person.action";
import { ReplaceProfile } from "./replace-profile.action";
import { SendOfficerLetter } from "./send-officer-letter.action";
import { UpdatePerson } from "./update-person.action";

export const Portal2Officer = DefineActionBus({
  name: "portal-2-officer",
  actions: {
    note: Portal2OfficerNote,
    RecordPerson,
    UpdatePerson,
    DownloadOfficerLetter,
    SendOfficerLetter,
    UpdateOfficerProfile: ReplaceProfile,
  },
});

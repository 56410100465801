<template>
  <div class="guardian-list">
    <GuardianSummary
      v-for="role in guardians.sortBy('sortOrder', 'asc')"
      :key="`guardian-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!guardians.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "GuardianListWrapper",
  "imports": {
    "GuardianSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/GuardianSummary.test"
  },
  "methods": {
    "guardians": {
      "type": "to-one",
      "selector": ".guardian-summary",
      "wrapper": "GuardianSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import GuardianSummary from "@/module/kinvault.kintin/component/summary-cards/GuardianSummary.vue";
import { UpdateGuardianForm } from "@/module/kinvault.kintin/form/update-guardian.form";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { Guardian, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuardianList",
  components: { GuardianSummary, Card },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.kinvault)],
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    guardians: {
      type: Array as () => Guardian[],
      required: true,
    },
  },
  methods: {
    async edit(guardian: Guardian): Promise<void> {
      try {
        await UpdateGuardianForm({
          data: guardian,
          kintin: this.kintin,
        }).then((form) =>
          form.dialog({
            dialog: {
              title: "Edit Guardian",
            },
          }),
        );
      } catch {
        guardian.$restore();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.update({
      //   person: guardian.person,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.person.UpdatePerson(guardian.person);

      // await window.Kernel.ActionBus.kinvaultKintin.guardian.update({
      //   guardian,
      //   kintin,
      // });
      await this.$actionBus.guardian.UpdateGuardian(guardian);

      this.$emit("reload");
    },
    async remove(guardian: Guardian): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Guardian",
          message: "Are you sure you want to delete this guardian?",
        },
      });

      // await window.Kernel.ActionBus.kinvaultKintin.guardian.delete({
      //   guardian,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.guardian.DeleteGuardian(guardian);

      this.$emit("reload");
    },
  },
});
</script>

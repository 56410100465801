<template>
  <RouterChildView> </RouterChildView>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsCallScriptWrapper",
  "route": "KinvaultKintinDetailsCallScript",
  "imports": {
    "KinvaultKintinDetailsCallScriptSidebarWrapper": "@/module/kinvault.kintin/page/details/call-script/KinvaultKintinDetails.CallScript.Sidebar.test"
  },
  "methods": {
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsCallScriptSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams, KintinDetailsCallScriptRoute } from ".";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: { RouterChildView },
  async beforeRouteEnter(to): Promise<void> {
    const params = to.params as KintinDetailsCallScriptParams;
    // await window.Kernel.ActionBus.kinvaultKintin.callScript.record(
    await window.Kernel.ActionBus2.portal2.kinvault.callScript.RecordCallScriptData(
      {
        message: params.kintin,
      },
    );
  },
});
</script>

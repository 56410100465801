import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteAccountReferralCode } from "./delete-account-referral-code.action";
import { RecordAccountReferralCode } from "./record-account-referral-code.action";
import { UpdateAccountReferralCode } from "./update-account-referral-code.action";

export const Portal2AdminAccountReferralCode = DefineActionBus({
  name: "portal-2-admin-account-referral-code",
  actions: {
    RecordAccountReferralCode,
    DeleteAccountReferralCode,
    UpdateAccountReferralCode,
  },
});

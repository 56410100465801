import { Api } from "@/service/api.service";
import { Address, Kintin } from "@kinherit/sdk";

type createRoyalMailOrderMessage = {
  kintin: string | Kintin;
  address: string | Address;
};

interface createRoyalMailOrderResponse {
  [key: string]: never;
}

export const CreateRoyalMailOrder = async (
  message: createRoyalMailOrderMessage,
): Promise<createRoyalMailOrderResponse> => {
  const kintin =
    "string" === typeof message.kintin ? message.kintin : message.kintin.id;
  const address =
    "string" === typeof message.address ? message.address : message.address.id;

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/process/create-royal-mail-order",
  )
    .method("get")
    .params({
      kintin,
      address,
    })
    .result();

  return {};
};

// KintinAccess

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const ReadKintinAccess = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/kintin-access",
  method: "get",
  rootModel: KintinAccess,
  parse: () => ({}),
});

import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  FileLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
};

type Response = {
  files: FileLog[];
};

export const UploadEmailLogAttachmentHandler = async (
  message: Message,
): Promise<Response> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal-2/introducer/company/{owner}/email-log/{emailLog}/attachment",
    [IntroducerContact.$name]:
      "/v2/portal-2/introducer/contact/{owner}/email-log/{emailLog}/attachment",
    [Kintin.$name]:
      "/v2/portal-2/kinvault/{owner}/email-log/{emailLog}/attachment",
    [Lead.$name]: "/v2/portal-2/lead/{owner}/email-log/{emailLog}/attachment",
    [Person.$name]:
      "/v2/portal-2/officer/{owner}/email-log/{emailLog}/attachment",
    [BrandedKinvault.$name]:
      "/v2/portal-2/admin/branded-kinvault/{owner}/email-log/{emailLog}/attachment",
  } as const;

  const route = routes[message.owner.$name];

  const files: FileLog[] = [];

  for (const file of message.files) {
    const response = await Api.resource("portal-2", route, {
      owner: message.owner,
      emailLog: message.emailLog,
    })
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();

    files.push(...FileLog.$inflate(response.fileLog));
  }

  return {
    files,
  };
};

// KnowledgeBaseCategory

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const CreateKnowledgeBaseCategory = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/knowledge-base",
  method: "post",
  parse: (target: KnowledgeBaseCategory) => ({
    body: {
      title: target.$data.title,
      parentCategory: target.$data.parentCategory,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

// IntroducerContract

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerContract } from "@kinherit/sdk/model/introducer-contract";

export const CreateIntroducerContract = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contract",
  method: "post",
  parse: (target: IntroducerContract) => ({
    body: {
      sentAt: target.$data.sentAt,
      datedAt: target.$data.datedAt,
      isSigned: target.$data.isSigned,
      ai_5a_percent: target.$data.ai_5a_percent,
      ai_5b_percent: target.$data.ai_5b_percent,
      ai_5c_percent: target.$data.ai_5c_percent,
      i_4c_percent: target.$data.i_4c_percent,
      ai_5a_months: target.$data.ai_5a_months,
      ai_5b_months: target.$data.ai_5b_months,
      ai_5c_months: target.$data.ai_5c_months,
      i_4c_months: target.$data.i_4c_months,
      prePaidClients: target.$data.prePaidClients,
      notes: target.$data.notes,
      datedTill: target.$data.datedTill,
      name: target.$data.name,
      company: target.$data.company,
      type: target.$data.type,
      vat: target.$data.vat,
      parentContract: target.$data.parentContract,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateUsers } from "./create-users.action";
import { ReadUsers } from "./read-users.action";
import { Portal2AdminUsersStaff } from "./staff";

export const Portal2AdminUsers = DefineActionBus({
  name: "portal-2-admin-users",
  actions: {
    ReadUsers,
    CreateUser: CreateUsers,
    staff: Portal2AdminUsersStaff,
  },
});

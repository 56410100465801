// Tag

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Tag } from "@kinherit/sdk/model/tag";

export const ReadTags = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/tags",
  method: "get",
  rootModel: Tag,
  parse: () => ({}),
});

// BrandedKinvaultSubscriptionReferral, BrandedKinvaultSubscriptionReferralCode, KintinSubscriptionIntent, KintinSubscription, Profile, EmailAddress, KintinPrice

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk/model/branded-kinvault-subscription-referral";

export const ReadBrandedKinvaultSubscriptionReferral = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/subscription-referral",
  method: "get",
  rootModel: BrandedKinvaultSubscriptionReferral,
  parse: () => ({}),
});

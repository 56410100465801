import {
  KnowledgeBaseCategoryBreadCrumb,
  KnowledgeBaseCategoryMasterListBreadCrumb,
  KnowledgeBaseDocumentBreadCrumb,
  KnowledgeBaseTopLevelCategoryBreadCrumb,
} from "@/module/knowledge-base/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

/** Documents */
export const KnowledgeBaseDocumentDetailsContentRoute =
  "KnowledgeBaseDocumentDetailsContent";
export const KnowledgeBaseDocumentDetailsContentURI =
  "/knowledge-base/:topLevelCategory/category/:category/document/:document";
export type KnowledgeBaseDocumentDetailsContentParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsContentURI
>;

export const KnowledgeBaseDocumentDetailsFilesRoute =
  "KnowledgeBaseDocumentDetailsFiles";
export const KnowledgeBaseDocumentDetailsFilesURI =
  "/knowledge-base/:topLevelCategory/category/:category/document/:document/files";
export type KnowledgeBaseDocumentDetailsFilesParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsFilesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KnowledgeBaseDocumentDetailsContentRoute,
    path: KnowledgeBaseDocumentDetailsContentURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgeBaseDocumentDetails.Content" */ "./KnowledgeBaseDocumentDetails.Content.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        KnowledgeBaseTopLevelCategoryBreadCrumb(params),
        KnowledgeBaseCategoryBreadCrumb(params),
        KnowledgeBaseDocumentBreadCrumb(params),
      ],
    },
  },
  {
    name: KnowledgeBaseDocumentDetailsFilesRoute,
    path: KnowledgeBaseDocumentDetailsFilesURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgeBaseDocumentDetails.Files" */ "./KnowledgeBaseDocumentDetails.Files.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        KnowledgeBaseTopLevelCategoryBreadCrumb(params),
        KnowledgeBaseCategoryBreadCrumb(params),
        KnowledgeBaseDocumentBreadCrumb(params),
        {
          text: "Files",
          route: { name: KnowledgeBaseDocumentDetailsFilesRoute, params },
        },
      ],
    },
  },
];

// Kintin, Profile, AccountReferral, AccountReferralCode, KintinAccess, User, Person, KintinCheck, PhoneNumber, EmailAddress, Website, CallScript, Address, IntroducerCompany, IntroducerContact, Property, Lead, BrandedKinvault, BrandedKinvaultMembership, IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const RecordKintin = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}",
  method: "get",
  rootModel: Kintin,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});

// Adviser

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Adviser } from "@kinherit/sdk/model/adviser";

export const RecordAdviser = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/adviser/{adviser}",
  method: "get",
  rootModel: Adviser,
  parse: (adviser: Adviser) => ({
    params: {
      adviser,
      kintin: adviser.$data.kintin,
    },
  }),
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateXeroRepeatingInvoice } from "./create-xero-repeating-invoice.action";
import { DeleteSubscription } from "./delete-subscription.action";
import { ReadSubscription } from "./read-subscription.action";
import { UpdateSubscription } from "./update-subscription.action";

export const Portal2Subscriptions = DefineActionBus({
  name: "portal-2-subscriptions",
  actions: {
    ReadSubscription,
    UpdateSubscription,
    CreateXeroRepeatingInvoice,
    DeleteSubscription,
  },
});

import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  BrandedKinvaultMembership,
  Credential,
  EmailAddress,
  Profile,
  Role,
  User,
} from "@kinherit/sdk";

export interface ReadSessionMessage {
  [key: string]: never;
}

interface ReadSessionResponse {
  users: Array<User>;
  profiles: Array<Profile>;
  brandedKinvaultMembers: Array<BrandedKinvaultMembership>;
  brandedKinvaults: Array<BrandedKinvault>;
  roles: Array<Role>;
  emailAddresses: Array<EmailAddress>;
  credentials: Array<Credential>;
}

export const ReadSession = async (
  message: ReadSessionMessage,
): Promise<ReadSessionResponse> => {
  const request = Api.resource("auth", "/v2/auth/session/portal").method("get");
  const response = await request.result();

  return {
    users: User.$inflate(response.user, undefined, response.$rootIds),
    profiles: Profile.$inflate(response.profile),
    brandedKinvaultMembers: BrandedKinvaultMembership.$inflate(
      response.brandedKinvaultMembership,
    ),
    brandedKinvaults: BrandedKinvault.$inflate(response.brandedKinvault),
    roles: Role.$inflate(response.role),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    credentials: Credential.$inflate(response.credential),
  };
};

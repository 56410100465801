// BrandedKinvaultSubscriptionReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/branded-kinvault-subscription-referral-code";

export const ReadBrandedKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/branded-kinvault-subscription-referral-code",
  method: "get",
  rootModel: BrandedKinvaultSubscriptionReferralCode,
  parse: () => ({}),
});

// Guardian

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Guardian } from "@kinherit/sdk/model/guardian";

export const CreateGuardian = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/guardian",
  method: "post",
  parse: (target: Guardian) => ({
    body: {
      isReserve: target.$data.isReserve ?? false,
      includePartner: target.$data.includePartner ?? false,
      notes: target.$data.notes,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

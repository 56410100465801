// IntroducerFeePayment, IntroducerFeePaymentItem, XeroContact

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePayment } from "@kinherit/sdk/model/introducer-fee-payment";

export const RecordIntroducerFeePayment = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/fees/payment/{introducerFeePayment}",
  method: "get",
  rootModel: IntroducerFeePayment,
  parse: (introducerFeePayment: IntroducerFeePayment | string) => ({
    params: {
      introducerFeePayment,
    },
  }),
});

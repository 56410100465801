// Attorney

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Attorney } from "@kinherit/sdk/model/attorney";

export const RecordAttorney = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/attorney/{attorney}",
  method: "get",
  rootModel: Attorney,
  parse: (attorney: Attorney) => ({
    params: {
      attorney,
      kintin: attorney.$data.kintin,
    },
  }),
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailLogHandler } from "./create-email-log.action";
import { CreateEmailNamedAttachmentHandler } from "./create-email-named-attachment.action";
import { DeleteEmailLogAttachmentHandler } from "./delete-email-log-attachment.action";
import { DeleteEmailLogHandler } from "./delete-email-log.action";
import { DeleteEmailNamedAttachmentHandler } from "./delete-email-named-attachment.action";
import { ReadEmailLogEmailAddressHandler } from "./read-email-log-email-address.action";
import { ReadEmailLogHandler } from "./read-email-log.action";
import { ReadLegacyEmailLogHandler } from "./read-legacy-email-log.action";
import { SendEmailLogHandler } from "./send-email-log.action";
import { UpdateEmailLogHandler } from "./update-email-log.action";
import { UploadEmailLogAttachmentHandler } from "./upload-email-log-attachment.action";

export const CoreEmailLog = DefineActionBus({
  name: "core-email-log",
  actions: {
    CreateEmailLog: CreateEmailLogHandler,
    CreateEmailNamedAttachment: CreateEmailNamedAttachmentHandler,
    DeleteEmailLogAttachment: DeleteEmailLogAttachmentHandler,
    DeleteEmailLog: DeleteEmailLogHandler,
    DeleteEmailNamedAttachment: DeleteEmailNamedAttachmentHandler,
    ReadEmailLogEmailAddress: ReadEmailLogEmailAddressHandler,
    ReadEmailLog: ReadEmailLogHandler,
    ReadLegacyEmailLog: ReadLegacyEmailLogHandler,
    SendEmailLog: SendEmailLogHandler,
    UpdateEmailLog: UpdateEmailLogHandler,
    UploadEmailLogAttachment: UploadEmailLogAttachmentHandler,
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2SettingsAcuity } from "./acuity";
import { SortReferReconcile } from "./sortrefer-reconcile.action";
import { TestContactEndpoint } from "./test-contact-endpoint.action";
import { TestEmail } from "./test-email.action";

export const Portal2Settings = DefineActionBus({
  name: "portal-2-settings",
  actions: {
    acuity: Portal2SettingsAcuity,
    TestEmail,
    TestContactEndpoint,
    SortReferReconcile,
  },
});

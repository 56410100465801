import { Api } from "@kinherit/sdk/api";
import { Kintin, Person, User } from "@kinherit/sdk/index";

//
export const FillLpaHealth = async (message: {
  kintin: Kintin;
  person: Person;
  user: User;
}) => {
  const { person, user, kintin } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/process/person/{person}/specialist/{user}/lpa-health",
    {
      kintin: kintin,
      person: person,
      user: user,
    },
  )
    .method("get")
    .params({
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: ` ${kintin.ref} LPA Health - ${surname}`,
    });
};

import { Api } from "@kinherit/sdk/api";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/index";

// BrandedKinvaultInvitation
export const SendInvitationEmail = async ({
  invite,
  type,
}: {
  invite: BrandedKinvaultInvitation;
  type: "initial" | "followup1" | "followup2";
}) => {
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/admin/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}",
    {
      brandedKinvaultInvitation: invite,
      invitationType: type,
    },
  )
    .method("put")
    .result();

  const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
    response.brandedKinvaultInvitation,
  ).first();

  if (!brandedKinvaultInvitation) {
    throw new Error("Failed to send branded kinvault invitation email");
  }

  return {
    brandedKinvaultInvitation,
  };
};

import { SharedFormProps } from "@/config/form.config";
import { CreateSignDoc } from "@/config/model.config";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { Option, Product, SignDoc } from "@kinherit/sdk";
import { reactive } from "vue";

export const UpdateSignDocDisclaimerForm = async (
  products: Product[],
  data?: SignDoc,
) => {
  let showDefaultsButton = false;
  if (!data) {
    showDefaultsButton = true;
    data = CreateSignDoc();
  }

  const clauses = Option.$findBy({
    group: "disclaimerClause",
  }).sortBy("text");

  const conditions = Option.$findBy({
    group: "disclaimerCondition",
  }).sortBy("text");

  const localData = reactive({
    currentTab: 0,
  });

  return defineForm({
    name: "update-sign-doc-disclaimer-form",
    data: () => data as SignDoc,
    props: SharedFormProps,
    formAreas: (data) => [
      defineFormArea({
        name: "update-sign-doc-disclaimer-form-area",
        data,
        template: GridLayout(
          showDefaultsButton ? [["defaults"], ["options"]] : [["options"]],
        ),
        components: () => ({
          defaults: [
            FormButton({
              props: {
                text: "Load Defaults",
                reference: "loadDefaults",
              },
              emits: {
                click: async (value, data, controls) => {
                  const item = await OpenContextMenu({
                    items: [
                      { title: "Advised" },
                      { title: "Hosted" },
                      { title: "LPAs" },
                      { title: "Reset All" },
                    ],
                    titleField: "title",
                    trackingField: "title",
                  });

                  switch (item.title) {
                    case "Advised":
                      data.clauses.forEach((clause) =>
                        data.removeClauses(clause),
                      );

                      clauses.forEach((clause) => {
                        if (clause.value === "hosted") {
                          return;
                        }
                        data.addClauses(clause);
                      });

                      break;
                    case "Hosted":
                      data.clauses.forEach((clause) =>
                        data.removeClauses(clause),
                      );

                      clauses.forEach((clause) => {
                        if (clause.value !== "hosted") {
                          return;
                        }
                        data.addClauses(clause);
                      });

                      break;
                    case "LPAs":
                      data.clauses.forEach((clause) =>
                        data.removeClauses(clause),
                      );

                      clauses.forEach((clause) => {
                        if (
                          ![
                            "onlinesubmission",
                            "customersummary",
                            "notmisleading",
                            "signingprocess",
                            "paymentterms",
                            "waiver",
                            "boundaries",
                            "lpas",
                          ].includes(clause.value)
                        ) {
                          return;
                        }

                        data.addClauses(clause);
                      });
                      break;
                    case "Reset All":
                      data.products.forEach((product) =>
                        data.removeProducts(product),
                      );

                      data.conditions.forEach((condition) =>
                        data.removeConditions(condition),
                      );

                      data.clauses.forEach((clause) =>
                        data.removeClauses(clause),
                      );
                      break;
                  }

                  localData.currentTab = 0;
                  controls.incrementFormRenderKey();
                },
              },
            }),
          ],
          options: [
            FormTabs({
              props: {
                config: [
                  { label: "Clauses" },
                  { label: "Products" },
                  { label: "Conditions" },
                ],
                reference: "tabs",
              },
              models: {
                tab: {
                  get: () => localData.currentTab,
                  set: (value) => (localData.currentTab = value as number),
                },
              },
              slots: {
                clauses: clauses.map((c) =>
                  FormCheckboxField({
                    props: {
                      label: c.text,
                      reference: "clauses",
                    },
                    models: {
                      value: {
                        get: (data) => data.$data.clauses.includes(c.id),
                        set: (value, data, controls) => {
                          if (value) {
                            data.addClauses(c);
                          } else {
                            data.removeClauses(c);
                          }

                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ),
                products: products.map((p) =>
                  FormCheckboxField({
                    props: {
                      label: p.publicText,
                      reference: "products",
                    },
                    models: {
                      value: {
                        get: (data) => data.$data.products.includes(p.id),
                        set: (value, data, controls) => {
                          if (value) {
                            data.addProducts(p);
                          } else {
                            data.removeProducts(p);
                          }

                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ),
                conditions: conditions.map((c) =>
                  FormCheckboxField({
                    props: {
                      label: c.text,
                      reference: "conditions",
                    },
                    models: {
                      value: {
                        get: (data) => data.$data.conditions.includes(c.id),
                        set: (value, data, controls) => {
                          if (value) {
                            data.addConditions(c);
                          } else {
                            data.removeConditions(c);
                          }

                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ),
              },
            }),
          ],
        }),
      }),
    ],
  });
};

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadPhysicalStorageFileLog } from "./read-physical-storage-file-log.action";
import { UpdatePhysicalStorageFileLog } from "./update-physical-storage-file-log.action";

export const Portal2StorageRequests = DefineActionBus({
  name: "portal-2-storage-requests",
  actions: {
    ReadPhysicalStorageFileLog,
    UpdatePhysicalStorageFileLog,
  },
});

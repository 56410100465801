import {
  KintinTypeField,
  OptionsSelectField,
  UserField,
} from "@/config/form.config";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import { Kintin, User } from "@kinherit/sdk";

export const UpdateKintinSettingsForm = (kintin: Kintin) =>
  defineForm({
    name: "update-kintin-settings-form",
    data: () => kintin,
    formAreas: (data) => [
      defineFormArea({
        name: "update-kintin-settings-form-area",
        data,
        template: GridLayout([
          ["type", "process"],
          { is: "hr" },
          ["specialist", "legalAssistant"],
          { is: "hr" },
          ["isActive", "isEscalated", "statusUpdatedAt"],
        ]),
        components: () => ({
          type: [
            KintinTypeField({
              selectField: true,
              props: {
                label: "Account Type",
                validators: ["required"],
                vModel: "type",
                message:
                  "Warning, changing from joint -> single will remove the second owner.",
              },
            }),
          ],
          process: [
            OptionsSelectField({
              group: "process",
              vModel: "process",
            }),
          ],
          specialist: [
            UserField({
              props: {
                label: "Estate Planner",
                isMultiSelect: true,
                reference: "estatePlanners",
                vModel: {
                  get: (data) => data.estatePlanners,
                  set: (value: Array<User>, data) => {
                    const existingIds = data.estatePlanners.pluck("id");
                    const newIds = value.pluck("id");

                    value.forEach((user) => {
                      if (!existingIds.includes(user.id)) {
                        data.addEstatePlanners(user);
                      }
                    });

                    data.estatePlanners.forEach((user) => {
                      if (!newIds.includes(user.id)) {
                        data.removeEstatePlanners(user);
                      }
                    });
                  },
                },
                placeholder: "Any",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          legalAssistant: [
            UserField({
              props: {
                label: "Legal Assistant",
                isMultiSelect: true,
                reference: "legalAssistants",
                vModel: {
                  get: (data) => data.legalAssistants,
                  set: (value: Array<User>, data) => {
                    const existingIds = data.legalAssistants.pluck("id");
                    const newIds = value.pluck("id");

                    value.forEach((user) => {
                      if (!existingIds.includes(user.id)) {
                        data.addLegalAssistants(user);
                      }
                    });

                    data.legalAssistants.forEach((user) => {
                      if (!newIds.includes(user.id)) {
                        data.removeLegalAssistants(user);
                      }
                    });
                  },
                },
                placeholder: "Any",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          isActive: [
            FormSwitchField({
              props: {
                label: "Account is Active",
                message:
                  "If the account is inactive, it may be hidden in certain circumstances.",
              },
              models: {
                value: "isActive",
              },
            }),
          ],
          isEscalated: [
            FormSwitchField({
              props: {
                label: "Escalated",
                message: "Urgent and priority account",
              },
              models: {
                value: "isEscalated",
              },
            }),
          ],
          statusUpdatedAt: [
            FormDateField({
              props: {
                label: "Status Updated At",
                message:
                  "Manual override for Status Last Updated At; changing this will affect autochaser schedule",
              },
              models: {
                value: "statusUpdatedAt",
              },
            }),
          ],
        }),
      }),
    ],
  });

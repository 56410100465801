// Notification, User, Note, Profile, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Notification } from "@kinherit/sdk/model/notification";

export const ReadNotification = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/dashboard/notifications",
  method: "get",
  rootModel: Notification,
  parse: () => ({}),
});

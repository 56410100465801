import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerContract } from "./create-introducer-contract.action";
import { DeleteIntroducerContract } from "./delete-introducer-contract.action";
import { ReadIntroducerContract } from "./read-introducer-contract.action";
import { RecordIntroducerContract } from "./record-introducer-contract.action";
import { UpdateIntroducerContract } from "./update-introducer-contract.action";

export const Portal2IntroducerContract = DefineActionBus({
  name: "portal-2-introducer-contract",
  actions: {
    CreateIntroducerContract,
    DeleteIntroducerContract,
    RecordIntroducerContract,
    UpdateIntroducerContract,
    ReadIntroducerContract,
  },
});

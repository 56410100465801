// BrandedKinvaultSubscriptionReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/branded-kinvault-subscription-referral-code";

export const UpdateBrandedKinvaultSubscriptionReferralCode = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
  method: "patch",
  parse: (target: BrandedKinvaultSubscriptionReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      brandedKinvaultSubscriptionReferralCode: target,
    },
  }),
});

// BrandedKinvault

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";

export const UpdateBrandedKinvault = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/{brandedKinvault}",
  method: "patch",
  parse: (target: BrandedKinvault) => ({
    body: {
      memberCount: target.$data.memberCount,
      frontendUrl: target.$data.frontendUrl,
      notes: target.$data.notes,
      companyUrl: target.$data.companyUrl,
      systemEmail: target.$data.systemEmail,
      emailLogo: target.$data.emailLogo,
      restrictUserEmailDomain: target.$data.restrictUserEmailDomain,
      allowedEmailDomains: target.$data.allowedEmailDomains,
      redirectUrl: target.$data.redirectUrl,
      useBrandedKinvaultInvitations: target.$data.useBrandedKinvaultInvitations,
      useUserInvitations: target.$data.useUserInvitations,
      useSetupWizard: target.$data.useSetupWizard,
      allowPublicRegistration: target.$data.allowPublicRegistration,
      allowDefaultAssetAccess: target.$data.allowDefaultAssetAccess,
      profile: target.$data.profile,
      kinvaultTheme: target.$data.kinvaultTheme,
      type: target.$data.type,
      clientPoolType: target.$data.clientPoolType,
      siteMode: target.$data.siteMode,
      xeroContact: target.$data.xeroContact,
    },
    params: {
      brandedKinvault: target,
    },
  }),
});

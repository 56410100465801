import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerOutsource } from "./create-introducer-outsource.action";
import { DeleteIntroducerOutsource } from "./delete-introducer-outsource.action";
import { Portal2IntroducerOutsourcesNote } from "./notes";
import { ReadIntroducerOutsource } from "./read-introducer-outsource.action";
import { RecordIntroducerOutsource } from "./record-introducer-outsource.action";
import { UpdateIntroducerOutsource } from "./update-introducer-outsource.action";

export const Portal2IntroducerOutsources = DefineActionBus({
  name: "portal-2-introducer-outsources",
  actions: {
    CreateIntroducerOutsource,
    ReadIntroducerOutsource,
    RecordIntroducerOutsource,
    DeleteIntroducerOutsource,
    UpdateIntroducerOutsource,
    notes: Portal2IntroducerOutsourcesNote,
  },
});

<template>
  <div class="introducer-company-details-billing">
    <PageHeader htag="h2" text="Billing Contacts" class="mt-4">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="$emit('create-billing-contact')"
        >
          Create Billing Contact
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      :columns="columns"
      :rows="rows"
      is-nested
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsBillingWrapper",
  "route": "IntroducerCompanyDetailsBilling",
  "selector": ".introducer-company-details-billing",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "UpdateProfileFormWrapper": "@/shared/form/profile/update-profile.form.test"
  },
  "methods": {
    "createBillingContact": {
      "type": "click",
      "selector": ".create"
    },
    "createBillingContactForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateProfileFormWrapper"
    },
    "entries": {
      "type": "to-many",
      "selector": "tbody",
      "wrapper": "IntroducerCompanyDetailsBillingRowWrapper"
    }
  }
}
</cypress-wrapper>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsBillingRowWrapper",
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": "",
      "items": ["Edit Billing Profile", "Edit Remittance Settings", "Delete"]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateIntroducerBillingContactForm } from "@/module/introducer.company/form/update-introducer-billing-contact.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Equal } from "@kinherit/orm/index";
import {
  IIntroducerBillingContact,
  IntroducerBillingContact,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsBillingRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsBillingRoute,
  mixins: [
    IntroducerCompanyDetailsMixin,
    AuthService.mixin({ sessionRequired: true }),
  ],
  emits: ["create-billing-contact"],
  components: {
    MasterListPage,
    PageHeader,
    Button,
  },
  data: () => ({
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: IntroducerBillingContact) =>
          v.profile.fullName ??
          v.profile.organisationName ??
          "-> Unknown profile?",
      },
      {
        title: "Email",
        map: (v: IntroducerBillingContact) =>
          v.profile.emails.pluck("email").join(", "),
      },
      {
        title: "Global",
        map: (v: IntroducerBillingContact) => (v.isGlobal ? "Yes" : "No"),
      },
      {
        title: "Introducer Remittance",
        map: (v) => v.introducerRemittanceType?.text ?? "-",
      },
      {
        title: "Agent Remittance",
        map: (v) => v.agentRemittanceType?.text ?? "-",
      },
    ] as Array<TableColumn<IntroducerBillingContact>>,
    rows: Array<IntroducerBillingContact>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerBillingContact,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(): Promise<void> {
      const { introducerBillingContact, $pagination } =
        await this.$actionBus.billingContacts.ReadIntroducerBillingContacts({
          query: {
            company: {
              id: Equal(this.$params.introducerCompany),
            },
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = introducerBillingContact;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    async handleClick(contact: IntroducerBillingContact): Promise<void> {
      if (!this.hasWritePermission) {
        return;
      }

      const contextMenu = this.$auth.openContextMenu({
        request: {
          items: [
            {
              label: `Edit Billing Profile`,
              line1: `Make changes to ${
                contact.profile.fullName ?? `this billing contact`
              }`,
              icon: StyleService.icon.edit.icon,
              action: this.updateProfile,
              permissions: ["introducer-company:write"],
            },
            {
              label: `Edit Remittance Settings`,
              line1: `Change Settings for ${
                contact.profile.fullName ?? `this contact`
              }`,
              icon: StyleService.icon.settings.icon,
              action: this.updateSettings,
              permissions: ["introducer-company:write"],
            },
            {
              label: `Delete`,
              line1: `Remove ${
                contact.profile.fullName ?? `this billing contact`
              } from this company`,
              icon: StyleService.icon.delete.icon,
              action: this.delete,
              permissions: ["introducer-company:write"],
            },
          ],
          titleField: "label",
          trackingField: "label",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(contact),
      });

      await contextMenu();
      await this.refresh();
    },
    async updateProfile(contact: IntroducerBillingContact): Promise<void> {
      if (!this.$auth.hasPermission("introducer-company:write")) {
        return;
      }

      if (!this.introducerCompany) {
        return;
      }

      try {
        await UpdateProfileForm(contact.profile).dialog({
          dialog: {
            title: "Update Billing Profile",
          },
        });
      } catch (e) {
        contact.profile.$restore();
        throw e;
      }

      // await window.Kernel.ActionBus.introducerCompany.billingContacts.updateProfile(
      //   {
      //     introducerCompany: this.introducerCompany,
      //     introducerBillingContact: contact,
      //   },
      // );
      await this.$actionBus.billingContacts.UpdateIntroducerBillingContactProfile(
        contact,
      );

      await this.refresh();
    },
    async updateSettings(contact: IntroducerBillingContact): Promise<void> {
      if (!this.$auth.hasPermission("introducer-company:write")) {
        return;
      }

      try {
        await UpdateIntroducerBillingContactForm(contact).dialog({
          dialog: {
            title: "Update Settings",
          },
        });
      } catch (e) {
        contact.$restore();
        throw e;
      }

      if (!this.introducerCompany) {
        return;
      }

      // await window.Kernel.ActionBus.introducerCompany.billingContacts.update({
      //   introducerCompany: this.introducerCompany,
      //   introducerBillingContact: contact,
      // });
      await this.$actionBus.billingContacts.UpdateIntroducerBillingContact(
        contact,
      );

      await this.refresh();
    },
    async delete(contact: IntroducerBillingContact): Promise<void> {
      if (!this.$auth.hasPermission("introducer-company:write")) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Delete ${contact.profile.fullName}`,
          message: `Are you sure you want to delete ${contact.profile.fullName}?`,
        },
      });

      if (!this.introducerCompany) {
        return;
      }

      // await window.Kernel.ActionBus.introducerCompany.billingContacts.delete({
      //   introducerCompany: this.introducerCompany,
      //   introducerBillingContact: contact,
      // });
      await this.$actionBus.billingContacts.DeleteIntroducerBillingContact(
        contact,
      );

      await this.refresh();
    },
  },
});
</script>

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateExclusion } from "./create-exclusion.action";
import { DeleteExclusion } from "./delete-exclusion.action";
import { ReadExclusion } from "./read-exclusion.action";
import { RecordExclusion } from "./record-exclusion.action";
import { UpdateExclusion } from "./update-exclusion.action";

export const Portal2KinvaultExclusion = DefineActionBus({
  name: "portal-2-kinvault-exclusion",
  actions: {
    CreateExclusion,
    DeleteExclusion,
    ReadExclusion,
    UpdateExclusion,
    RecordExclusion,
  },
});

// Trustee

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Trustee } from "@kinherit/sdk/model/trustee";

export const RecordTrustee = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/trustee/{trustee}",
  method: "get",
  rootModel: Trustee,
  parse: (trustee: Trustee) => ({
    params: {
      trustee,
      kintin: trustee.$data.kintin,
    },
  }),
});

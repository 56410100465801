// Person

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Person } from "@kinherit/sdk/model/person";

export const UpdatePerson = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/person/{person}",
  method: "patch",
  parse: (target: Person) => ({
    body: {
      dateOfBirth: target.$data.dateOfBirth,
      dateOfDeath: target.$data.dateOfDeath,
      hasFormerAddresses: target.$data.hasFormerAddresses,
      requiresCare: target.$data.requiresCare,
      formerNames: target.$data.formerNames,
      nationalInsuranceNumber: target.$data.nationalInsuranceNumber,
      socialSecurityNumber: target.$data.socialSecurityNumber,
      notifications: target.$data.notifications,
      type: target.$data.type,
      draftPerson: target.$data.draftPerson,
      draftProfile: target.$data.draftProfile,
      draftAddresses: target.$data.draftAddresses,
      draftEmailAddresses: target.$data.draftEmailAddresses,
      draftPhoneNumbers: target.$data.draftPhoneNumbers,
      isTrusted: target.$data.isTrusted,
      notificationDate: target.$data.notificationDate,
      under18ForPlanningPurposes: target.$data.under18ForPlanningPurposes,
      notifyOnDeath: target.$data.notifyOnDeath,
      kintin: target.$data.kintin,
      profile: target.$data.profile,
      gender: target.$data.gender,
      maritalStatus: target.$data.maritalStatus,
      officerCallStatus: target.$data.officerCallStatus,
      credential: target.$data.credential,
      relationToPrimaryPerson: target.$data.relationToPrimaryPerson,
      relationToSecondaryPerson: target.$data.relationToSecondaryPerson,
    },
    params: {
      person: target,
      kintin: target.$data.kintin!,
    },
  }),
});

import { Api } from "@kinherit/sdk";

export interface CreateSortReferNoteMessage {
  externalId: string;
  note: string;
}

interface CreateSortReferNoteResponse {}

export const CreateSortReferNote = async (
  message: CreateSortReferNoteMessage,
): Promise<CreateSortReferNoteResponse> => {
  const request = Api.resource("external", "/v2/external/sort-refer/note")
    .method("post")
    .body({
      externalId: message.externalId,
      note: message.note,
    });

  const response = await request.result();

  return response;
};

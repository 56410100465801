import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteKnowledgeBaseDocument } from "./delete-knowledge-base-document.action";
import { RecordKnowledgeBaseDocument } from "./record-knowledge-base-document.action";
import { UpdateKnowledgeBaseDocument } from "./update-knowledge-base-document.action";
import { Portal2KnowledgeBaseCategoryDocumentFileLog } from "@/action-bus/portal-2/knowledge-base/category/document/file-log";

export const Portal2KnowledgeBaseCategoryDocument = DefineActionBus({
  name: "portal-2-knowledge-base-category-document",
  actions: {
    DeleteKnowledgeBaseDocument,
    RecordKnowledgeBaseDocument,
    UpdateKnowledgeBaseDocument,
    fileLog: Portal2KnowledgeBaseCategoryDocumentFileLog,
  },
});

// KintinCheck, Note

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KintinCheck } from "@kinherit/sdk/model/kintin-check";

export const UpdateKintinCheck = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/kintin-check/{kintinCheck}",
  method: "patch",
  parse: (target: KintinCheck) => ({
    body: {
      step: target.$data.step,
      checkValue: target.$data.checkValue,
      checkType: target.$data.checkType,
      kintin: target.$data.kintin,
      note: {
        name: target.note.$data.name,
        type: target.note.$data.type,
        completedAt: target.note.$data.completedAt,
        dueAt: target.note.$data.dueAt,
        notes: target.note.$data.notes,
        pinned: target.note.$data.pinned,
        kintin: target.note.$data.kintin,
        lead: target.note.$data.lead,
        introducerOutsource: target.note.$data.introducerOutsource,
        company: target.note.$data.company,
        contact: target.note.$data.contact,
        person: target.note.$data.person,
        isPublic: target.note.$data.isPublic,
      },
    },
    params: {
      kintinCheck: target,
      kintin: target.$data.kintin,
    },
  }),
});

// Adviser

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Adviser } from "@kinherit/sdk/model/adviser";

export const UpdateAdviser = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/adviser/{adviser}",
  method: "patch",
  parse: (target: Adviser) => ({
    body: {
      defaultTrustIfa: target.$data.defaultTrustIfa,
      notes: target.$data.notes,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      adviser: target,
      kintin: target.$data.kintin,
    },
  }),
});

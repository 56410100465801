// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const DeleteSubscription = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/subscription/{subscription}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    subscription: Subscription | string;
  }) => ({
    params: {
      kintin: message.kintin,
      subscription: message.subscription,
    },
  }),
  after: ({ message }) => {
    Subscription.$delete(message.subscription);
  },
});

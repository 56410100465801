// EmailCampaign, Profile

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";

export const DeleteEmailCampaign = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/email-campaign/{emailCampaign}",
  method: "delete",
  parse: (emailCampaign: EmailCampaign | string) => ({
    params: {
      emailCampaign,
    },
  }),
  after: ({ message }) => {
    EmailCampaign.$delete(message);
  },
});

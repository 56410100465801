// AccountReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const CreateAccountReferralCode = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/account-referral-codes",
  method: "post",
  parse: (target: AccountReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      isForUsers: target.$data.isForUsers,
      isFeeSacrifice: target.$data.isFeeSacrifice,
      isSalarySacrifice: target.$data.isSalarySacrifice,
      isFriendsAndFamily: target.$data.isFriendsAndFamily,
      notes: target.$data.notes,
      referralCount: target.$data.referralCount,
      company: target.$data.company,
      category: target.$data.category,
    },
  }),
  after: ({ message }) => {
    AccountReferralCode.$delete(message);
  },
});

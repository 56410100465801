<template>
  <div class="kintin-details-address-book-attorneys">
    <PageHeader htag="h2" text="Attorneys" v-if="kintin">
      <template #buttons>
        <Button
          text="Add Attorney"
          @click="createAttorney"
          class="add-button"
        />
        <Button
          :text="sorting ? 'Done' : 'Sort'"
          @click="togglingSorting"
          class="sort-button"
          v-if="!isPeopleTab"
        />
        <Button
          v-if="kintin.type.value === 'joint'"
          text="Copy To"
          @click="copy"
          class="copy-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        v-if="!sorting"
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4 person-tabs"
      />
      <div v-if="!isPeopleTab">
        <Title size="is-6" is-emphasised>Main Attorneys</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.attorneys.main"
        />
        <AttorneyList
          v-else
          class="main-attorneys"
          :kintin="kintin"
          :attorneys="$data.filtered.attorneys.main"
          @reload="filter"
        />
        <Title size="is-6" is-emphasised>Reserve Attorneys</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.attorneys.reserve"
        />
        <AttorneyList
          v-else
          class="reserve-attorneys"
          :kintin="kintin"
          :attorneys="$data.filtered.attorneys.reserve"
          @reload="filter"
        />
        <Title size="is-6" is-emphasised>Certificate Provider</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.attorneys.certProv"
        />
        <AttorneyList
          v-else
          class="cert-prov-attorneys"
          :kintin="kintin"
          :attorneys="$data.filtered.attorneys.certProv"
          @reload="filter"
        />
      </div>
      <div v-else>
        <PersonList
          :key="renderKey"
          :kintin="kintin"
          :people="$data.filtered.people"
          @reload="filter"
        />
      </div>
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AttorneysContainerWrapper",
  "selector": ".kintin-details-address-book-attorneys",
  "imports": {
    "AttorneyListWrapper": "@/module/kinvault.kintin/component/lists/AttorneyList.test",
    "PersonListWrapper": "@/module/kinvault.kintin/component/lists/PersonList.test",
    "UpdateAttorneyFormWrapper": "@/module/kinvault.kintin/form/update-attorney.form.test"
  },
  "methods": {
    "tabs": {
      "type": "indexed-tabs",
      "selector": ".person-tabs"
    },
    "attorneyList": {
      "type": "to-one",
      "selector": ".main-attorneys",
      "wrapper": "AttorneyListWrapper"
    },
    "reserveAttorneyList": {
      "type": "to-one",
      "selector": ".reserve-attorneys",
      "wrapper": "AttorneyListWrapper"
    },
    "certProvAttorneyList": {
      "type": "to-one",
      "selector": ".cert-prov-attorneys",
      "wrapper": "AttorneyListWrapper"
    },
    "personList": {
      "type": "to-one",
      "selector": ".person-list",
      "wrapper": "PersonListWrapper"
    },
    "addAttorney": {
      "type": "click",
      "selector": ".add-button"
    },
    "addAttorneyForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateAttorneyFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateAttorney } from "@/config/model.config";
import AttorneyList from "@/module/kinvault.kintin/component/lists/AttorneyList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdateAttorneyForm } from "@/module/kinvault.kintin/form/update-attorney.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Attorney, Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { CopyRolesForm } from "../../form/copy-roles.form";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `AttorneysContainer`,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    Tabs,
    Json,
    AttorneyList,
    Button,
    PageHeader,
    PersonList,
    Title,
    SortRoles,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      attorneys: {
        main: Array<Attorney>(),
        reserve: Array<Attorney>(),
        certProv: Array<Attorney>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    renderKey: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const attorneys = Attorney.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin?.primaryPerson?.id,
                this.kintin?.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      this.filtered.attorneys = attorneys.groupBy({
        main: (b) => !b.isReserve && !b.isCertProv,
        reserve: (b) => b.isReserve && !b.isCertProv,
        certProv: (b) =>
          b.isCertProv !== null &&
          b.isCertProv !== undefined &&
          b.isCertProv === true,
      });

      this.filtered.attorneys.main =
        this.filtered.attorneys.main.sortBy("sortOrder");
      this.filtered.attorneys.reserve =
        this.filtered.attorneys.reserve.sortBy("sortOrder");
      this.filtered.attorneys.certProv =
        this.filtered.attorneys.certProv.sortBy("sortOrder");

      this.filtered.people = attorneys
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");

      this.renderKey++;

      this.$emit("reload");
    },
    async createAttorney() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const attorney = CreateAttorney({
        forPerson,
        kintin: this.kintin.id,
      });

      try {
        await UpdateAttorneyForm({
          data: attorney,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create attorney",
          },
        });
      } catch {
        attorney.$delete();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.attorney.create({
      //   attorney,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.attorney.CreateAttorney(attorney);

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.attorneys.main,
          ...this.$data.filtered.attorneys.reserve,
          ...this.$data.filtered.attorneys.certProv,
        ].forEachAsync((attorney) =>
          // window.Kernel.ActionBus.kinvaultKintin.attorney.update({
          //   attorney,
          //   kintin: this.kintin,
          // }),
          this.$actionBus.attorney.UpdateAttorney(attorney),
        );
      }

      this.sorting = !this.sorting;
    },
    async copy() {
      const forPerson =
        this.selectedTab === 0
          ? this.kintin.secondaryPerson
          : this.kintin.primaryPerson;

      let name: string = "";

      if (forPerson?.profile.fullName) {
        name = forPerson?.profile.fullName;
      }

      const type =
        this.selectedTab === 0 ? "secondary person" : "primary person";

      name = name === "" ? type : `${name} (${type})`;

      const { rolesToCopy } = await CopyRolesForm(
        [
          ...this.$data.filtered.attorneys.main,
          ...this.$data.filtered.attorneys.reserve,
          ...this.$data.filtered.attorneys.certProv,
        ].filter((a) => a.person.id !== forPerson?.id),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy to ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (attorney) => {
        const newAttorney = CreateAttorney({
          ...attorney.$data,
          forPerson: forPerson?.id,
          kintin: this.kintin.id,
        });

        // await window.Kernel.ActionBus.kinvaultKintin.attorney.create({
        //   attorney: newAttorney,
        //   kintin: this.kintin,
        // });

        await this.$actionBus.attorney.CreateAttorney(newAttorney);
      });

      this.selectedTab = this.selectedTab === 0 ? 1 : 0;
      this.filter();
    },
  },
});
</script>

// Tag

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Tag } from "@kinherit/sdk/model/tag";

export const ReadTag = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/tag",
  method: "get",
  rootModel: Tag,
  parse: () => ({}),
});

// Person, Trustee, Executor, Guardian, Beneficiary, Attorney, Adviser, Profile, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Person } from "@kinherit/sdk/model/person";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const RecordEmailLog = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/email-log",
  method: "get",
  rootModel: Person,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});

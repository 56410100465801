// Product

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Product } from "@kinherit/sdk/model/product";

export const ReadProduct = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/product",
  method: "get",
  rootModel: Product,
  parse: () => ({}),
});

<template>
  <Card v-if="$data.form" class="introducer-company-details-update-profile">
    <FormControls
      :form="$data.form"
      mode="update"
      :title="$data.form.localData.fullName ?? undefined"
      @cancel="$router.back"
      @save="save"
      :delete-button="false"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!introducerCompany" />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsUpdateProfileWrapper",
  "route": "IntroducerCompanyDetailsUpdateProfile",
  "selector": ".introducer-company-details-update-profile",
  "imports": {
    "UpdateProfileFormWrapper": "@/shared/form/profile/update-profile.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "form",
      "wrapper": "UpdateProfileFormWrapper"
    },
    "save": {
      "type": "click",
      "selector": ".save-button"
    },
    "cancel": {
      "type": "click",
      "selector": ".cancel-button"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { IntroducerCompanyDetailsMixin } from "@/module/introducer.company/mixin/introducer-company-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsUpdateProfileRoute } from ".";

export default defineComponent({
  name: IntroducerCompanyDetailsUpdateProfileRoute,
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: ["introducer-company:write"],
    }),
    IntroducerCompanyDetailsMixin,
  ],
  components: {
    PageNotFound,
    Card,
    FormControls,
  },
  data: () => ({
    form: null as null | FormBuilder<() => Profile, string>,
    profile: null as null | Profile,
  }),
  mounted(): void {
    if (null === this.introducerCompany) {
      return;
    }

    this.profile = this.introducerCompany.profile;
    this.form = UpdateProfileForm(this.profile as Profile);
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.introducerCompany || null === this.profile) {
        return;
      }
      // await window.Kernel.ActionBus.introducerCompany.profile.update({
      await this.$actionBus.company.UpdateIntroducerCompanyProfile(
        this.introducerCompany,
      );

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.form?.localData.$restore;
    },
  },
});
</script>

import { KnowledgeBaseCategory } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KnowledgeBaseCategoryDetailsParams } from "../page";

export const KnowledgeBaseCategoryMixin = () =>
  defineComponent({
    name: "KnowledgeBaseCategoryMixin",
    computed: {
      $params(): KnowledgeBaseCategoryDetailsParams {
        return this.$route.params as KnowledgeBaseCategoryDetailsParams;
      },
      knowledgeBaseCategory(): KnowledgeBaseCategory | null {
        return KnowledgeBaseCategory.$findOne(this.$params.category);
      },
      knowledgeBaseTopLevelCategory(): KnowledgeBaseCategory | null {
        return KnowledgeBaseCategory.$findOne(this.$params.topLevelCategory);
      },
    },
  });

import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthLoginRoute } from "@/module/auth/page";
import { Store } from "@kinherit/orm";
import { nextTick } from "vue";

interface LogoutSessionMessage {
  [key: string]: never;
}

interface LogoutSessionResponse {
  [key: string]: never;
}

export const LogoutSessionHandler = async (
  message: LogoutSessionMessage,
): Promise<LogoutSessionResponse> => {
  Store.purge();
  ActiveSession.$getInstance()?.$delete();
  nextTick(() => {
    window.Kernel.ComponentOptions.Navbar.callRefresh();
    window.Kernel.visitRoute({
      name: AuthLoginRoute,
    });
  });

  return {};
};

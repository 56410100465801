// EmailTemplate

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const DeleteEmailTemplate = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/email-template/{emailTemplate}",
  method: "delete",
  parse: (emailTemplate: EmailTemplate | string) => ({
    params: {
      emailTemplate,
    },
  }),
  after: ({ message }) => {
    EmailTemplate.$delete(message);
  },
});

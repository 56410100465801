// CreditNote, Note

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { CreditNote } from "@kinherit/sdk/model/credit-note";

export const UpdateCreditNote = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/credit-note/{creditNote}",
  method: "patch",
  parse: (target: CreditNote) => ({
    body: {
      initialValue: target.$data.initialValue,
      remainingValue: target.$data.remainingValue,
      issuedAt: target.$data.issuedAt,
      relatedOrder: target.$data.relatedOrder,
      note: {
        completedAt: target.note.$data.completedAt,
        dueAt: target.note.$data.dueAt,
        kintin: target.note.$data.kintin,
        name: target.note.$data.name,
        notes: target.note.$data.notes,
        pinned: target.note.$data.pinned,
        type: "creditNote" as const,
      },
      relatedKintin: target.$data.relatedKintin,
      issuedBy: target.$data.issuedBy,
      relatedUser: target.$data.relatedUser,
    },
    params: {
      creditNote: target,
    },
  }),
});

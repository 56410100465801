import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadTest } from "./read-test.action";
import { Reconcile } from "./reconcile.action";
import { RecordAppointment } from "./record-appointment.action";
import { RecordKintin } from "./record-kintin.action";
import { RecordLead } from "./record-lead.action";
import { Type } from "./type.action";
import { UpdateAppointment } from "./update-appointment.action";

export const Portal2Appointments = DefineActionBus({
  name: "portal-2-appointments",
  actions: {
    AppointmentTypes: Type,
    UpdateAppointment,
    ReadAppointments: ReadTest,
    RecordKintin,
    RecordLead,
    Reconcile,
    RecordAppointment,
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBeneficiary } from "./create-beneficiary.action";
import { DeleteBeneficiary } from "./delete-beneficiary.action";
import { ReadBeneficiary } from "./read-beneficiary.action";
import { RecordBeneficiary } from "./record-beneficiary.action";
import { UpdateBeneficiary } from "./update-beneficiary.action";

export const Portal2KinvaultBeneficiary = DefineActionBus({
  name: "portal-2-kinvault-beneficiary",
  actions: {
    CreateBeneficiary,
    DeleteBeneficiary,
    ReadBeneficiary,
    UpdateBeneficiary,
    RecordBeneficiary,
  },
});

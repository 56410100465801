import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultPricingSchema } from "./create-branded-kinvault-pricing-schema.action";
import { DeleteBrandedKinvaultPricingSchema } from "./delete-branded-kinvault-pricing-schema.action";
import { ReadBrandedKinvaultPricingSchema } from "./read-branded-kinvault-pricing-schema.action";
import { UpdateBrandedKinvaultPricingSchema } from "./update-branded-kinvault-pricing-schema.action";

export const Portal2AdminBrandedKinvaultKinvaultPricingSchema = DefineActionBus(
  {
    name: "portal-2-admin-branded-kinvault-kinvault-pricing-schema",
    actions: {
      ReadBrandedKinvaultPricingSchema,
      CreateBrandedKinvaultPricingSchema,
      DeleteBrandedKinvaultPricingSchema,
      UpdateBrandedKinvaultPricingSchema,
    },
  },
);

// BrandedKinvaultMembership, Kintin, User

import { Api } from "@/service/api.service";
import { BrandedKinvaultMembership, Kintin, User } from "@kinherit/sdk";

export interface KintinUserJoinDefaultBrandedKinvaultMessage {
  kintin: Kintin;
  user: User;
}

export interface KintinUserJoinDefaultBrandedKinvaultResponse {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

export const JoinDefaultBrandedKinvault = async (
  message: KintinUserJoinDefaultBrandedKinvaultMessage,
): Promise<KintinUserJoinDefaultBrandedKinvaultResponse> => {
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/user/{user}/join-default-branded-kinvault",
    {
      kintin: message.kintin.id,
      user: message.user.id,
    },
  )
    .method("put")

    .result();

  const brandedKinvaultMembership = BrandedKinvaultMembership.$inflate(
    response.brandedKinvaultMembership,
  ).first();

  if (!brandedKinvaultMembership) {
    throw new Error("BrandedKinvault membership not found");
  }

  return {
    brandedKinvaultMembership,
  };
};

// AccountReferralCode, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const ReadAccountReferralCodes = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/contact/referral/{introducerContact}/codes",
  method: "get",
  rootModel: AccountReferralCode,
  parse: (introducerContact: IntroducerContact | string) => ({
    params: {
      introducerContact,
    },
  }),
});

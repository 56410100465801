<template>
  <Message
    v-if="!accountReferral"
    color="is-warning"
    text="No Account Referral available?"
  />
  <UploadFormPage v-if="accountReferral" :accountReferral="accountReferral" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsUploadFormWrapper",
  "route": "KintinDetailsUploadForm",
  "extends": {
    "name": "UploadFormPageWrapper",
    "path": "@/shared/component/upload-form/UploadFormPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import UploadFormPage from "@/shared/component/upload-form/UploadFormPage.vue";
import Message from "@kinherit/framework/component.display/message";
import { AccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsUploadFormRoute } from ".";

export default defineComponent({
  name: KintinDetailsUploadFormRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    UploadFormPage,
    Message,
  },
  computed: {
    accountReferral(): AccountReferral | null {
      return this.kintin?.referral ?? null;
    },
  },
});
</script>

import { Api } from "@kinherit/sdk/api";
import { Kintin } from "@kinherit/sdk/index";

// BrandedKinvaultMembership
export const RevokeBrandedKinvaultAccess = async (kintin: Kintin) => {
  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/status/revoke-branded-kinvault-access",
    { kintin },
  )
    .method("put")
    .result();
};

import {
  BrandedKinvaultDetailsParams,
  BrandedKinvaultDetailsRoute,
} from "@/module/admin.branded-kinvault/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { BrandedKinvault } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const BrandedKinvaultDetailsMixin = defineComponent({
  name: "branded-kinvault-details-mixin",
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.brandedKinvault,
    ),
  ],
  computed: {
    $params(): BrandedKinvaultDetailsParams {
      return this.$route.params as any;
    },
    brandedKinvault(): BrandedKinvault | null {
      return BrandedKinvault.$findOne(this.$params.brandedKinvault);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("branded-kinvault:write");
    },
  },
  methods: {
    visitBrandedKinvaultDetails(): void {
      window.Kernel.Router.push({
        name: BrandedKinvaultDetailsRoute,
        params: { lead: this.brandedKinvault?.id },
      });
    },
  },
});

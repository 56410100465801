// Role

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Role } from "@kinherit/sdk/model/role";

export const ReadRole = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/role",
  method: "get",
  rootModel: Role,
  parse: () => ({}),
});

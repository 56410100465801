// EmailTemplate

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const ReadEmailTemplate = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/email-template",
  method: "get",
  rootModel: EmailTemplate,
  parse: () => ({}),
});

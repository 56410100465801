// KnowledgeBaseCategory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const ReadKnowledgeBaseCategory = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/knowledge-base-category",
  method: "get",
  rootModel: KnowledgeBaseCategory,
  parse: () => ({}),
});

import { Api } from "@/service/api.service";
import { IntroducerCompany } from "@kinherit/sdk";

interface ComputeIntroducerCompanyStatsMessage {
  introducerCompany: IntroducerCompany;
}

interface ComputeIntroducerCompanyStatsResponse {
  lastLead?: string | undefined;
  lifeTimeValue: number;
  lifeTimeAvgValue: number;
  totalContacts: number;
  totalContracts: number;
  totalLeads: number;
  quarters: {
    name: "Q1" | "Q2" | "Q3" | "Q4" | "QTD" | "Last 12 Months";
    year: string;
    totalLeads: number;
    convertedToCall1: number;
    totalSales: number;
    averagePriceOfSale: number;
  }[];
}

export const ComputeIntroducerCompanyStats = async (
  message: ComputeIntroducerCompanyStatsMessage,
): Promise<ComputeIntroducerCompanyStatsResponse> => {
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/company/{introducerCompany}/stats",
    {
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
    },
  )
    .method("get")
    .result();

  return response;
};

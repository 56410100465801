// BrandedKinvaultSubscriptionReferral

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk/model/branded-kinvault-subscription-referral";

export const DeleteBrandedKinvaultSubscriptionReferral = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/subscription-referral/{brandedKinvaultSubscriptionReferral}",
  method: "delete",
  parse: (
    brandedKinvaultSubscriptionReferral:
      | BrandedKinvaultSubscriptionReferral
      | string,
  ) => ({
    params: {
      brandedKinvaultSubscriptionReferral,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultSubscriptionReferral.$delete(message);
  },
});

// Notification, Note, User, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Notification } from "@kinherit/sdk/model/notification";

export const ReadNotifications = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/log",
  method: "get",
  rootModel: Notification,
  parse: () => ({}),
});

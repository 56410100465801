// WillRevision

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const CreateWillRevision = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/will-revision",
  method: "post",
  parse: (target: WillRevision) => ({
    body: {
      approvedAt: target.$data.approvedAt,
      content: target.$data.content,
      notes: target.$data.notes,
      hasBusinessTrust: target.$data.hasBusinessTrust,
      hasEstateProtectionTrust: target.$data.hasEstateProtectionTrust,
      hasIipTrust: target.$data.hasIipTrust,
      hasBusinessClause: target.$data.hasBusinessClause,
      kintin: target.$data.kintin,
      ownedBy: target.$data.ownedBy,
      createdBy: target.$data.createdBy,
      approvedBy: target.$data.approvedBy,
    },
  }),
});

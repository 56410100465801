// AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";

export const RecordAccountReferral = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/account-referral/{accountReferral}",
  method: "get",
  rootModel: AccountReferral,
  parse: (accountReferral: AccountReferral) => ({
    params: {
      kintin: accountReferral.$data.kintin!,
      accountReferral,
    },
  }),
});

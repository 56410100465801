<template>
  <div class="cpd-details-introducers">
    <PageHeader htag="h2" text="Participants">
      <template #buttons>
        <Button class="create" size="is-normal" @click="handleCreate">
          Add Participant
        </Button>
        <Button class="download" size="is-normal" @click="downloadParticipants">
          Download
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      is-nested
      :count="introducerCpds.length"
      :columns="columns"
      :rows="introducerCpds"
      :enable-per-page="false"
      :is-clickable="false"
    >
      <template #name="{ row }">
        <a @click="visitIntroducer(row, $event)">
          {{ row.contact?.profile.fullName }}
        </a>
      </template>
      <template #actions="{ row }">
        <div class="buttons">
          <Button
            :icon-left="StyleService.icon.edit.icon"
            aria-label="Edit"
            @click="updateIntroducer(row)"
            color="is-plain"
          />
          <Button
            :icon-left="StyleService.icon.delete.icon"
            aria-label="Delete"
            @click="deleteAttendance(row)"
            color="is-plain"
          />
          <Button
            v-if="row.status.value === 'attended'"
            icon-left="Certificate"
            color="is-plain"
            aria-label="Download Certificate"
            v-tool-tip="'Download Certificate'"
            @click="downloadCertificate(row)"
          />
        </div>
      </template>
      <template #emails="{ row }: { row: IntroducerCpd }">
        <p v-for="email in row.contact?.profile.emails" :key="email.id">
          {{ email.email }}
        </p>
      </template>
      <template #companies="{ row }: { row: IntroducerCpd }">
        <p v-for="company in row.contact?.companies" :key="company.id">
          {{ company.profile.organisationName ?? company.profile.fullName }}
        </p>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "CpdDetailsIntroducersWrapper",
  "route": "CpdDetailsIntroducers",
  "selector": ".cpd-details-introducers",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateCpdSessionIntroducersForm } from "@/module/cpd/form/create-cpd-session-introducers.form";
import { UpdateCpdSessionIntroducersForm } from "@/module/cpd/form/update-cpd-session-introducers.form";
import { CpdDetailsMixin } from "@/module/cpd/mixin/cpd-details.mixin";
import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from "@/module/introducer.contact/page/details";
import { StyleService } from "@/service/style.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IntroducerCpd, Option } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CpdDetailsIntroducersRoute } from ".";

export default defineComponent({
  name: CpdDetailsIntroducersRoute,
  components: {
    MasterListPage,
    PageHeader,
    Button,
  },
  mixins: [CpdDetailsMixin],
  data: () => ({
    StyleService,
    columns: [
      {
        title: "Name",
        slot: "name",
        blockClicks: true,
      },
      {
        title: "Email",
        sort: true,
        slot: "emails",
      },
      {
        title: "Status",
        sort: true,
        map: (v: IntroducerCpd) => v.status.text,
      },
      {
        title: "Companies",
        sort: true,
        slot: "companies",
      },
      {
        slot: "actions",
      },
    ] satisfies Array<TableColumn>,
    introducerCpds: Array<IntroducerCpd>(),
  }),
  mounted(): void {
    this.updateList();
  },
  methods: {
    updateList(): void {
      this.introducerCpds = IntroducerCpd.$findBy({
        session: {
          id: this.cpdSession?.$data.id,
        },
      });
    },
    async handleCreate(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      const introducerCpd = new IntroducerCpd({
        id: Uuid.generate(),
        contact: Uuid.generate(),
        status: Option.$findOneByOrThrow({
          group: "introducerCpdStatus",
          value: "invited",
        }).id,
        createdAt: new DateTime().formatMachine,
        session: this.cpdSession.$data.id,
        type: this.cpdSession.$data.type,
      }) as IntroducerCpd;

      try {
        await CreateCpdSessionIntroducersForm(introducerCpd).dialog({
          dialog: {
            title: "Update Introducer Status",
          },
        });
      } catch (e) {
        introducerCpd.$delete();
        return;
      }

      // await window.Kernel.ActionBus.cpd.introducerCpd.create({
      //   introducerCpd,
      // });
      await this.$actionBus.introducer.CreateIntroducerCpd(introducerCpd);

      await this.refresh();
      this.updateList();
    },
    visitIntroducer(introducer: IntroducerCpd, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: IntroducerContactDetailsRoute,
          params: {
            introducerContact: introducer.$data.contact,
          } as IntroducerContactDetailsParams,
        },
        event.ctrlKey,
      );
    },
    async updateIntroducer(introducer: IntroducerCpd): Promise<void> {
      await UpdateCpdSessionIntroducersForm(introducer).dialog({
        dialog: {
          title: "Update Introducer Status",
        },
      });

      // await window.Kernel.ActionBus.cpd.introducerCpd.update({
      //   introducerCpd: introducer,
      // });
      await this.$actionBus.introducer.UpdateIntroducerCpd(introducer);

      await this.refresh();
      this.updateList();
    },
    async downloadCertificate(introducer: IntroducerCpd): Promise<void> {
      // await window.Kernel.ActionBus.cpd.introducerCpd.generateCertificate({
      //   introducerCpd: introducer,
      // });
      await this.$actionBus.introducer.GenerateCpdCertificate(introducer);
    },
    async downloadParticipants(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      // await window.Kernel.ActionBus.cpd.introducerCpd.downloadCsv({
      //   session: this.cpdSession,
      // });
      await this.$actionBus.introducer.DownloadIntroducerCpdCsv(
        this.cpdSession,
      );
    },
    async deleteAttendance(introducer: IntroducerCpd): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete CPD Attendance Record",
          message:
            "Are you sure you want to delete this CPD attendance record? (it might be more appropriate to update the status to declined maybe?)",
        },
      });

      // await window.Kernel.ActionBus.cpd.introducerCpd.delete({
      //   introducerCpd: introducer,
      // });
      await this.$actionBus.introducer.DeleteIntroducerCpd(introducer);

      await this.refresh();
      this.updateList();
    },
  },
});
</script>

<template>
  <ExclusionsContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookExclusionsWrapper",
  "route": "KinvaultKintinDetailsAddressBookExclusions",
  "extends": {
    "name": "ExclusionsContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/ExclusionsContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateExclusion } from "@/config/model.config";
import ExclusionsContainer from "@/module/kinvault.kintin/component/address-book/ExclusionsContainer.vue";
import { UpdateExclusionForm } from "@/module/kinvault.kintin/form/update-exclusion.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Exclusion } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookExclusionsRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookExclusionsRoute,
  components: {
    ExclusionsContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      exclusions: Array<Exclusion>(),
    },
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      this.filtered.exclusions = this.kintin?.exclusions ?? [];
    },
    async createExclusion() {
      if (!this.kintin) {
        return;
      }

      let excludedBy: string | undefined = undefined;

      if (this.kintin.type.value === "single") {
        excludedBy = this.kintin.primaryPerson?.id;
      }

      const exclusion = CreateExclusion({
        excludedBy,
      });

      try {
        await UpdateExclusionForm({
          data: exclusion,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create exclusion",
          },
        });
      } catch {
        exclusion.$delete();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.exclusion.create({
      await this.$actionBus.exclusion.CreateExclusion(exclusion);

      this.$emit("reload");
    },
  },
});
</script>

// User

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { User } from "@kinherit/sdk/model/user";

export const DeleteUser = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/user/{user}",
  method: "delete",
  parse: (user: User | string) => ({
    params: {
      user,
    },
  }),
  after: ({ message }) => {
    User.$delete(message);
  },
});

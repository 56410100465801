import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadOrder } from "./read-order.action";
import { RecordOrder } from "./record-order.action";
import { UpdateOrder } from "./update-order.action";

export const Portal2IntroducerFeesOrder = DefineActionBus({
  name: "portal-2-introducer-fees-order",
  actions: {
    ReadOrder,
    UpdateOrder,
    RecordOrder,
  },
});

// IntroducerNetwork

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerNetwork } from "@kinherit/sdk/model/introducer-network";

export const UpdateIntroducerNetwork = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/networks/{introducerNetwork}",
  method: "patch",
  parse: (target: IntroducerNetwork) => ({
    body: {
      name: target.$data.name,
      companyCount: target.$data.companyCount,
    },
    params: {
      introducerNetwork: target,
    },
  }),
});

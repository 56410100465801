// BrandedKinvaultSubscriptionReferralCode

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/branded-kinvault-subscription-referral-code";

export const DeleteBrandedKinvaultSubscriptionReferralCode = DefineDeleteAction(
  {
    interface: "portal-2",
    resource:
      "/v2/portal-2/admin/branded-kinvault/subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
    method: "delete",
    parse: (
      brandedKinvaultSubscriptionReferralCode:
        | BrandedKinvaultSubscriptionReferralCode
        | string,
    ) => ({
      params: {
        brandedKinvaultSubscriptionReferralCode,
      },
    }),
    after: ({ message }) => {
      BrandedKinvaultSubscriptionReferralCode.$delete(message);
    },
  },
);

import { TagsField, UserField } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import { Lead, Tag } from "@kinherit/sdk";

export const UpdateLeadSettingsForm = (data: Lead) =>
  defineForm({
    name: "update-lead-settings-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-lead-settings-form-area",
        data,
        template: GridLayout([
          ["ownedBy", "assignedTo"],
          ["tags", "externalId"],
          ["description"],
        ]),
        components: () => ({
          ownedBy: [
            UserField({
              props: {
                label: "Owner",
                message: "i.e Estate Planner",
                vModel: "ownedBy",
                reference: "ownedBy",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                vModel: "assignedTo",
                label: "Assigned To",
                message: "Who's dealing with this lead?",
                reference: "assignedTo",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          tags: [
            TagsField({
              props: {
                isMultiSelect: true,
                reference: "tags",
                vModel: {
                  get: (data: Lead) => data.tags,
                  set: (value: Tag[], data: Lead) => {
                    value.forEach((tag) => {
                      if (!data.tags.pluck("id").includes(tag.id)) {
                        data.addTags(tag);
                      }
                    });

                    data.tags.forEach((tag) => {
                      if (!value.pluck("id").includes(tag.id)) {
                        data.removeTags(tag);
                      }
                    });
                  },
                },
                placeholder: "Search or add a tag",
              },
            }),
          ],
          externalId: [
            FormTextField({
              props: {
                label: "External ID",
                message: "Used for SortRefer etc",
              },
              models: {
                value: "referral.externalId",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: "Notes",
                placeholder: "Lead Description if appropriate",
                isTextArea: true,
              },
              models: {
                value: "description",
              },
            }),
          ],
        }),
      }),
    ],
  });

<template>
  <ProfileSummary
    :key="key"
    v-if="introducerContact && !loading"
    class="introducer-contact-details"
    :profile-id="introducerContact.profile.id"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar-above>
      <RouterChildView name="sidebar">
        <IntroducerContactDetailsSummarySidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <IntroducerContactDetailsSummary />
      </RouterChildView>
    </template>
  </ProfileSummary>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsWrapper",
  "route": "IntroducerContactDetails",
  "selector": ".introducer-contact-details",
  "imports": {
    "IntroducerContactDetailsClientProgressWrapper": "@/module/introducer.contact/page/details/client-progress/IntroducerContactDetails.ClientProgress.test",
    "IntroducerContactDetailsCpdSessionWrapper": "@/module/introducer.contact/page/details/cpd-session/IntroducerContactDetails.CpdSession.test",
    "IntroducerContactDetailsEmailLogWrapper": "@/module/introducer.contact/page/details/email-log/IntroducerContactDetails.EmailLog.test",
    "IntroducerContactDetailsKinvaultAccessWrapper": "@/module/introducer.contact/page/details/kinvault-access/IntroducerContactDetails.KinvaultAccess.test",
    "IntroducerContactDetailsNotesWrapper": "@/module/introducer.contact/page/details/notes/IntroducerContactDetails.Notes.test",
    "IntroducerContactDetailsReferralFormWrapper": "@/module/introducer.contact/page/details/referral-form/IntroducerContactDetails.ReferralForm.test",
    "IntroducerContactDetailsSummaryWrapper": "@/module/introducer.contact/page/details/summary/IntroducerContactDetails.Summary.test",
    "IntroducerContactDetailsUpdateProfileWrapper": "@/module/introducer.contact/page/details/update-profile/IntroducerContactDetails.UpdateProfile.test",
    "IntroducerContactDetailsSidebarWrapper": "@/module/introducer.contact/page/details/IntroducerContactDetails.Sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Edit Profile", "Create Note", "Settings", "Delete"]
    },
    "tabs": {
      "type": "tabs",
      "items": [
        "Summary",
        "Cpd Sessions",
        "Client Progress",
        "Referral Form",
        "Kinvault Access",
        "Emails",
        "Notes"
      ]
    },
    "clientProgress": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsClientProgressWrapper"
    },
    "cpdSession": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsCpdSessionWrapper"
    },
    "emailLog": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsEmailLogWrapper"
    },
    "kinvaultAccess": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsKinvaultAccessWrapper"
    },
    "notes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsNotesWrapper"
    },
    "referralForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsReferralFormWrapper"
    },
    "summary": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsSummaryWrapper"
    },
    "updateProfile": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsUpdateProfileWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerContactDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { IntroducerContactDetailsMixin } from "@/module/introducer.contact/mixin/introducer-contact-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from ".";
import { IntroducerContactMasterListRoute } from "..";
import IntroducerContactDetailsSummarySidebar from "./IntroducerContactDetails.Sidebar.vue";
import { IntroducerContactDetailsClientProgressRoute } from "./client-progress";
import { IntroducerContactDetailsCpdSessionRoute } from "./cpd-session";
import { IntroducerContactDetailsEmailLogRoute } from "./email-log";
import { IntroducerContactDetailsKinvaultAccessRoute } from "./kinvault-access";
import { IntroducerContactDetailsNotesRoute } from "./notes";
import { IntroducerContactDetailsSummaryRoute } from "./summary";
import IntroducerContactDetailsSummary from "./summary/IntroducerContactDetails.Summary.vue";
import { IntroducerContactDetailsUpdateProfileRoute } from "./update-profile";

import { IntroducerContactSettingsForm } from "@/module/introducer.contact/form/introducer-contact-settings.form";
import { TransferIntroducerContactForm } from "@/module/introducer.contact/form/transfer-introducer-contact.form";
import { IntroducerCompany } from "@kinherit/sdk/index";
import { IntroducerContactDetailsReferralFormRoute } from "./referral-form";

export default defineComponent({
  name: IntroducerContactDetailsRoute,
  mixins: [IntroducerContactDetailsMixin, StyleService.mixin],
  components: {
    PageNotFound,
    RouterChildView,
    IntroducerContactDetailsSummary,
    IntroducerContactDetailsSummarySidebar,
    ProfileSummary,
  },
  computed: {
    showContextMenu(): boolean {
      return AuthService.hasPermission("introducer-contact:write");
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: IntroducerContactDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Cpd Sessions",
        route: {
          name: IntroducerContactDetailsCpdSessionRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.cpd.icon,
      },
      {
        label: "Client Progress",
        route: {
          name: IntroducerContactDetailsClientProgressRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.progress.icon,
      },
      {
        label: "Referral Form",
        route: {
          name: IntroducerContactDetailsReferralFormRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.referral.icon,
      },
      {
        label: "Kinvault Access",
        route: {
          name: IntroducerContactDetailsKinvaultAccessRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.kintin.icon,
      },
      {
        label: "Emails",
        route: {
          name: IntroducerContactDetailsEmailLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.email.icon,
      },
      {
        label: "Notes",
        route: {
          name: IntroducerContactDetailsNotesRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.notes.icon,
      },
    ],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.introducerContact.contact.record(
    const params = to.params as IntroducerContactDetailsParams;
    await window.Kernel.ActionBus2.portal2.introducer.contact.RecordIntroducerContact(
      { message: params.introducerContact },
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Profile",
              line1: `Edit ${this.introducerContact.profile.fullName} profile`,
              iconLeft: StyleService.icon.profile.icon,
              permissions: "introducer-contact:write",
              action: this.editProfile,
            },
            {
              title: "Create Note",
              line1: `Add a note to ${this.introducerContact.profile.fullName}`,
              iconLeft: StyleService.icon.notes.icon,
              permissions: "introducer-contact:write",
              action: this.createIntroducerNote,
            },
            {
              title: "Settings",
              line1: `Edit ${this.introducerContact.profile.fullName} settings`,
              iconLeft: this.$style.icon.settings.icon,
              permissions: "introducer-contact:write",
              action: this.editSettings,
            },
            {
              title: "Transfer",
              line1: `Move this contact to another company`,
              iconLeft: this.$style.icon.referral.icon,
              permissions: "introducer-contact:write",
              action: this.transferIntroducerContact,
            },
            {
              title: "Delete",
              line1: `Delete ${this.introducerContact.profile.fullName}`,
              iconLeft: this.$style.icon.delete.icon,
              permissions: "introducer-contact:write",
              action: this.deleteIntroducerContact,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "iconLeft",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: IntroducerContactDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    async deleteIntroducerContact(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Introducer Contact",
          message: `Are you sure you want to delete ${this.introducerContact.profile.fullName}?`,
        },
      });

      // await window.Kernel.ActionBus.introducerCompany.contacts.delete({
      await this.$actionBus.contact.DeleteIntroducerContact(
        this.introducerContact,
      );

      window.Kernel.Router.push({
        name: IntroducerContactMasterListRoute,
      });
    },
    async createIntroducerNote(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      const note = await UpdateNoteForm({
        data: "create-introducer-note",
      }).dialog({
        dialog: {
          title: "Create note",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      if (!note.introducerNote) {
        throw new Error("Introducer note is required");
      }

      note.contact = this.introducerContact;
      // @todo update note form to select introducer company to attach to the note
      note.company = this.introducerContact.companies.first() ?? null;

      await window.Kernel.ActionBus2.core.notes.CreateNote({
        note: note,
        introducerContact: this.introducerContact,
      });
      this.key++;
    },
    async editSettings(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      try {
        await IntroducerContactSettingsForm(this.introducerContact).dialog({
          dialog: {
            title: "Edit Contact Settings",
          },
        });
      } catch (error) {
        this.introducerContact.$restore();
        return;
      }

      // await window.Kernel.ActionBus.introducerContact.contact.update({
      await this.$actionBus.contact.UpdateIntroducerContact(
        this.introducerContact,
      );
    },
    async transferIntroducerContact(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      try {
        const result = await TransferIntroducerContactForm().dialog({
          dialog: {
            title: "Transfer Contact",
          },
        });

        const company = result.company as IntroducerCompany | null;

        if (!company) {
          return;
        }

        // await window.Kernel.ActionBus.introducerContact.contact.transfer({
        //   contact: this.introducerContact.id,
        //   company: company.id,
        // });
        await this.$actionBus.contact.ChangeCompany({
          contact: this.introducerContact.id,
          company: company.id,
        });
      } catch (error) {
        this.introducerContact.$restore();
        return;
      }
    },
  },
});
</script>

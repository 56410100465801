<template>
  <SidebarPage
    :key="key"
    v-if="introducerFeePaymentRun"
    class="introducer-fee-payment-run-details"
    :name="introducerFeePaymentRun.name"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <IntroducerFeePaymentRunDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <IntroducerFeePaymentRunDetailsSummary />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateIntroducerFeePaymentRunForm } from "@/module/introducer.fees/form/update-introducer-fee-payment-run.form";
import { IntroducerFeePaymentRunDetailsMixin } from "@/module/introducer.fees/mixin/introducer-fee-payment-run-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerFeePaymentRunDetailsParams,
  IntroducerFeePaymentRunDetailsRoute,
} from ".";
import { IntroducerFeePaymentRunMasterListRoute } from "..";
import IntroducerFeePaymentRunDetailsSidebar from "./IntroducerFeePaymentRunDetails.Sidebar.vue";
import { IntroducerFeePaymentRunDetailsItemsRoute } from "./items";
import { IntroducerFeePaymentRunDetailsSummaryRoute } from "./summary";
import IntroducerFeePaymentRunDetailsSummary from "./summary/IntroducerFeePaymentRunDetails.Summary.vue";

export default defineComponent({
  name: IntroducerFeePaymentRunDetailsRoute,
  mixins: [IntroducerFeePaymentRunDetailsMixin, AuthService.mixin()],
  components: {
    PageNotFound,
    SidebarPage,
    IntroducerFeePaymentRunDetailsSummary,
    IntroducerFeePaymentRunDetailsSidebar,
    RouterChildView,
  },
  computed: {
    showContextMenu(): boolean {
      return this.$auth.hasPermission("introducer-fee-payment-run:write");
    },
  },
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.introducerFees.paymentRun.record(
    const params = to.params as IntroducerFeePaymentRunDetailsParams;
    await window.Kernel.ActionBus2.portal2.introducer.fees.paymentRun.RecordIntroducerFeePaymentRun(
      {
        message: params.introducerFeePaymentRun,
      },
    );
  },
  data: (ctx) => ({
    key: 0,
    tabs: [
      {
        label: "Summary",
        route: {
          name: IntroducerFeePaymentRunDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Items",
        route: {
          name: IntroducerFeePaymentRunDetailsItemsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.file.icon,
      },
    ],
  }),
  methods: {
    async contextMenu(): Promise<void> {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
          items: [
            {
              title: "Edit",
              icon: StyleService.icon.edit.icon,
              line1: "Edit this introducer fee payment run",
              action: this.edit,
              premissions: ["introducer-fee-payment-run:write"],
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this introducer fee payment run",
              action: this.delete,
              premissions: ["introducer-fee-payment-run:write"],
            },
          ],
        },
        filter: "all",
        path: "premissions",
        callback: (item) => item.action(),
      });

      await contextMenu();
    },
    async delete(): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Introducer Fee Payment Run",
          message: `Are you sure you want to delete this introducer fee payment run?`,
        },
      });

      // await window.Kernel.ActionBus.introducerFees.paymentRun.delete(
      //   this.$params,
      // );
      await this.$actionBus.paymentRun.DeleteIntroducerFeePaymentRun(
        this.$params.introducerFeePaymentRun,
      );

      window.Kernel.Router.push({
        name: IntroducerFeePaymentRunMasterListRoute,
      });
    },
    async edit(): Promise<void> {
      if (!this.$data.introducerFeePaymentRun) {
        return;
      }

      try {
        await UpdateIntroducerFeePaymentRunForm(
          this.$data.introducerFeePaymentRun,
        ).dialog({
          dialog: {
            title: "Edit Introducer Fee Payment Run",
          },
        });

        // await window.Kernel.ActionBus.introducerFees.paymentRun.update({
        await this.$actionBus.paymentRun.UpdateIntroducerFeePaymentRun(
          this.$data.introducerFeePaymentRun,
        );
      } catch {
        this.$data.introducerFeePaymentRun.$restore();
      }
    },
  },
});
</script>

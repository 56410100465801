import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinCheckFollowUp } from "./create-kintin-check-follow-up.action";
import { DeleteKintinCheckFollowUp } from "./delete-kintin-check-follow-up.action";
import { ReadKintinCheckFollowUp } from "./read-kintin-check-follow-up.action";
import { RecordKintinCheckFollowUp } from "./record-kintin-check-follow-up.action";
import { UpdateKintinCheckFollowUp } from "./update-kintin-check-follow-up.action";

export const Portal2KinvaultKintinCheckFollowUp = DefineActionBus({
  name: "portal-2-kinvault-kintin-check-follow-up",
  actions: {
    CreateKintinCheckFollowUp,
    DeleteKintinCheckFollowUp,
    ReadKintinCheckFollowUp,
    UpdateKintinCheckFollowUp,
    RecordKintinCheckFollowUp,
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateCallScript } from "./create-call-script.action";
import { DeleteCallScript } from "./delete-call-script.action";
import { DownloadClientSummary } from "./download-client-summary.action";
import { DownloadWillReview } from "./download-will-review.action";
import { ReadCallScript } from "./read-call-script.action";
import { RecordCallScriptData } from "./record-call-script-data.action";
import { RecordCallScript } from "./record-call-script.action";
import { UpdateCallScript } from "./update-call-script.action";

export const Portal2KinvaultCallScript = DefineActionBus({
  name: "portal-2-kinvault-call-script",
  actions: {
    CreateCallScript,
    DeleteCallScript,
    ReadCallScript,
    UpdateCallScript,
    RecordCallScript,
    RecordCallScriptData,
    DownloadWillReview,
    DownloadClientSummary,
  },
});

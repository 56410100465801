import { Api, Person } from "@kinherit/sdk/index";

//
export const SendOfficerLetter = async (officer: Person) => {
  await Api.resource("portal-2", `/v2/portal-2/officer/{officer}/send`, {
    officer,
  })
    .method("get")
    .result();
};

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Between, Equal, Like } from "@kinherit/orm/index";
import { Lead, Note } from "@kinherit/sdk/index";

export const ReadNoteHandler = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/note/{owner}",
  method: "get",
  rootModel: Note,
  parse: (message: {
    pinned?: boolean | null;
    name?: string | null;
    notes?: string | null;
    createdAt?: [number, number] | null;
    type?: string | null;
    lead: Lead | string;
  }) => ({
    query: {
      pinned: Equal(message.pinned),
      name: Like(message.name),
      notes: Like(message.notes),
      createdAt: Between(message.createdAt),
      type: Equal(message.type),
    },
    params: { owner: message.lead },
  }),
});

import { Api } from "@kinherit/sdk";

export interface UpdateSortReferMilestoneMessage {
  externalId: string;
  eventId: string; // SortRefer EventId
}

interface UpdateSortReferMilestoneResponse {}

export const UpdateSortReferMilestone = async (
  message: UpdateSortReferMilestoneMessage,
): Promise<UpdateSortReferMilestoneResponse> => {
  const request = Api.resource("external", "/v2/external/sort-refer/milestone")
    .method("put")
    .body({
      externalId: message.externalId,
      eventId: message.eventId,
    });

  const response = await request.result();

  return response;
};

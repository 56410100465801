// IntroducerContact, Profile, PhoneNumber, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const ReadIntroducerContact = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/register",
  method: "get",
  rootModel: IntroducerContact,
  parse: () => ({}),
});

import { Api, IntroducerFeePaymentRun } from "@kinherit/sdk/index";

// IntroducerFeePaymentRun, IntroducerFeePayment
export const ApproveIntroducerFeePaymentRun = async (
  paymentRun: IntroducerFeePaymentRun,
) => {
  await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/fees/payment-run/{introducerFeePaymentRun}/approve",
    {
      introducerFeePaymentRun: paymentRun.id,
    },
  )
    .method("post")
    .result();
};

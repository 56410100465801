<template>
  <div class="will-revision-master-list">
    <PageHeader htag="h1" text="Will Revisions" />
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :is-clickable="false"
      :sort-by-options="{
        'kintin.friendlyName': 'Client',
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #client="{ row }">
        <a
          class="client-name"
          tabindex="0"
          @click="(event: MouseEvent) => handleClientClick(row, event)"
        >
          {{ row.kintin?.friendlyName }}
        </a>
        <br />
        <small class="is-family-monospace">{{ row.kintin?.ref }}</small>
      </template>
      <template #by="{ row }">
        <Avatar :name="row.createdBy?.profile?.fullName" />
      </template>
      <template #actions="{ row }: { row: WillRevision }">
        <div
          class="buttons is-right"
          :style="{
            'flex-wrap': 'nowrap',
          }"
        >
          <Button
            v-if="null === row.approvedAt"
            @click="handleDeleteClick(row)"
            class="delete-button"
            :icon-left="$style.icon.delete.icon"
            aria-label="Delete"
            size="is-small"
            color="is-plain"
            tooltip="Delete"
          />
          <Button
            @click="handleEditClick(row)"
            class="details-button"
            :icon-left="$style.icon.edit.icon"
            aria-label="Edit"
            size="is-small"
            color="is-plain"
            tooltip="Edit"
          />
        </div>
      </template>
      <template #approvedAt="{ row }: { row: WillRevision }">
        <span v-if="row.approvedAt" class="is-small badge is-success"
          >{{ row.approvedAt.formatDate }} /
          {{ row.approvedBy?.profile.fullName }}</span
        >
        <span v-else class="is-small badge is-danger">Awaiting Approval</span>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "WillRevisionMasterListWrapper",
  "route": "WillRevisionMasterList",
  "selector": ".will-revision-master-list",
  "imports": {
    "ReadWillRevisionFormWrapper": "@/module/kinvault.will-revision/form/read-will-revision.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadWillRevisionFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  KinvaultKintinDetailsSummaryParams,
  KinvaultKintinDetailsSummaryRoute,
} from "@/module/kinvault.kintin/page/details/summary";
import {
  KinvaultKintinDetailsWillParams,
  KinvaultKintinDetailsWillRoute,
} from "@/module/kinvault.kintin/page/details/will";
import { ReadWillRevisionForm } from "@/module/kinvault.will-revision/form/read-will-revision.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Avatar from "@kinherit/framework/component.display/avatar";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, IsNotNull, IsNull, Like } from "@kinherit/orm/index";
import { IWillRevision, WillRevision } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultWillRevisionMasterListRoute } from ".";

export default defineComponent({
  name: KinvaultWillRevisionMasterListRoute,
  mixins: [
    AuthService.mixin(),
    StyleService.mixin,
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.willRevision),
  ],
  components: { MasterListPage, Button, PageHeader, Avatar },
  data: () => ({
    filters: ReadWillRevisionForm(),
    columns: [
      {
        title: "Client",
        sort: true,
        slot: "client",
      },
      {
        title: "Created",
        sort: true,
        map: (v: WillRevision) => v.createdAt.formatDate,
      },
      {
        title: "By",
        sort: true,
        slot: "by",
        // map: (v: WillRevision) => v.createdBy?.profile?.fullName,
      },
      {
        title: "Approved at",
        slot: "approvedAt",
        class: "is-narrow",
      },
      {
        title: "Notes",
        mapHtml: (v: WillRevision) =>
          v.notes ? v.notes?.slice(0, 100) + "..." : "No Notes",
      },
      {
        slot: "actions",
        class: "is-narrow",
      },
    ] as Array<TableColumn>,
    rows: Array<WillRevision>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IWillRevision,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    async handleEditClick(willRevision: WillRevision): Promise<void> {
      await window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsWillRoute,
          params: {
            kintin: willRevision.kintin.id,
            // willRevision: willRevision.id, // @todo
          } satisfies KinvaultKintinDetailsWillParams,
        },
        false,
      );
    },
    handleClientClick(willRevision: WillRevision, event: MouseEvent): void {
      const kintin = willRevision.kintin;

      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsSummaryRoute,
          params: {
            kintin: kintin.id,
          } satisfies KinvaultKintinDetailsSummaryParams,
        },
        event.ctrlKey,
      );
    },
    async handleDeleteClick(willRevision: WillRevision): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Will Revision",
          message: "Are you sure you want to delete this will revision?",
        },
      });

      // const result = await window.Kernel.ActionBus.kinvaultWillRevision.delete({
      //   willRevision,
      // });

      await this.$actionBus.DeleteWillRevision(willRevision);

      await this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<typeof ReadWillRevisionForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.kinvaultWillRevision.read({
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });

      const data = await this.$actionBus.ReadWillRevision({
        query: {
          kintin: {
            friendlyName: Like(formData.search),
          },
          approvedAt:
            null === formData.approved || undefined === formData.approved
              ? undefined
              : true === formData.approved
                ? IsNotNull()
                : IsNull(),
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.willRevision;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

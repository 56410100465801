import { Api } from "@kinherit/sdk/api";
import {
  IntroducerContact,
  Kintin,
  Person,
  Profile,
} from "@kinherit/sdk/index";

// Person, Profile, Kintin, EmailAddress, PhoneNumber
export const CreatePersonFromIntroducerContact = async (message: {
  introducerContact: string | IntroducerContact;
  kintin: string | Kintin;
}) => {
  const result = await Api.resource(
    "portal",
    "/v2/portal/kintin-person/create-person-from-introducer-contact",
  )
    .method("put")
    .body({
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
    })
    .result();

  const people = Person.$inflate(result.person);

  const person = people.first();

  if (!person) {
    throw new Error("Failed to create person from introducer contact");
  }

  const profiles = Profile.$inflate(result.profile);

  const profile = profiles.first();

  if (!profile) {
    throw new Error("Failed to create profile from introducer contact");
  }

  return {
    person,
    profile,
  };
};

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateAccountReferralCode } from "./create-account-referral-code.action";
import { ReadAccountReferralCode } from "./read-account-referral-code.action";

export const Portal2AdminAccountReferralCodes = DefineActionBus({
  name: "portal-2-admin-account-referral-codes",
  actions: {
    ReadAccountReferralCode,
    CreateAccountReferralCode,
  },
});

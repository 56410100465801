import { Api, Kintin, Property, User } from "@kinherit/sdk/index";

//
export const FillLRSeveranceForm = async (message: {
  kintin: Kintin;
  property: Property;
  user: User;
}) => {
  const { kintin, property, user } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/process/lr-severance-form",
    {
      kintin: kintin,
    },
  )
    .method("get")
    .params({
      kintin: kintin.id,
      property: property.id,
      user: user.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} LR Severance Form - ${surname}`,
    });
};

// EmailCampaign, Profile, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";

export const ReadEmailCampaigns = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/email-campaigns",
  method: "get",
  rootModel: EmailCampaign,
  parse: () => ({}),
});

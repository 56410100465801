import { Api } from "@/service/api.service";

interface ChangeMyPasswordMessage {
  newPassword: string;
}

interface ChangeMyPasswordResponse {
  [key: string]: never;
}

export const ChangeMyPassword = async (
  message: ChangeMyPasswordMessage,
): Promise<ChangeMyPasswordResponse> => {
  await Api.resource("auth", "/v2/auth/change-password")
    .method("put")
    .body({
      newPassword: message.newPassword,
    })

    .result();

  return {};
};

import { PopulateIntroducerFeePaymentRun } from "@/action-bus/portal-2/introducer/fees/payment-run/populate-introducer-fee-payment-run.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ApproveIntroducerFeePaymentRun } from "./approve-introducer-fee-payment-run.action";
import { CreateIntroducerFeePaymentRun } from "./create-introducer-fee-payment-run.action";
import { DeleteIntroducerFeePaymentRun } from "./delete-introducer-fee-payment-run.action";
import { PublishIntroducerFeePaymentRun } from "./publish-introducer-fee-payment-run.action";
import { ReadIntroducerFeePaymentRun } from "./read-introducer-fee-payment-run.action";
import { RecordIntroducerFeePaymentRun } from "./record-introducer-fee-payment-run.action";
import { UpdateIntroducerFeePaymentRun } from "./update-introducer-fee-payment-run.action";

export const Portal2IntroducerFeesPaymentRun = DefineActionBus({
  name: "portal-2-introducer-fees-payment-run",
  actions: {
    RecordIntroducerFeePaymentRun,
    CreateIntroducerFeePaymentRun,
    ReadIntroducerFeePaymentRun,
    UpdateIntroducerFeePaymentRun,
    ApproveIntroducerFeePaymentRun,
    PublishIntroducerFeePaymentRun,
    DeleteIntroducerFeePaymentRun,
    PopulateIntroducerFeePaymentRun,
  },
});

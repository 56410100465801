<template>
  <Message
    v-if="hasWarnings"
    title="Warning"
    :color="'is-warning'"
    size="is-small"
    class="mb-4 mt-4"
  >
    <span v-if="!hasSortReferReferralCode" class="is-block">
      <b>Not a SortRefer referral</b>
      <br />
      <small>
        A SortRefer referral code is required to view the SortRefer details
      </small>
    </span>
    <span v-if="!hasexternalId" class="is-block">
      <b>External Lead ID Not Defined</b>
      <br />
      <small>
        In order to view the SortRefer details, a SortRefer external lead ID
        must be set
      </small>
    </span>
  </Message>
  <Tabs v-if="!hasWarnings" :config="tabsConfig" />
  <RouterChildView v-if="!hasWarnings" />
</template>

<script lang="ts">
import {
  LeadDetailsSortReferRoute,
  LeadDetailsSortReferStatusRoute,
} from "@/module/lead/page/details/external";
import { StyleService } from "@/service/style.service";
import Message from "@kinherit/framework/component.display/message";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsSortReferRoute,
  mixins: [LeadDetailsMixin],
  components: {
    Tabs,
    RouterChildView,
    Message,
  },
  data: () => ({
    tabsConfig: [
      {
        label: "Status",
        route: {
          name: LeadDetailsSortReferStatusRoute,
        },
        icon: StyleService.icon.summary.icon,
      },
    ] as TabOptions[],
  }),
  computed: {
    hasWarnings() {
      return !this.hasexternalId || !this.hasSortReferReferralCode;
    },
    hasexternalId() {
      return this.lead?.referral.externalId;
    },
  },
});
</script>

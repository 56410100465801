// MfAuthMethod

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { MfAuthMethod } from "@kinherit/sdk/model/mf-auth-method";

export const CreateMfAuthMethod = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/user/{user}/mf-auth-methods/{credential}/mf-auth-methods",
  method: "post",
  parse: (target: MfAuthMethod) => ({
    body: {
      alias: target.$data.alias,
      type: target.$data.type,
      key: target.$data.key,
      device: target.$data.device,
      setupCompleteAt: target.$data.setupCompleteAt,
      credential: target.$data.credential,
    },
    params: {
      user: target.credential.user!,
      credential: target.credential,
    },
  }),
  after: ({ message }) => {
    MfAuthMethod.$delete(message);
  },
});

// IntroducerCompany, Profile, User, AccountReferral, CompanyPartnerPortalPermissions, IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const ReadIntroducerCompanies = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/company",
  method: "get",
  rootModel: IntroducerCompany,
  parse: () => ({}),
});

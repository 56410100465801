import { Api } from "@/service/api.service";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface DownloadKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: FileLog;
}

interface DownloadKnowledgeBaseDocumentFileLogResponse {}

export const DownloadFileLog = async (
  message: DownloadKnowledgeBaseDocumentFileLogMessage,
): Promise<DownloadKnowledgeBaseDocumentFileLogResponse> => {
  const { url } = await Api.resource(
    "portal-2",
    "/v2/portal-2/knowledge-base/category/document/file-log/{knowledgeBaseDocument}/document/{fileLog}/download",
    {
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
      fileLog: message.file.id,
    },
  )
    .method("get")
    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            <a href="${url}" target="_blank" class='button is-large is-fullwidth is-themed is-positive is-outlined is-rounded'>
            <span class="icon is-normal"><i class="fa-download fa-regular is-unselectable"></i></span> Click here to download the file
            <a href="${url}" target="_blank">
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};

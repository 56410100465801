import {
  AccountReferralCodeDetailsParams,
  AccountReferralCodeDetailsRoute,
} from "@/module/admin.account-referral-code/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { AccountReferralCode } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const AccountReferralCodeDetailsMixin = defineComponent({
  name: "account-referral-details-mixin",
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.accountReferralCode,
    ),
  ],
  computed: {
    $params(): AccountReferralCodeDetailsParams {
      return this.$route.params as any;
    },
    referralCode(): AccountReferralCode | null {
      return AccountReferralCode.$findOne(this.$params.accountReferralCode);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("account-referral-code:write");
    },
  },
  methods: {
    visitAccountReferralCodeDetails(): void {
      window.Kernel.Router.push({
        name: AccountReferralCodeDetailsRoute,
        params: {
          accountReferralCode: this.referralCode?.id,
        } as AccountReferralCodeDetailsParams,
      });
    },
  },
});

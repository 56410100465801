import { Api } from "@/service/api.service";
import { Currency } from "@kinherit/ts-common/index";

interface CreateXeroRepeatingInvoiceMessage {
  price: Currency;
  xeroContactId: string;
  subscriptionId: string;
  quantity?: number;
  description?: string;
  accountCode?: string;
}

interface CreateXeroRepeatingInvoiceResponse {}

export const CreateXeroRepeatingInvoice = async (
  message: CreateXeroRepeatingInvoiceMessage,
): Promise<CreateXeroRepeatingInvoiceResponse> => {
  const response = await Api.resource(
    "external",
    "/v2/external/xero/create-repeating-invoice",
  )
    .method("post")

    .body({
      price: message.price?.amount ?? 0,
      xeroContactId: message.xeroContactId,
      subscriptionId: message.subscriptionId,
      quantity: message.quantity,
      description: message.description,
      accountCode: message.accountCode,
    })
    .result();

  console.log(response);
  return {};
};

// KintinAccess

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const RecordKintinAccess = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/kintin-access/{kintinAccess}",
  method: "get",
  rootModel: KintinAccess,
  parse: (kintinAccess: KintinAccess) => ({
    params: {
      kintinAccess,
      kintin: kintinAccess.$data.kintin,
    },
  }),
});

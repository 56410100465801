// UploadForm, FileLog, SignDoc

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const ReadUploadForm = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/upload-form/{accountReferral}",
  method: "get",
  rootModel: UploadForm,
  parse: (accountReferral: AccountReferral | string) => ({
    params: {
      accountReferral,
    },
  }),
});

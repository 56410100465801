// Guardian

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/index";
import { Guardian } from "@kinherit/sdk/model/guardian";

export const ReadGuardian = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/guardian",
  method: "get",
  rootModel: Guardian,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});

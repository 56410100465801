import { Api } from "@/service/api.service";
import {
  CommunicationNote,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type CreateNoteMessage = {
  note: Note;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string | IntroducerOutsource;
    }
);

type Controllers =
  | "/v2/portal-2/kinvault/{owner}/notes"
  | "/v2/portal-2/lead/{owner}/notes"
  | "/v2/portal-2/introducer/company/note/{owner}"
  | "/v2/portal-2/introducer/contact/note/{owner}"
  | "/v2/portal-2/officer/{owner}/notes"
  | "/v2/portal-2/introducer/outsources/note/{owner}";

interface CreateNoteResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
}

export const CreateNoteHandler = async (
  message: CreateNoteMessage,
): Promise<CreateNoteResponse> => {
  let path = "" as Controllers;
  const params = {} as any;
  if ("kintin" in message && message.kintin) {
    path = "/v2/portal-2/kinvault/{owner}/notes";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal-2/lead/{owner}/notes";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal-2/introducer/company/note/{owner}";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal-2/introducer/contact/note/{owner}";
    params.owner = message.introducerContact;
  } else if ("person" in message && message.person) {
    path = "/v2/portal-2/officer/{owner}/notes";
    params.owner = message.person;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal-2/introducer/outsources/note/{owner}";
    params.owner = message.introducerOutsource;
  } else {
    throw new Error("Invalid message");
  }

  const company =
    "introducerCompany" in message ? message.introducerCompany : null;
  const contact =
    "introducerContact" in message ? message.introducerContact : null;
  const lead = "lead" in message ? message.lead : null;
  const kintin = "kintin" in message ? message.kintin : null;
  const person = "person" in message ? message.person : null;
  const introducerOutsource =
    "introducerOutsource" in message ? message.introducerOutsource : null;

  const response = await Api.resource("portal-2", path as Controllers, params)
    .method("post")
    .body({
      company: "string" === typeof company ? company : company?.id,
      contact: "string" === typeof contact ? contact : contact?.id,
      lead: "string" === typeof lead ? lead : lead?.id,
      kintin: "string" === typeof kintin ? kintin : kintin?.id,
      person: "string" === typeof person ? person : person?.id,
      introducerOutsource:
        "string" === typeof introducerOutsource
          ? introducerOutsource
          : introducerOutsource?.id,
      notes: message.note.$data.notes,
      dueAt: message.note.$data.dueAt ?? undefined,
      name: message.note.$data.name,
      pinned: message.note.$data.pinned,
      isPublic: message.note.$data.isPublic,
      completedAt: message.note.$data.completedAt ?? undefined,
      type: message.note.$data.type,
      notifications: message.note.notifications.map((n) => ({
        user: n.$data.user,
      })),
    })

    .result();

  const note = Note.$inflate(response.note).first();

  if (!note) {
    throw new Error("Failed to create note");
  }

  if (message.note.communicationNote) {
    await Api.resource("portal-2", `${path as Controllers}/{note}`, {
      note,
      owner: params.owner,
    })
      .method("patch")
      .body({
        communicationNote: {
          communicationType:
            message.note.communicationNote.$data.communicationType,
          reference: message.note.communicationNote.$data.reference,
          note: note.id,
          outcome: message.note.communicationNote.$data.outcome,
        },
      })

      .result();
  }

  if (message.note.introducerNote) {
    await Api.resource("portal-2", `${path as Controllers}/{note}`, {
      note,
      owner: params.owner,
    })
      .method("patch")
      .body({
        company: message.note.$data.company,
        contact: message.note.$data.contact,
        introducerNote: {
          dueAtNotifySales: message.note.introducerNote.$data.dueAtNotifySales,
          dueAtNotifySpecialist:
            message.note.introducerNote.$data.dueAtNotifySpecialist,
          note: note.id,
        },
      })

      .result();
  }

  if (message.note.statusLog) {
    await Api.resource("portal-2", `${path as Controllers}/{note}`, {
      note,
      owner: params.owner,
    })
      .method("patch")
      .body({
        statusLog: {
          nextActionAt: message.note.statusLog.$data.nextActionAt,
          status: message.note.statusLog.$data.status ?? undefined,
          stage: message.note.statusLog.$data.stage ?? undefined,
          kintin: message.note.statusLog.$data.kintin,
          note: note.id,
        },
      })

      .result();
  }

  message.note.$delete({
    introducerNote: true,
    communicationNote: true,
    notifications: true,
  });

  return await window.Kernel.ActionBus2.core.notes.RecordNote({
    ...message,
    note,
  });
};

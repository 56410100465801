import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../breadcrumbs";
// events, emails, call-script, ifa-call-script, additional-actions, update-profile
import { Routes as LeadDetailsCallScriptRoutes } from "./call-script";
import { Routes as LeadDetailsChangeLogRoutes } from "./change-log";
import { Routes as LeadDetailsEmailsRoutes } from "./email-log";
import { Routes as LeadDetailsExternalRoutes } from "./external";
import { Routes as LeadDetailsIfaCallScriptRoutes } from "./ifa-call-script";
import { LeadDetailsNotesRoute, Routes as LeadNotesRoutes } from "./notes";
import { Routes as LeadDetailsUpdateProfileRoutes } from "./update-profile";
import { Routes as LeadDetailsUploadFormsRoutes } from "./upload-forms";

export const LeadDetailsRoute = "LeadDetails";
export const LeadDetailsURI = "/lead/:lead";
export type LeadDetailsParams = RouteParamObject<typeof LeadDetailsURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsRoute,
    path: LeadDetailsURI,
    component: () =>
      import(/* webpackChunkName: "LeadDetails" */ "./LeadDetails.vue"),
    meta: {
      breadcrumbs: (params) => LeadDetailsBreadCrumb(params),
    },
    children: [
      ...LeadDetailsEmailsRoutes,
      ...LeadDetailsCallScriptRoutes,
      ...LeadDetailsIfaCallScriptRoutes,
      ...LeadDetailsUpdateProfileRoutes,
      ...LeadNotesRoutes,
      ...LeadDetailsChangeLogRoutes,
      ...LeadDetailsUploadFormsRoutes,
      ...LeadDetailsExternalRoutes,
    ],
    redirect: { name: LeadDetailsNotesRoute },
  },
];

import { Api } from "@/service/api.service";
import { Kintin, SignDoc } from "@kinherit/sdk";

export interface GetStripeIdentityVerificationSessionMessage {
  kintin: string | Kintin;
  signDoc: string | SignDoc;
}

type TVerificationReport = {
  id: string;
  object: "identity.verification_report";
  client_reference_id: string | null;
  created: number; // Unix timestamp in seconds
  document: {
    address: string | null;
    error: string | null;
    files: string[]; // Array of file IDs
    first_name: string;
    issued_date: {
      day: number;
      month: number;
      year: number;
    };
    issuing_country: string;
    last_name: string;
    status: "verified" | "unverified" | "pending";
    type: "passport" | "id_card" | "driver_license" | string; // Expand with possible document types
  };
  livemode: boolean;
  options: {
    document: {
      require_live_capture: boolean;
      require_matching_selfie: boolean;
    };
    email: Record<string, unknown>; // Adjust if more specific data is needed
    id_number: Record<string, unknown>; // Adjust if more specific data is needed
    phone: Record<string, unknown>; // Adjust if more specific data is needed
  };
  selfie: {
    document: string; // File ID for the associated document
    error: string | null;
    selfie: string; // File ID for the selfie
    status: "verified" | "unverified" | "pending";
  };
  type: "document" | "selfie" | string; // Expand if necessary
  verification_session: string;
};

export interface GetStripeIdentityVerificationSessionResponse {
  report: TVerificationReport | null;
}

export const ReadIdentityVerificationSession = async (
  message: GetStripeIdentityVerificationSessionMessage,
): Promise<GetStripeIdentityVerificationSessionResponse> => {
  const request = Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/sign-doc/{kintin}/sign-docs/{signDoc}/identity-verification-session",
    {
      kintin: message.kintin,
      signDoc: message.signDoc,
    },
  ).method("get");
  const response = await request.result();

  console.log("response", response);
  return response.report as never;
};

// DefaultBrandedKinvaultReferralCode, BrandedKinvault, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const ReadDefaultBrandedKinvaultReferralCode = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/default-branded-kinvault-referral-code",
  method: "get",
  rootModel: DefaultBrandedKinvaultReferralCode,
  parse: () => ({}),
});

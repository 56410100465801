import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { AddAccountReferralCode } from "./add-account-referral-code.action";
import { ReadAccountReferralCodeKintins } from "./read-account-referral-code-kintins.action";
import { ReadAccountReferralCodeLeads } from "./read-account-referral-code-leads.action";
import { ReadAccountReferralCodes } from "./read-account-referral-codes.action";
import { ReadAccountReferrals } from "./read-account-referrals.action";
import { RemoveAccountReferralCode } from "./remove-account-referral-code.action";

export const Portal2IntroducerCompanyReferral = DefineActionBus({
  name: "portal-2-introducer-company-referral",
  actions: {
    AddAccountReferralCode,
    RemoveAccountReferralCode,
    ReadAccountReferralCodeKintins,
    ReadAccountReferralCodeLeads,
    ReadAccountReferralCodes,
    ReadAccountReferrals,
  },
});

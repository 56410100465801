<template>
  <div class="attorney-list">
    <AttorneySummary
      v-for="role in attorneys.sortBy('sortOrder', 'asc')"
      :key="`attorney-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!attorneys.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AttorneyListWrapper",
  "imports": {
    "AttorneySummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/AttorneySummary.test"
  },
  "methods": {
    "attorneys": {
      "type": "to-one",
      "selector": ".attorney-summary",
      "wrapper": "AttorneySummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import AttorneySummary from "@/module/kinvault.kintin/component/summary-cards/AttorneySummary.vue";
import { UpdateAttorneyForm } from "@/module/kinvault.kintin/form/update-attorney.form";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { Attorney, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AttorneyList",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.kinvault)],
  components: { AttorneySummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    attorneys: {
      type: Array as () => Attorney[],
      required: true,
    },
  },
  methods: {
    async edit(attorney: Attorney): Promise<void> {
      try {
        await UpdateAttorneyForm({
          data: attorney,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Attorney",
          },
        });
      } catch {
        attorney.$restore();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.update({
      //   person: attorney.person,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.person.UpdatePerson(attorney.person);

      // await window.Kernel.ActionBus.kinvaultKintin.attorney.update({
      //   attorney,
      //   kintin,
      // });
      await this.$actionBus.attorney.UpdateAttorney(attorney);

      this.$emit("reload");
    },
    async remove(attorney: Attorney): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Attorney",
          message: `Are you sure you want to remove this attorney?`,
        },
      });

      // await window.Kernel.ActionBus.kinvaultKintin.attorney.delete({
      //   attorney,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.attorney.DeleteAttorney(attorney);

      this.$emit("reload");
    },
  },
});
</script>

import { Api } from "@kinherit/sdk/api";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/index";

// IntroducerFeePaymentRun, IntroducerFeePayment
export const PublishIntroducerFeePaymentRun = async (
  introducerFeePaymentRun: IntroducerFeePaymentRun,
) => {
  await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/fees/payment-run/{introducerFeePaymentRun}/publish",
    {
      introducerFeePaymentRun: introducerFeePaymentRun,
    },
  )
    .method("post")
    .result();
};

import { KnowledgeBaseCategoryField } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IsNull } from "@kinherit/orm/index";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

export const UpdateKnowledgeBaseCategoryForm = ({
  category,
  isTopLevel,
}: {
  category: KnowledgeBaseCategory;
  isTopLevel: boolean;
}) =>
  defineForm({
    name: "update-knowledge-base-category-form",
    data: () => category,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: isTopLevel
          ? GridLayout([["title"]])
          : GridLayout([["parentCategory"], ["title"]]),
        components: () => ({
          parentCategory: [
            KnowledgeBaseCategoryField({
              props: {
                label: "Section",
                vModel: "parentCategory",
                isMultiSelect: false,
                message: "Main section for this category",
              },
              query: {
                parentCategory: IsNull(),
              },
            }),
          ],
          title: [
            FormTextField({
              props: {
                label: "Category Title",
                validators: ["required"],
              },
              models: {
                value: "title",
              },
            }),
          ],
        }),
      }),
    ],
  });

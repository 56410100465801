import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteNotification } from "./notifications/delete-notification.action";
import { ReadNotification } from "./notifications/read-notification.action";
import { UpdateNotification } from "./notifications/update-notification.action";
import { ModifyPdf } from "./pdf-utils/modify-pdf.action";
import { Portal2DashboardSearch } from "./search";
import { ReadStatistics as ReadEPStatistics } from "./statistics/ep/read-statistics.action";
import { ReadStatistics as ReadLAStatistics } from "./statistics/la/read-statistics.action";

export const Portal2Dashboard = DefineActionBus({
  name: "portal-2-dashboard",
  actions: {
    ModifyPdf,
    search: Portal2DashboardSearch,
    notifications: DefineActionBus({
      name: "portal-2-dashboard-notifications",
      actions: {
        ReadNotification,
        DeleteNotification,
        UpdateNotification,
      },
    }),
    statistics: DefineActionBus({
      name: "portal-2-dashboard-statistics",
      actions: {
        ReadEPStatistics,
        ReadLAStatistics,
      },
    }),
  },
});

<template>
  <CallScriptSection title="Referral / Background">
    <Form v-if="$data.form" :config="$data.form" is-borderless @save="save" />
    <ConfirmCheck
      :step="step"
      :kintin="kintin"
      :checks="checks"
      :kintinChecks="kintinChecks"
      @update="(step, value) => $emit('update', step, value)"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { CallScriptReferralSectionForm } from "@/module/kinvault.kintin/form/call-script.form/referral-section.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import Form from "@kinherit/framework/component.form/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Check, Kintin, KintinCheck } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import ConfirmCheck from "./ConfirmCheck.vue";

export default defineComponent({
  name: "CallScriptReferralSection",
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    CallScriptSection,
    Form,
    ConfirmCheck,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
    step: {
      type: String as PropType<`${string}.${string}.${string}`>,
      required: true,
    },
    middle: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
    checks: {
      type: Array as PropType<Check[]>,
      required: true,
    },
    kintinChecks: {
      type: Array as PropType<KintinCheck[]>,
      required: true,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (step: string, value: number) => true,
  },
  data: () => ({
    form: null as null | FormBuilder<any, string>,
  }),
  mounted() {
    this.form = CallScriptReferralSectionForm({
      data: this.kintin,
      text: {
        middle: this.middle,
        end: this.end,
      },
    });
  },
  methods: {
    async save() {
      const referral = this.kintin.referral;

      if (!referral) {
        return;
      }

      referral.$persist();

      // await window.Kernel.ActionBus.core.accountReferral.update({
      //   referral,
      // });
      await this.$actionBus.accountReferral.UpdateAccountReferral(referral);

      // await window.Kernel.ActionBus.kinvaultKintin.kintin.update({
      //   kintin: this.kintin,
      // });
      await this.$actionBus.UpdateKintin(this.kintin);
    },
  },
});
</script>

import { BrandedKinvaultField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { BrandedKinvault } from "@kinherit/sdk";

export const UserJoinBrandedKinvaultForm = async (data: {
  brandedKinvault: BrandedKinvault | null;
}): Promise<
  FormBuilder<
    () => {
      brandedKinvault: BrandedKinvault | null;
    },
    "default"
  >
> => {
  return defineForm({
    name: "user-join-branded-kinvault-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout(["brandedKinvault"]),
        components: () => ({
          brandedKinvault: [
            BrandedKinvaultField<any>({
              props: {
                showClearButton: false,
                vModel: "brandedKinvault",
                validators: ["required"],
              },
              query: {},
            }),
          ],
        }),
      }),
    ],
  });
};

<template>
  <div class="order-details-summary">
    <Card>
      <Button
        @click="$emit('edit-order')"
        icon-left="Edit"
        aria-label="Edit Order"
        text="Edit Order"
        size="is-small"
        class="mr-1"
      />
      <Button
        v-if="order?.status?.value === 'paid' && null === order?.invoiceNumber"
        @click="$emit('push-to-xero')"
        icon-left="ChevronUp"
        aria-label="Push To Xero"
        text="Push To Xero"
        size="is-small"
        class="mr-1"
      />
      <Button
        @click="$emit('download-invoice')"
        icon-left="PDF"
        aria-label="Download Invoice"
        text="Download Invoice"
        size="is-small"
        class="mr-1"
      />
      <Button
        @click="$emit('download-business-invoice')"
        icon-left="PDF"
        aria-label="Download Invoice"
        text="Download Business Invoice"
        size="is-small"
      />
    </Card>
    <Card v-if="order?.status?.value !== 'paid'" title="Order Payment Link">
      <TextField :value="url">
        <template #right>
          <Button
            icon-left="Copy"
            @click="copyUrl"
            aria-label="Copy Order URL"
          />
        </template>
      </TextField>
    </Card>
    <Card v-if="orderNotes" title="Notes">
      <div v-html="orderNotes"></div>
    </Card>
    <Card title="Order Summary">
      <Table :columns="columns" :rows="order?.orderItems" />
      <!--pre>{{ orderTotals }}</pre-->
      <Table is-mobile :columns="orderTotalcolumns" :rows="orderTotalRows" />
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "OrderDetailsSummaryWrapper",
  "route": "OrderDetailsSummary",
  "selector": ".order-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import { OrderItemTableColumns } from "@/config/table-columns.config";
import Table from "@kinherit/framework/component.display/table";
import Button from "@kinherit/framework/component.input/button";
import TextField from "@kinherit/framework/component.input/text-field";
import Card from "@kinherit/framework/component.layout/card";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
import { defineComponent } from "vue";
import { OrderDetailsSummaryRoute } from ".";
import { OrderDetailsMixin } from "../../../mixin/order-details.mixin";

export default defineComponent({
  name: OrderDetailsSummaryRoute,
  components: {
    Card,
    Table,
    TextField,
    Button,
  },
  mixins: [OrderDetailsMixin],
  data: () => ({
    columns: OrderItemTableColumns(),
    url: "",
    orderTotalcolumns: [
      {
        title: "List Price",
        field: "listPrice",
        class: "has-text-weight-bold",
      },
      {
        title: "Extras",
        field: "extras",
        class: "has-text-weight-bold",
      },
      {
        title: "Discount Applied",
        field: "discount",
        class: "has-text-weight-bold has-text-danger",
      },
      {
        title: "Final Total Ex VAT",
        field: "exVat",
      },
      {
        title: "Final Total VAT",
        field: "vat",
      },
      {
        title: "Final Total",
        field: "price",
        class: "has-text-weight-bold",
      },
    ],
  }),
  computed: {
    orderNotes(): string {
      return this.order?.notes ?? "";
    },
    orderTotalRows(): Array<any> {
      const totals = this.orderTotals;
      if (!totals) {
        return [];
      }

      return [
        {
          listPrice: totals.total.price.format,
          extras: this.order?.extrasPrice?.format,
          discount: totals.discount.format,
          exVat: totals.modified.exVat.format,
          vat: totals.modified.vat.format,
          price: totals.modified.price.format,
        },
      ];
    },
  },
  mounted() {
    this.setUrl();
  },
  methods: {
    async setUrl() {
      const order = this.order?.id;
      const kintinRef = this.order?.kintin?.ref;

      if (!order || !kintinRef) {
        return;
      }

      const url = await window.Kernel.ActionBus2.core.SignUrl();

      const link = `${url}/pay/order/${kintinRef}/${order}`;

      this.url = link;
    },
    copyUrl(): void {
      ClipBoardService.copyTextToClipBoard(this.url, {
        wrapLinks: false,
        showSnackbar: true,
      });
    },
  },
});
</script>

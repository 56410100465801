// CreditNote, Note, User, Profile, Order, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CreditNote } from "@kinherit/sdk/model/credit-note";

export const RecordCreditNote = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/credit-note/{creditNote}",
  method: "get",
  rootModel: CreditNote,
  parse: (creditNote: CreditNote | string) => ({
    params: {
      creditNote,
    },
  }),
});

// FileLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { FileLog } from "@kinherit/sdk/model/file-log";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadFile = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/file-log",
  method: "get",
  rootModel: FileLog,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});

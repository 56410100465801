// Property

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/index";
import { Property } from "@kinherit/sdk/model/property";

export const ReadProperty = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/property",
  method: "get",
  rootModel: Property,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});

// Beneficiary

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Beneficiary } from "@kinherit/sdk/model/beneficiary";

export const UpdateBeneficiary = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/beneficiary/{beneficiary}",
  method: "patch",
  parse: (target: Beneficiary) => ({
    body: {
      position: target.$data.position,
      sharePercentage: target.$data.sharePercentage,
      shareFraction: target.$data.shareFraction,
      notes: target.$data.notes,
      sortOrder: target.$data.sortOrder,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      ifPredeceased: target.$data.ifPredeceased,
      classGiftType: target.$data.classGiftType,
      reserveclassGiftType: target.$data.reserveclassGiftType,
      reservePerson: target.$data.reservePerson,
      kintin: target.$data.kintin,
    },
    params: {
      beneficiary: target,
      kintin: target.$data.kintin,
    },
  }),
});

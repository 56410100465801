// IntroducerFeePayment, IntroducerFeePaymentRun, IntroducerFeePaymentItem

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerFeePayment } from "@kinherit/sdk/model/introducer-fee-payment";

export const UpdateIntroducerFeePayment = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/fees/payment/{introducerFeePayment}",
  method: "patch",
  parse: (target: IntroducerFeePayment) => ({
    body: {
      completedAt: target.$data.completedAt,
      introducerFeePaymentRun: target.$data.introducerFeePaymentRun,
      status: target.$data.status,
      xeroContact: target.$data.xeroContact,
    },
    params: {
      introducerFeePayment: target,
    },
  }),
});

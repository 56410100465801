// BrandedKinvaultInvitation

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/model/branded-kinvault-invitation";

export const UpdateBrandedKinvaultInvitation = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/invitation/{brandedKinvaultInvitation}",
  method: "patch",
  parse: (target: BrandedKinvaultInvitation) => ({
    body: {
      signUpToken: target.$data.signUpToken,
      externalRef: target.$data.externalRef,
      status: target.$data.status,
      sentAt: target.$data.sentAt,
      completedAt: target.$data.completedAt,
      declinedAt: target.$data.declinedAt,
      cancelledAt: target.$data.cancelledAt,
      isBillable: target.$data.isBillable,
      brandedKinvault: target.$data.brandedKinvault,
      createdBy: target.$data.createdBy,
      profile: target.$data.profile,
      introducerContact: target.$data.introducerContact,
      kintin: target.$data.kintin,
    },
    params: {
      brandedKinvaultInvitation: target,
    },
  }),
});

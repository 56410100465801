import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerContactPartnerPortal } from "./create-introducer-contact-partner-portal.action";
import { DeleteIntroducerContactPartnerPortal } from "./delete-introducer-contact-partner-portal.action";
import { ReadIntroducerContactPartnerPortal } from "./read-introducer-contact-partner-portal.action";
import { UpdateIntroducerContactPartnerPortal } from "./update-introducer-contact-partner-portal.action";

export const Portal2IntroducerContactPartnerPortalPermissions = DefineActionBus(
  {
    name: "portal-2-introducer-contact-partner-portal-permissions",
    actions: {
      CreateIntroducerContactPartnerPortal,
      ReadIntroducerContactPartnerPortal,
      UpdateIntroducerContactPartnerPortal,
      DeleteIntroducerContactPartnerPortal,
    },
  },
);

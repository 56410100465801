// IntroducerContact

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const DeleteIntroducerContact = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/{introducerContact}",
  method: "delete",
  parse: (introducerContact: IntroducerContact | string) => ({
    params: {
      introducerContact,
    },
  }),
  after: ({ message }) => {
    IntroducerContact.$delete(message);
  },
});

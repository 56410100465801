// AccountReferralCode, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const ReadAccountReferralCode = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/account-referral-code",
  method: "get",
  rootModel: AccountReferralCode,
  parse: () => ({}),
});

import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsParams } from "../page";

export const KnowledgeBaseDocumentMixin = () =>
  defineComponent({
    name: "KnowledgeBaseDocumentMixin",
    mixins: [
      ActionBusMixin(
        () => window.Kernel.ActionBus2.portal2.knowledgeBase.category.document,
      ),
    ],
    computed: {
      $params(): KnowledgeBaseDocumentDetailsParams {
        return this.$route.params as KnowledgeBaseDocumentDetailsParams;
      },
      knowledgeBaseDocument(): KnowledgeBaseDocument | null {
        return KnowledgeBaseDocument.$findOne(this.$params.document);
      },
      knowledgeBaseCategory(): KnowledgeBaseCategory | null {
        return KnowledgeBaseCategory.$findOne(this.$params.category);
      },
      knowledgeBaseTopLevelCategory(): KnowledgeBaseCategory | null {
        return KnowledgeBaseCategory.$findOne(this.$params.topLevelCategory);
      },
    },
  });

// AccountReferralCode, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { Lead } from "@kinherit/sdk/model/lead";

export const RecordAccountReferralCode = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/lead/{lead}/account-referral-code/{accountReferralCode}",
  method: "get",
  rootModel: AccountReferralCode,
  parse: (message: {
    lead: Lead | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: {
      lead: message.lead,
      accountReferralCode: message.accountReferralCode,
    },
  }),
});

<template>
  <Card title="Leads">
    <p>
      This table uses the Lead creation date to group by week/month, not the
      date that the account was converted/created. When filtering by assigned
      specialist, this is taken from the account, not the lead.
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
      class="mb-3"
    />
    <Button
      @click="displayLeadSources = !displayLeadSources"
      :color="displayLeadSources ? 'is-positive' : 'is-plain'"
      size="is-small"
      is-rounded
      class="mr-1"
    >
      Lead Source
    </Button>
    <Button
      @click="displayPercentages = !displayPercentages"
      :color="displayPercentages ? 'is-positive' : 'is-plain'"
      size="is-small"
      is-rounded
      class="mr-1"
    >
      Conversion %
    </Button>
    <Button
      @click="displayMaxPercentages = !displayMaxPercentages"
      :color="displayMaxPercentages ? 'is-positive' : 'is-plain'"
      size="is-small"
      is-rounded
      class="mr-1"
    >
      Max Conversion %
    </Button>
    <Button
      @click="displayDropout = !displayDropout"
      :color="displayDropout ? 'is-positive' : 'is-plain'"
      size="is-small"
      is-rounded
    >
      Dropout %
    </Button>
    <div
      v-if="datasets.length > 0"
      class="chart-container"
      style="position: relative; height: 40vh"
    >
      <BarChart
        :datasets="computedDatasets"
        :labels="labels"
        :options="{
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'linear',
              position: 'left',
            },
            percentage: {
              type: 'linear',
              position: 'right',
              beginAtZero: true,
              max: 100,
              min: 0,
              grid: {
                display: false,
              },
            },
          },
        }"
      />
    </div>
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      :footer="footer"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import BarChart from "@kinherit/framework/component.chart/bar-chart";
import Table from "@kinherit/framework/component.display/table";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { LeadsByEpReportRoute } from ".";
import { TLeadReportResult } from "../action/compute-leads-report.action";
import {
  hasCurrentRowHighlighting,
  quickPercentage,
} from "../data/helper-functions";
import { ReadLeadsReportFilters } from "../form/read-leads-report.form";

export default defineComponent({
  name: LeadsByEpReportRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.reports)],
  components: {
    Card,
    MasterListFilters,
    BarChart,
    Table,
    Button,
  },
  data: () => ({
    displayLeadSources: true,
    displayPercentages: false,
    displayMaxPercentages: false,
    displayDropout: false,
    datasets: [] as any[],
    conversionDatasets: [] as any[],
    maxConversionDatasets: [] as any[],
    displayDropoutDatasets: [] as any[],
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadLeadsReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Total", // the total number of leads
        class: "has-text-weight-bold",
        map: (results: TLeadReportResult) => results.lead.count,
      },
      {
        title: "Pending", // the number of leads that are in some sort of pending status, i.e new bld nurt rebk pot inp inp2 wr
        mapHtml: (results: TLeadReportResult) => {
          const pendingTotal = quickPercentage(
            results.lead.pendingCount,
            results.lead.count,
          );
          const buildTotal =
            results.lead.buildCount > 0
              ? ` <strong class='has-text-info'>(${results.lead.buildCount} Bld)</strong>`
              : "";
          const unqualifiedTotal =
            results.lead.unqualifiedCount > 0
              ? ` <strong class='has-text-warning-dark'>(${results.lead.unqualifiedCount} UQ)</strong>`
              : "";
          return `${pendingTotal}${buildTotal}${unqualifiedTotal}`;
        },
      },
      {
        title: "Dropout", // the number of leads that are in some sort of dropout status, i.e. QO UC1 UC2 junk
        class: "has-text-danger",
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(results.lead.dropoutCount, results.lead.count);
        },
      },
      {
        title: "Converted", // the number of leads that are in some sort of converted status, i.e. Enq Conv
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(
            results.lead.convertedCount,
            results.lead.count,
          );
        },
      },
      {
        title: "Acc", // the number of accounts
        map: (results: TLeadReportResult) => results.account.count,
      },
      {
        title: "Unpaid", // the number of accounts which don't have a paid order
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(
            results.account.unpaidCount,
            results.lead.count,
          );
        },
      },
      {
        title: "Not Now", // the number of accounts which don't have a paid order
        class: "has-text-warning-dark has-text-weight-bold",
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(
            results.account.notNowCount,
            results.lead.count,
          );
        },
      },
      {
        title: "Dropout", // the number of accounts which qualified out
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(
            results.account.dropoutCount,
            results.lead.count,
          );
        },
      },
      {
        title: "Paid", // the number of accounts paid
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: TLeadReportResult) => {
          return quickPercentage(results.account.paidCount, results.lead.count);
        },
      },
    ],
    footer: [
      "Total",
      // Total
      {
        class: "has-text-weight-bold",
        map: (results: TLeadReportResult[]) => {
          return results.reduce((total, row) => total + row.lead.count, 0);
        },
      },
      // Pending
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.pendingCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Dropout
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.dropoutCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Converted
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.convertedCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Account
      {
        class: "has-text-weight-bold",
        map: (results: TLeadReportResult[]) => {
          return results.reduce((total, row) => total + row.account.count, 0);
        },
      },
      // Unpaid
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.unpaidCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Not Now
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.notNowCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Dropout
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.dropoutCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Paid
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.paidCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
    ],
  }),
  computed: {
    computedDatasets() {
      const datasets = [];
      if (this.displayLeadSources) {
        datasets.push(...this.datasets);
      }
      if (this.displayPercentages) {
        datasets.push(...this.conversionDatasets);
      }
      if (this.displayMaxPercentages) {
        datasets.push(...this.maxConversionDatasets);
      }
      if (this.displayDropout) {
        datasets.push(...this.displayDropoutDatasets);
      }
      return datasets;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.report.leads(
      //   this.filters.localData,
      // );
      const result = await this.$actionBus.Leads(this.filters.localData);

      this.datasets = result.datasets;
      this.conversionDatasets = result.conversionDatasets;
      this.maxConversionDatasets = result.maxConversionDatasets;
      this.displayDropoutDatasets = result.dropoutDatasets;
      this.labels = result.labels.map((label) => label.toString());
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>

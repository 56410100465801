// UploadForm

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const DeleteUploadForm = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/upload-form/{accountReferral}/{uploadForm}",
  method: "delete",
  parse: (uploadForm: UploadForm) => ({
    params: {
      uploadForm,
      accountReferral: uploadForm.$data.accountReferral,
    },
  }),
  after: ({ message }) => {
    UploadForm.$delete(message);
  },
});

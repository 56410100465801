import { Api } from "@/service/api.service";
import { User } from "@kinherit/sdk";

interface RequestPasswordChangeMessage {
  user: string | User;
}

interface RequestPasswordChangeResponse {
  [key: string]: never;
}

export const RequestPasswordChange = async (
  message: RequestPasswordChangeMessage,
): Promise<RequestPasswordChangeResponse> => {
  await Api.resource("auth", "/v2/auth/change-password/user/{user}", {
    user: message.user,
  })
    .method("put")

    .result();

  return {};
};

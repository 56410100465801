import {
  KnowledgeBaseCategoryBreadCrumb,
  KnowledgeBaseCategoryMasterListBreadCrumb,
  KnowledgeBaseDocumentBreadCrumb,
  KnowledgeBaseTopLevelCategoryBreadCrumb,
} from "@/module/knowledge-base/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KnowledgeBaseDocumentDetailsContentRoute,
  Routes as KnowledgeBaseDocumentRoutes,
} from "./document/index";

export const KnowledgeBaseRoute = "KnowledgeBase";

/** Top Level Categories */
export const KnowledgeBaseTopLevelCategoryMasterListRoute =
  "KnowledgeBaseTopLevelCategoryMasterList";

export const KnowledgeBaseTopLevelCategoryDetailsRoute =
  "KnowledgeBaseTopLevelCategoryDetails";
export const KnowledgeBaseTopLevelCategoryDetailsURI =
  "/knowledge-base/:topLevelCategory";
export type KnowledgeBaseTopLevelCategoryDetailsParams = RouteParamObject<
  typeof KnowledgeBaseTopLevelCategoryDetailsURI
>;

export const KnowledgeBaseCategoryDetailsRoute = "KnowledgeBaseCategoryDetails";
export const KnowledgeBaseCategoryDetailsURI =
  "/knowledge-base/:topLevelCategory/category/:category";
export type KnowledgeBaseCategoryDetailsParams = RouteParamObject<
  typeof KnowledgeBaseCategoryDetailsURI
>;

/** Documents */
export const KnowledgeBaseDocumentDetailsRoute = "KnowledgeBaseDocumentDetails";
export const KnowledgeBaseDocumentDetailsURI =
  "/knowledge-base/:topLevelCategory/category/:category/document/:document";
export type KnowledgeBaseDocumentDetailsParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KnowledgeBaseRoute,
    path: "/knowledge-base",
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseMasterList" */ "./KnowledgeBaseCategoryMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KnowledgeBaseCategoryMasterListBreadCrumb],
    },
  },
  {
    name: KnowledgeBaseTopLevelCategoryDetailsRoute,
    path: KnowledgeBaseTopLevelCategoryDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseDetails" */ "./KnowledgeBaseCategoryMasterList.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        KnowledgeBaseTopLevelCategoryBreadCrumb(params),
      ],
    },
  },
  {
    name: KnowledgeBaseCategoryDetailsRoute,
    path: KnowledgeBaseCategoryDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseDetails" */ "./KnowledgeBaseCategoryDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        KnowledgeBaseTopLevelCategoryBreadCrumb(params),
        KnowledgeBaseCategoryBreadCrumb(params),
      ],
    },
  },
  {
    name: KnowledgeBaseDocumentDetailsRoute,
    path: KnowledgeBaseDocumentDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseDetails" */ "./KnowledgeBaseDocumentDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        KnowledgeBaseTopLevelCategoryBreadCrumb(params),
        KnowledgeBaseCategoryBreadCrumb(params),
        KnowledgeBaseDocumentBreadCrumb(params),
      ],
    },
    children: KnowledgeBaseDocumentRoutes,
    redirect: {
      name: KnowledgeBaseDocumentDetailsContentRoute,
    },
  },
];

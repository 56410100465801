<template>
  <div
    v-for="(options, title) in items"
    class="address-book-sidebar"
    :key="`widget-${title}-${$route.name?.toString()}`"
  >
    <div v-if="title" class="address-book-sidebar__title">{{ title }}</div>
    <Tabs
      v-if="options.length > 0"
      class="sidebar-page__tabs"
      :tab="options.findIndex((item) => item.route.name === $route.name)"
      is-vertical
      is-compact
      :config="options"
    >
      <template #tab-item-left="{ tab }">
        <div style="min-width: 1.6em; text-align: right">
          <Badge
            is-compact
            size="is-small"
            :color="tab.count > 0 ? 'is-kinherit-petrol' : 'is-white'"
            class="m-0"
            >{{ tab.count }}</Badge
          >
        </div>
      </template>
    </Tabs>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookSidebarWrapper",
  "selector": ".address-book-sidebar",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".sidebar-page__tabs",
      "items": [
        "All",
        "Account Holder",
        "Children",
        "Dependents",
        "Advisers",
        "Attorneys",
        "Beneficiaries",
        "Executors",
        "Gift Recipients",
        "Guardians",
        "Trustees",
        "Addresses",
        "Charities",
        "Companies",
        "Exclusions"
      ]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import Badge from "@kinherit/framework/component.display/badge";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import RouteStorageMixin from "@kinherit/framework/component.mixin/route-storage-mixin";
import { IsNotNull } from "@kinherit/orm";
import { Guardian, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookRoute } from ".";
import {
  KinvaultKintinDetailsAddressBookAccountHoldersRoute,
  KinvaultKintinDetailsAddressBookAddressesRoute,
  KinvaultKintinDetailsAddressBookAdvisersRoute,
  KinvaultKintinDetailsAddressBookAllRoute,
  KinvaultKintinDetailsAddressBookAttorneysRoute,
  KinvaultKintinDetailsAddressBookBeneficiariesRoute,
  KinvaultKintinDetailsAddressBookCharitiesRoute,
  KinvaultKintinDetailsAddressBookChildrenRoute,
  KinvaultKintinDetailsAddressBookCompaniesRoute,
  KinvaultKintinDetailsAddressBookDependentsRoute,
  KinvaultKintinDetailsAddressBookExclusionsRoute,
  KinvaultKintinDetailsAddressBookExecutorsRoute,
  KinvaultKintinDetailsAddressBookGiftRecipientsRoute,
  KinvaultKintinDetailsAddressBookGuardiansRoute,
  KinvaultKintinDetailsAddressBookTrusteesRoute,
} from "./index";
import { Titles } from "./types";

type Item = {
  count: number;
  label: string;
  route: { name: string };
} & TabOptions;

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookRoute,
  mixins: [KinvaultKintinDetailsMixin, RouteStorageMixin(true, "query")],
  components: {
    Tabs,
    Badge,
  },
  data: () => ({
    items: Object.freeze({
      "": [
        {
          count: 0,
          label: Titles.All,
          route: { name: KinvaultKintinDetailsAddressBookAllRoute },
        },
      ],
      Family: [
        {
          count: 0,
          label: Titles.AccountHolder,
          route: { name: KinvaultKintinDetailsAddressBookAccountHoldersRoute },
          nestedRoutes: [KinvaultKintinDetailsAddressBookRoute],
        },
        {
          count: 0,
          label: Titles.Children,
          route: { name: KinvaultKintinDetailsAddressBookChildrenRoute },
        },
        {
          count: 0,
          label: Titles.Dependents,
          route: { name: KinvaultKintinDetailsAddressBookDependentsRoute },
        },
      ],
      Roles: [
        {
          count: 0,
          label: Titles.Advisers,
          route: { name: KinvaultKintinDetailsAddressBookAdvisersRoute },
        },
        {
          count: 0,
          label: Titles.Attorneys,
          route: { name: KinvaultKintinDetailsAddressBookAttorneysRoute },
        },
        {
          count: 0,
          label: Titles.Beneficiaries,
          route: { name: KinvaultKintinDetailsAddressBookBeneficiariesRoute },
        },
        {
          count: 0,
          label: Titles.Executors,
          route: { name: KinvaultKintinDetailsAddressBookExecutorsRoute },
        },
        {
          count: 0,
          label: Titles.GiftRecipients,
          route: { name: KinvaultKintinDetailsAddressBookGiftRecipientsRoute },
        },
        {
          count: 0,
          label: Titles.Guardians,
          route: { name: KinvaultKintinDetailsAddressBookGuardiansRoute },
        },
        {
          count: 0,
          label: Titles.Trustees,
          route: { name: KinvaultKintinDetailsAddressBookTrusteesRoute },
        },
      ],
      Other: [
        {
          count: 0,
          label: Titles.Addesses,
          route: { name: KinvaultKintinDetailsAddressBookAddressesRoute },
        },
        {
          count: 0,
          label: Titles.Charities,
          route: { name: KinvaultKintinDetailsAddressBookCharitiesRoute },
        },
        {
          count: 0,
          label: Titles.Companies,
          route: { name: KinvaultKintinDetailsAddressBookCompaniesRoute },
        },
        {
          count: 0,
          label: Titles.Exclusions,
          route: { name: KinvaultKintinDetailsAddressBookExclusionsRoute },
        },
      ],
    }) satisfies Record<"" | "Family" | "Roles" | "Other", Array<Item>>,
    listener: null as null | (() => void),
  }),
  mounted(): void {
    this.setCounts();
    this.listener =
      // window.Kernel.ActionBus.kinvaultKintin.addressBook.$addListener(
      //   "record",
      //   () => this.setCounts(),
      // );
      this.$actionBus.$addListener("AddressBookRecord", () => this.setCounts());
  },
  unmounted(): void {
    if (!this.listener) {
      return;
    }

    // window.Kernel.ActionBus.kinvaultKintin.addressBook.$removeListener(
    //   "record",
    //   this.listener,
    // );
    this.$actionBus.$removeListener("AddressBookRecord", this.listener);
  },
  computed: {
    computedSelected(): { label: string; route: { name: string } } {
      return (
        Object.values(this.items)
          .flat()
          .find(
            (item) =>
              item.route.name.toString() === this.$route.name?.toString(),
          ) || this.items["Family"][1]
      );
    },
  },
  methods: {
    setCounts(): void {
      Object.keys(this.items).forEach((_groupKey) => {
        const groupKey = _groupKey as keyof typeof this.items;

        Object.keys(this.items[groupKey]).forEach((_itemKey) => {
          const itemKey =
            _itemKey as keyof (typeof this.items)[typeof groupKey];
          const item = this.items[groupKey][itemKey] as Item;

          switch (item.label) {
            case Titles.All:
              item.count = Person.$findBy({
                kintin: {
                  id: this.kintin?.id,
                },
              }).length;

              break;
            case Titles.AccountHolder:
              item.count = 1;

              if (this.kintin?.$data.secondaryPerson) {
                item.count++;
              }

              break;
            case Titles.Children:
              item.count = [
                ...Person.$findBy({
                  kintin: {
                    id: this.kintin?.id,
                  },
                  relationToPrimaryPerson: IsNotNull(),
                }),
                ...Person.$findBy({
                  kintin: {
                    id: this.kintin?.id,
                  },
                  relationToSecondaryPerson: IsNotNull(),
                }),
              ]
                .unique("id")
                .filter((person) => {
                  return (
                    person.relationToPrimaryPerson?.data?.isChild === true ||
                    person.relationToSecondaryPerson?.data?.isChild === true
                  );
                }).length;

              break;
            case Titles.Dependents:
              item.count = [
                ...Person.$findBy({
                  kintin: {
                    id: this.kintin?.id,
                  },
                  relationToPrimaryPerson: IsNotNull(),
                }),
                ...Person.$findBy({
                  kintin: {
                    id: this.kintin?.id,
                  },
                  relationToSecondaryPerson: IsNotNull(),
                }),
              ]
                .unique("id")
                .cast<Person>()
                .filter((person) => {
                  return (
                    person.under18ForPlanningPurposes || person.requiresCare
                  );
                }).length;

              break;
            case Titles.Advisers:
              item.count = [
                ...(this.kintin?.primaryPerson?.appointedAdviser ?? []),
                ...(this.kintin?.secondaryPerson?.appointedAdviser ?? []),
              ]
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;

              break;
            case Titles.Attorneys:
              item.count = [
                ...(this.kintin?.primaryPerson?.appointedAttorney ?? []),
                ...(this.kintin?.secondaryPerson?.appointedAttorney ?? []),
              ]
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;

              break;
            case Titles.Beneficiaries:
              item.count = [
                ...(this.kintin?.primaryPerson?.appointedBeneficiary ?? []),
                ...(this.kintin?.secondaryPerson?.appointedBeneficiary ?? []),
              ]
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;

              break;
            case Titles.Executors:
              item.count = [
                ...(this.kintin?.primaryPerson?.appointedExecutor ?? []),
                ...(this.kintin?.secondaryPerson?.appointedExecutor ?? []),
              ]
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;

              break;
            case Titles.GiftRecipients:
              item.count = [
                ...(this.kintin?.primaryPerson?.sendingGifts ?? []),
                ...(this.kintin?.secondaryPerson?.sendingGifts ?? []),
              ]
                .pluck("forPerson")
                .unique("id")
                .filter(Boolean).length;
              break;
            case Titles.Guardians:
              item.count = Guardian.$findBy({
                forPerson: {
                  kintin: {
                    id: this.kintin?.id,
                  },
                },
              })
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;
              break;
            case Titles.Trustees:
              item.count = [
                ...(this.kintin?.primaryPerson?.appointedTrustee ?? []),
                ...(this.kintin?.secondaryPerson?.appointedTrustee ?? []),
              ]
                .pluck("person")
                .unique("id")
                .filter(Boolean).length;

              break;
            case Titles.Addesses:
              item.count = (this.kintin?.people ?? [])
                .map((p) => p.profile.addresses)
                .flat()
                .unique("summary")
                .filter(Boolean).length;
              break;
            case Titles.Charities:
              item.count = (
                this.kintin?.people.filter((p) => p.type === "charity") ?? []
              ).length;
              break;
            case Titles.Companies:
              item.count = (
                this.kintin?.people.filter((p) => p.type === "company") ?? []
              ).length;
              break;
            case Titles.Exclusions:
              item.count = (this.kintin?.exclusions ?? []).length;
              break;
          }

          // @ts-ignore
          this.items[groupKey][itemKey] = item;
        });
      });

      this.$forceUpdate();
    },
  },
});
</script>

<style lang="scss">
.address-book-sidebar__title {
  border-right: 1px solid var(--color-border);
  cursor: default;
  @include font-weight(700);
}
</style>

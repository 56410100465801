// BrandedKinvaultSubscriptionReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/branded-kinvault-subscription-referral-code";

export const CreateBrandedKinvaultSubscriptionReferralCode = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/subscription-referral-code",
  method: "post",
  parse: (target: BrandedKinvaultSubscriptionReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      brandedKinvault: target.$data.brandedKinvault,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultSubscriptionReferralCode.$delete(message);
  },
});

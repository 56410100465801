import { Api } from "@kinherit/sdk/api";

//
export const ReconcileTypes = async () => {
  return await Api.resource(
    "portal-2",
    "/v2/portal-2/settings/acuity/reconcile-types",
  )
    .method("get")
    .result();
};

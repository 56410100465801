<template>
  <div class="account-referral-code-master-list">
    <PageHeader htag="h1" text="Referrals">
      <template #buttons>
        <Button
          v-if="hasWriteAccess"
          class="create"
          size="is-normal"
          @click="create"
        >
          Create Referral
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        code: 'Code',
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #sacrificeFee="{ row }">
        <Icon v-if="row.isFeeSacrifice" icon="Tick" class="has-text-success" />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>

      <template #salarySacrifice="{ row }">
        <Icon
          v-if="row.isSalarySacrifice"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #ff30="{ row }">
        <Icon
          v-if="row.isFriendsAndFamily"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralCodeMasterListWrapper",
  "route": "AccountReferralCodeMasterList",
  "selector": ".account-referral-code-master-list",
  "imports": {
    "ReadAccountReferralCodeFormWrapper": "@/module/admin.account-referral-code/form/read-account-referral-code.form.test",
    "AccountReferralCodeDetailsWrapper": "@/module/admin.account-referral-code/page/details/AccountReferralCodeDetails.test",
    "UpdateAccountReferralCodeFormWrapper": "@/module/admin.account-referral-code/form/update-account-referral-code.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadAccountReferralCodeFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "AccountReferralCodeDetailsWrapper"
    },
    "createAccountReferralCode": {
      "type": "click",
      "selector": ".create"
    },
    "createAccountReferralCodeForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateAccountReferralCodeFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadAccountReferralCodeForm } from "@/module/admin.account-referral-code/form/read-account-referral-code.form";
import { AuthService } from "@/service/auth.service";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, Like } from "@kinherit/orm/index";
import { AccountReferralCode, IAccountReferralCode } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { ReferralCodeMasterListRoute } from ".";
import { UpdateAccountReferralCodeForm } from "../form/update-account-referral-code.form";
import {
  AccountReferralCodeDetailsParams,
  AccountReferralCodeDetailsRoute,
} from "./details";

export default defineComponent({
  name: ReferralCodeMasterListRoute,
  components: { MasterListPage, PageHeader, Button, Icon },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.accountReferralCodes,
    ),
  ],
  data: () => ({
    filters: ReadAccountReferralCodeForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: AccountReferralCode) => v.name,
      },
      {
        title: "Code",
        sort: true,
        map: (v: AccountReferralCode) => v.code,
      },
      {
        title: "Category",
        sort: true,
        map: (v: AccountReferralCode) => v.category?.text,
      },
      {
        title: "Usage",
        sort: true,
        map: (v: AccountReferralCode) => v.referralCount,
      },
      {
        title: "Sacrifice Fee",
        slot: "sacrificeFee",
      },
      {
        title: "Salary Sacrifice",
        slot: "salarySacrifice",
      },
      {
        title: "F&F 30%",
        slot: "ff30",
      },
      {
        title: "Notes",
        sort: true,
        map: (v: AccountReferralCode) => v.notes,
        style: {
          whiteSpace: "normal",
        },
      },
    ] as Array<TableColumn>,
    rows: Array<AccountReferralCode>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferralCode,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("account-referral-code:write");
    },
  },
  methods: {
    async create(): Promise<void> {
      const accountReferralCode = await UpdateAccountReferralCodeForm().dialog({
        dialog: {
          title: "Create Referral Code",
        },
      });
      //      await window.Kernel.ActionBus.adminAccountReferralCode.create({
      await this.$actionBus.CreateAccountReferralCode(accountReferralCode);

      this.refresh(this.filters.localData);
    },
    handleClick(referral: AccountReferralCode, event: MouseEvent): void {
      const params: AccountReferralCodeDetailsParams = {
        accountReferralCode: referral.id,
      };

      window.Kernel.visitRoute(
        {
          name: AccountReferralCodeDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadAccountReferralCodeForm>["localData"],
    ): Promise<void> {
      // await window.Kernel.ActionBus.adminAccountReferralCode.read({
      const data = await this.$actionBus.ReadAccountReferralCode({
        query: [
          {
            code: Like(formData.code),
            category: {
              id: In(formData.category),
            },
            createdAt: Between(formData.created),
          },
          {
            name: Like(formData.code),
            category: {
              id: In(formData.category),
            },
            createdAt: Between(formData.created),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.accountReferralCode;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

// EntityHistory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EntityHistory } from "@kinherit/sdk/model/entity-history";
import { Lead } from "@kinherit/sdk/model/lead";

export const ReadHistory = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/lead/{lead}/history",
  method: "get",
  rootModel: EntityHistory,
  parse: (lead: Lead | string) => ({
    params: {
      lead,
    },
  }),
});

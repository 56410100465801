import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultInvitation } from "./create-branded-kinvault-invitation.action";
import { CreateEmailCampaign } from "./create-email-campaign.action";
import { DeleteBrandedKinvaultInvitation } from "./delete-branded-kinvault-invitation.action";
import { Portal2AdminBrandedKinvaultInvitationFees } from "./fees";
import { ReadBrandedKinvaultInvitation } from "./read-branded-kinvault-invitation.action";
import { RecordBrandedKinvaultInvitation } from "./record-branded-kinvault-invitation.action";
import { SendInvitationEmail } from "./send-invitation-email.action";
import { SendMembersCsv } from "./send-members-csv.action";
import { UpdateBrandedKinvaultInvitation } from "./update-branded-kinvault-invitation.action";
import { UploadMembersCsv } from "./upload-members-csv.action";
import { ValidateMembersCsv } from "./validate-members-csv.action";
import { Portal2AdminBrandedKinvaultDefaultBrandedKinvaultReferralCode } from "@/action-bus/portal-2/admin/branded-kinvault/default-branded-kinvault-referral-code";

export const Portal2AdminBrandedKinvaultInvitation = DefineActionBus({
  name: "portal-2-admin-branded-kinvault-invitation",
  actions: {
    CreateBrandedKinvaultInvitation,
    CreateEmailCampaign,
    DeleteBrandedKinvaultInvitation,
    ReadBrandedKinvaultInvitation,
    RecordBrandedKinvaultInvitation,
    SendInvitationEmail,
    SendMembersCsv,
    UpdateBrandedKinvaultInvitation,
    UploadMembersCsv,
    ValidateMembersCsv,
    fees: Portal2AdminBrandedKinvaultInvitationFees,
    defaultBrandedKinvaultReferralCode:
      Portal2AdminBrandedKinvaultDefaultBrandedKinvaultReferralCode,
  },
});

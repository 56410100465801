// Exclusion

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Exclusion } from "@kinherit/sdk/model/exclusion";

export const DeleteExclusion = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/exclusion/{exclusion}",
  method: "delete",
  parse: (exclusion: Exclusion) => ({
    params: {
      exclusion,
      kintin: exclusion.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Exclusion.$delete(message);
  },
});

import { Api } from "@kinherit/sdk/api";
import { Kintin, Person } from "@kinherit/sdk/index";

// Person, Profile, Address, PhoneNumber, EmailAddress
export const MergeSignDocContact = async (message: {
  person: Person | string;
  kintin: Kintin | string;
  property:
    | "organisationName"
    | "organisationNumber"
    | "title"
    | "firstName"
    | "middleNames"
    | "lastName"
    | "phoneNumbers"
    | "emails"
    | "addresses"
    | "dateOfBirth";
}) => {
  const person =
    "string" === typeof message.person
      ? message.person
      : message.person.$data.id;
  const kintin =
    "string" === typeof message.kintin
      ? message.kintin
      : message.kintin.$data.id;

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/sign-doc/merge-contacts",
  )
    .method("post")
    .body({
      person,
      kintin,
      property: message.property,
    })
    .result();

  return {};
};

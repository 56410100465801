import { Api } from "@kinherit/sdk/api";
import { Kintin, Property } from "@kinherit/sdk/index";

//
export const FillNoticeOfSeverance = async (message: {
  kintin: Kintin;
  property: Property;
}) => {
  const { kintin, property } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/process/notice-of-severance",
    {
      kintin: kintin,
    },
  )
    .method("get")
    .params({
      kintin: kintin.id,
      property: property.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} Notice of Severance - ${surname}`,
    });
};

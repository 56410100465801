// Executor

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Executor } from "@kinherit/sdk/model/executor";

export const DeleteExecutor = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/executor/{executor}",
  method: "delete",
  parse: (executor: Executor) => ({
    params: {
      executor,
      kintin: executor.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

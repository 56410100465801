<template>
  <RouterChildView
    :key="renderKey"
    @reload="load"
    class="kinvault-kintin-details-address-book"
  >
    <KinvaultKintinDetailsAddressAccountHolders />
  </RouterChildView>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookWrapper",
  "route": "KinvaultKintinDetailsAddressBook",
  "selector": ".kinvault-kintin-details-address-book",
  "imports": {
    "KinvaultKintinDetailsAddressBookAccountHoldersWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.AccountHolders.test",
    "KinvaultKintinDetailsAddressBookAddressesWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Addresses.test",
    "KinvaultKintinDetailsAddressBookAdvisersWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Advisers.test",
    "KinvaultKintinDetailsAddressBookAllWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.All.test",
    "KinvaultKintinDetailsAddressBookAttorneysWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Attorneys.test",
    "KinvaultKintinDetailsAddressBookBeneficiariesWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Beneficiaries.test",
    "KinvaultKintinDetailsAddressBookCharitiesWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Charities.test",
    "KinvaultKintinDetailsAddressBookChildrenWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Children.test",
    "KinvaultKintinDetailsAddressBookCompaniesWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Companies.test",
    "KinvaultKintinDetailsAddressBookDependentsWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Dependents.test",
    "KinvaultKintinDetailsAddressBookExclusionsWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Exclusions.test",
    "KinvaultKintinDetailsAddressBookExecutorsWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Executors.test",
    "KinvaultKintinDetailsAddressBookGiftRecipientsWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.GiftRecipients.test",
    "KinvaultKintinDetailsAddressBookGuardiansWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Guardians.test",
    "KinvaultKintinDetailsAddressBookTrusteesWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Trustees.test",
    "KinvaultKintinDetailsAddressBookSidebarWrapper": "@/module/kinvault.kintin/page/details/address-book/KintinDetails.AddressBook.Sidebar.test"
  },
  "methods": {
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookSidebarWrapper"
    },
    "accountHolders": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookAccountHoldersWrapper"
    },
    "addresses": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookAddressesWrapper"
    },
    "advisers": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookAdvisersWrapper"
    },
    "all": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookAllWrapper"
    },
    "attorneys": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookAttorneysWrapper"
    },
    "beneficiaries": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookBeneficiariesWrapper"
    },
    "charities": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookCharitiesWrapper"
    },
    "children": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookChildrenWrapper"
    },
    "companies": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookCompaniesWrapper"
    },
    "dependents": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookDependentsWrapper"
    },
    "exclusions": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookExclusionsWrapper"
    },
    "executors": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookExecutorsWrapper"
    },
    "giftRecipients": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookGiftRecipientsWrapper"
    },
    "guardians": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookGuardiansWrapper"
    },
    "trustees": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsAddressBookTrusteesWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookRoute } from ".";
import KinvaultKintinDetailsAddressAccountHolders from "./KintinDetails.AddressBook.AccountHolders.vue";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    RouterChildView,
    KinvaultKintinDetailsAddressAccountHolders,
  },
  data: () => ({
    renderKey: 0,
    actionBusListeners: [] as string[],
  }),
  async beforeMount(): Promise<void> {
    await this.load();
  },
  methods: {
    async load(): Promise<void> {
      // await window.Kernel.ActionBus.kinvaultKintin.addressBook.record(
      await this.$actionBus.AddressBookRecord({
        message: this.$params.kintin,
      });

      this.$forceUpdate();
    },
  },
});
</script>

// KnowledgeBaseDocument

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const CreateKnowledgeBaseDocument = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/knowledge-base/category",
  method: "post",
  parse: (target: KnowledgeBaseDocument) => ({
    body: {
      title: target.$data.title,
      content: target.$data.content,
      categories: target.$data.categories,
    },
  }),
  after: async ({ message }) => {
    message.$delete();
  },
});

<template>
  <div
    class="status-component is-clickable"
    @click="handleClick"
    :key="renderKey"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <template v-if="isBlock">
      <div
        :class="[
          'status-component__box has-border',
          {
            'has-border-success has-background-success': isComplete,
            'has-border-danger': isClosed,
            'has-border-warning': isNotNow,
            'has-border-purple has-background-purple':
              isBereavement || isDeceased,
          },

          { 'has-background-danger': isClosed && hover },
        ]"
      >
        <span v-if="showStage">{{ stage.text }}</span>
        <span v-if="showStatus">{{ status.text }}</span>
        <span v-if="showNextActionDate"> - ({{ nextAction.text }})</span>
        <span v-if="showStatusReason">
          {{ statusReason ? " (" + statusReason + ")" : "" }}
        </span>
      </div>
    </template>
    <template v-else>
      <div
        v-if="showStage"
        :class="[
          `status-component__box has-border has-border-stage-${stage.value?.slice(
            0,
            1,
          )}`,
          `has-background-stage-${stage.value?.slice(0, 1)}${
            hover ? '' : '-light'
          }
        }`,
        ]"
      >
        {{ stage.text }}
      </div>
      <div
        v-if="showStatus"
        :class="[
          `status-component__box has-border has-border-${status.class}`,
          `has-background-${status.class + (hover ? '' : '-light')}`,
        ]"
      >
        {{ status.text }}
      </div>
      <div
        v-if="showNextActionDate"
        :class="[
          `status-component__box has-border has-border-${nextAction.class}`,
          `has-background-${nextAction.class + (hover ? '' : '-light')}`,
        ]"
      >
        {{ nextAction.text }}
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { CreateNote, CreateStatusLog } from "@/config/model.config";
import { UpdateKintinStatusForm } from "@/shared/form/kintin/update-kintin-status.form";
import { Kintin } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";

import { Option } from "@kinherit/sdk";
export default defineComponent({
  name: "KintinStatusComponent",
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
  },
  data: () => ({
    stage: {
      text: "Unknown",
      class: "grey",
      value: null as null | string,
    },
    status: {
      text: "Unknown",
      class: "grey",
    },
    nextAction: {
      text: "Not Set",
      class: "grey",
    },
    statusReason: "",
    renderKey: 0,
    hover: false as boolean,
  }),
  computed: {
    isComplete(): boolean {
      return this.kintin.stage.value === "h";
    },
    isNotNow(): boolean {
      return this.kintin.status.value === "notnow";
    },
    isClosed(): boolean {
      return this.kintin.status.value === "closed";
    },
    isBereavement(): boolean {
      return this.kintin.stage.value === "z1";
    },
    isDeceased(): boolean {
      return this.kintin.stage.value === "z2";
    },
    isBlock(): boolean {
      return (
        this.isClosed ||
        this.isComplete ||
        this.isNotNow ||
        this.isBereavement ||
        this.isDeceased
      );
    },
    showStage(): boolean {
      return !this.isClosed && !this.isNotNow;
    },
    showStatus(): boolean {
      return !this.isComplete && !this.isBereavement && !this.isDeceased;
    },
    showNextActionDate(): boolean {
      return !this.isClosed && !this.isComplete && !this.isDeceased;
    },
    showStatusReason(): boolean {
      return this.isClosed;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const kintin = Kintin.$findOneOrThrow(this.kintin.id);

      this.stage = {
        text: kintin.stage?.text ?? ``,
        class: kintin.stage?.class ?? ``,
        value: kintin.stage?.value ?? ``,
      };
      this.status = {
        text: kintin.status?.text ?? ``,
        class: kintin.status?.class ?? ``,
      };
      this.statusReason =
        Option.$findOne(kintin.statusChangeReason)?.text ?? "";

      const nextActionAt = kintin.referral?.nextActionAt;
      const isToday = nextActionAt?.sameDayAs(new DateTime()) ?? false;
      const isTomorrow =
        nextActionAt?.sameDayAs(new DateTime().add(1, "day")) ?? false;
      const isOverdue = nextActionAt?.isBefore(new DateTime()) ?? false;

      switch (true) {
        case isToday:
          this.nextAction = {
            text: "Today",
            class: "teal",
          };
          break;
        case isTomorrow:
          this.nextAction = {
            text: "Tomorrow",
            class: "purple",
          };
          break;
        case isOverdue:
          this.nextAction = {
            text:
              "Overdue by " +
              (nextActionAt?.since({
                format: "day",
                short: true,
              }) ?? "Unknown"),
            class: "danger",
          };
          break;
        default:
          this.nextAction = {
            text: nextActionAt?.formatDate ?? "Unknown",
            class: "grey",
          };
          break;
      }

      this.renderKey++;
      this.$forceUpdate();
    },
    async handleClick() {
      const kintin = Kintin.$findOneOrThrow(this.kintin.id);

      try {
        const result = await UpdateKintinStatusForm(kintin).dialog({
          dialog: {
            title: `${kintin.friendlyName} - ${kintin.ref}`,
            type: "fullscreen",
          },
          button: {
            ok: {
              text: "Update",
            },
            cancel: {
              text: "Cancel",
            },
          },
        });

        kintin.$persist();

        // await window.Kernel.ActionBus.kinvaultKintin.kintin.update({
        //   kintin,
        // });
        await window.Kernel.ActionBus2.portal2.kinvault.UpdateKintin(kintin);

        const referral = kintin.referral;
        if (referral) {
          // await window.Kernel.ActionBus.core.accountReferral.update({
          //   referral,
          // });
          await window.Kernel.ActionBus2.portal2.kinvault.accountReferral.UpdateAccountReferral(
            referral,
          );
        }

        if ([null, ""].includes(result.notes) === false) {
          const note = CreateNote({
            kintin: kintin.id,
            notes: result.notes,
            name: `Status changed to ${kintin.status.text}`,
            type: "kintinStatusChange",
            pinned: false,
          }).$persist();

          note.statusLog = CreateStatusLog({
            stage: kintin.stage.id,
            status: kintin.status.id,
            kintin: kintin.id,
            nextActionAt: kintin.referral?.nextActionAt?.formatMachine,
          });

          note.statusLog.$persist();

          await window.Kernel.ActionBus2.core.notes.CreateNote({
            note,
            kintin,
          });

          note.$delete({
            statusLog: true,
          });
        }

        if (result.removeUserBrandedKinvaults) {
          await window.Kernel.ActionBus2.portal2.kinvault.status.RevokeBrandedKinvaultAccess(
            kintin,
          );
        }

        if (result.voidOutstandingOrders) {
          // await window.Kernel.ActionBus.kinvaultKintin.order.voidOutstand({
          //   kintin,
          // });
          await window.Kernel.ActionBus2.portal2.kinvault.status.VoidOutstandingOrders(
            { kintin },
          );
        }

        if (result.disableUserAccount) {
          // await window.Kernel.ActionBus.kinvaultKintin.kintinUserAccess.revoke({
          //   kintin,
          // });
          await window.Kernel.ActionBus2.portal2.kinvault.status.RevokeAccess({
            kintin,
          });
        }

        kintin.$restore({
          referral: true,
        });

        this.load();
      } catch (e) {
        kintin.$restore();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.status-component {
  display: flex;
  gap: 0.5em;

  @media (max-width: 1024px) {
    flex-direction: column;

    &__box span {
      position: static !important;
      transform: none !important;
    }
  }

  &__box {
    padding: 0.75em 0.75em;
    font-size: $size-small;
    line-height: 1;
    @include font-weight(700);
    flex: 1;
    min-width: 12em;
    transition: all 0.25s;
    border-width: 0 0 0 0.5em;

    @media (min-width: 1024px) {
      &:first-of-type {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
      }

      &:last-of-type {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
      }
    }
  }
}
</style>

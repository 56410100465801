<template>
  <SortReferStatusPage v-if="externalId" :externalId="externalId" />
</template>

<script lang="ts">
import { LeadDetailsSortReferRoute } from "@/module/lead/page/details/external";
import SortReferStatusPage from "@/shared/component/external/SortReferStatusPage.vue";
import { defineComponent } from "vue";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsSortReferRoute,
  mixins: [LeadDetailsMixin],
  components: {
    SortReferStatusPage,
  },
  computed: {
    externalId(): string | null {
      return this.lead?.referral.externalId || null;
    },
  },
});
</script>

// CallScript

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/index";
import { CallScript } from "@kinherit/sdk/model/call-script";

export const ReadCallScript = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/call-script",
  method: "get",
  rootModel: CallScript,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});

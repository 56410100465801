<template>
  <Card title="Cache">
    <p>
      Clears the site wide API cache. Note, if using Redis cluster this should
      only be needed in extremis as cached entries will naturally expire after 7
      days.
    </p>
    <Button text="Clear Cache" @click="clearCache"
  /></Card>
</template>

<script lang="ts">
import { SettingsCacheRoute } from "@/module/settings/page";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsCacheRoute,
  components: { Card, Button },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.core)],
  methods: {
    async clearCache() {
      await this.$actionBus.ClearCache();
    },
  },
});
</script>

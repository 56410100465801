// User, Profile, Credential

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";
import { User } from "@kinherit/sdk/model/user";

export const ReadMembers = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/{brandedKinvault}/members",
  method: "get",
  rootModel: User,
  parse: (brandedKinvault: BrandedKinvault | string) => ({
    params: {
      brandedKinvault,
    },
  }),
});

// IntroducerContact, Profile, IntroducerCompany

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const ReadIntroducerContact = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/introducer-contact",
  method: "get",
  rootModel: IntroducerContact,
  parse: () => ({}),
});

// PhysicalStorageFileLog

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { PhysicalStorageFileLog } from "@kinherit/sdk/model/physical-storage-file-log";

export const UpdatePhysicalStorageFileLog = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/storage-requests/{physicalStorageFileLog}",
  method: "patch",
  parse: (target: PhysicalStorageFileLog) => ({
    body: {
      createdBy: target.$data.createdBy,
      confirmedBy: target.$data.confirmedBy,
      completedAt: target.$data.completedAt,
      notes: target.$data.notes,
      requestedAt: target.$data.requestedAt,
      file: target.$data.file,
      logAction: target.$data.logAction,
      kintin: target.$data.kintin,
    },
    params: {
      physicalStorageFileLog: target,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});

// Gift

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Gift } from "@kinherit/sdk/model/gift";

export const UpdateGift = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/gift/{gift}",
  method: "patch",
  parse: (target: Gift) => ({
    body: {
      amount: target.$data.amount,
      notes: target.$data.notes,
      onSecondDeath: target.$data.onSecondDeath,
      asPrimaryResidence: target.$data.asPrimaryResidence,
      charityName: target.$data.charityName,
      charityNo: target.$data.charityNo,
      sortOrder: target.$data.sortOrder,
      outputPreference: target.$data.outputPreference,
      kintin: target.$data.kintin,
      address: target.$data.address,
      forPerson: target.$data.forPerson,
      sentBy: target.$data.sentBy,
      type: target.$data.type,
      classGiftType: target.$data.classGiftType,
      reserveclassGiftType: target.$data.reserveclassGiftType,
      reservePerson: target.$data.reservePerson,
      ifPredeceased: target.$data.ifPredeceased,
      reminder: target.$data.reminder,
    },
    params: {
      gift: target,
      kintin: target.$data.kintin,
    },
  }),
});

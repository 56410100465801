// BrandedKinvaultSubscriptionReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/branded-kinvault-subscription-referral-code";

export const RecordBrandedKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
  method: "get",
  rootModel: BrandedKinvaultSubscriptionReferralCode,
  parse: (
    brandedKinvaultSubscriptionReferralCode:
      | BrandedKinvaultSubscriptionReferralCode
      | string,
  ) => ({
    params: {
      brandedKinvaultSubscriptionReferralCode,
    },
  }),
});

// IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerNetwork } from "@kinherit/sdk/model/introducer-network";

export const ReadIntroducerNetwork = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/networks",
  method: "get",
  rootModel: IntroducerNetwork,
  parse: () => ({}),
});

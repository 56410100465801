<template>
  <div class="mt-4 introducer-company-details-referrals">
    <MasterListPage
      v-if="$data.filters"
      title="Referrals"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="rowClicked"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsReferralsWrapper",
  "route": "IntroducerCompanyDetailsReferrals",
  "selector": ".introducer-company-details-referrals",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadAccountReferralFormWrapper": "@/module/introducer.company/form/read-account-referral.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadAccountReferralFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadAccountReferralForm } from "@/module/introducer.company/form/read-account-referral.form";
import { IntroducerCompanyDetailsMixin } from "@/module/introducer.company/mixin/introducer-company-details.mixin";
import { IntroducerCompanyDetailsReferralsRoute } from "@/module/introducer.company/page/details/referrals";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import { AccountReferral, IAccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: IntroducerCompanyDetailsReferralsRoute,
  components: { MasterListPage },
  mixins: [AuthService.mixin(), IntroducerCompanyDetailsMixin],
  data: () => ({
    filters: ReadAccountReferralForm(),
    columns: [
      {
        title: "Type",
        sort: true,
        map: (v) => (v.kintin ? "Kintin" : "Lead"),
      },
      {
        title: "Introduced On",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        title: "By",
        sort: true,
        mapHtml: (v) => v.contact?.profile?.fullName ?? "<em>Unassigned</em>",
      },
      {
        title: "Client",
        sort: true,
        map: (v) => v.kintin?.friendlyName || v.lead?.profile.fullName,
      },
      {
        title: "Stage",
        sort: true,
        mapHtml: (v) => v.kintin?.stage?.text ?? "<em>Lead</em>",
      },
      {
        title: "Status",
        sort: true,
        map: (v) => v.kintin?.status?.text ?? v.lead?.status?.text ?? "Not Set",
      },
      {
        title: "Code",
        sort: true,
        map: (v) => v.referralCode?.code,
      },
    ] as Array<TableColumn<AccountReferral>>,
    rows: Array<AccountReferral>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferral,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("introducer-company:write");
    },
  },
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadAccountReferralForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.company.referral.ReadAccountReferrals({
        message: this.$params.introducerCompany,
        query: [
          {
            kintin: {
              friendlyName: Like(formData.name),
            },
            createdAt: Between(formData.created),
          },
          {
            lead: {
              profile: {
                fullName: Like(formData.name),
              },
            },
            createdAt: Between(formData.created),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.accountReferral;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    rowClicked(row: AccountReferral): void {
      if (row.kintin) {
        this.$router.push({
          name: KinvaultKintinDetailsRoute,
          params: {
            kintin: row.kintin.id,
          } as KinvaultKintinDetailsParams,
        });
      } else if (row.lead) {
        this.$router.push({
          name: LeadDetailsRoute,
          params: {
            lead: row.lead.id,
          } as LeadDetailsParams,
        });
      }
    },
  },
});
</script>

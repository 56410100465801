import "@kinherit/framework/api/handlers";
import Style from "@kinherit/framework/style";
import "@kinherit/ts-common/prototype/array";
import "@kinherit/ts-common/prototype/console";
import "@kinherit/ts-common/prototype/number";
import "@kinherit/ts-common/prototype/object";
import "@kinherit/ts-common/prototype/string";

import "@/config/style.scss";

import "@/module";

import { FooterConfig } from "@/config/footer.config";
import { NavbarConfig } from "@/config/navbar.config";
import App from "@kinherit/framework/core/internal-components/app";
import { Kernel, KernelInterface } from "@kinherit/framework/core/kernel";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { createApp } from "vue";

import { ActionBus as ActionBus2 } from "@/action-bus";
import { ActionBus } from "@/module/action-bus";
import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Router } from "./config/router.config";

const kernel = new Kernel({
  APP_VERSION: process.env.APP_VERSION as string,
  Mode:
    (import.meta.env.VITE_APP_KERNEL_MODE as KernelModes) ?? KernelModes.Dev,
  Components: {
    footer: FooterConfig,
    navbar: NavbarConfig,
  },
  Router,
  FrameworkUrl: import.meta.env.VITE_APP_API_URL as string,
  StripeKey: import.meta.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string,
  SentryDsn:
    "https://9dbc3f8df5f900055bbc8c0e4996784e@o4506846247321600.ingest.us.sentry.io/4506846249680896",
  SentryBeforeSend(event, hint, sentry) {
    const username = ActiveSession.$getInstance()?.username;
    sentry.setUser(username ? { username: username } : null);
    event.environment = import.meta.env.MODE;

    return event;
  },
  SentryRecord: false,
  ActionBus: ActionBus,
  ActionBus2: ActionBus2,
});

(async () => {
  sessionStorage.clear();

  const app = createApp(App);

  kernel.init(app, async () => {
    const activeSession = ActiveSession.$getInstance();

    if (false === activeSession?.mfaRequired) {
      try {
        // await window.Kernel.ActionBus.execute("auth/session/complete", {
        //   activeSession,
        // });
        await window.Kernel.ActionBus2.auth.session.CompleteSession({
          activeSession,
        });
      } catch (e) {
        console.error(e);
      }
    }
  });

  kernel.injectStylesheet("framework", Style);

  kernel.Theme.setCssVars([
    {
      key: "--site-header-logo-width",
      value: "100px",
    },
    {
      key: "--site-header-margin",
      value: "1rem 2rem",
    },
    {
      key: "--color-background",
      value: "#fbfbfb",
    },
    {
      key: "--card-margin-bottom",
      value: "var(--size-normal)",
    },
    {
      key: "--card-padding-top",
      value: "var(--size-normal)",
    },
    {
      key: "--card-padding-bottom",
      value: "var(--size-normal)",
    },
    {
      key: "--card-padding-left",
      value: "var(--size-normal)",
    },
    {
      key: "--card-padding-right",
      value: "var(--size-normal)",
    },
    {
      key: "--content-max-width",
      value: "1800px",
    },
    {
      key: "--page-padding",
      value: "0",
    },
  ]);
})();

declare global {
  interface Window {
    Kernel: KernelInterface<typeof ActionBus, typeof ActionBus2>;
  }
}

// UploadForm

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const UpdateUploadForm = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/upload-form/{accountReferral}/{uploadForm}",
  method: "patch",
  parse: (target: UploadForm) => ({
    body: {
      name: target.$data.name,
      legacySignkey: target.$data.legacySignkey,
      validTill: target.$data.validTill,
      submissionCount: target.$data.submissionCount,
      accountReferral: target.$data.accountReferral,
    },
    params: {
      uploadForm: target,
      accountReferral: target.$data.accountReferral,
    },
  }),
});

// Kintin, Profile, AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const ReadAccountReferralCodeKintins = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/contact/referral/{introducerContact}/code/{accountReferralCode}/kintins",
  method: "get",
  rootModel: Kintin,
  parse: (message: {
    introducerContact: IntroducerContact | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: {
      introducerContact: message.introducerContact,
      accountReferralCode: message.accountReferralCode,
    },
  }),
});

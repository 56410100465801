<template>
  <PageHeader htag="h1" text="Dashboard (EP)" />
  <MasterListFilters
    v-if="$data.filters"
    :filters="$data.filters"
    @submit="refresh"
  />
  <div v-if="$data.filters">
    <!--
    This whole section is aimed at establishing the existing workload, and not performance.
  -->
    <DashboardStatisticWidgetWrapper title="Current Workload">
      <!--
      The total number of accounts in the system to which they are assigned AND the stage is one of A-> D OR E2
      Of those accounts, how many have a nextaction date greater than today
    -->
      <DashboardStatisticWidgetGroup
        v-if="accountsInProgress"
        v-bind="accountsInProgress"
      />
      <!--
      The accounts assigned to them, and in the appropriate status
    -->
      <DashboardStatisticWidgetGroup
        v-if="accountsBreakdown"
        v-bind="accountsBreakdown"
      />
      <!--
      The number of orders issued where paidAt is null and status is new or pending and order createdby = the EP
    -->
      <DashboardStatisticWidgetGroup
        v-if="orderPipeline"
        v-bind="orderPipeline"
      />
      <!--
      Appointments for timeframe. Default to Today -> end of week. Date picker should have Next Week / Next 6 weeks etc
    -->
      <DashboardStatisticWidgetGroup
        v-if="appointmentsOverview"
        v-bind="appointmentsOverview"
      />
    </DashboardStatisticWidgetWrapper>
    <!--
    This whole section is aimed at establishing historical performance. The date range defaults to 4wk rolling.
  -->
    <DashboardStatisticWidgetWrapper
      :title="'Performance ' + selectedTimeFrame"
    >
      <!--
      Total cash in for period, includes LPAOnly etc. 
    -->
      <DashboardStatisticWidgetGroup v-if="cashIn" v-bind="cashIn" />
      <!--
      Total cash in for period advised only
    -->
      <DashboardStatisticWidgetGroup
        v-if="advisedOrders"
        v-bind="advisedOrders"
      />
      <!--
      Product breakdown for period.
    -->
      <DashboardStatisticWidgetGroup
        v-if="productsBreakdown"
        v-bind="productsBreakdown"
      />
    </DashboardStatisticWidgetWrapper>
    <!--
    These are extra rolling metrics and not affected by the time period
  -->
    <DashboardStatisticWidgetWrapper
      title="Metrics Last 13 Weeks"
      class="dashboard-statistic-widget-wrapper"
    >
      <!--
      How long does it take after issuing an order for it be paid? (Mode)
    -->
      <DashboardStatisticWidgetGroup
        v-if="timeToPayment"
        v-bind="timeToPayment"
      />
      <!--
      i.e converted lead / cash for those leads where account created at last 13 weeks
      i.e, what avg value can be attributed to all accounts created in the last 13 weeks divided by the total cash in?
    -->
      <DashboardStatisticWidgetGroup
        v-if="accountValue"
        v-bind="accountValue"
      />
      <!--
      Account Creation Date to order Paid
      i.e How long from when the lead was converted / account created till the first order which has been paid? (Not AVG, but most common)
    -->
      <DashboardStatisticWidgetGroup v-if="timeToCash" v-bind="timeToCash" />
    </DashboardStatisticWidgetWrapper>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import DashboardStatisticWidgetGroup from "@/shared/component/widget/DashboardStatisticWidgetGroup.vue";
import DashboardStatisticWidgetWrapper from "@/shared/component/widget/DashboardStatisticWidgetWrapper.vue";
import { TDashboardStatisticWidgetGroupProps } from "@/shared/component/widget/dashboard-statistic-widget";

import { ReadDashboardStatisticForm } from "@/module/dashboard/form/read-dashboard-statistic.form";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { DashboardEstatePlannerRoute } from ".";

import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { ReadDashboardStatisticEPResponse } from "../../action/dashboard-statistic/read-dashboard-statistic-ep.action";

export default defineComponent({
  name: DashboardEstatePlannerRoute,
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.dashboard.statistics),
  ],
  components: {
    PageHeader,
    DashboardStatisticWidgetWrapper,
    DashboardStatisticWidgetGroup,
    MasterListFilters,
  },
  data: () => ({
    filters: ReadDashboardStatisticForm(),
    dashboardData: null as null | ReadDashboardStatisticEPResponse,
  }),
  computed: {
    selectedUserId() {
      return this.$data.filters?.localData?.selected?.id;
    },
    selectedUserName() {
      return this.$data.filters?.localData?.selected?.profile?.fullName;
    },
    selectedTimeFrame() {
      return this.$data.filters?.localData?.timeFrame;
    },
    accountsInProgress(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Accounts in Progress",
        details: [
          {
            label: "Current",
            value: this.dashboardData.accounts.inProgress.currentCount,
          },
          {
            label: "Overdue",
            subValue:
              this.dashboardData.accounts.inProgress.overduePercentage
                .toFixed(2)
                .toString() + "%",
            value: this.dashboardData.accounts.inProgress.overdueCount,
            cssClass: "text-danger",
          },
        ],
      };
    },
    accountsBreakdown(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Accounts Breakdown",
        details: [
          {
            label: "B",
            value: this.dashboardData.accounts.breakdown.b,
          },
          {
            label: "C",
            value: this.dashboardData.accounts.breakdown.c,
          },
          {
            label: "D",
            value: this.dashboardData.accounts.breakdown.d,
          },
          {
            label: "E2",
            value: this.dashboardData.accounts.breakdown.e2,
          },
          {
            label: "Hold",
            value: this.dashboardData.accounts.breakdown.hold,
          },
        ],
      };
    },
    orderPipeline(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Order Pipeline",
        hint: "This is the total value and number of the EPs orders in the pipeline",
        details: [
          {
            label: "Outstanding",
            value: this.dashboardData.orders.pipeline.orderCount,
          },
          {
            label: "Value",
            value:
              "£" + this.dashboardData.orders.pipeline.orderValue.toFixed(2),
          },
        ],
      };
    },
    appointmentsOverview(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Appointments Overview",
        hint: "For the next 30 days",
        details: [
          {
            label: "Enq",
            value: this.dashboardData.appointments.enqCount,
          },
          {
            label: "Call 1",
            value: this.dashboardData.appointments.call1Count,
          },
          {
            label: "Call 2",
            value: this.dashboardData.appointments.call2Count,
          },
          {
            label: "Cancelled",
            value: this.dashboardData.appointments.cancelledCount,
          },
        ],
      };
    },
    cashIn(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Cash In",
        hint: "Orders within timeframe including LPAs Only etc.",
        details: [
          {
            label: "Orders",
            value: this.dashboardData.orders.cashIn.orderCount,
          },
          {
            label: "Value",
            value: "£" + this.dashboardData.orders.cashIn.orderValue.toFixed(2),
          },
        ],
      };
    },
    advisedOrders(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Advised Orders",
        hint: "Orders within timeframe",
        details: [
          {
            label: "Orders",
            value: this.dashboardData.orders.advised.orderCount,
          },
          {
            label: "Value",
            value:
              "£" + this.dashboardData.orders.advised.orderValue.toFixed(2),
          },
          {
            label: "AVG",
            value: "£" + this.dashboardData.orders.advised.orderAvg.toFixed(2),
          },
        ],
      };
    },
    productsBreakdown(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Products Breakdown",
        hint: "Product breakdown for period",
        details: [
          {
            label: "Wills",
            value: this.dashboardData.products.breakdown.wills,
          },
          {
            label: "Trusts",
            value: this.dashboardData.products.breakdown.trusts,
          },
          {
            label: "LPAs",
            value: this.dashboardData.products.breakdown.lpas,
          },
          {
            label: "DocStorage",
            value: this.dashboardData.products.breakdown.docstorage,
          },
          {
            label: "Other",
            value: this.dashboardData.products.breakdown.other,
          },
          {
            label: "Total",
            value: this.dashboardData.products.total,
          },
        ],
      };
    },
    timeToPayment(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Time to Payment",
        hint: "Once an order is paid, what was the median number of days between order creation and payment",
        details: [
          {
            label: "days",
            value: this.dashboardData.timeToPayment,
          },
        ],
      };
    },
    accountValue(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Account Value",
        hint: "Account Value x weeks Rolling",
        details: [
          {
            label: "Value",
            value: "£" + this.dashboardData.accountValue,
          },
        ],
      };
    },
    timeToCash(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }

      return {
        title: "Time to Cash",
        hint: "Median time to cash last 13 weeks",
        details: [
          {
            label: "days",
            value: this.dashboardData.timeToCash,
          },
        ],
      };
    },
  },
  methods: {
    async refresh(): Promise<void> {
      const formData = this.$data.filters?.localData;
      const { selected, timeFrame } = formData;
      if (!selected) {
        return;
      }
      const data = await this.$actionBus.ReadEPStatistics({
        selected: selected,
        timeFrame: timeFrame,
      });
      this.dashboardData = data;
      console.log("data", data);
    },
  },
});
</script>

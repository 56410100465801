<template>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(attorney, index) in attorneys"
    :key="attorney.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">{{ attorney.sortOrder }}</b> -->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(attorney)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(attorney)"
        :is-disabled="index === attorneys.length - 1"
      />
    </div>
    <AttorneySummary style="flex-grow: 1" :role="attorney" :kintin="kintin" />
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(beneficiary, index) in beneficiaries"
    :key="beneficiary.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">
        {{ beneficiary.sortOrder }}
      </b>-->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(beneficiary)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(beneficiary)"
        :is-disabled="index === beneficiaries.length - 1"
      />
    </div>
    <BeneficiarySummary
      style="flex-grow: 1"
      :role="beneficiary"
      :kintin="kintin"
    />
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(executor, index) in executors"
    :key="executor.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">{{ executor.sortOrder }}</b> -->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(executor)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(executor)"
        :is-disabled="index === executors.length - 1"
      />
    </div>
    <ExecutorSummary style="flex-grow: 1" :role="executor" :kintin="kintin" />
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(gift, index) in gifts"
    :key="gift.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">{{ gift.sortOrder }}</b> -->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(gift)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(gift)"
        :is-disabled="index === gifts.length - 1"
      />
    </div>
    <GiftSummary style="flex-grow: 1" :gift="gift" :kintin="kintin" />
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(guardian, index) in guardians"
    :key="guardian.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">{{ guardian.sortOrder }}</b> -->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(guardian)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(guardian)"
        :is-disabled="index === guardians.length - 1"
      />
    </div>
    <GuardianSummary style="flex-grow: 1" :role="guardian" :kintin="kintin" />
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center"
    v-for="(trustee, index) in trustees"
    :key="trustee.id"
  >
    <div style="display: flex; flex-direction: column">
      <!-- <b style="text-align: center" class="mb-4">{{ trustee.sortOrder }}</b> -->
      <Button
        icon-right="ChevronUp"
        aria-label="Move Up"
        @click="moveUp(trustee)"
        :is-disabled="index === 0"
      />
      <Button
        icon-right="ChevronDown"
        aria-label="Move Down"
        @click="moveDown(trustee)"
        :is-disabled="index === trustees.length - 1"
      />
    </div>
    <TrusteeSummary style="flex-grow: 1" :role="trustee" :kintin="kintin" />
  </div>
</template>
<script lang="ts">
import AttorneySummary from "@/module/kinvault.kintin/component/summary-cards/AttorneySummary.vue";
import BeneficiarySummary from "@/module/kinvault.kintin/component/summary-cards/BeneficiarySummary.vue";
import ExecutorSummary from "@/module/kinvault.kintin/component/summary-cards/ExecutorSummary.vue";
import GiftSummary from "@/module/kinvault.kintin/component/summary-cards/GiftRecipientSummary.vue";
import GuardianSummary from "@/module/kinvault.kintin/component/summary-cards/GuardianSummary.vue";
import TrusteeSummary from "@/module/kinvault.kintin/component/summary-cards/TrusteeSummary.vue";
import Button from "@kinherit/framework/component.input/button";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import {
  Attorney,
  Beneficiary,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Trustee,
} from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
export default defineComponent({
  name: `SortRoles`,
  components: {
    AttorneySummary,
    BeneficiarySummary,
    ExecutorSummary,
    GiftSummary,
    GuardianSummary,
    TrusteeSummary,
    Button,
  },
  props: {
    roles: {
      type: Array as PropType<
        | Attorney[]
        | Beneficiary[]
        | Executor[]
        | Gift[]
        | Guardian[]
        | Trustee[]
      >,
      required: true,
    },
    kintin: { type: Kintin, required: true },
  },
  computed: {
    isDevMode(): boolean {
      return window.Kernel.Mode === KernelModes.Dev;
    },
    sortedRoles(): (
      | Attorney
      | Beneficiary
      | Executor
      | Gift
      | Guardian
      | Trustee
    )[] {
      return this.roles.clone().sort((a, b) => a.sortOrder - b.sortOrder);
    },
    attorneys(): Attorney[] {
      return this.sortedRoles.filter(
        (r) => r instanceof Attorney,
      ) as Attorney[];
    },
    beneficiaries(): Beneficiary[] {
      return this.sortedRoles.filter(
        (r) => r instanceof Beneficiary,
      ) as Beneficiary[];
    },
    executors(): Executor[] {
      return this.sortedRoles.filter(
        (r) => r instanceof Executor,
      ) as Executor[];
    },
    gifts(): Gift[] {
      return this.sortedRoles.filter((r) => r instanceof Gift) as Gift[];
    },
    guardians(): Guardian[] {
      return this.sortedRoles.filter(
        (r) => r instanceof Guardian,
      ) as Guardian[];
    },
    trustees(): Trustee[] {
      return this.sortedRoles.filter((r) => r instanceof Trustee) as Trustee[];
    },
  },
  methods: {
    moveUp(
      role: Attorney | Beneficiary | Executor | Gift | Guardian | Trustee,
    ) {
      const position = this.sortedRoles.findIndex((r) => r.id === role.id);
      const newPosition = position - 1;
      if (newPosition < 0) {
        return;
      }
      const otherRole = this.sortedRoles[newPosition];
      if (!otherRole) {
        return;
      }

      const roleSortOrder = role.sortOrder;
      const otherRoleSortOrder = otherRole.sortOrder;

      role.sortOrder = otherRoleSortOrder;
      otherRole.sortOrder = roleSortOrder;
    },
    moveDown(
      role: Attorney | Beneficiary | Executor | Gift | Guardian | Trustee,
    ) {
      const position = this.sortedRoles.findIndex((r) => r.id === role.id);
      const newPosition = position + 1;
      if (newPosition > this.sortedRoles.length) {
        return;
      }
      const otherRole = this.sortedRoles[newPosition];
      if (!otherRole) {
        return;
      }

      const roleSortOrder = role.sortOrder;
      const otherRoleSortOrder = otherRole.sortOrder;

      role.sortOrder = otherRoleSortOrder;
      otherRole.sortOrder = roleSortOrder;
    },
  },
});
</script>

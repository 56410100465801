// AccountReferral

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";

export const UpdateAccountReferral = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/lead/{lead}/account-referral/{accountReferral}",
  method: "patch",
  parse: (target: AccountReferral) => ({
    body: {
      nextActionAt: target.$data.nextActionAt,
      referralCode: target.$data.referralCode,
      kintin: target.$data.kintin,
      lead: target.$data.lead,
      contact: target.$data.contact,
    },
    params: {
      accountReferral: target,
      lead: target.lead!,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});

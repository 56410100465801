import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

type Response = {
  emailLog: EmailLog;
};

export const UpdateEmailLogHandler = async (
  message: Message,
): Promise<Response> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal-2/introducer/company/{owner}/email-log/{emailLog}",
    [IntroducerContact.$name]:
      "/v2/portal-2/introducer/contact/{owner}/email-log/{emailLog}",
    [Kintin.$name]: "/v2/portal-2/kinvault/{owner}/email-log/{emailLog}",
    [Lead.$name]: "/v2/portal-2/lead/{owner}/email-log/{emailLog}",
    [Person.$name]: "/v2/portal-2/officer/{owner}/email-log/{emailLog}",
    [BrandedKinvault.$name]:
      "/v2/portal-2/admin/branded-kinvault/{owner}/email-log/{emailLog}",
  } as const;

  const route = routes[message.owner.$name];

  const localAttachments = message.emailLog.attachments.pluck("id") ?? [];
  const locatNamedAttachments =
    message.emailLog.namedAttachments.pluck("id") ?? [];

  const response = await Api.resource("portal-2", route, {
    owner: message.owner.$id,
    emailLog: message.emailLog,
  })
    .method("patch")

    .body({
      template: message.emailLog.$data.template,
      status: message.emailLog.$data.status,
      data: message.emailLog.$data.data,
      preloadedFiles: message.emailLog.$data.preloadedFiles,
      cc: message.emailLog.$data.cc,
      bcc: message.emailLog.$data.bcc,
      to: message.emailLog.$data.to,
      from: message.emailLog.$data.from,
      title: message.emailLog.$data.title,
      subject: message.emailLog.$data.subject,
      sentAt: message.emailLog.$data.sentAt,
      html: message.emailLog.$data.html,
      markdown: message.emailLog.$data.markdown,
    })
    .result();

  const emailLog = EmailLog.$inflate(response.emailLog).first();

  if (!emailLog) {
    throw new Error("Failed to update email log");
  }

  // Unnamed attachments
  const remoteAttachments = emailLog.attachments.pluck("id");
  const deletedAttachments = remoteAttachments.remove(...localAttachments);

  await window.Kernel.ActionBus2.core.emailLog.UploadEmailLogAttachment({
    owner: message.owner,
    emailLog,
    files: message.files,
  });

  for (const fileLog of deletedAttachments) {
    await window.Kernel.ActionBus2.core.emailLog.DeleteEmailLogAttachment({
      owner: message.owner,
      emailLog,
      fileLog,
    });
  }

  // Named attachments
  const remoteNamedAttachments = emailLog.namedAttachments.pluck("id");
  const deletedNamedAttachments = remoteNamedAttachments.remove(
    ...locatNamedAttachments,
  );
  await window.Kernel.ActionBus2.core.emailLog.CreateEmailNamedAttachment({
    owner: message.owner,
    emailLog,
    files: message.requiredFiles,
  });

  for (const emailNamedAttachment of deletedNamedAttachments) {
    await window.Kernel.ActionBus2.core.emailLog.DeleteEmailNamedAttachment({
      owner: message.owner,
      emailLog,
      emailNamedAttachment,
    });
  }

  return {
    emailLog,
  };
};

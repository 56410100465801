// Exclusion

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Exclusion } from "@kinherit/sdk/model/exclusion";

export const UpdateExclusion = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/exclusion/{exclusion}",
  method: "patch",
  parse: (target: Exclusion) => ({
    body: {
      who: target.$data.who,
      why: target.$data.why,
      relationship: target.$data.relationship,
      kintin: target.$data.kintin,
      excludedBy: target.$data.excludedBy,
    },
    params: {
      exclusion: target,
      kintin: target.$data.kintin,
    },
  }),
});

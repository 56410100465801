import { DateTime } from "@kinherit/ts-common/index";

export const PushToXero = async (data: {
  brandedKinvault: string;
  startDate: DateTime;
  endDate: DateTime;
}): Promise<void> => {
  const { startDate, endDate } = data;
  const startDateFormatted = startDate.formatYMD;
  const endDateFormatted = endDate.formatYMD;

  console.log("Pushing branded kinvault fees to Xero");
  await window.Kernel.Api.resource(
    "external",
    "/v2/external/xero/create-branded-kinvault-usage-invoice/{brandedKinvault}",
    {
      brandedKinvault: data.brandedKinvault,
    },
  )
    .method("post")
    .params({
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    })
    .result();
};

// KintinCheckFollowUp, Note, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/index";
import { KintinCheckFollowUp } from "@kinherit/sdk/model/kintin-check-follow-up";

export const ReadKintinCheckFollowUp = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/kintin-check-follow-up",
  method: "get",
  rootModel: KintinCheckFollowUp,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});

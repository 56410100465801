// Subscription

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const DeleteSubscription = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/subscriptions/{subscription}",
  method: "delete",
  parse: (subscription: Subscription | string) => ({
    params: {
      subscription,
    },
  }),
});

// WillRevision

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const DeleteWillRevision = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/will-revision/{willRevision}",
  method: "delete",
  parse: (willRevision: WillRevision) => ({
    params: {
      willRevision,
      kintin: willRevision.$data.kintin,
    },
  }),
});

import { CreateKnowledgeBaseCategory } from "@/action-bus/portal-2/knowledge-base/create-knowledge-base-category.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2KnowledgeBaseCategory } from "./category";
import { ReadKnowledgeBaseCategory } from "./read-knowledge-base-category.action";

export const Portal2KnowledgeBase = DefineActionBus({
  name: "portal-2-knowledge-base",
  actions: {
    ReadKnowledgeBaseCategory,
    CreateKnowledgeBaseCategory,
    category: Portal2KnowledgeBaseCategory,
  },
});

// Trustee

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Trustee } from "@kinherit/sdk/model/trustee";

export const CreateTrustee = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/trustee",
  method: "post",
  parse: (target: Trustee) => ({
    body: {
      isReserve: target.$data.isReserve,
      notes: target.$data.notes,
      attainmentAge: target.$data.attainmentAge,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

import { Portal2AdminBrandedKinvaultSubscriptionReferralCode } from "@/action-bus/portal-2/admin/branded-kinvault/subscription-referral-code";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteBrandedKinvault } from "./delete-branded-kinvault.action";
import { Portal2AdminBrandedKinvaultInvitation } from "./invitation";
import { Portal2AdminBrandedKinvaultKinvaultPricingSchema } from "./kinvault-pricing-schema";
import { ReadMembers } from "./read-members.action";
import { RecordBrandedKinvault } from "./record-branded-kinvault.action";
import { ReplaceProfile } from "./replace-profile.action";
import { Portal2AdminBrandedKinvaultTheme } from "./theme";
import { UpdateBrandedKinvault } from "./update-branded-kinvault.action";
import { Portal2AdminBrandedKinvaultSubscriptionReferral } from "@/action-bus/portal-2/admin/branded-kinvault/subscription-referral";

export const Portal2AdminBrandedKinvault = DefineActionBus({
  name: "portal-2-admin-branded-kinvault",
  actions: {
    DeleteBrandedKinvault,
    RecordBrandedKinvault,
    ReadMembers,
    UpdateBrandedKinvault,
    UpdateBrandedKinvaultProfile: ReplaceProfile,
    pricingSchema: Portal2AdminBrandedKinvaultKinvaultPricingSchema,
    invitation: Portal2AdminBrandedKinvaultInvitation,
    theme: Portal2AdminBrandedKinvaultTheme,
    subscriptionReferral: Portal2AdminBrandedKinvaultSubscriptionReferral,
    subscriptionReferralCode:
      Portal2AdminBrandedKinvaultSubscriptionReferralCode,
  },
});

<template>
  <Card title="Document Storage">
    <p>Number of files &amp; folders in document storage</p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { DocstorageReportRoute } from ".";
import { TDocstorageReportResult } from "../action/compute-docstorage-report.action";
import { hasCurrentRowHighlighting } from "../data/helper-functions";
import { ReadDocstorageReportFilters } from "../form/read-docstorage-report.form";

export default defineComponent({
  name: DocstorageReportRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.reports)],
  components: {
    Card,
    MasterListFilters,
    Table,
  },
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadDocstorageReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "New Folders",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) => results.accountCount,
      },
      {
        title: "New Files",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) => results.fileCount,
      },
      {
        title: "New Clients",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) => results.clientCount,
      },
      {
        title: "Total Folders",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) =>
          results.culumativeAccountCount,
      },
      {
        title: "Total Files",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) => results.culumativeFileCount,
      },
      {
        title: "Total Clients",
        class: "has-text-weight-bold",
        map: (results: TDocstorageReportResult) =>
          results.culumativeClientCount,
      },
    ],
  }),
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.report.docstorage(
      //   this.filters.localData,
      // );
      const result = await this.$actionBus.Docstorage(this.filters.localData);

      this.datasets = result.datasets;
      this.labels = result.labels.map((l) => l.toString());
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>

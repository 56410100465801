<template>
  <Card class="email-campaign-details-members-csv">
    <PageHeader htag="h2" text="Members CSV"> </PageHeader>
    <div v-if="false === validate">
      <ul>
        <li>Upload a CSV file to populate this email campaigns member list.</li>
        <li>The first line of the CSV file is reserved for the headers.</li>
        <li>
          The header that most closely resembles <b>`email address`</b> will be
          used
        </li>
        <li>
          The profile must have the given email address marked as
          <b>primary</b> to be considered a match
        </li>
      </ul>
      <FileField
        class="pt-4 pb-4"
        v-model:value="csvFile"
        :validators="[
          [
            'file-limit',
            {
              numberOfFiles: 1,
            },
          ],
        ]"
      />
    </div>
    <div v-else>
      <p class="mb-5">
        <b>Detected column: </b>
        <span class="has-text-weight-bold has-text-positive">{{
          emailAddressColumnTitle
        }}</span>
      </p>
      <p v-if="unmatchedEmailAddresses.isNotEmpty()">
        <b>Unmatched Email Addresses: </b>
      </p>
      <ul v-if="unmatchedEmailAddresses.isNotEmpty()" class="mb-5">
        <li
          v-for="(emailAddress, index) in unmatchedEmailAddresses"
          :key="`unmatched-email-address-${index}`"
        >
          {{ emailAddress }}
        </li>
      </ul>
      <hr />
      <Table
        :columns="table.columns"
        v-model:page="table.page"
        v-model:per-page="table.perPage"
        :rows="table.rows"
        :total-records="duplicateProfiles.length"
        :total-pages="Math.ceil(duplicateProfiles.length ?? 0 / table.perPage)"
        is-expanded
      >
        <template #details="{ row }">
          <RadioField
            @update:value="
              (value) =>
                (amendedProfiles[row.emailAddress] = row.profiles[value])
            "
            :options="row.profiles"
            direction="is-horizontal"
          >
            <template #label="{ option }">
              <Grid></Grid>
              <p class="mb-0 ml-2 mr-2">
                {{ option.fullName }}
              </p>
              <p class="mt-0 ml-2">
                Type:
                {{
                  {
                    appointment: "Appointment",
                    user: "User",
                    cpdSessionRegistration: "Cpd Session Registration",
                    introducerBillingContact: "Introducer Billing Contact",
                    introducerCompany: "Introducer Company",
                    introducerContact: "Introducer Contact",
                    lead: "Lead",
                    brandedKinvault: "Branded Kinvault",
                    person: "Person",
                    proPartner: "Pro Partner",
                    trustRegPerson: "Trust Registration Person",
                    trustReg: "Trust Registration",
                    null: "Unknown",
                  }[(option as Profile).type ?? "null"]
                }}
              </p>
            </template>
          </RadioField>
        </template>
      </Table>
    </div>
    <div class="buttons is-right">
      <Button
        v-if="false === validate"
        :is-disabled="csvFile.isEmpty()"
        @click="validateCsvFile"
        text="Validate"
      />
      <Button
        v-else
        :is-disabled="
          duplicateProfiles.length !== Object.keys(amendedProfiles).length
        "
        @click="importCsvFile"
        text="Import"
      />
    </div>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailCampaignDetailsMembersCsvWrapper",
  "route": "EmailCampaignDetailsMembersCsv",
  "selector": ".email-campaign-details-members-csv"
}
</cypress-wrapper>

<script lang="ts">
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import FileField from "@kinherit/framework/component.input/file-field";
import RadioField from "@kinherit/framework/component.input/radio-field";
import Card from "@kinherit/framework/component.layout/card";
import Grid from "@kinherit/framework/component.layout/grid";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { Equal, In } from "@kinherit/orm";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  EmailCampaignDetailsMembersCsvRoute,
  EmailCampaignDetailsMembersRoute,
} from ".";

export default defineComponent({
  name: EmailCampaignDetailsMembersCsvRoute,
  mixins: [EmailCampaignDetailsMixin],
  components: {
    PageHeader,
    Card,
    FileField,
    Button,
    Table,
    RadioField,
    Grid,
  },
  data: () => ({
    validate: false,
    duplicateProfiles: [] as {
      emailAddress: string;
      profiles: string[];
    }[],
    unmatchedEmailAddresses: [] as string[],
    emailAddressColumnTitle: null as null | string,
    amendedProfiles: {} as Record<string, Profile>,
    csvFile: [] as File[],
    table: {
      columns: [
        {
          field: "emailAddress",
        },
      ] satisfies TableColumn<{
        emailAddress: string;
        profiles: Profile[];
      }>[],
      rows: [] as {
        emailAddress: string;
        profiles: Profile[];
      }[],
      page: 0,
      perPage: 15,
    },
  }),
  methods: {
    async validateCsvFile(): Promise<void> {
      // window.Kernel.ActionBus.adminEmailCampaign.membersCsv.validate
      const {
        duplicateProfiles,
        emailAddressColumnTitle,
        unmatchedEmailAddresses,
      } = await this.$actionBus.ValidateMembersCsv(this.csvFile[0]);

      this.duplicateProfiles = duplicateProfiles;
      this.emailAddressColumnTitle = emailAddressColumnTitle;
      this.unmatchedEmailAddresses = unmatchedEmailAddresses;

      await this.refresh();

      this.validate = true;
    },
    async refresh(): Promise<void> {
      if (null === this.duplicateProfiles || this.duplicateProfiles.isEmpty()) {
        return;
      }

      const ids = this.duplicateProfiles
        .slice(
          this.table.page * this.table.perPage,
          this.table.page * this.table.perPage + this.table.perPage,
        )
        .pluck("profiles")
        .flat();

      const { emailAddress } =
        // await window.Kernel.ActionBus.adminEmailCampaign.profile.read({
        await this.$actionBus.profile.ReadProfiles({
          query: {
            id: In(ids),
          },
        });

      this.table.rows = emailAddress
        .unique((emailAddress) => emailAddress.email)
        .filter((emailAddress) => true === emailAddress.primary)
        .map((emailAddress) => ({
          emailAddress: emailAddress.email,
          profiles: Profile.$findBy({
            id: In(ids),
            emails: {
              email: Equal(emailAddress.email),
              primary: Equal(true),
            },
          }),
        }));

      this.$forceUpdate();
    },
    async importCsvFile(): Promise<void> {
      const emailCampaign = this.emailCampaign;

      if (!emailCampaign) {
        return;
      }

      const amendments: {
        emailAddress: string;
        profile: string;
      }[] = [];

      Object.keys(this.amendedProfiles).forEach((emailAddress) => {
        amendments.push({
          emailAddress,
          profile: this.amendedProfiles[emailAddress].id,
        });
      });

      // await window.Kernel.ActionBus.adminEmailCampaign.membersCsv.upload({
      await this.$actionBus.UploadMembersCsv({
        emailCampaign,
        amendments,
        file: this.csvFile[0],
      });

      window.Kernel.visitRoute({
        name: EmailCampaignDetailsMembersRoute,
        params: this.$params,
      });
    },
  },
});
</script>

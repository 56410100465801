import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateOrderItem } from "./create-order-item.action";
import { CreateOrder } from "./create-order.action";
import { ReadOrder } from "./read-order.action";
import { RecordOrder } from "./record-order.action";
import { SendOrderPaymentEmail } from "./send-order-payment-email.action";

export const Portal2KinvaultOrder = DefineActionBus({
  name: "portal-2-kinvault-order",
  actions: {
    CreateOrderItem,
    CreateOrder,
    ReadOrder,
    RecordOrder,
    SendOrderPaymentEmail,
  },
});

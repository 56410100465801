import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeCashflowReportMessage {
  group: "paidAt" | "createdAt";
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
  referral: string | null;
  referrerCategory: string | null;
}

interface CashflowReportGraphDataset {
  label: string;
  stack: string;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
  borderDash?: Array<number>;
  data: Array<number>;
  yAxisID?: string;
  order?: number;
  type?: string;
}
export interface ComputeCashflowReportResponse {
  datasets: Array<CashflowReportGraphDataset>;
  feeDatasets: Array<CashflowReportGraphDataset>;
  labels: Array<string | number>;
  results: Array<TCashflowReportResult>;
}
export type TCashflowReportResult = {
  year: number;
  month: number;
  week: number;
  quarter: number;
  unpaidValue: number;
  unpaidCount: number;
  advisedValue: number;
  advisedCount: number;
  hostedValue: number;
  hostedCount: number;
  lpasOnlyValue: number;
  lpasOnlyCount: number;
  subscriptionValue: number;
  subscriptionCount: number;
  outsourcedCount: number;
  outsourcedValue: number;
  introducerFeeCount: number;
  introducerFeeValue: number;
  agentFeeCount: number;
  agentFeeValue: number;
  parentAgentFeeCount: number;
  parentAgentFeeValue: number;
  sumValue: number;
};
export const ComputeCashflowReportHandler = async (
  message: ComputeCashflowReportMessage,
): Promise<ComputeCashflowReportResponse> => {
  const {
    year,
    created,
    granularity,
    assignedUser,
    referral,
    referrerCategory,
    compareTo,
    group,
  } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/report/cashflow",
    {},
  )
    .method("get")
    .params({
      group: group,
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      assignedUser: assignedUser ?? null,
      referral: referral ?? null,
      referrerCategory: referrerCategory ?? null,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
    })

    .result();

  const datasets: CashflowReportGraphDataset[] = [];

  const feeDatasets: CashflowReportGraphDataset[] = [];

  const coloursAdvised = ["#689F38", "#33691E"];
  const coloursLpasOnly = ["#3287a8", "#225a70"];
  const coloursOther = ["#f9a825", "#f57f17"];
  const coloursSubscription = ["#5f30a6", "#351c5c"];

  // For each dataset returned, create a new dataset object with the correct format
  // for the chart.js graph
  response.datasets.forEach((dataset, index: number) => {
    datasets.push({
      label: "Advised",
      stack: "Stack " + index,
      backgroundColor: coloursAdvised[index],
      data: dataset.datasets.map((i: any) => {
        return i.advisedValue;
      }),
    });
    datasets.push({
      label: "LPAs Only",
      stack: "Stack " + index,
      backgroundColor: coloursLpasOnly[index],
      data: dataset.datasets.map((i: any) => {
        return i.lpasOnlyValue;
      }),
    });
    datasets.push({
      label: "Other",
      stack: "Stack " + index,
      backgroundColor: coloursOther[index],
      data: dataset.datasets.map((i: any) => {
        return i.outsourcedValue;
      }),
    });
    datasets.push({
      label: "Subscription",
      stack: "Stack " + index,
      backgroundColor: coloursSubscription[index],
      data: dataset.datasets.map((i: any) => {
        return i.subscriptionValue;
      }),
    });
    feeDatasets.push({
      label: "Introducer Fee",
      stack: "Stack " + index,
      backgroundColor: "#b0171c",
      data: dataset.datasets.map((i: any) => {
        return -i.introducerFeeValue;
      }),
    });
    feeDatasets.push({
      label: "Agent Fee",
      stack: "Stack " + index,
      backgroundColor: "#8a2226",
      data: dataset.datasets.map((i: any) => {
        return -i.agentFeeValue;
      }),
    });
    feeDatasets.push({
      label: "Parent Agent Fee",
      stack: "Stack " + index,
      backgroundColor: "#572325",
      data: dataset.datasets.map((i: any) => {
        return -i.parentAgentFeeValue;
      }),
    });
  });

  return {
    datasets,
    feeDatasets,
    labels: response.labels.map((label) => label.toString()),
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};

// IntroducerOutsource

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerOutsource } from "@kinherit/sdk/model/introducer-outsource";

export const DeleteIntroducerOutsource = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/outsources/{introducerOutsource}",
  method: "delete",
  parse: (introducerOutsource: IntroducerOutsource | string) => ({
    params: {
      introducerOutsource,
    },
  }),
});

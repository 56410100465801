import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateTag } from "./create-tag.action";
import { DeleteTag } from "./delete-tag.action";
import { RecordTag } from "./record-tag.action";
import { UpdateTag } from "./update-tag.action";

export const Portal2AdminTag = DefineActionBus({
  name: "portal-2-admin-tag",
  actions: {
    DeleteTag,
    UpdateTag,
    RecordTag,
    CreateTag,
  },
});

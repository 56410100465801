// UploadForm, FileLog, SignDoc

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const ReadUploadForm = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvault/{kintin}/upload-forms/account-referral/{accountReferral}",
  method: "get",
  rootModel: UploadForm,
  parse: (message: {
    kintin: Kintin | string;
    accountReferral: AccountReferral | string;
  }) => ({
    params: {
      kintin: message.kintin,
      accountReferral: message.accountReferral,
    },
  }),
});

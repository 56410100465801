// FileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { EmailCampaignLog } from "@kinherit/sdk/model/email-campaign-log";
import { FileLog } from "@kinherit/sdk/model/file-log";

export const DeleteAttachment = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/email-campaign/log/{emailCampaignLog}/attachment/{fileLog}",
  method: "delete",
  parse: (message: {
    emailCampaignLog: EmailCampaignLog | string;
    fileLog: FileLog | string;
  }) => ({
    params: {
      emailCampaignLog: message.emailCampaignLog,
      fileLog: message.fileLog,
    },
  }),
  after: ({ message }) => {
    FileLog.$delete(message.fileLog);
  },
});

// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const ReadSubscription = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/subscription",
  method: "get",
  rootModel: Subscription,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});

// Kintin, Person, Profile, Executor, Attorney, Guardian, Trustee

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadPerson = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/trusted-people",
  method: "get",
  rootModel: Kintin,
  parse: () => ({}),
});

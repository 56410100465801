// IntroducerOutsource

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerOutsource } from "@kinherit/sdk/model/introducer-outsource";

export const UpdateIntroducerOutsource = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/outsources/{introducerOutsource}",
  method: "patch",
  parse: (target: IntroducerOutsource) => ({
    body: {
      clientName: target.$data.clientName,
      datedAt: target.$data.datedAt,
      expectedValue: target.$data.expectedValue,
      paidValue: target.$data.paidValue,
      paidAt: target.$data.paidAt,
      nextActionAt: target.$data.nextActionAt,
      postcode: target.$data.postcode,
      status: target.$data.status,
      type: target.$data.type,
      company: target.$data.company,
      createdBy: target.$data.createdBy,
      assignedTo: target.$data.assignedTo,
      sourceKintin: target.$data.sourceKintin,
    },
    params: {
      introducerOutsource: target,
    },
  }),
});

import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeLeadsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  referral: string | null;
  referrerCategory: string | null;
  network: string | null;
  compareTo: string | null;
}

interface LeadReportGraphDataset {
  label: string;
  stack?: string;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
  borderDash?: Array<number>;
  data: Array<number>;
  yAxisID?: string;
  order?: number;
  type?: string;
}

export interface ComputeLeadsReportResponse {
  datasets: Array<LeadReportGraphDataset>;
  conversionDatasets: Array<LeadReportGraphDataset>;
  maxConversionDatasets: Array<LeadReportGraphDataset>;
  dropoutDatasets: Array<LeadReportGraphDataset>;
  labels: Array<string | number>;
  results: Array<TLeadReportResult>;
}

export type TLeadReportResult = {
  year: number;
  week: number;
  month: number;
  quarter: number;
  lead: {
    count: number;
    buildCount: number;
    unqualifiedCount: number;
    pendingCount: number;
    dropoutCount: number;
    convertedCount: number;
    ifaCount: number;
    mortgageCount: number;
    organicCount: number;
    otherCount: number;
  };
  account: {
    count: number;
    unpaidCount: number;
    notNowCount: number;
    dropoutCount: number;
    paidCount: number;
  };
};
export const ComputeLeadsReportHandler = async (
  message: ComputeLeadsReportMessage,
): Promise<ComputeLeadsReportResponse> => {
  const {
    year,
    created,
    granularity,
    assignedUser,
    referral,
    compareTo,
    referrerCategory,
    network,
  } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/report/leads",
    {},
  )
    .method("get")
    .params({
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      assignedUser: assignedUser ?? null,
      referral: referral ?? null,
      referrerCategory: referrerCategory ?? null,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
      network: network ?? null,
    })

    .result();

  const datasets: LeadReportGraphDataset[] = [];

  const conversionDatasets: LeadReportGraphDataset[] = [];
  const maxConversionDatasets: LeadReportGraphDataset[] = [];
  const dropoutDatasets: LeadReportGraphDataset[] = [];

  const coloursIfa = ["#0373fc", "#0256bd"];
  const coloursOrganic = ["#d98e16", "#a36a11"];
  const coloursMortgage = ["#911da3", "#6d167a"];
  const coloursOther = ["#6bc7ab", "#3fa687"];

  // For each dataset returned, create a new dataset object with the correct format
  // for the chart.js graph
  response.datasets.forEach((dataset: any, index: number) => {
    datasets.push({
      label: "IFA",
      stack: "Stack " + index,
      backgroundColor: coloursIfa[index],
      order: 1,
      yAxisID: "y",
      data: dataset.datasets.map((i: any) => {
        return i.lead.ifaCount;
      }),
    });

    datasets.push({
      label: "Mortgage",
      stack: "Stack " + index,
      backgroundColor: coloursMortgage[index],
      order: 1,
      yAxisID: "y",
      data: dataset.datasets.map((i: any) => {
        return i.lead.mortgageCount;
      }),
    });

    datasets.push({
      label: "Organic",
      stack: "Stack " + index,
      backgroundColor: coloursOrganic[index],
      order: 1,
      yAxisID: "y",
      data: dataset.datasets.map((i: any) => {
        return i.lead.organicCount;
      }),
    });

    datasets.push({
      label: "Other",
      stack: "Stack " + index,
      backgroundColor: coloursOther[index],
      order: 1,
      yAxisID: "y",
      data: dataset.datasets.map((i: any) => {
        return i.lead.otherCount;
      }),
    });

    conversionDatasets.push({
      label: "Converted %",
      borderColor: "#49c431",
      backgroundColor: "#49c431",
      borderWidth: 2,
      order: 0,
      yAxisID: "percentage",
      type: "line",
      data: dataset.datasets.map((i: any) => {
        return (
          Number(((i.lead.convertedCount / i.lead.count) * 100).toFixed(0)) || 0
        );
      }),
    });

    conversionDatasets.push({
      label: "Paid %",
      borderColor: "#328722",
      backgroundColor: "#328722",
      order: 0,
      yAxisID: "percentage",
      type: "line",
      data: dataset.datasets.map((i: any) => {
        return (
          Number(((i.account.paidCount / i.lead.count) * 100).toFixed(0)) || 0
        );
      }),
    });

    maxConversionDatasets.push({
      label: "Max Converted %",
      borderColor: "#86a181",
      backgroundColor: "#86a181",
      order: 0,
      yAxisID: "percentage",
      type: "line",
      borderDash: [5, 5],
      data: dataset.datasets.map((i: any) => {
        return (
          Number(
            (
              ((i.lead.count - i.lead.dropoutCount) / i.lead.count) *
              100
            ).toFixed(0),
          ) || 0
        );
      }),
    });

    maxConversionDatasets.push({
      label: "Max Paid %",
      borderColor: "#9dd692",
      backgroundColor: "#9dd692",
      order: 0,
      yAxisID: "percentage",
      type: "line",
      borderDash: [5, 5],
      data: dataset.datasets.map((i: any) => {
        return (
          Number(
            (
              ((i.account.count - i.account.dropoutCount) / i.lead.count) *
              100
            ).toFixed(0),
          ) || 0
        );
      }),
    });

    dropoutDatasets.push({
      label: "Dropout Lead %",
      borderColor: "#c43149",
      backgroundColor: "#c43149",
      order: 0,
      yAxisID: "percentage",
      type: "line",
      data: dataset.datasets.map((i: any) => {
        return (
          Number(((i.lead.dropoutCount / i.lead.count) * 100).toFixed(0)) || 0
        );
      }),
    });

    dropoutDatasets.push({
      label: "Dropout Account %",
      borderColor: "#872232",
      backgroundColor: "#872232",
      order: 0,
      yAxisID: "percentage",
      type: "line",
      data: dataset.datasets.map((i: any) => {
        return (
          Number(((i.account.dropoutCount / i.lead.count) * 100).toFixed(0)) ||
          0
        );
      }),
    });
  });

  return {
    datasets,
    conversionDatasets,
    maxConversionDatasets,
    dropoutDatasets,
    labels: response.labels,
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};

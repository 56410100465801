import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultSubscriptionReferralCode } from "./create-branded-kinvault-subscription-referral-code.action";
import { DeleteBrandedKinvaultSubscriptionReferralCode } from "./delete-branded-kinvault-subscription-referral-code.action";
import { ReadBrandedKinvaultSubscriptionReferralCode } from "./read-branded-kinvault-subscription-referral-code.action";
import { RecordBrandedKinvaultSubscriptionReferralCode } from "./record-branded-kinvault-subscription-referral-code.action";
import { UpdateBrandedKinvaultSubscriptionReferralCode } from "./update-branded-kinvault-subscription-referral-code.action";

export const Portal2AdminBrandedKinvaultSubscriptionReferralCode =
  DefineActionBus({
    name: "portal-2-admin-branded-kinvault-subscription-referral-code",
    actions: {
      CreateBrandedKinvaultSubscriptionReferralCode,
      ReadBrandedKinvaultSubscriptionReferralCode,
      UpdateBrandedKinvaultSubscriptionReferralCode,
      DeleteBrandedKinvaultSubscriptionReferralCode,
      RecordBrandedKinvaultSubscriptionReferralCode,
    },
  });

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateUploadForm } from "./create-upload-form.action";
import { DeleteUploadForm } from "./delete-upload-form.action";
import { DownloadUploadFormSubmission } from "./download-upload-form-submission.action";
import { ReadUploadForm } from "./read-upload-form.action";
import { UpdateUploadForm } from "./update-upload-form.action";

export const Portal2UploadForm = DefineActionBus({
  name: "portal-2-upload-form",
  actions: {
    CreateUploadForm,
    DeleteUploadForm,
    DownloadUploadFormSubmission,
    ReadUploadForm,
    UpdateUploadForm,
  },
});

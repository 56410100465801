<template>
  <div class="subscription-master-list">
    <PageHeader htag="h1" text="Subscriptions" />
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :is-clickable="false"
      :sort-by-options="{
        billingDate: 'Billing Date',
        'kintin.friendlyName': 'Client',
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #status="{ row }: { row: Subscription }">
        <Badge
          :text="row.status.text"
          :color="row.status.class ? ((`is-` + row.status.class) as any) : ``"
          size="is-small"
          isCompact
        />
      </template>
      <template #files="{ row }: { row: Subscription }">
        <Badge
          :color="row.fileCount > 0 ? `is-purple` : `is-light-grey`"
          size="is-small"
          isCompact
          >{{ row.fileCount }}
        </Badge>
      </template>
      <template #product="{ row }: { row: Subscription }">
        <small
          ><code>{{ row.productSku?.publicText }}</code></small
        >
        {{ row.subscriptionValue?.format }}
      </template>
      <template #progress="{ row }: { row: Subscription }">
        <Badge
          text="Direct Debit"
          :color="row.procDd ? `is-success` : `is-light-grey`"
          size="is-small"
          isCompact
        />
        <Badge
          text="Xero Setup"
          :color="row.procXero ? `is-success` : `is-light-grey`"
          size="is-small"
          isCompact
        />
      </template>
      <template #client="{ row }: { row: Subscription }">
        <a
          class="client-name"
          @click="(event: MouseEvent) => handleClientClick(row, event)"
        >
          {{ row.kintin.friendlyName ?? row.kintin.ref }}
        </a>
        <br />
        <small class="is-family-monospace">{{ row.kintin.ref }}</small>
      </template>
      <template #actions="{ row }: { row: Subscription }">
        <Button
          v-if="
            $auth.hasPermission('subscription:write') &&
            !row.procXero &&
            !row.procDd
          "
          @click="handleMarkInProgressClick(row)"
          class="mark-inprogress-button"
          :icon-left="$style.icon.progress.icon"
          ariaLabel="Mark as In Progress"
          size="is-small"
          color="is-plain"
          tooltip="Mark as In Progress"
        />
        <Button
          v-if="
            $auth.hasPermission('subscription:write') &&
            !row.procXero &&
            row.procDd
          "
          @click="handleEditXeroClick(row)"
          class="xero-button"
          :icon-left="$style.icon.subscription.icon"
          ariaLabel="Create Xero Repeating Invoice"
          size="is-small"
          color="is-plain"
          tooltip="Create Xero Repeating Invoice"
        />
        <Button
          v-if="$auth.hasPermission('subscription:write')"
          @click="handleEditClick(row)"
          class="edit-button"
          :icon-left="$style.icon.edit.icon"
          ariaLabel="Edit Subscription"
          size="is-small"
          color="is-plain"
          tooltip="Edit Subscription"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "SubscriptionMasterListWrapper",
  "route": "KinvaultSubscriptionMasterList",
  "selector": ".subscription-master-list",
  "imports": {
    "ReadSubscriptionFormWrapper": "@/module/kinvault.subscription/form/read-subscription.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadSubscriptionFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateNotification } from "@/config/model.config";
import {
  KintinDetailsSubscriptionParams,
  KintinDetailsSubscriptionRoute,
} from "@/module/kinvault.kintin/page/details/documents/subscriptions";
import { CreateXeroRepeatingInvoice } from "@/module/kinvault.subscription/form/create-xero-repeating-invoice.form";
import { ReadSubscriptionForm } from "@/module/kinvault.subscription/form/read-subscription.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Badge } from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, IsNotNull, Like } from "@kinherit/orm/index";
import { ISubscription, Note, Option } from "@kinherit/sdk";
import { Notification, Subscription } from "@kinherit/sdk/index";
import { Currency, DateTime, Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultSubscriptionMasterListRoute } from ".";
import { UpdateSubscriptionForm } from "../form/update-subscription.form";

export default defineComponent({
  name: KinvaultSubscriptionMasterListRoute,
  mixins: [
    AuthService.mixin(),
    StyleService.mixin,
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.subscriptions),
  ],
  components: { MasterListPage, Button, Badge, PageHeader },
  data: () => ({
    filters: ReadSubscriptionForm(),
    columns: [
      {
        title: "Client",
        sort: true,
        slot: "client",
        blockClicks: true,
      },
      {
        title: "Product",
        sort: true,
        slot: "product",
      },
      {
        title: "Status",
        sort: false,
        slot: "status",
      },
      {
        title: "Created",
        sort: true,
        map: (v: Subscription) => v.createdAt.formatDate,
      },
      {
        title: "Billing",
        sort: true,
        map: (v: Subscription) => v.billingDate?.formatDate,
      },
      {
        title: "Ended",
        sort: true,
        map: (v: Subscription) => v.billingEndDate?.formatDate,
      },
      {
        title: "Files",
        sort: true,
        slot: "files",
      },
      {
        title: "Progress",
        sort: false,
        slot: "progress",
      },
      {
        slot: "actions",
        class: "is-narrow",
        blockClicks: true,
      },
    ] as Array<TableColumn>,
    rows: Array<Subscription>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ISubscription,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    async handleMarkInProgressClick(subscription: Subscription): Promise<void> {
      if (!this.$auth.hasPermission("subscription:write")) {
        return;
      }

      subscription.procDd = true; // Direct Debit
      subscription.status = Option.$findOneByOrThrow({
        group: "subscriptionStatus",
        value: "inprogress",
      });

      // await window.Kernel.ActionBus.kinvaultSubscription.update({
      //   subscription,
      // });
      await this.$actionBus.UpdateSubscription(subscription);

      await this.refresh(this.filters.localData);
    },
    async handleEditXeroClick(subscription: Subscription): Promise<void> {
      if (!this.$auth.hasPermission("subscription:write")) {
        return;
      }
      try {
        const data = await CreateXeroRepeatingInvoice({
          formTitle:
            subscription.kintin.friendlyName + " - " + subscription.kintin.ref,
          subscriptionId: subscription.id,
          price:
            subscription.subscriptionValue ??
            new Currency({
              amount: 50,
            }),
          xeroContactId: undefined,
          quantity: 1,
          description: subscription.productSku?.publicText,
          accountCode: "200",
          ref: subscription.kintin.ref,
          suggestedXeroContactId: undefined,
        }).dialog({
          dialog: {
            title: "Xero Repeating Invoice",
          },
          button: {
            ok: {
              text: "Create Repeating Invoice",
            },
          },
        });

        if (undefined === data.xeroContactId) {
          return;
        }

        // await window.Kernel.ActionBus.kinvaultSubscription.createXeroRepeatingInvoice(
        await this.$actionBus.CreateXeroRepeatingInvoice({
          accountCode: data.accountCode,
          description: data.description,
          price: data.price,
          quantity: data.quantity,
          subscriptionId: data.subscriptionId,
          xeroContactId: data.xeroContactId,
        });

        // Add Note to account automatically
        const sevenDaysFromNow = new DateTime().add(7, "days");
        const nUUID = Uuid.generate();
        const legalAssistants = subscription.kintin.$data.legalAssistants;
        const amount = data?.price?.amount;
        const notifications: Array<Notification> = [];

        const note = new Note({
          id: nUUID,
          type: "note",
          name: "Physical Document Storage Subscription",
          pinned: false,
          createdAt: new DateTime().formatMachine,
          isPublic: false,
          notes: `Direct Debit for ${amount} Setup for ${sevenDaysFromNow.formatDate}`,
          kintin: subscription.kintin.id,
          lead: null,
        });

        note.$persist();

        legalAssistants.forEach((assistant) => {
          const notification = CreateNotification({
            user: assistant,
            note: nUUID,
            createdAt: new DateTime().formatMachine,
          });
          notification.$persist();
          notifications.push(notification);
        });

        await window.Kernel.ActionBus2.core.notes.CreateNote({
          kintin: subscription.kintin.id,
          note: note,
        } as never);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async handleEditClick(subscription: Subscription): Promise<void> {
      if (!this.$auth.hasPermission("subscription:write")) {
        return;
      }

      try {
        await UpdateSubscriptionForm(subscription).dialog({
          dialog: {
            title: "Subscription",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        subscription.$restore();
        throw e;
      }

      subscription.$persist();

      // await window.Kernel.ActionBus.kinvaultSubscription.update({
      //   subscription,
      // });
      await this.$actionBus.UpdateSubscription(subscription);

      await this.refresh(this.filters.localData);
    },
    handleClientClick(subscription: Subscription, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KintinDetailsSubscriptionRoute,
          params: {
            kintin: subscription.kintin.id,
            subscription: subscription.id,
          } satisfies KintinDetailsSubscriptionParams,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadSubscriptionForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.kinvaultSubscription.read({
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });
      const data = await this.$actionBus.ReadSubscription({
        query: {
          kintin: {
            friendlyName: Like(formData.search),
          },
          status: {
            id: In(formData.status),
          },
          createdAt: Between(formData.created),
          files: {
            id: formData.hasFiles ? IsNotNull() : undefined,
          },
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.subscription;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

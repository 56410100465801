// IntroducerCompany

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const DeleteIntroducerCompany = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/company/{introducerCompany}",
  method: "delete",
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});

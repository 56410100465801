// Attorney

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Attorney } from "@kinherit/sdk/model/attorney";

export const DeleteAttorney = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/attorney/{attorney}",
  method: "delete",
  parse: (attorney: Attorney) => ({
    params: {
      attorney,
      kintin: attorney.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Attorney.$delete(message);
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateNote } from "./create-note.action";
import { DeleteNoteHandler } from "./delete-note.action";
import { ReadNoteHandler } from "./read-note.action";
import { UpdateNote } from "./update-note.action";

export const Portal2IntroducerCompanyNote = DefineActionBus({
  name: "portal-2-introducer-company-note",
  actions: {
    CreateNote,
    DeleteNote: DeleteNoteHandler,
    ReadNote: ReadNoteHandler,
    UpdateNote,
  },
});

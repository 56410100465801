// Kintin, Profile, AccountReferral, AccountReferralCode, KintinAccess, User, Person, KintinCheck, PhoneNumber, EmailAddress, Website, CallScript, Address, IntroducerCompany, IntroducerContact, Property, Lead, BrandedKinvault, BrandedKinvaultMembership, IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Order } from "@kinherit/sdk/model/order";

export const RecordKintin = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/order/{order}/kintin/{kintin}",
  method: "get",
  rootModel: Kintin,
  parse: (message: { order: Order | string; kintin: Kintin | string }) => ({
    params: {
      order: message.order,
      kintin: message.kintin,
    },
  }),
});

// EmailCampaignLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailCampaignLog } from "@kinherit/sdk/model/email-campaign-log";

export const ReadEmailCampaignLog = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/email-campaign/log",
  method: "get",
  rootModel: EmailCampaignLog,
  parse: () => ({}),
});

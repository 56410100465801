import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultSubscriptionReferral } from "./create-branded-kinvault-subscription-referral.action";
import { DeleteBrandedKinvaultSubscriptionReferral } from "./delete-branded-kinvault-subscription-referral.action";
import { ReadBrandedKinvaultSubscriptionReferral } from "./read-branded-kinvault-subscription-referral.action";
import { RecordBrandedKinvaultSubscriptionReferral } from "./record-branded-kinvault-subscription-referral.action";
import { UpdateBrandedKinvaultSubscriptionReferral } from "./update-branded-kinvault-subscription-referral.action";

export const Portal2AdminBrandedKinvaultSubscriptionReferral = DefineActionBus({
  name: "portal-2-admin-branded-kinvault-subscription-referral",
  actions: {
    CreateBrandedKinvaultSubscriptionReferral,
    ReadBrandedKinvaultSubscriptionReferral,
    UpdateBrandedKinvaultSubscriptionReferral,
    DeleteBrandedKinvaultSubscriptionReferral,
    RecordBrandedKinvaultSubscriptionReferral,
  },
});

// Address

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Address } from "@kinherit/sdk/model/address";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadKintinAddress = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/address/kintin/{kintin}",
  method: "get",
  rootModel: Address,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});

<template>
  <div v-if="knowledgeBaseDocument" class="knowledge-base-document">
    <Card v-if="knowledgeBaseDocument.content">
      <div v-html="knowledgeBaseDocument.content"></div>
    </Card>
    <Card title="Attached Files" v-if="knowledgeBaseDocument.fileLog">
      <KnowledgeBaseFileLogTable
        :rows="knowledgeBaseDocument.fileLog"
        :allow-download="true"
        :allow-update="false"
        :allow-delete="false"
      />
    </Card>
  </div>
</template>

<script lang="ts">
import KnowledgeBaseFileLogTable from "@/module/knowledge-base/component/KnowledgeBaseFileLogTable.vue";
import { AuthService } from "@/service/auth.service";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsContentRoute } from ".";
import { KnowledgeBaseDocumentMixin } from "../../mixin/knowledge-base-document.mixin";

export default defineComponent({
  name: KnowledgeBaseDocumentDetailsContentRoute,
  components: { Card, KnowledgeBaseFileLogTable },
  mixins: [AuthService.mixin(), KnowledgeBaseDocumentMixin()],
});
</script>

import { Api } from "@/service/api.service";
import { IntroducerContact } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

interface ComputeIntroducerContactStatsMessage {
  introducerContact: IntroducerContact | string;
}

interface ComputeIntroducerContactStatsResponse {
  lastLead?: DateTime | undefined;
  lastCpdAttendance?: DateTime | undefined;
  lifeTimeValue: number;
  totalLeads: number;
}

export const ComputeIntroducerContactStats = async (
  message: ComputeIntroducerContactStatsMessage,
): Promise<ComputeIntroducerContactStatsResponse> => {
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/contact/{introducerContact}/stats",
    {
      introducerContact:
        "string" === typeof message.introducerContact
          ? message.introducerContact
          : message.introducerContact.id,
    },
  )
    .method("get")
    .result();

  return {
    ...response,
    lastLead: response.lastLead
      ? DateTime.fromString(response.lastLead)
      : undefined,
    lastCpdAttendance: response.lastCpdAttendance
      ? DateTime.fromString(response.lastCpdAttendance)
      : undefined,
  };
};

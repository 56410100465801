import { AuthService } from "@/service/auth.service";
import { Tag } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { TagDetailsParams, TagDetailsRoute } from "../page";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";

export const TagDetailsMixin = defineComponent({
  name: "tag-details-mixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.tag)],
  computed: {
    $params(): TagDetailsParams {
      return this.$route.params as any;
    },
    tag(): Tag | null {
      return Tag.$findOne(this.$params.tag);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("tag:write");
    },
  },
  methods: {
    visitTagDetails(): void {
      window.Kernel.Router.push({
        name: TagDetailsRoute,
        params: { tag: this.tag?.id },
      });
    },
  },
});

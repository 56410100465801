<template>
  <div class="will-review-output" v-bind="$attrs">
    <div class="page">
      <div class="grey">
        <h1>About this document</h1>
        <p>
          This document has been prepared to supplement our recent discussions,
          and explain the key points we identified as part of our high-level
          review of your Will(s). While we’ve taken care to consider your main
          risk areas, this Will-review is not a substitute for the full Will
          consultation process and is for information only.
        </p>
      </div>
      <div class="white">
        <h2>
          Kinherit will give your family better protection than almost any other
          Will-writer
        </h2>
        <p>
          For most people, a simple off-the-shelf Will is not going to cover the
          full extent of their family situation. And for virtually everyone,
          good planning and estate protection will add substantially to the
          protection of your estate … whilst helping your loved ones get through
          the practical side of death faster and with less distress.
        </p>
        <p>
          We cover over 200 points in our full Will consultations to give you
          clear, helpful Will & Trust advice tailored to your specific
          situation. We explain the full range of legal protections available to
          you (in addition to your Will) and help you set up wider planning to
          provide a smoother and more personalised handover on death.
        </p>
      </div>
    </div>
    <div class="page">
      <div class="grey">
        <h1>Our observations on your situation explained</h1>
        <p class="lead">
          Based on our discussion and review of your Will, we have identified
          the following areas to raise as concerns:
        </p>
      </div>
      <div class="white">
        <!--1, 3, 4, 17, 18, 23 -->
        <div
          v-if="
            checkIsNo('1.0.0') ||
            checkIsNo('1.0.2') ||
            checkIsYes('1.0.3') ||
            checkIsNo('1.0.17') ||
            checkIsNo('1.0.22') ||
            checkIsNo('1.0.19')
          "
          class="risk"
        >
          <h4>
            Your Will and its construction – factors affecting legal validity
          </h4>
          <p v-if="checkIsNo('1.0.0')">
            You don’t have a revocation clause written into your Will, which
            creates ambiguity about whether your Will states your wishes
            accurately. It also increases the risk that your Will is challenged
            or won’t work as intended.
            <strong class="has-text-danger"
              >You should re-write your Will to include a revocation
              clause.</strong
            >
          </p>
          <p v-if="checkIsNo('1.0.2')">
            We have identified that some of your details (as testator) may not
            be correct and up to date.
            <strong class="has-text-danger"
              >You should update your Will to correct this.</strong
            >
          </p>
          <p v-if="checkIsYes('1.0.3')">
            As you have married since making your Will, your Will is now invalid
            (marriage revokes a Will).
            <strong class="has-text-danger"
              >You should update your Will.
            </strong>
          </p>
          <p v-if="checkIsNo('1.0.17')">
            We discussed that you have a simple Will that does not divert to
            trust (or that it does divert, but there may be issues with the
            diversion).
            <strong class="has-text-danger">
              You should consider rewriting your Will to divert your estate to a
              trust in a way that maximises the tax treatment and protects your
              estate for your intended beneficiaries from things like
              re-marriage and divorce risks.
            </strong>
          </p>
          <p v-if="checkIsNo('1.0.22')">
            We discussed that you do not have proper handover-plan on death, and
            this means your loved ones will face greater uncertainty about what
            to do and how to do it.
            <strong class="has-text-danger">
              You should consider wider end of life planning which provides
              clear instructions for your executors and family to ensure assets
              aren’t lost, and that all the information needed to tie up your
              affairs is easy to access.
            </strong>
          </p>
          <p v-if="checkIsNo('1.0.19')">
            We discussed that there could be a serious issue with the Will
            validity and/or your physical Will document.
            <strong class="has-text-danger">
              You need to re-write your Will as you do not currently have
              reliable Will-protection.
            </strong>
          </p>
        </div>
      </div>
      <div class="risk">
        <h4>Your wishes – family and next of kin</h4>
        <!-- 2, 5, 9, 10, 11, 12, 13, 24 -->

        <p v-if="checkIsYes('1.0.1')">
          Your Will includes wishes for your funeral, but most Wills are not
          ‘read’ by the time the funeral is organised.
          <strong class="has-text-danger"
            >This can be solved by using our Kinvault handover platform, or by
            writing out your wishes and ensuring your next of kin have copies.
          </strong>
        </p>
        <p v-if="checkIsNo('1.0.1')">
          Your Will does not include wishes for your funeral.
          <strong class="has-text-danger"
            >You should write instructions for what you would want to happen
            (even if you do not have a preference) and put it on our Kinvault
            handover platform or make sure your next-of-kin all have a
            copy.</strong
          >
        </p>
        <p v-if="checkIsNo('1.0.4')">
          We have identified that some of your beneficiary details may not be
          correct and up to date.
          <strong class="has-text-danger"
            >You should update your Will to correct this.
          </strong>
        </p>
        <p v-if="checkIsYes('1.0.8')">
          We discussed that you don’t have any provision for financial
          assistance to be made to guardians if called on. This is a problem as
          the government grant (£18 per week at the time of producing this
          document) is insufficient to cover the substantial day to day costs
          and challenges of raising children.
          <strong class="has-text-danger">
            You should consider including in your Will and wider planning a
            financial support provision for guardians (if called upon).
          </strong>
          Note that guardian support is not straightforward to include in a Will
          and is better dealt with via a Will & Trust solution which allows
          continuing instruction.
        </p>
        <p v-if="checkIsYes('1.0.9')">
          We discussed that there could be some issues with the way your gifts
          have been drafted.
          <strong class="has-text-danger">
            You should update your Will to correct this.
          </strong>
        </p>
        <p v-if="checkIsYes('1.0.10')">
          We discussed that a validly-constructed ‘right to reside’ clause is
          needed to allow your loved ones to continue to live in your property
          after you die, without triggering adverse inheritance tax or other
          consequences.
          <strong class="has-text-danger"
            >You should re-write your Will with a properly constructed ‘right to
            reside’ clause.
          </strong>
          Please note that using a trust to protect the clause can be key to
          avoiding inheritance tax consequences and protecting your wishes and
          your property.
        </p>
        <p v-if="checkIsYes('1.0.11') && checkIsYes('1.0.12')">
          You have a gift of property in your Will and your property is
          currently owned as ‘Joint Tenants’. This means that the property will
          always automatically pass to the surviving owner, regardless of what
          your Will states.
          <strong class="has-text-danger">
            At a minimum you should convert the property to ‘Tenants in Common’
            ownership via a Deed of Severance, to allow your share of the
            property to be gifted as stated in your Will.
          </strong>
          Note we would also recommend updating your Will to incorporate a
          distribution to trust relating to the property and other assets, to
          better protect the various parties.
        </p>
        <p v-if="checkIsYes('1.0.11') && checkIsNo('1.0.12')">
          You have a gift of property in your Will and the property is currently
          held as ‘Tenants in Common’.
          <strong class="has-text-danger"
            >You should consider incorporating a trust into the Will, to protect
            both shares from threats such as divorce, remarriage, care fees,
            etc.
          </strong>
        </p>
        <p v-if="getFollowUpCheckByStep('1.0.23')?.note">
          <strong class="">Additional Notes<br /></strong>
          <span v-html="getFollowUpCheckByStep('1.0.23')?.note?.notes"></span>
        </p>
        <p
          v-if="
            !(
              checkIsYes('1.0.1') ||
              checkIsNo('1.0.1') ||
              checkIsNo('1.0.4') ||
              checkIsYes('1.0.8') ||
              checkIsYes('1.0.9') ||
              checkIsYes('1.0.10') ||
              checkIsYes('1.0.11') ||
              getFollowUpCheckByStep('1.0.23')?.note
            )
          "
        >
          We discussed your wishes and from our high-level review they appear to
          be reflected in your Will.
        </p>
      </div>
      <div class="risk">
        <!-- 6, 7, 8, 15, 25 -->
        <h4>Roles defined in your Will</h4>
        <p v-if="checkIsNo('1.0.5')">
          We discussed the risk of not having enough personal executors listed
          in your Will.
          <strong class="has-text-danger"
            >You should consider updating your Will to include sufficient
            personal executors, so that if one is unable or unwilling to act
            there is a backup you are comfortable with.
          </strong>
        </p>
        <p v-if="checkIsNo('1.0.16')">
          We discussed the risk of not having enough trustees willing and able
          to perform the role.
          <strong class="has-text-danger"
            >You should consider rewriting your Will to include more trustees or
            reserve trustees.
          </strong>
        </p>
        <p v-if="checkIsYes('1.0.6')">
          Your Will Writer has written themselves into your Will to provide
          ‘probate’ services on your death. This is a common way for
          Will-writers to get access to substantial (and often substantially
          inflated) fees after death, which we consider a major conflict of
          interest.
          <strong class="has-text-danger"
            >You should get confirmation from your Will-writer of the additional
            probate fees that they will charge, and assess whether their
            interests are aligned with yours and those of your family (for fast,
            low-cost probate). If you have concerns, you should re-write your
            Will to avoid exposing your family to this conflict of interest.
          </strong>
        </p>
        <p v-if="checkIsYes('1.0.27')">
          We discussed that a professional is named in your Will. This exposes
          you to the risk of uncertain cost and competence at the time of death.
        </p>
        <p v-if="checkIsNo('1.0.7')">
          We discussed the risk of not having enough guardians listed in your
          Will. If you die before your child(ren) reach 18 years old, and you
          don’t have named guardians willing and able to assume the role, then
          your child(ren) will become the responsibility of the local authority.
          <strong class="has-text-danger">
            You should re-write your Will to ensure that it includes guardians
            and backup guardians to protect your children if it becomes
            necessary.
          </strong>
        </p>
        <p v-if="checkIsNo('1.0.14')">
          We discussed the risk of not having enough beneficiaries listed in
          your Will. If your beneficiaries die before you, your estate will pass
          according to the Rules of Intestacy which will involve extra costs and
          disruption.
          <strong class="has-text-danger">
            You should consider re-writing your Will to ensure that it includes
            sufficient beneficiaries and with proper definitions.
          </strong>
        </p>
        <p v-if="getFollowUpCheckByStep('1.0.24')?.note">
          <strong class="">Additional Notes<br /></strong>
          <span v-html="getFollowUpCheckByStep('1.0.24')?.note?.notes"></span>
        </p>
        <p
          v-if="
            !(
              checkIsNo('1.0.5') ||
              checkIsNo('1.0.16') ||
              checkIsYes('1.0.6') ||
              checkIsNo('1.0.7') ||
              checkIsNo('1.0.14') ||
              getFollowUpCheckByStep('1.0.24')?.note
            )
          "
        >
          We discussed the roles people need to take on your death and they
          appear to be reflected in your Will.
        </p>
      </div>
      <div class="risk">
        <!-- 12b, 14, 16, 26 -->
        <h4>Tax planning, the home and estate protection</h4>
        <p v-if="checkIsYes('1.0.13')">
          Looking at the land registry, your property is owned jointly, and not
          as ‘Tenants in Common’.
          <strong class="has-text-danger"
            >You should consider changing the ownership status to protect the
            home from various threats.
          </strong>
        </p>
        <p v-if="checkIsYes('1.0.15')">
          Based on our review and discussions, the way your Will is currently
          laid out could have adverse inheritance tax consequences.
          <strong class="has-text-danger">
            You should re-write your Will to avoid the risk of unnecessary tax
            charges.
          </strong>
        </p>
        <p v-if="checkIsNo('1.0.21')">
          We discussed that you do not have proper end-of-life planning in
          place, and as a result your family will find it more difficult to
          ‘step-into’ the practical side of your affairs.
          <strong class="has-text-danger"
            >You should consider wider end of life planning which provides clear
            instructions for your executors and family to ensure assets aren’t
            lost, and that all the information needed to tie up your affairs is
            easy to access.
          </strong>
        </p>
        <p v-if="getFollowUpCheckByStep('1.0.25')?.note">
          <strong class="">Additional Notes<br /></strong>
          <span v-html="getFollowUpCheckByStep('1.0.25')?.note?.notes"></span>
        </p>
        <p
          v-if="
            !(
              checkIsYes('1.0.13') ||
              checkIsYes('1.0.15') ||
              checkIsNo('1.0.21') ||
              getFollowUpCheckByStep('1.0.25')?.note
            )
          "
        >
          You should keep your situation under review for change, to ensure your
          Will deals with your assets in a tax-efficient way.
        </p>
      </div>
      <div class="risk">
        <!-- 19, 20, 21, 27  -->
        <h4>
          More than a Will – planning to lower distress, help process, and speed
          pay-outs
        </h4>
        <p v-if="checkIsNo('1.0.18')">
          Your Will would appear to be missing a standard paragraph that
          references the STEP provisions, which are used to explain how the
          instructions are to be interpreted.
          <strong class="has-text-danger"
            >You should re-write your Will with the standard explanative
            provisions.
          </strong>
        </p>
        <p v-if="checkIsNo('1.0.20')">
          We discussed that Lasting Powers of Attorney are a key planning tool,
          and recommend everyone has these in place – noting accidents/illness
          can happen at any time.
          <strong class="has-text-danger">
            You should consider taking out Lasting Powers of Attorney – Health &
            Welfare.
          </strong>
        </p>
        <p v-if="getFollowUpCheckByStep('1.0.26')?.note">
          <strong class="">Additional Notes<br /></strong>
          <span v-html="getFollowUpCheckByStep('1.0.26')?.note?.notes"></span>
        </p>
        <p
          v-if="
            !(
              checkIsNo('1.0.18') ||
              checkIsNo('1.0.20') ||
              getFollowUpCheckByStep('1.0.26')?.note
            )
          "
        >
          We discussed the wider planning you have to reduce distress, help the
          process and speed pay-outs.
        </p>
      </div>
    </div>
    <div class="page">
      <div class="white">
        <h2>Helping you re-write your Will</h2>
        <p>
          If you’d like our help to re-write your Will, we offer complimentary
          full Will consultations and it should be possible for us to use much
          of the data you’ve already provided. Every Will we write also includes
          our Kinvault planning platform, which enables you to easily hand
          everything over to your family and executors.
        </p>
        <p>Speak to your estate planner for more information.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Kintin, KintinCheckFollowUp } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import { KintinCheckService } from "../../service/kintin-check.service";

export default defineComponent({
  name: "WillReviewOutput",
  props: {
    kintin: {
      type: Object as PropType<Kintin>,
      required: true,
    },
  },
  data: (ctx) => ({
    kintinCheckService: KintinCheckService.instance(ctx.kintin),
  }),
  methods: {
    checkIsYes(step: `${string}.${string}.${string}`): boolean {
      return this.kintinCheckService.checkIsYes(step);
    },
    checkIsNo(step: `${string}.${string}.${string}`): boolean {
      return this.kintinCheckService.checkIsNo(step);
    },
    checkIsMaybe(step: `${string}.${string}.${string}`): boolean {
      return this.kintinCheckService.checkIsMaybe(step);
    },
    getFollowUpCheckByStep(
      step: `${string}.${string}.${string}`,
    ): KintinCheckFollowUp | null {
      const check = this.kintin.checkFollowUps.find((c) => c.step === step);

      if (check) {
        return check;
      }

      return null;
    },
  },
});
</script>

<style lang="scss">
.will-review-output {
  .page {
    //padding: 1.5rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>

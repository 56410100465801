// BrandedKinvault, BrandedKinvaultInvitation, AccountReferralCode, IntroducerContact, Profile, EmailAddress, BrandedKinvaultInviteReferral

import { Api } from "@/service/api.service";
import { AccountReferralCode, BrandedKinvault } from "@kinherit/sdk";

interface Message {
  brandedKinvault: BrandedKinvault;
  file: File;
  defaultReferralCode: AccountReferralCode;
  brandOverride: BrandedKinvault | null;
}

interface Response {
  [key: string]: never;
}

export const UploadMembersCsv = async (message: Message): Promise<Response> => {
  const { file } = message;

  return (await Api.resource(
    "portal-2",
    "/v2/portal-2/admin/branded-kinvault/invitation/{brandedKinvault}/csv/upload",
    {
      brandedKinvault: message.brandedKinvault,
    },
  )
    .method("put")
    .files({
      uploadedFile: file,
      defaultReferralCode: message.defaultReferralCode.id,
      brandOverride: message.brandOverride && message.brandOverride.id,
    })
    .result()) as never;
};

// Person, Profile, Address, EmailAddress, PhoneNumber, Website

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Person } from "@kinherit/sdk/model/person";

export const DeletePerson = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/person/{person}",
  method: "delete",
  parse: (message: { kintin: Kintin | string; person: Person | string }) => ({
    params: {
      kintin: message.kintin,
      person: message.person,
    },
  }),
  after: ({ message }) => {
    Person.$delete(message.person);
  },
});

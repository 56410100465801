<template>
  <div class="knowledge-base-document-file-log-table">
    <Table
      :key="renderKey"
      :rows="rows"
      :columns="columns"
      empty-message="No Files Here Yet"
      is-narrow
    >
      <template #actions="{ row }">
        <div class="buttons is-right">
          <Button
            v-if="allowUpdate"
            size="is-small"
            color="is-plain"
            @click="updateFile(row)"
            tooltip="Rename"
            icon-left="Edit"
          />
          <Button
            v-if="allowDelete"
            icon-left="Trash"
            color="is-plain"
            size="is-small"
            @click="deleteFile(row)"
            tooltip="Delete File"
          />
        </div>
      </template>
      <template #fileName="{ row }">
        <span
          v-if="allowDownload"
          class="has-text-weight-bold is-clickable has-text-link"
          @click="downloadFile(row)"
          >{{ row.fileName }}</span
        >
        <span v-else>{{ row.fileName }}</span>
      </template>
    </Table>
  </div>
</template>
<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { FileLog } from "@kinherit/sdk/index";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "KnowledgeBaseFileLogTable",
  props: {
    rows: {
      type: Array as PropType<FileLog[]>,
      required: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowUpdate: {
      type: Boolean,
      default: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Button,
    Table,
  },
  emits: ["update-file", "download-file", "delete-file"],
  data: () => ({
    columns: [
      {
        title: "File Name",
        slot: "fileName",
      },
      {
        title: "Created",
        map: (row: FileLog) => row.createdAt.formatYMD,
      },
      {
        slot: "actions",
      },
    ] as TableColumn<FileLog>[],
    renderKey: 0,
  }),
  methods: {
    updateFile(file: FileLog) {
      this.$emit("update-file", file);
    },
    downloadFile(file: FileLog) {
      this.$emit("download-file", file);
    },
    deleteFile(file: FileLog) {
      this.$emit("delete-file", file);
    },
  },
});
</script>

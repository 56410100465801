// KintinAccess

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const CreateKintinAccess = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/kintin-access",
  method: "post",
  parse: (target: KintinAccess) => ({
    body: {
      accessType: target.$data.accessType,
      legal: target.$data.legal,
      assets: target.$data.assets,
      contacts: target.$data.contacts,
      admin: target.$data.admin,
      care: target.$data.care,
      death: target.$data.death,
      misc: target.$data.misc,
      settings: target.$data.settings,
      allowAccess: target.$data.allowAccess,
      kintin: target.$data.kintin,
      user: target.$data.user,
      introducerContact: target.$data.introducerContact,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

// EmailCampaignLog

import { Api } from "@/service/api.service";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface CreateEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}

export interface CreateEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
}

export const CreateEmailCampaignLog = async (
  message: CreateEmailCampaignLogMessage,
): Promise<CreateEmailCampaignLogResponse> => {
  const request = Api.resource(
    "portal-2",
    "/v2/portal-2/email-campaign/log",
    {},
  )
    .method("post")

    .body({
      campaign: message.emailCampaignLog.$data.campaign,
      scheduledToSendAt: message.emailCampaignLog.$data.scheduledToSendAt,
      body: message.emailCampaignLog.$data.body,
      title: message.emailCampaignLog.$data.title,
      subject: message.emailCampaignLog.$data.subject,
      template: message.emailCampaignLog.$data.template,
      data: message.emailCampaignLog.$data.data,
      from: message.emailCampaignLog.$data.from,
      status: message.emailCampaignLog.$data.status,
    });

  const response = await request.result();

  const emailCampaignLog = EmailCampaignLog.$inflate(
    response.emailCampaignLog,
  ).first();

  if (!emailCampaignLog) {
    throw new Error("Failed to create email log");
  }

  // await window.Kernel.ActionBus.execute(
  //   "admin/email-campaign/campaign-log/attachment/upload",
  //   {
  //     emailCampaignLog,
  //     files: message.files,
  //   },
  // );

  await window.Kernel.ActionBus2.portal2.emailCampaign.log.UploadAttachment({
    emailCampaignLog,
    files: message.files,
  });

  message.emailCampaignLog.$delete();

  return {
    emailCampaignLog: EmailCampaignLog.$inflate(
      response.emailCampaignLog,
      undefined,
      response.$rootIds,
    ),
  };
};

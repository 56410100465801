import { Api } from "@kinherit/sdk";

export interface UpdateSortReferStatusMessage {
  externalId: string;
  newStatusId: string;
}

interface UpdateSortReferStatusResponse {}

export const UpdateSortReferStatus = async (
  message: UpdateSortReferStatusMessage,
): Promise<UpdateSortReferStatusResponse> => {
  const request = Api.resource("external", "/v2/external/sort-refer/status")
    .method("put")
    .body({
      externalId: message.externalId,
      newStatusId: message.newStatusId,
    });

  const response = await request.result();

  return response;
};

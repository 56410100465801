// IntroducerCpd

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerCpd } from "@kinherit/sdk/model/introducer-cpd";

export const UpdateIntroducerCpd = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/introducer/introducer-cpd/{introducerCpd}",
  method: "patch",
  parse: (target: IntroducerCpd) => ({
    body: {
      contact: target.$data.contact,
      session: target.$data.session,
      status: target.$data.status,
      type: target.$data.type,
    },
    params: {
      introducerCpd: target,
    },
  }),
});

import { Api } from "@kinherit/sdk/api";
import { EmailCampaignLog } from "@kinherit/sdk/index";

// EmailCampaignLog, FileLog

export const UploadAttachment = async (message: {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}): Promise<{}> => {
  for (const file of message.files) {
    await Api.resource(
      "portal-2",
      "/v2/portal-2/email-campaign/log/{emailCampaignLog}/attachment",
      {
        emailCampaignLog: message.emailCampaignLog,
      },
    )
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();
  }

  return {};
};

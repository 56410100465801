// CpdSessionRegistration

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { CpdSessionRegistration } from "@kinherit/sdk/model/cpd-session-registration";

export const DeleteCpdSessionRegistration = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/register/{cpdSessionRegistration}",
  method: "delete",
  parse: (cpdSessionRegistration: CpdSessionRegistration | string) => ({
    params: {
      cpdSessionRegistration,
    },
  }),
  after: ({ message }) => {
    CpdSessionRegistration.$delete(message);
  },
});

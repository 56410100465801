<template>
  <SidebarPage
    :key="key"
    v-if="order && !loading"
    class="order-details"
    :name="`Client: ${name}`"
    :avatar-name="name"
    :tabs="tabs"
    :has-context-menu="true"
    @context-menu="contextMenu"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <OrderDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView
        name="default"
        @create-credit-note="createCreditNote"
        @edit-order="editOrder"
        @download-invoice="downloadInvoice"
        @download-business-invoice="downloadBusinessInvoice"
        @push-to-xero="pushToXero"
      >
        <OrderDetailsSummary />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "OrderDetailsWrapper",
  "route": "OrderDetails",
  "selector": ".order-details",
  "imports": {
    "OrderDetailsSummaryWrapper": "@/module/order/page/details/summary/OrderDetails.Summary.test",
    "OrderDetailsSidebarWrapper": "@/module/order/page/details/OrderDetails.Sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": [
        "Edit Order",
        "Download Invoice",
        "Download Business Invoice",
        "Delete Order"
      ]
    },
    "tabs": {
      "type": "tabs",
      "items": ["Summary"]
    },
    "summary": {
      "type": "to-one",
      "selector": "",
      "wrapper": "OrderDetailsSummaryWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "OrderDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateCreditNoteForm } from "@/module/core/forms/update-credit-note.form";
import { KintinDetailsOrderRoute } from "@/module/kinvault.kintin/page/details/documents/orders";
import { OrderDetailsMixin } from "@/module/order/mixin/order-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { OrderDetailsParams, OrderDetailsRoute } from ".";
import { UpdateOrderForm } from "../../form/update-order.form";
import OrderDetailsSidebar from "./OrderDetails.Sidebar.vue";
// import { OrderDetailsCreditNotesRoute } from "./credit-notes";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Order } from "@kinherit/sdk";
import { OrderDetailsSummaryRoute } from "./summary";
import OrderDetailsSummary from "./summary/OrderDetails.Summary.vue";

export default defineComponent({
  name: OrderDetailsRoute,
  mixins: [OrderDetailsMixin],
  components: {
    PageNotFound,
    RouterChildView,
    OrderDetailsSummary,
    OrderDetailsSidebar,
    SidebarPage,
  },
  computed: {
    $params(): OrderDetailsParams {
      return this.$route.params as OrderDetailsParams;
    },
    name(): string {
      if (this.order?.isTrustReg) {
        return "Trust Reg";
      }

      const kintin = this.order?.kintin;

      return kintin?.friendlyName ?? "";
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: OrderDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.calendar.icon,
      },
      // {
      //   label: "Credit Notes",
      //   route: {
      //     name: OrderDetailsCreditNotesRoute,
      //     params: ctx.$params,
      //   },
      //   icon: StyleService.icon.creditNote.icon,
      // },
    ],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.order.order.record(
    //   to.params as OrderDetailsParams,
    // );
    const params = to.params as OrderDetailsParams;

    await window.Kernel.ActionBus2.portal2.order.RecordOrder({
      message: params.order,
    });
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.order) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Order",
              permission: ["order:write", "kintin:write", "trust-reg:write"],
              line1: "Edit this order",
              icon: StyleService.icon.edit.icon,
              action: this.editOrder,
            },
            {
              title: "Download Invoice",
              permission: [],
              line1: "Download the invoice for this order",
              icon: StyleService.icon.signFile.icon,
              action: this.downloadInvoice,
            },
            {
              title: "Download Business Invoice",
              permission: [],
              line1: "Invoice for continuity planning",
              icon: StyleService.icon.signFile.icon,
              action: this.downloadBusinessInvoice,
            },
            {
              title: "Delete Order",
              permission: ["order:write", "kintin:write", "trust-reg:write"],
              line1: "Delete this order",
              icon: StyleService.icon.delete.icon,
              action: this.deleteOrder,
            },
            //{
            //  title: "Create Credit note",
            //  permission: ["order:write", "kintin:write", "trust-reg:write"],
            //  line1: "Create a credit note for this order",
            //  icon: StyleService.icon.creditNote.icon,
            //  action: this.createCreditNote,
            //},
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permission",
        filter: "some",
        callback: (item) => item.action(),
      })();
    },
    async editOrder(): Promise<void> {
      if (!this.order) {
        return;
      }

      const order = Order.$findOne(this.order.$id);

      if (!order) {
        return;
      }

      try {
        await UpdateOrderForm({
          data: order,
          referralCode: order.kintin?.referral?.referralCode ?? undefined,
        }).dialog({
          dialog: {
            title: "Update Order",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        this.order?.orderItems?.forEach((item) => item.$delete());

        // await window.Kernel.ActionBus.order.order.record(this.$params);
        await this.$actionBus.RecordOrder({
          message: this.$params.order,
        });

        throw e;
      }

      await this.$actionBus.ReplaceOrderItems(order);
      await this.$actionBus.UpdateOrder(order);

      order.$delete({
        orderItems: true,
      });

      await this.$actionBus.RecordOrder({
        message: this.$params.order,
      });

      this.key++;
    },
    async deleteOrder(): Promise<void> {
      const order = this.order;

      if (!order) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Order",
          message: "Are you sure you want to delete this order?",
        },
        button: {
          ok: {
            text: "Delete",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      const kintinOwner = order.$data.kintin;

      // await window.Kernel.ActionBus.order.order.delete({
      //   order: order,
      // });
      await this.$actionBus.DeleteOrder(order);

      window.Kernel.visitRoute({
        name: KintinDetailsOrderRoute,
        params: {
          kintin: kintinOwner,
        },
      });
    },
    async downloadBusinessInvoice(): Promise<void> {
      await this.downloadInvoice(true);
    },
    async downloadInvoice(isBusinessInvoice: boolean = false): Promise<void> {
      const order = this.order;

      if (!order) {
        return;
      }

      // await window.Kernel.ActionBus.order.order.downloadInvoice({
      //   order: order,
      //   isBusinessInvoice: isBusinessInvoice,
      // });
      await this.$actionBus.DownloadInvoice({
        order,
        isBusinessInvoice,
      });
    },
    async createCreditNote(): Promise<void> {
      const creditNote = await UpdateCreditNoteForm().dialog({
        dialog: {
          title: "Create Credit Note",
        },
      });

      creditNote.relatedOrder = this.order;

      await window.Kernel.ActionBus2.portal2.creditNote.UpdateCreditNote(
        creditNote,
      );

      this.key++;
    },
    async pushToXero(): Promise<void> {
      const order = this.order;

      if (!order) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: "Push To Xero",
          message: "Are you sure you want to push this order to Xero?",
        },
        button: {
          ok: {
            text: "Yes Push To Xero",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      // await window.Kernel.ActionBus.order.order.pushToXero({
      //   order: order,
      // });
      await this.$actionBus.PushOrderToXero(order);
    },
  },
});
</script>

import {
  KinvaultKintinDetailsPostPaymentBreadCrumb,
  KinvaultKintinDetailsPostPaymentMergeContactsBreadCrumb,
} from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

/** PostPayment */
export const KinvaultKintinDetailsPostPaymentRoute =
  "KinvaultKintinDetailsPostPayment";
const KinvaultKintinDetailsPostPaymentURI =
  "/kinvault/:kintin/documents/post-payment";
export type KinvaultKintinDetailsPostPaymentParams = RouteParamObject<
  typeof KinvaultKintinDetailsPostPaymentURI
>;

export const KinvaultKintinDetailsPostPaymentMergeContactsRoute =
  "KinvaultKintinDetailsPostPaymentMergeContacts";
const KinvaultKintinDetailsPostPaymentMergeContactsURI =
  "/kinvault/:kintin/documents/post-payment/:signDoc/merge-contacts";
export type KinvaultKintinDetailsPostPaymentMergeContactsParams =
  RouteParamObject<typeof KinvaultKintinDetailsPostPaymentMergeContactsURI>;

export const KinvaultKintinDetailsPostPaymentVerificationSessionRoute =
  "KinvaultKintinDetailsPostPaymentVerificationSession";
const KinvaultKintinDetailsPostPaymentVerificationSessionURI =
  "/kinvault/:kintin/documents/post-payment/:signDoc/verification-session";
export type KinvaultKintinDetailsPostPaymentVerificationSessionParams =
  RouteParamObject<
    typeof KinvaultKintinDetailsPostPaymentVerificationSessionURI
  >;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsPostPaymentRoute,
    path: KinvaultKintinDetailsPostPaymentURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsPostPayment" */ "./KintinDetails.PostPayment.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsPostPaymentBreadCrumb,
    },
  },
  {
    name: KinvaultKintinDetailsPostPaymentMergeContactsRoute,
    path: KinvaultKintinDetailsPostPaymentMergeContactsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsPostPaymentMergeContacts" */ "./KintinDetails.PostPayment.MergeContacts.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsPostPaymentMergeContactsBreadCrumb,
    },
  },
  {
    name: KinvaultKintinDetailsPostPaymentVerificationSessionRoute,
    path: KinvaultKintinDetailsPostPaymentVerificationSessionURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsPostPaymentVerificationSession" */ "./KintinDetails.PostPayment.VerificationSession.vue"
        ),
    },
  },
];

import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeCompaniesReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
}

interface ComputeCompaniesReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    companyCount: number;
  }>;
}
export type TCompanyReportResult = {
  year: number;
  week: number;
  quarter: number;
  companyCount: number;
  culmativeCount: number;
};

export const ComputeCompaniesReportHandler = async (
  message: ComputeCompaniesReportMessage,
): Promise<ComputeCompaniesReportResponse> => {
  const { year, created, granularity, assignedUser, compareTo } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/report/companies",
    {},
  )
    .method("get")
    .params({
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      assignedUser: assignedUser ?? null,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
    })

    .result();

  const datasets: {
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }[] = [];

  const colours = ["#773ab5", "#431573"];
  // For each dataset returned, create a new dataset object with the correct format
  // for the chart.js graph
  response.datasets.forEach((dataset: any, index: number) => {
    datasets.push({
      label: "Companies",
      stack: "Stack " + index,
      backgroundColor: colours[index],
      data: dataset.datasets.map((i: any) => {
        return i.culmativeCount;
      }),
    });
  });

  return {
    datasets: datasets,
    labels: response.labels,
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};

<template>
  <div class="kintin-details-address-book-exclusions">
    <PageHeader htag="h2" text="Exclusions" v-if="kintin">
      <template #buttons>
        <Button
          text="Add Exclusion"
          @click="createExclusion"
          class="add-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <ExclusionList
        :kintin="kintin"
        :exclusions="$data.filtered.exclusions"
        @reload="filter"
      />
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "ExclusionsContainerWrapper",
  "selector": ".kintin-details-address-book-exclusions",
  "imports": {
    "ExclusionListWrapper": "@/module/kinvault.kintin/component/lists/ExclusionList.test",
    "UpdateExclusionFormWrapper": "@/module/kinvault.kintin/form/update-exclusion.form.test"
  },
  "methods": {
    "exclusionList": {
      "type": "to-one",
      "selector": ".exclusion-list",
      "wrapper": "ExclusionListWrapper"
    },
    "addExclusion": {
      "type": "click",
      "selector": ".add-button"
    },
    "addExclusionForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateExclusionFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateExclusion } from "@/config/model.config";
import ExclusionList from "@/module/kinvault.kintin/component/lists/ExclusionList.vue";
import { UpdateExclusionForm } from "@/module/kinvault.kintin/form/update-exclusion.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { Json } from "@kinherit/framework/component.display/json";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Exclusion, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `ExclusionsContainer`,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    Json,
    ExclusionList,
    Button,
    PageHeader,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      exclusions: Array<Exclusion>(),
    },
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      this.filtered.exclusions = Exclusion.$findBy({
        kintin: {
          id: this.kintin.id,
        },
      });

      this.$emit("reload");
    },
    async createExclusion() {
      if (!this.kintin) {
        return;
      }

      let excludedBy: string | undefined = undefined;

      if (this.kintin.type.value === "single") {
        excludedBy = this.kintin.primaryPerson?.id;
      }

      const exclusion = CreateExclusion({
        excludedBy,
        kintin: this.kintin.id,
      });

      try {
        await UpdateExclusionForm({
          data: exclusion,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create exclusion",
          },
        });
      } catch {
        exclusion.$delete();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.exclusion.create({
      //   exclusion,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.exclusion.CreateExclusion(exclusion);

      this.filter();
    },
  },
});
</script>

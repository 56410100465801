// CpdSession, Address, Website

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { CpdSession } from "@kinherit/sdk/model/cpd-session";

export const DeleteCpdSession = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/{cpdSession}",
  method: "delete",
  parse: (cpdSession: CpdSession | string) => ({
    params: {
      cpdSession,
    },
  }),
  after: ({ message }) => {
    CpdSession.$delete(message);
  },
});

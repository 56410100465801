import { Api } from "@kinherit/sdk/api";
import { IntroducerFeePaymentRun, EmailAddress } from "@kinherit/sdk/index";

// IntroducerFeePayment
export const SendIntroducerRemittanceEmails = async (data: {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
  overrideEmailAddress?: EmailAddress | null;
}): Promise<void> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment/remittance/send",
  )
    .method("put")
    .body({
      introducerFeePaymentRun: data.introducerFeePaymentRun.toString(),
      overrideEmailAddress: data.overrideEmailAddress?.id,
    })
    .result();
};

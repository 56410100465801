import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateNoteHandler } from "./create-note.action";
import { DeleteNoteHandler } from "./delete-note.action";
import { ReadNoteHandler } from "./read-note.action";
import { RecordNoteHandler } from "./record-note.action";
import { UpdateNoteHandler } from "./update-note.action";

export const CoreNote = DefineActionBus({
  name: "core-note",
  actions: {
    CreateNote: CreateNoteHandler,
    DeleteNote: DeleteNoteHandler,
    ReadNote: ReadNoteHandler,
    RecordNote: RecordNoteHandler,
    UpdateNote: UpdateNoteHandler,
  },
});

import { Api } from "@kinherit/sdk";

export interface GetSortReferCaseMessage {
  externalId: string;
}

interface GetSortReferCaseResponse {}

export const GetSortReferCase = async (
  message: GetSortReferCaseMessage,
): Promise<GetSortReferCaseResponse> => {
  const request = Api.resource(
    "external",
    "/v2/external/sort-refer/get-instruction/{externalId}",
    {
      externalId: message.externalId,
    },
  ).method("get");

  const response = await request.result();

  return response;
};

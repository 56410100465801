import { AddDocument } from "@/action-bus/portal-2/knowledge-base/category/add-document.action";
import { CreateKnowledgeBaseDocument } from "@/action-bus/portal-2/knowledge-base/category/create-knowledge-base-document.action";
import { DeleteKnowledgeBaseCategory } from "@/action-bus/portal-2/knowledge-base/category/delete-knowledge-base-category.action";
import { Portal2KnowledgeBaseCategoryDocument } from "@/action-bus/portal-2/knowledge-base/category/document";
import { RecordKnowledgeBaseCategory } from "@/action-bus/portal-2/knowledge-base/category/record-knowledge-base-category.action";
import { RemoveDocument } from "@/action-bus/portal-2/knowledge-base/category/remove-document.action";
import { UpdateKnowledgeBaseCategory } from "@/action-bus/portal-2/knowledge-base/category/update-knowledge-base-category.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadKnowledgeBaseDocument } from "./read-knowledge-base-document.action";

export const Portal2KnowledgeBaseCategory = DefineActionBus({
  name: "portal-2-knowledge-base-category",
  actions: {
    CreateKnowledgeBaseDocument,
    DeleteKnowledgeBaseCategory,
    RecordKnowledgeBaseCategory,
    ReadKnowledgeBaseDocument,
    UpdateKnowledgeBaseCategory,
    AddDocument,
    RemoveDocument,
    document: Portal2KnowledgeBaseCategoryDocument,
  },
});

// User, Profile, PhoneNumber, EmailAddress, Address, Website, BrandedKinvaultMembership, BrandedKinvault, KintinAccess, Kintin, Credential

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { User } from "@kinherit/sdk/model/user";

export const RecordUser = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/log/{user}",
  method: "get",
  rootModel: User,
  parse: (user: User | string) => ({
    params: {
      user,
    },
  }),
});

import { ReadIdentityVerificationSessionFile } from "@/action-bus/portal-2/kinvault/sign-doc/read-identity-verification-session-file.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateSignDoc } from "./create-sign-doc.action";
import { DeleteSignDoc } from "./delete-sign-doc.action";
import { DownloadDisclaimer } from "./download-disclaimer.action";
import { GenerateDisclaimer } from "./generate-disclaimer.action";
import { MergeSignDocContact } from "./merge-sign-doc-contact.action";
import { PreviewDisclaimer } from "./preview-disclaimer.action";
import { ReadSignDoc } from "./read-sign-doc.action";
import { RecordSignDoc } from "./record-sign-doc.action";
import { UpdateSignDoc } from "./update-sign-doc.action";
import { ReadIdentityVerificationSession } from "@/action-bus/portal-2/kinvault/sign-doc/read-identity-verification-session.action";

export const Portal2KinvaultSignDoc = DefineActionBus({
  name: "portal-2-kinvault-sign-doc",
  actions: {
    CreateSignDoc,
    DeleteSignDoc,
    GenerateDisclaimer,
    MergeSignDocContact,
    ReadSignDoc,
    RecordSignDoc,
    PreviewDisclaimer,
    DownloadDisclaimer,
    UpdateSignDoc,
    ReadIdentityVerificationSession,
    ReadIdentityVerificationSessionFile,
  },
});

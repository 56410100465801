// Profile, Person, PhoneNumber, EmailAddress, Website, Address, Adviser, Attorney, Beneficiary, Executor, Gift, Guardian, Trustee

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Person } from "@kinherit/sdk/model/person";

export const RecordPerson = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/person/{person}",
  method: "get",
  rootModel: Person,
  parse: (message: { kintin: Kintin | string; person: Person | string }) => ({
    params: {
      kintin: message.kintin,
      person: message.person,
    },
  }),
});

// CpdSession, Address, Website, CpdSessionRegistration, Profile, PhoneNumber, EmailAddress, IntroducerContact, IntroducerCompany, IntroducerCpd

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CpdSession } from "@kinherit/sdk/model/cpd-session";

export const RecordCpdSession = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/{cpdSession}",
  method: "get",
  rootModel: CpdSession,
  parse: (cpdSession: CpdSession | string) => ({
    params: {
      cpdSession,
    },
  }),
});

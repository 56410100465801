// KintinCheck, Note

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KintinCheck } from "@kinherit/sdk/model/kintin-check";

export const DeleteKintinCheck = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/kintin-check/{kintinCheck}",
  method: "delete",
  parse: (kintinCheck: KintinCheck) => ({
    params: {
      kintinCheck,
      kintin: kintinCheck.$data.kintin,
    },
  }),
  after: ({ message }) => {
    KintinCheck.$delete(message);
  },
});

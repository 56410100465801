<template>
  <MasterListPage
    class="branded-kinvault-details-members"
    v-if="brandedKinvault"
    :columns="columns"
    :rows="rows"
    is-nested
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    title="Members"
    subtitle="Manage users who belong to this branded kinvault"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsMembersWrapper",
  "route": "BrandedKinvaultDetailsMembers",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "selector": ".branded-kinvault-details-members"
}
</cypress-wrapper>

<script lang="ts">
import { UserTableColumns } from "@/config/table-columns.config";
import {
  AdminUserDetailsParams,
  AdminUserDetailsRoute,
} from "@/module/admin.user/page/details";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IUser, User } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsMembersRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsMembersRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: { MasterListPage },
  data: () => ({
    columns: UserTableColumns,
    rows: Array<User>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IUser,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      // const { users, pagination } =
      //   await window.Kernel.ActionBus.adminUser.read({
      //     brandedKinvault: [this.$params.brandedKinvault],
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   });

      const { user, $pagination } = await this.$actionBus.ReadMembers({
        message: this.$params.brandedKinvault,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = user;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    handleClick(user: User, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsRoute,
          params: { user: user.id } satisfies AdminUserDetailsParams,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>

// Property

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Property } from "@kinherit/sdk/model/property";

export const RecordProperty = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/property/{property}",
  method: "get",
  rootModel: Property,
  parse: (property: Property) => ({
    params: {
      property,
      kintin: property.$data.kintin,
    },
  }),
});

// ContactPartnerPortalPermissions

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk/model/contact-partner-portal-permissions";

export const DeleteIntroducerContactPartnerPortal = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/contact/partner-portal-permissions/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
  method: "delete",
  parse: (message: ContactPartnerPortalPermissions) => ({
    params: {
      introducerContact: message.$data.contact,
      contactPartnerPortalPermissions: message,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Auth } from "./auth";
import { Core } from "./core";
import { Portal2 } from "./portal-2";

export const ActionBus = DefineActionBus({
  name: "action-bus",
  actions: {
    auth: Auth,
    core: Core,
    portal2: Portal2,
  },
});

import { Api } from "@/service/api.service";

interface TransferIntroducerContactMessage {
  contact: string;
  company: string;
}

interface TransferIntroducerContactResponse {}

export const ChangeCompany = async (
  message: TransferIntroducerContactMessage,
): Promise<TransferIntroducerContactResponse> => {
  const { contact, company } = message;

  if (!contact || !company) {
    throw new Error("No contact or company found");
  }

  await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/contact/change-company",
  )
    .method("put")
    .body({
      contact: contact,
      company: company,
    })

    .result();

  return {};
};

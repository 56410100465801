// IntroducerCpd, CpdSession

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";
import { IntroducerCpd } from "@kinherit/sdk/model/introducer-cpd";

export const ReadIntroducerCpds = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/{introducerContact}/cpd",
  method: "get",
  rootModel: IntroducerCpd,
  parse: (introducerContact: IntroducerContact | string) => ({
    params: {
      introducerContact,
    },
  }),
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailTemplate } from "./create-email-template.action";
import { ReadEmailTemplate } from "./read-email-template.action";

export const Portal2AdminEmailTemplates = DefineActionBus({
  name: "portal-2-admin-email-templates",
  actions: {
    ReadEmailTemplate,
    CreateEmailTemplate,
  },
});

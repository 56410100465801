import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinFromLead } from "./create-kintin-from-lead.action";
import { DeleteLead } from "./delete-lead.action";
import { Portal2LeadNote } from "./notes";
import { ReadHistory } from "./read-history.action";
import { RecordAccountReferralCode } from "./record-account-referral-code.action";
import { RecordIntroducerContact } from "./record-introducer-contact.action";
import { RecordKintin } from "./record-kintin.action";
import { RecordLead } from "./record-lead.action";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateAccountReferral } from "./update-account-referral.action";
import { UpdateLead } from "./update-lead.action";
import { Portal2LeadAccountReferral } from "@/action-bus/portal-2/lead/account-referral";

export const Portal2Lead = DefineActionBus({
  name: "portal-2-lead",
  actions: {
    note: Portal2LeadNote,
    accountReferral: Portal2LeadAccountReferral,
    RecordLead,
    UpdateLead,
    DeleteLead,
    ReadHistory,
    RecordKintin,
    UpdateAccountReferral,
    RecordIntroducerContact,
    RecordAccountReferralCode,
    UpdateLeadProfile: ReplaceProfile,
    CreateKintinFromLead,
  },
});

import { CpdDetailsParams, CpdDetailsRoute } from "@/module/cpd/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { CpdSession } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const CpdDetailsMixin = defineComponent({
  name: "cpd-details-mixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.cpd)],
  computed: {
    $params(): CpdDetailsParams {
      return this.$route.params as CpdDetailsParams;
    },
    cpdSession(): CpdSession | null {
      return CpdSession.$findOne(this.$params.cpdSession);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("cpd-session:write");
    },
  },
  methods: {
    visitKintinDetails(): void {
      window.Kernel.Router.push({
        name: CpdDetailsRoute,
        params: { kintin: this.cpdSession?.id },
      });
    },
    async refresh(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      // await window.Kernel.ActionBus.cpd.session.record({
      await this.$actionBus.RecordCpdSession({ message: this.cpdSession });
    },
  },
});

<template>
  <div class="columns is-centered">
    <div class="column is-6-tablet is-4-desktop">
      <Card title="Login">
        <Form is-borderless :config="$data.form" @submit="handleClick" />
        <div class="buttons is-right mt-5">
          <Button
            class="login-button"
            :is-fullwidth="true"
            color="is-positive"
            @click="handleClick"
            >Login</Button
          >
        </div>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { DashboardIndexRoute } from "@/module/dashboard/page";
import { Form } from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";
import { AuthLoginRoute, AuthRequestMfParams, AuthRequestMfRoute } from ".";
import { LoginForm } from "../form/login.form";

export default defineComponent({
  name: AuthLoginRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.auth)],
  components: { Form, Button, Card },
  data: () => ({
    form: LoginForm(),
  }),
  methods: {
    async handleClick(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      // await window.Kernel.ActionBus.auth.session.request(this.form.localData);
      const { activeSession } = await this.$actionBus.session.RequestSession(
        this.form.localData,
      );

      if (activeSession.mfaRequired) {
        const params: AuthRequestMfParams = {
          username: activeSession.username,
        };

        window.Kernel.visitRoute({
          name: AuthRequestMfRoute,
          params,
          query: this.$route.query,
        });
      } else if (this.$route.query.redirect) {
        window.Kernel.visitRoute({
          path: this.$route.query.redirect as string,
          query: Object.omit(this.$route.query, ["redirect"]),
        });
      } else {
        window.Kernel.visitRoute({
          name: DashboardIndexRoute,
        });
      }
    },
  },
});
</script>

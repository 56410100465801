// Beneficiary

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Beneficiary } from "@kinherit/sdk/model/beneficiary";

export const DeleteBeneficiary = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/beneficiary/{beneficiary}",
  method: "delete",
  parse: (beneficiary: Beneficiary) => ({
    params: {
      beneficiary,
      kintin: beneficiary.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Beneficiary.$delete(message);
  },
});

// BrandedKinvault, Profile, Address, PhoneNumber, Website, EmailAddress, XeroContact

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";

export const RecordBrandedKinvault = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/{brandedKinvault}",
  method: "get",
  rootModel: BrandedKinvault,
  parse: (brandedKinvault: BrandedKinvault | string) => ({
    params: {
      brandedKinvault,
    },
  }),
});

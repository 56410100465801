<template>
  <div class="button-list-widget">
    <Button
      v-for="(item, i) in items"
      :key="'button' + i"
      @click="item.onClick"
      is-fullwidth
      class="has-text-left mb-1"
      is-outlined
      :color="item.color || 'is-plain'"
    >
      <Icon v-if="item.icon" :icon="item.icon" class="mr-2" />
      <div class="button__description">
        <strong>{{ item.title }}</strong>
        <small v-if="item.subTitle">{{ item.subTitle }}</small>
      </div>
    </Button>
  </div>
</template>

<script lang="ts">
import Icon from "@kinherit/framework/component.display/icon";
import Button from "@kinherit/framework/component.input/button";
import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import { ThemeIconNameType } from "@kinherit/framework/theme/prop/icon";
import { defineComponent } from "vue";

export type TButtonListWidgetItem = {
  title: string;
  subTitle?: string;
  icon?: ThemeIconNameType;
  color?: ThemeColorType;
  onClick: () => void;
};

export default defineComponent({
  name: "ButtonListWidget",
  props: {
    items: {
      type: Array as () => TButtonListWidgetItem[],
      required: true,
    },
  },
  components: {
    Button,
    Icon,
  },
});
</script>

<style scoped lang="scss">
.button-list-widget {
  .button {
    flex-direction: row;
    align-items: start;
    height: auto;

    &__description {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.25rem;
    }
  }
}
</style>

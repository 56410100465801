import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreatePerson } from "./create-person.action";
import { DeletePerson } from "./delete-person.action";
import { RecordPerson } from "./record-person.action";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdatePerson } from "./update-person.action";

export const Portal2KinvaultPerson = DefineActionBus({
  name: "portal-2-kinvault-person",
  actions: {
    CreatePerson,
    DeletePerson,
    UpdatePerson,
    RecordPerson,
    UpdateProfile: ReplaceProfile,
  },
});

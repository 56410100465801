<template>
  <div class="cpd-session-master-list">
    <PageHeader htag="h1" text="CPD Sessions">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="create"
        >
          Create CPD Session
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        allowBookings: 'Allow Bookings',
        createdAt: 'Created',
        date: 'Date',
        duration: 'Duration',
        title: 'Title',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #bookable="{ row }: { row: CpdSession }">
        <Badge
          v-if="row.allowBookings"
          tooltip="Bookings Enabled"
          color="is-kinherit-lime"
          size="is-small"
          isRounded
          isOutlined
          isCompact
          icon="Tick"
        />
        <Badge
          v-if="!row.allowBookings"
          tooltip="Bookings Not Enabled"
          color="is-plain"
          size="is-small"
          isCompact
          icon="Cross"
        />
      </template>
      <template #isPrivate="{ row }: { row: CpdSession }">
        <Badge
          v-if="row.isPrivate"
          tooltip="Private Event"
          color="is-kinherit-lime"
          size="is-small"
          isRounded
          isOutlined
          isCompact
          icon="Tick"
        />
        <Badge
          v-if="!row.isPrivate"
          tooltip="Public Event"
          color="is-plain"
          size="is-small"
          isCompact
          icon="Cross"
        />
      </template>
      <template #participants="{ row }: { row: CpdSession }">
        <Badge
          v-if="row.introducerCount > 0"
          :text="row.introducerCount"
          color="is-light-grey"
          size="is-small"
          icon="PersonTie"
        /><span v-if="row.introducerCount === 0">-</span>
      </template>
      <template #registrations="{ row }: { row: CpdSession }">
        <div
          :class="{
            badge: true,
            'is-small': true,
            'is-success': row.registrationCount > 0,
            'is-danger': row.registrationCount === 0,
          }"
        >
          {{ row.registrationCount }}
        </div>
      </template>
      <template #unmatched="{ row }: { row: CpdSession }">
        <Badge
          v-if="row.unmachedRegistrationCount"
          tooltip="Unmatched Registration Count"
          color="is-danger"
          size="is-small"
          isRounded
          isCompact
          :text="row.unmachedRegistrationCount"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "CpdSessionMasterListWrapper",
  "route": "CpdSessionsMasterList",
  "selector": ".cpd-session-master-list",
  "imports": {
    "ReadCpdFormWrapper": "@/module/cpd/form/read-cpd.form.test",
    "CpdDetailsWrapper": "@/module/cpd/page/details/CpdDetails.test",
    "UpdateCpdSessionFormWrapper": "@/module/cpd/form/update-cpd-session.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadCpdFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CpdDetailsWrapper"
    },
    "createCpdSession": {
      "type": "click",
      "selector": ".create"
    },
    "createCpdSessionForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateCpdSessionFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadCpdForm } from "@/module/cpd/form/read-cpd.form";
import { AuthService } from "@/service/auth.service";
import { Badge } from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  Between,
  Equal,
  In,
  LessThan,
  MoreThanOrEqual,
  Operator,
} from "@kinherit/orm/index";
import { CpdSession, ICpdSession } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CpdSessionsMasterListRoute } from ".";
import { UpdateCpdSessionForm } from "../form/update-cpd-session.form";
import { CpdDetailsParams, CpdDetailsRoute } from "./details";

export default defineComponent({
  name: CpdSessionsMasterListRoute,
  components: { MasterListPage, Button, PageHeader, Badge },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.cpds),
  ],
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("cpd-session:write");
    },
  },
  data: () => ({
    filters: ReadCpdForm(),
    columns: [
      {
        title: "Date",
        sort: true,
        map: (session: CpdSession) => session.date.formatDateTime,
      },
      {
        title: "Title",
        sort: true,
        field: "title",
      },
      {
        title: "Duration",
        sort: true,
        map: (session: CpdSession) => `${session.duration.toMinutes()} minutes`,
      },
      {
        title: "Bookable",
        slot: "bookable",
      },
      {
        title: "Participants",
        slot: "participants",
      },
      {
        title: "Registrations",
        sort: true,
        slot: "registrations",
      },
      {
        title: "Unmatched",
        sort: true,
        slot: "unmatched",
      },
      {
        title: "Private",
        slot: "isPrivate",
      },
    ] as Array<TableColumn>,
    rows: Array<CpdSession>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "date" as keyof ICpdSession,
      direction: "asc" as "desc" | "asc",
    },
    period: null as "any" | "forthcoming" | "recent" | null,
  }),
  mounted(): void {
    this.period = this.filters.localData?.period ?? "forthcoming";
  },
  methods: {
    handleClick(cpd: CpdSession, event: MouseEvent): void {
      const params: CpdDetailsParams = { cpdSession: cpd.id };

      window.Kernel.visitRoute(
        {
          name: CpdDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadCpdForm>["localData"],
    ): Promise<void> {
      if (this.period !== formData.period) {
        this.period = formData?.period ?? "forthcoming";

        this.sort.by = "date";
        this.sort.direction = this.period === "recent" ? "desc" : "asc";
      }

      let date: undefined | Operator;

      if (formData.period === "forthcoming") {
        date = MoreThanOrEqual(new DateTime().timestamp);
      } else if (formData.period === "recent") {
        date = LessThan(new DateTime().timestamp);
      }

      // const data = await window.Kernel.ActionBus.cpd.session.read({
      const data = await this.$actionBus.ReadCpdSessions({
        query: {
          createdAt: Between(formData.created),
          type: {
            id: In(formData.type),
          },
          unmachedRegistrationCount:
            formData.isMatched === null
              ? undefined
              : formData.isMatched
                ? Equal(0)
                : MoreThanOrEqual(1),
          date,
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.cpdSession;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const cpdSession = await UpdateCpdSessionForm().dialog({
        dialog: {
          title: "Create CPD Session",
        },
      });

      // await window.Kernel.ActionBus.cpd.session.create({
      //   cpdSession,
      // });
      await this.$actionBus.CreateCpdSession(cpdSession);

      this.refresh(this.filters.localData);
    },
  },
});
</script>

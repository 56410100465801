// Order

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Order } from "@kinherit/sdk/model/order";

export const DeleteOrder = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/order/{order}",
  method: "delete",
  parse: (order: Order | string) => ({
    params: {
      order,
    },
  }),
  after: ({ message }) => {
    Order.$delete(message);
  },
});

<template>
  <MasterListPage
    v-if="$data.filters"
    class="email-log-master-list"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    title="Email Log"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #emails="{ row }: { row: EmailLog }">
      <span class="is-block">From: {{ row.from?.email }}</span>
      <span class="is-block"
        >To: {{ row.to?.map((e) => e.email).join(", ") }}</span
      >
    </template>
    <template #status="{ row }: { row: EmailLog }">
      <Badge
        v-if="row.status === 'failed'"
        type="danger"
        text="Failed"
        size="is-small"
        color="is-danger"
        is-rounded
      ></Badge>
      <Badge
        v-else-if="row.status === 'draft'"
        type="warning"
        text="Draft"
        size="is-small"
        color="is-kinherit-ochre"
        is-rounded
      ></Badge>
      <Badge
        v-else-if="row.status === 'sent'"
        type="success"
        text="Sent"
        size="is-small"
        color="is-success"
        is-rounded
      ></Badge>
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailLogMasterListWrapper",
  "route": "EmailLogMasterList",
  "selector": ".email-log-master-list",
  "imports": {
    "ReadEmailLogFormWrapper": "@/module/admin.log/form/read-email-log.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadEmailLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadEmailLogForm } from "@/module/admin.log/form/read-email-log.form";
import { EmailLogMasterListRoute } from "@/module/admin.log/page";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between } from "@kinherit/orm/index";
import { EmailLog, IEmailLog } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: EmailLogMasterListRoute,
  components: { MasterListPage, Badge },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.log)],
  data: () => ({
    filters: ReadEmailLogForm(),
    columns: [
      {
        map: (row) => row.createdAt.formatDateTime,
        title: "Created At",
      },
      {
        title: "Status",
        slot: "status",
      },
      {
        title: "Emails",
        slot: "emails",
      },
      {
        map: (row) => row.subject,
        title: "Subject",
      },
    ] as Array<TableColumn<EmailLog>>,
    rows: Array<EmailLog>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IEmailLog,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadEmailLogForm>["localData"],
    ): Promise<void> {
      const { emailLog, $pagination } =
        // await window.Kernel.ActionBus.adminLog.emailLog.read({
        await this.$actionBus.ReadEmailLog({
          query: {
            createdAt: Between(formData.created),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = emailLog;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

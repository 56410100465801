// CallLog, Person, Kintin, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CallLog } from "@kinherit/sdk/model/call-log";

export const ReadCallLog = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/call-logs",
  method: "get",
  rootModel: CallLog,
  parse: () => ({}),
});

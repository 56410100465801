// EmailTemplate

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const CreateEmailTemplate = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/email-templates",
  method: "post",
  parse: (target: EmailTemplate) => ({
    body: {
      name: target.$data.name,
      subject: target.$data.subject,
      body: target.$data.body,
      allowAttachments: target.$data.allowAttachments,
      strictAttachments: target.$data.strictAttachments,
      notes: target.$data.notes,
      requiredAttachments: target.$data.requiredAttachments,
      type: target.$data.type,
      includeTo: target.$data.includeTo,
    },
  }),
  after: ({ message }) => {
    EmailTemplate.$delete(message);
  },
});

<template>
  <div v-if="!loading" class="introducer-contact-details-client-progress">
    <Card title="Client Progress" subtitle="Select code to view leads">
      <Table
        class="client-progress-table"
        is-scrollable
        @click="referralClicked"
        enable-per-page
        v-model:page="pagination.currentPage"
        @update:page="refresh"
        v-model:perPage="pagination.perPage"
        @update:perPage="refresh"
        :total-pages="pagination.lastPage"
        :total-records="pagination.count"
        :columns="referralColumns"
        :rows="referralCodes"
        is-fullwidth
        is-narrow
        is-hoverable
        is-clickable
        v-model:sortBy="sort.by"
        @update:sortBy="refresh"
        v-model:sortDirection="sort.direction"
        @update:sortDirection="refresh"
        :sort-by-options="{
          createdAt: 'Created',
        }"
      />
    </Card>
    <Card
      v-for="(title, index) in orderAssignmentKeys"
      :key="`assignment-table-${index}`"
      title="Referrals"
    >
      <Table
        class="referrals-table"
        @click="assignmentClicked"
        :total-records="assignments[title]?.length ?? 0"
        :columns="assignmentColumns"
        :rows="assignments[title]"
        is-fullwidth
        is-narrow
        is-hoverable
        is-clickable
        copy-to-clipboard
      />
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsClientProgressWrapper",
  "route": "IntroducerContactDetailsClientProgress",
  "selector": ".introducer-contact-details-client-progress",
  "imports": {
    "TableWrapper": "@kinherit/framework/component.display/table/table.test"
  },
  "methods": {
    "clientProgressTable": {
      "type": "to-one",
      "selector": ".client-progress-table",
      "wrapper": "TableWrapper"
    },
    "referralsTable": {
      "type": "to-many",
      "selector": ".referrals-table",
      "wrapper": "TableWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { Table } from "@kinherit/framework/component.display/table";
import Card from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { NotEqual } from "@kinherit/orm/index";
import {
  AccountReferralCode,
  IAccountReferralCode,
  Kintin,
  Lead,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerContactDetailsClientProgressRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";

export default defineComponent({
  name: IntroducerContactDetailsClientProgressRoute,
  mixins: [IntroducerContactDetailsMixin],
  components: {
    Card,
    Table,
    PageHeader,
  },
  data: () => ({
    loading: false,
    referralColumns: [
      {
        title: "Code",
        sort: true,
        map: (referral: AccountReferralCode) => referral.code,
      },
      {
        title: "Created",
        sort: true,
        map: (referral: AccountReferralCode) =>
          referral.createdAt.formatDateTime,
      },
    ],
    assignmentColumns: [
      {
        title: "Type",
        map: (v: Lead | Kintin) => v.$name,
      },
      {
        title: "Introduced on",
        sort: true,
        map: (v: Lead | Kintin) => v.createdAt.formatDateTime,
      },
      {
        title: "Client",
        sort: true,
        map: (v: Lead | Kintin) =>
          (v instanceof Lead ? v.profile.fullName : v.friendlyName) ?? ``,
      },
      {
        title: "Stage",
        sort: true,
        map: (v: Lead | Kintin) =>
          (v instanceof Lead ? "Unqualified Lead" : v.stage?.text) ?? ``,
      },
      {
        title: "Status",
        sort: true,
        map: (v: Lead | Kintin) => v.status?.text ?? ``,
      },
    ],
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferralCode,
      direction: "desc" as "desc" | "asc",
    },
    referralCodes: Array<AccountReferralCode>(),
    assignments: {} as Record<string, Array<Lead | Kintin>>,
    orderAssignmentKeys: Array<string>(),
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      this.loading = true;

      const { accountReferralCode, $pagination } =
        // await window.Kernel.ActionBus.introducerContact.accountReferral.codes({
        //   ...this.$params,
        //   sort: this.sort,
        //   pagination: this.pagination,
        // });
        await this.$actionBus.contact.referral.ReadAccountReferralCodes({
          message: this.$params.introducerContact,
          sort: this.sort,
          pagination: this.pagination,
        });

      this.orderAssignmentKeys = [];
      this.referralCodes = accountReferralCode;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.loading = false;
    },
    async referralClicked(referralCode: AccountReferralCode): Promise<void> {
      this.loading = true;
      if (!this.introducerContact) {
        return;
      }

      // const { kintins } =
      //   await window.Kernel.ActionBus.introducerContact.accountReferral.kintins(
      //     {
      //       introducerContact: this.introducerContact,
      //       referralCode: referralCode,
      //       pagination: false,
      //     },
      //   );
      const { kintin: kintins } =
        await this.$actionBus.contact.referral.ReadAccountReferralCodeKintins({
          message: {
            introducerContact: this.introducerContact,
            accountReferralCode: referralCode,
          },
          pagination: false,
        });

      // const { leads } =
      //   await window.Kernel.ActionBus.introducerContact.accountReferral.leads({
      //     introducerContact: this.introducerContact,
      //     referralCode: referralCode,
      //     query: {
      //       status: {
      //         value: NotEqual("converted"),
      //       },
      //     },
      //     pagination: false,
      //   });
      const { lead: leads } =
        await this.$actionBus.contact.referral.ReadAccountReferralCodeLeads({
          message: {
            introducerContact: this.introducerContact,
            accountReferralCode: referralCode,
          },
          query: {
            status: {
              value: NotEqual("converted"),
            },
          },
          pagination: false,
        });

      this.assignments = {};

      leads.forEach((v) => {
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ] ??= [];
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ].push(v);
      });

      kintins.forEach((v) => {
        if (null === v.referral?.contact) {
          this.assignments[`Unassigned`] ??= [];
          this.assignments[`Unassigned`].push(v);
          return;
        }

        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ] ??= [];
        this.assignments[
          v.referral?.contact?.profile?.fullName ?? `Unassigned`
        ].push(v);
      });

      this.orderAssignmentKeys = Object.keys(this.assignments)
        .remove(`Unassigned`)
        .sort();

      this.loading = false;
    },
    assignmentClicked(assignment: Lead | Kintin, event: MouseEvent): void {
      if (assignment instanceof Lead) {
        window.Kernel.visitRoute(
          {
            name: LeadDetailsRoute,
            params: {
              lead: assignment.id,
            } as LeadDetailsParams,
          },
          event.ctrlKey,
        );
      } else {
        window.Kernel.visitRoute(
          {
            name: KinvaultKintinDetailsRoute,
            params: {
              kintin: assignment.id,
            } as KinvaultKintinDetailsParams,
          },
          event.ctrlKey,
        );
      }
    },
  },
});
</script>

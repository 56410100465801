// CpdSession, Address, Website

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { CpdSession } from "@kinherit/sdk/model/cpd-session";

export const UpdateCpdSession = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpd/{cpdSession}",
  method: "patch",
  parse: (target: CpdSession) => ({
    body: {
      date: target.$data.date,
      title: target.$data.title,
      duration: target.$data.duration,
      outcomes: target.$data.outcomes,
      allowBookings: target.$data.allowBookings,
      inviteDescription: target.$data.inviteDescription,
      unmachedRegistrationCount: target.$data.unmachedRegistrationCount,
      registrationCount: target.$data.registrationCount,
      introducerCount: target.$data.introducerCount,
      isPrivate: target.$data.isPrivate,
      type: target.$data.type,
      address: target.address
        ? {
            city: target.address.city,
            country: target.address.country,
            line1: target.address.line1,
            line2: target.address.line2,
            postcode: target.address.postcode,
            primaryMailing: target.address.primaryMailing,
            primaryResidential: target.address.primaryResidential,
            state: target.address.state,
          }
        : undefined,
      websites: target.websites.map((website) => ({
        url: website.url,
      })),
    },
    params: {
      cpdSession: target,
    },
  }),
});

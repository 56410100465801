// Option

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Option } from "@kinherit/sdk/model/option";

export const Read = DefineReadAction({
  interface: "core",
  resource: "/v2/core/option",
  method: "get",
  rootModel: Option,
  parse: () => ({}),
});

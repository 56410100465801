import { SharedFormProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateSortReferNoteForm = () => {
  return defineForm({
    name: "create-sort-refer-note-form",
    props: SharedFormProps,
    data: () => {
      return {
        note: "",
      };
    },
    formAreas: (data) => [
      defineFormArea({
        name: "note-form-area",
        data,
        template: GridLayout(["note"]),
        components: () => ({
          note: [
            FormTextField({
              props: {
                label: "Message",
                isTextArea: true,
                placeholder: "Enter a message",
                message:
                  "This message will be viewable by the SortRefer Broker. Avoid specific personal data.",
              },
              models: {
                value: "note",
              },
            }),
          ],
        }),
      }),
    ],
  });
};

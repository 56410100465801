<template>
  <MasterListPage
    v-if="$data.filters"
    class="failed-login-attempts-master-list"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    title="Failed Logins"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #reason="{ row }">
      <Badge
        v-if="row.reason === 'invalid-password'"
        type="danger"
        text="Incorrect Password"
        size="is-small"
        color="is-danger"
        is-rounded
      ></Badge>
      <Badge
        v-else-if="row.reason === 'invalid-username'"
        type="danger"
        text="Incorrect Username"
        size="is-small"
        color="is-kinherit-ochre"
        is-rounded
      ></Badge>
      <Badge
        v-else
        type="warning"
        text="Unknown"
        size="is-small"
        is-rounded
      ></Badge>
    </template>
    <template #details="{ row }: { row: FailedLoginAttempt }">
      {{ row.userAgent }}
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "FailedLoginAttemptsMasterListWrapper",
  "route": "FailedLoginAttemptsMasterList",
  "selector": ".failed-login-attempts-master-list",
  "imports": {
    "ReadFailedLoginAttemptsFormWrapper": "@/module/admin.log/form/read-failed-login-attempts.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadFailedLoginAttemptsFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadFailedLoginAttemptsForm } from "@/module/admin.log/form/read-failed-login-attempts.form";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import { FailedLoginAttempt, IFailedLoginAttempt } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { FailedLoginAttemptsMasterListRoute } from ".";

export default defineComponent({
  name: FailedLoginAttemptsMasterListRoute,
  components: { MasterListPage, Badge },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.log)],
  data: () => ({
    filters: ReadFailedLoginAttemptsForm(),
    columns: [
      {
        map: (row) => row.createdAt.formatDateTime,
        title: "Created At",
      },
      {
        field: "ipAddress",
        title: "IP Address",
      },
      {
        field: "origin",
        title: "Origin",
      },
      {
        field: "username",
        title: "Username",
      },
      {
        title: "Reason",
        slot: "reason",
      },
    ] as Array<TableColumn<FailedLoginAttempt>>,
    rows: Array<FailedLoginAttempt>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IFailedLoginAttempt,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadFailedLoginAttemptsForm>["localData"],
    ): Promise<void> {
      const data =
        // await window.Kernel.ActionBus.adminLog.failedLoginAttempts.read({
        await this.$actionBus.ReadFailedLoginAttempt({
          query: {
            createdAt: Between(formData.created),
            origin: Like(formData.origin),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = data.failedLoginAttempt;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadAccountReferralCodeKintins } from "./read-account-referral-code-kintins.action";
import { ReadAccountReferralCodeLeads } from "./read-account-referral-code-leads.action";
import { ReadAccountReferralCodes } from "./read-account-referral-codes.action";

export const Portal2IntroducerContactReferral = DefineActionBus({
  name: "portal-2-introducer-contact-referral",
  actions: {
    ReadAccountReferralCodeKintins,
    ReadAccountReferralCodeLeads,
    ReadAccountReferralCodes,
  },
});

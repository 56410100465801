// Kintin, AccountReferral

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const UpdateKintin = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/{kintin}",
  method: "patch",
  parse: (target: Kintin) => ({
    body: {
      friendlyName: target.$data.friendlyName,
      ref: target.$data.ref,
      whyEol: target.$data.whyEol,
      statusUpdatedAt: target.$data.statusUpdatedAt,
      statusChangeReason: target.$data.statusChangeReason,
      requireSetup: target.$data.requireSetup,
      isActive: target.$data.isActive,
      isEscalated: target.$data.isEscalated,
      showSplash: target.$data.showSplash,
      noteCount: target.$data.noteCount,
      referral: {
        contact: target.referral?.$data.contact,
        referralCode: target.referral?.$data.referralCode,
        nextActionAt: target.referral?.$data.nextActionAt,
        externalId: target.referral?.$data.externalId,
      },
      primaryPerson: target.$data.primaryPerson,
      secondaryPerson: target.$data.secondaryPerson,
      type: target.$data.type,
      status: target.$data.status,
      callScript: target.$data.callScript,
      permissions: target.$data.permissions,
      sourceKintin: target.$data.sourceKintin,
      process: target.$data.process,
      stage: target.$data.stage,
    },
    params: {
      kintin: target,
    },
  }),
  after: ({ message }) => {
    message.$persist({
      referral: true,
    });
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadProfiles } from "./read-profiles.action";
import { RecordKintin } from "./record-kintin.action";

export const Portal2DashboardSearch = DefineActionBus({
  name: "portal-2-dashboard-search",
  actions: {
    RecordKintin,
    ReadProfiles,
  },
});

<template>
  <div class="email-campaign-details-members">
    <PageHeader htag="h2" text="Members">
      <template #buttons>
        <Button
          v-if="hasWriteAccess && !isEditing"
          class="edit"
          size="is-normal"
          @click="edit"
          title="Edit"
          tooltip="Edit"
          icon-left="Edit"
          text="Edit Members"
        />
        <span v-if="isEditing" class="buttons">
          <Button
            class="done"
            size="is-normal"
            @click="done"
            title="Done"
            tooltip="Done"
            icon-left="Tick"
            text="Finish"
          />
        </span>
      </template>
    </PageHeader>
    <Tabs
      v-if="isEditing"
      class="profile-list-tabs"
      v-model:tab="tabs.active"
      :config="Object.values(tabs.config)"
    />
    <ProfileList v-if="!isEditing" :filter-members="true" :is-editing="false" />
    <ProfileList
      v-else-if="tabs.active === tabs.index.members"
      :key="`members-${renderKey}`"
      :filter-members="true"
      :is-editing="true"
      :persist-state="false"
    />
    <ProfileList
      v-else-if="tabs.active === tabs.index.system"
      :key="`system-${renderKey}`"
      :filter-members="false"
      :is-editing="true"
      :persist-state="false"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailCampaignDetailsMembersWrapper",
  "route": "EmailCampaignDetailsMembers",
  "selector": ".email-campaign-details-members",
  "imports": {
    "ProfileListWrapper": "@/module/admin.email-campaign/component/ProfileList.test"
  },
  "methods": {
    "editMembers": {
      "selector": ".edit",
      "type": "click"
    },
    "doneEditingMembers": {
      "selector": ".done",
      "type": "click"
    },
    "profileList": {
      "type": "to-one",
      "wrapper": "ProfileListWrapper"
    },
    "tabs": {
      "type": "tabs",
      "selector": ".profile-list-tabs",
      "items": ["Existing Members", "Add Members"]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import ProfileList from "@/module/admin.email-campaign/component/ProfileList.vue";
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { EmailCampaignDetailsMembersRoute } from ".";

export default defineComponent({
  name: EmailCampaignDetailsMembersRoute,
  mixins: [EmailCampaignDetailsMixin],
  components: {
    PageHeader,
    Button,
    Tabs,
    ProfileList,
  },
  data: () => ({
    isEditing: false,
    tabs: {
      active: 0,
      index: {
        members: 0,
        system: 1,
      },
      config: {
        members: {
          label: "Existing Members",
        },
        system: {
          label: "Add Members",
        },
      } satisfies Record<string, TabOptions>,
    },
    renderKey: 0,
  }),
  methods: {
    done(): void {
      this.isEditing = false;
    },
    edit(): void {
      this.isEditing = true;
    },
  },
});
</script>

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Lead, Note } from "@kinherit/sdk/index";

export const DeleteNoteHandler = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/note/{owner}/{note}",
  method: "delete",
  parse: (message: { lead: Lead | string; note: Note }) => ({
    params: { owner: message.lead, note: message.note },
  }),
  after: ({ message }) => {
    Note.$delete(message.note);
  },
});

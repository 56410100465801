import { Api } from "@kinherit/sdk";

type TestContactEndpointMessage = {
  name: string;
  email: string;
  tel: string;
  message: string;
  pref?: string;
  referrer?: string;
  helpwith?: string;
  referralcode?: string;
};
// export class TestContactEndpointHandler extends ActionBase {
//   constructor(private message: TestContactEndpointMessage) {
//     super();
//   }
//   // @todo fix types
//   public async execute(): Promise<void> {
export const TestContactEndpoint = async (
  message: TestContactEndpointMessage,
): Promise<void> => {
  await Api.resource("external", "/v2/external/contact")
    .method("post")
    .body({
      // @ts-ignore
      name: message.name,
      // @ts-ignore
      email: message.email,
      // @ts-ignore
      tel: message.tel,
      // @ts-ignore
      message: message.message,
      // @ts-ignore
      pref: message.pref,
      // @ts-ignore
      referrer: message.referrer,
      // @ts-ignore
      helpwith: message.helpwith,
      // @ts-ignore
      referralcode: message.referralcode,
    })
    .result();
};

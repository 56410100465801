import { Api } from "@kinherit/sdk";

export interface UpdateSortReferMilestoneByStageMessage {
  externalId: string;
  newKintinStageId: string;
}

interface UpdateSortReferMilestoneByStageResponse {}

export const UpdateSortReferMilestoneByStage = async (
  message: UpdateSortReferMilestoneByStageMessage,
): Promise<UpdateSortReferMilestoneByStageResponse> => {
  const request = Api.resource(
    "external",
    "/v2/external/sort-refer/milestone-by-stage",
  )
    .method("put")
    .body({
      externalId: message.externalId,
      newKintinStage: message.newKintinStageId,
    });

  const response = await request.result();

  return response;
};

// Appointment

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Appointment } from "@kinherit/sdk/model/appointment";

export const Type = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/appointments/type",
  method: "get",
  rootModel: Appointment,
  parse: () => ({}),
});

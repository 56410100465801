<template>
  <div class="post-payment-verification-session columns">
    <div class="column">
      <Card title="Identity & Selfie Files">
        <p>
          For manual comparison if the automated check fails. Often this is due
          to just a bad/blurry selfie image.
        </p>
        <p>
          <strong>All downloads are tracked.</strong>
        </p>
        <Button
          v-if="selfieDocumentFileId"
          @click="downloadFile(selfieDocumentFileId)"
          class="mt-4"
          color="is-warning"
          is-fullwidth
          >Download Document Photo</Button
        >
        <Button
          v-if="selfieFaceFileId"
          @click="downloadFile(selfieFaceFileId)"
          class="mt-4"
          color="is-warning"
          is-fullwidth
          >Download Selfie Photo</Button
        >
      </Card>
    </div>
    <div class="column">
      <DataListWidget title="Report" :details="reportDetails" />
    </div>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import {
  KinvaultKintinDetailsPostPaymentVerificationSessionParams,
  KinvaultKintinDetailsPostPaymentVerificationSessionRoute,
} from "@/module/kinvault.kintin/page/details/documents/post-payment";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { SignDoc } from "@kinherit/sdk/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: KinvaultKintinDetailsPostPaymentVerificationSessionRoute,
  components: {
    DataListWidget,
    Button,
    Card,
  },
  mixins: [KinvaultKintinDetailsMixin],
  data: () => ({
    signDoc: null as SignDoc | null,
    report: null as any,
  }),
  computed: {
    $params(): KinvaultKintinDetailsPostPaymentVerificationSessionParams {
      return this.$route
        .params as KinvaultKintinDetailsPostPaymentVerificationSessionParams;
    },
    selfieDocumentFileId(): string | null {
      if (!this.report) {
        return null;
      }

      if (!this.report.selfie) {
        return null;
      }

      if (!this.report.selfie.document) {
        return null;
      }

      return this.report.selfie.document;
    },
    selfieFaceFileId(): string | null {
      if (!this.report) {
        return null;
      }

      if (!this.report.selfie) {
        return null;
      }

      if (!this.report.selfie.selfie) {
        return null;
      }

      return this.report.selfie.selfie;
    },
    reportDetails(): Array<DataListWidgetDetail> {
      if (!this.report) {
        return [];
      }

      const result = Array<DataListWidgetDetail>();

      if (this.report.id) {
        result.push({
          label: "Report ID",
          value: this.report.id,
        });
      }

      if (this.report.object) {
        result.push({
          label: "Object",
          value: this.report.object,
        });
      }

      if (this.report.client_reference_id) {
        result.push({
          label: "Client Reference ID",
          value: this.report.client_reference_id,
        });
      }

      if (this.report.created) {
        result.push({
          label: "Created",
          value: {
            html: new Date(this.report.created * 1000).toLocaleString(),
          },
        });
      }

      if (this.report.document) {
        if (this.report.document.first_name) {
          result.push({
            label: "First Name",
            value: this.report.document.first_name,
          });
        }

        if (this.report.document.last_name) {
          result.push({
            label: "Last Name",
            value: this.report.document.last_name,
          });
        }

        if (this.report.document.type) {
          result.push({
            label: "Document Type",
            value: this.report.document.type,
          });
        }

        if (this.report.document.issuing_country) {
          result.push({
            label: "Document Issuing Country",
            value: this.report.document.issuing_country,
          });
        }

        if (this.report.document.issued_date) {
          result.push({
            label: "Issued Date",
            value: {
              html: `${this.report.document.issued_date.day}/${this.report.document.issued_date.month}/${this.report.document.issued_date.year}`,
            },
          });
        }

        if (this.report.document.status) {
          result.push({
            label: "Document Status",
            value: this.report.document.status,
          });
        }

        if (this.report.document.files) {
          result.push({
            label: "Document Files",
            value: this.report.document.files.join(", "),
          });
        }
      }

      if (this.report.selfie) {
        if (this.report.selfie.document) {
          result.push({
            label: "Selfie Document",
            value: this.report.selfie.document,
          });
        }

        if (this.report.selfie.selfie) {
          result.push({
            label: "Selfie File",
            value: this.report.selfie.selfie,
          });

          if (this.report.selfie.status) {
            result.push({
              label: "Selfie Status",
              value: this.report.selfie.status,
            });
          }

          if (this.report.livemode) {
            result.push({
              label: "Live Mode",
              value: this.report.livemode,
            });
          }

          if (this.report.verification_session) {
            result.push({
              label: "Verification Session",
              value: this.report.verification_session,
            });
          }
        }
      }
      return result;
    },
  },
  async mounted(): Promise<void> {
    await this.reload();
  },
  methods: {
    async reload(): Promise<void> {
      const { signDoc } = await this.$actionBus.signDoc.RecordSignDoc({
        message: {
          kintin: this.$params.kintin,
          signDoc: this.$params.signDoc,
        },
      });

      this.signDoc = signDoc.first() ?? null;

      if (!this.signDoc) {
        return;
      }
      const report =
        // await window.Kernel.ActionBus.kinvaultKintin.signDoc.getIdentitySession(
        await this.$actionBus.signDoc.ReadIdentityVerificationSession({
          kintin: this.signDoc.$data.kintin,
          signDoc: this.signDoc.id,
        });
      this.report = report;
    },
    async downloadFile(fileId: string): Promise<void> {
      //getIdentityFile
      const file =
        // await window.Kernel.ActionBus.kinvaultKintin.signDoc.getIdentityFile({
        await this.$actionBus.signDoc.ReadIdentityVerificationSessionFile({
          kintin: this.$params.kintin,
          signDoc: this.$params.signDoc,
          fileId,
        });

      if (!file.fileUrl) {
        return;
      }

      // Create a fake A link and click it
      const a = document.createElement("a");
      a.href = file.fileUrl;
      a.click();
    },
  },
});
</script>

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2KinvaultsEscalated } from "./escalated";
import { ReadKintin } from "./read-kintin.action";
import { UpdateAccountReferral } from "./update-account-referral.action";
import { UpdateKintin } from "./update-kintin.action";

export const Portal2Kinvaults = DefineActionBus({
  name: "portal-2-kinvaults",
  actions: {
    ReadKintin,
    UpdateKintin,
    UpdateAccountReferral,
    escalated: Portal2KinvaultsEscalated,
  },
});

// BrandedKinvaultPricingSchema

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultPricingSchema } from "@kinherit/sdk/model/branded-kinvault-pricing-schema";

export const CreateBrandedKinvaultPricingSchema = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/kinvault-pricing-schema",
  method: "post",
  parse: (target: BrandedKinvaultPricingSchema) => ({
    body: {
      schema: target.$data.schema,
      startDate: target.$data.startDate,
      endDate: target.$data.endDate,
      isDefault: target.$data.isDefault,
      name: target.$data.name,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultPricingSchema.$create(message);
  },
});

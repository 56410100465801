import {
  AccountReferral,
  AccountReferralCode,
  Adviser,
  Attorney,
  Beneficiary,
  BrandedKinvault,
  BrandedKinvaultInvitation,
  BrandedKinvaultInviteReferral,
  BrandedKinvaultPricingSchema,
  BrandedKinvaultSubscriptionReferralCode,
  CommunicationNote,
  CompanyPartnerPortalPermissions,
  ContactPartnerPortalPermissions,
  CpdSession,
  Credential,
  CreditNote,
  DefaultBrandedKinvaultReferralCode,
  EmailCampaign,
  EmailCampaignLog,
  EmailLog,
  EmailTemplate,
  Exclusion,
  Executor,
  FileLog,
  Gift,
  Guardian,
  IAccountReferral,
  IAccountReferralCode,
  IAdviser,
  IAttorney,
  IBeneficiary,
  IBrandedKinvault,
  IBrandedKinvaultInvitation,
  IBrandedKinvaultInviteReferral,
  IBrandedKinvaultPricingSchema,
  IBrandedKinvaultSubscriptionReferralCode,
  ICommunicationNote,
  ICompanyPartnerPortalPermissions,
  IContactPartnerPortalPermissions,
  ICpdSession,
  ICredential,
  ICreditNote,
  IDefaultBrandedKinvaultReferralCode,
  IEmailCampaign,
  IEmailCampaignLog,
  IEmailLog,
  IEmailTemplate,
  IExclusion,
  IExecutor,
  IFileLog,
  IGift,
  IGuardian,
  IIntroducerBillingContact,
  IIntroducerCompany,
  IIntroducerContact,
  IIntroducerContract,
  IIntroducerNetwork,
  IIntroducerNote,
  IIntroducerOutsource,
  IKintin,
  IKintinAccess,
  IKnowledgeBaseCategory,
  IKnowledgeBaseDocument,
  ILead,
  IMfAuthMethod,
  INote,
  INotification,
  IOrder,
  IPerson,
  IPhysicalStorageFile,
  IPhysicalStorageFileLog,
  IProfile,
  IProperty,
  ISignDoc,
  IStatusLog,
  ISubscription,
  ITag,
  IThemeCssVar,
  IThemeKinvault,
  IThemeKinvaultFooter,
  IThemeKinvaultHeader,
  IThemeKinvaultLogin,
  ITrustee,
  IUploadForm,
  IUser,
  IWillRevision,
  IntroducerBillingContact,
  IntroducerCompany,
  IntroducerContact,
  IntroducerContract,
  IntroducerNetwork,
  IntroducerNote,
  IntroducerOutsource,
  Kintin,
  KintinAccess,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
  Lead,
  MfAuthMethod,
  Note,
  Notification,
  Order,
  Person,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Profile,
  Property,
  SignDoc,
  StatusLog,
  Subscription,
  Tag,
  ThemeCssVar,
  ThemeKinvault,
  ThemeKinvaultFooter,
  ThemeKinvaultHeader,
  ThemeKinvaultLogin,
  Trustee,
  UploadForm,
  User,
  WillRevision,
} from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common";
import { Uuid } from "@kinherit/ts-common/service/uuid";

export const CreateProfile = (data: Partial<IProfile> = {}) =>
  Profile.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        jobTitle: null,
        knownAs: null,
        newsletterCampaigns: null,
        firstName: "",
        middleNames: "",
        lastName: "",
        organisationName: "",
        organisationNumber: "",
        addresses: [],
        emails: [],
        phoneNumbers: [],
        appointments: [],
        websites: [],
        createdAt: new DateTime().formatMachine,
        emailCampaigns: [],
      },
      data,
    ),
  ).$persist();

export const CreatePerson = (data: Partial<IPerson> = {}) =>
  Person.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        type: "person",
        kintin: null,
        profile: CreateProfile().$persist().id,
        under18ForPlanningPurposes: false,
        communicationNotes: [],
        trusteeFor: [],
        witnessFor: [],
        executorFor: [],
        notifyOnDeath: false,
        guardianFor: [],
        receivingGifts: [],
        beneficiaryFor: [],
        isTrusted: false,
        attorneyFor: [],
        adviserFor: [],
        appointedTrustee: [],
        appointedWitness: [],
        appointedExecutor: [],
        appointedGuardian: [],
        appointedBeneficiary: [],
        appointedAttorney: [],
        isNotifiable: false,
        appointedAdviser: [],
        notes: [],
        relations: [],
        relationToPrimaryPerson: null,
        relationToSecondaryPerson: null,
        relationOf: [],
        sendingGifts: [],
        creditNotes: [],
        exclusions: [],
        createdAt: new DateTime().formatMachine,
        emailLogs: [],
      },
      data,
    ),
  ).$persist();

export const CreateGift = (data: Partial<IGift> = {}) =>
  Gift.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        address: null,
        forPerson: null,
        sentBy: null,
        type: null,
        classGiftType: null,
        kintin: null,
        amount: null,
        notes: null,
        onSecondDeath: null,
        asPrimaryResidence: null,
        charityName: null,
        charityNo: null,
        ifPredeceased: null,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateExclusion = (data: Partial<IExclusion> = {}) =>
  Exclusion.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        ownedBy: null,
        kintin: null,
        who: null,
        why: null,
        relationship: null,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateTag = (data: Partial<ITag> = {}) =>
  Tag.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        name: "",
        description: "",
        kintinCount: [],
        appointmentCount: [],
        introducerCompanyCount: [],
        introducerContactCount: [],
        leadCount: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerNote = (data: Partial<IIntroducerNote> = {}) =>
  IntroducerNote.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        contact: null,
        company: null,
        note: CreateNote().id,
        dueAtNotifySales: false,
        dueAtNotifySpecialist: false,
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateNote = (data: Partial<INote> = {}) =>
  Note.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        name: "",
        type: "note",
        completedAt: null,
        dueAt: null,
        notes: "",
        pinned: false,
        notifications: [],
        isPublic: false,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerContract = (
  data: Partial<IIntroducerContract> = {},
) =>
  IntroducerContract.$create(
    Object.assign(
      {
        type: null,
        vat: null,
        parentContract: null,
        company: null,
        sentAt: null,
        datedAt: null,
        isSigned: false,
        ai_5a_percent: null,
        ai_5b_percent: null,
        ai_5c_percent: null,
        i_4c_percent: null,
        ai_5a_months: null,
        ai_5b_months: null,
        ai_5c_months: null,
        i_4c_months: null,
        prePaidClients: null,
        notes: null,
        childContracts: [],
        datedTill: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateAccountReferral = (data: Partial<IAccountReferral> = {}) =>
  AccountReferral.$create(
    Object.assign(
      {
        company: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        contact: null,
        referralCode: null,
        externalId: null,
      },
      data,
    ),
  ).$persist();

export const CreateKnowledgeBaseCategory = (
  data: Partial<IKnowledgeBaseCategory> = {},
) =>
  KnowledgeBaseCategory.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        name: "",
        slug: "",
        parentCategory: null,
        documents: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateKnowledgeBaseDocument = (
  data: Partial<IKnowledgeBaseDocument> = {},
) =>
  KnowledgeBaseDocument.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        title: "",
        content: "",
        categories: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateMfAuthMethod = (data: Partial<IMfAuthMethod> = {}) =>
  MfAuthMethod.$create(
    Object.assign(
      {
        type: null,
        status: null,
        user: null,
        alias: null,
        key: null,
        device: null,
        setupCompleteAt: null,
        id: Uuid.generate(),
      },
      data,
    ),
  ).$persist();

export const CreateOrder = (data: Partial<IOrder> = {}) =>
  Order.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        kintin: null,
        trustReg: null,
        status: null,
        paymentType: null,
        orderItems: [],
        listPrice: new Currency({ amount: 0, type: "GBP" }),
        listPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        listPriceVat: new Currency({ amount: 0, type: "GBP" }),
        extrasPrice: new Currency({ amount: 0, type: "GBP" }),
        extrasPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        extrasPriceVat: new Currency({ amount: 0, type: "GBP" }),
        discountValue: new Currency({ amount: 0, type: "GBP" }),
        totalPrice: new Currency({ amount: 0, type: "GBP" }),
        totalPriceExVat: new Currency({ amount: 0, type: "GBP" }),
        totalPriceVat: new Currency({ amount: 0, type: "GBP" }),
        opgFee: new Currency({ amount: 0, type: "GBP" }),
        totalPriceIncFees: new Currency({ amount: 0, type: "GBP" }),
        totalPriceExVatIncFees: new Currency({ amount: 0, type: "GBP" }),
        totalPriceVatIncFees: new Currency({ amount: 0, type: "GBP" }),
        discountPercentage: 0,
        proExecutor: null,
        proTrustee: null,
        coolingOff: null,
        paidAt: null,
        invoiceNumber: null,
        notes: null,
        paymentSignKey: null,
        transactionId: null,
        overrideListPrice: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutIntro: new Currency({ amount: 0, type: "GBP" }),
        feesPayoutAgent: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutIntroDate: null,
        feesPayOutAgentDate: null,
        feesPayOutParentAgent: new Currency({ amount: 0, type: "GBP" }),
        feesPayOutParentAgentDate: null,
        feesConfirmed: false,
        discountReason: null,
        xeroIntroducerBillId: null,
        xeroAgentBillId: null,
        billingName: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingRegion: null,
        billingPostalCode: null,
        stripeSessions: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateCpdSession = (data: Partial<ICpdSession> = {}) =>
  CpdSession.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        address: null,
        type: null,
        introducers: [],
        date: null,
        registrations: [],
        title: null,
        duration: null,
        outcomes: null,
        allowBookings: false,
        websites: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateAccountReferralCode = (
  data: Partial<IAccountReferralCode> = {},
) =>
  AccountReferralCode.$create(
    Object.assign(
      {
        referrals: [],
        company: null,
        category: null,
        code: null,
        name: null,
        isForUsers: false,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateSubscription = (data: Partial<ISubscription> = {}) =>
  Subscription.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        status: null,
        kintin: null,
        files: [],
        productSku: null,
        provider: null,
        billingDate: null,
        procXero: false,
        procDd: false,
        notes: null,
        subscriptionValue: null,
      },
      data,
    ),
  ).$persist();

export const CreateBrandedKinvault = (data: Partial<IBrandedKinvault> = {}) =>
  BrandedKinvault.$create(
    Object.assign(
      {
        brandedKinvaultMemberships: [],
        teams: [],
        referrals: [],
        profile: CreateProfile().id,
        kinvaultTheme: CreateThemeKinvault().id,
        frontendUrl: null,
        type: null,
        notes: null,
        companyUrl: null,
        clientPoolType: null,
        systemEmail: null,
        emailLogo: null,
        siteMode: null,
        restrictUserEmailDomain: null,
        allowedEmailDomains: [],
        redirectUrl: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateFile = (data: Partial<IFileLog> = {}) =>
  FileLog.$create(
    Object.assign(
      {
        kintin: null,
        trustReg: null,
        type: null,
        subType: null,
        tab: null,
        fileName: null,
        s3Location: null,
        fileExtension: null,
        fileSize: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateSignDoc = (data: Partial<ISignDoc> = {}) =>
  SignDoc.$create(
    Object.assign(
      {
        person: null,
        data: null,
        kintin: null,
        uploads: [],
        type: null,
        signKey: null,
        ipAddress: null,
        userAgent: null,
        signedAt: null,
        content: null,
        stripeSessions: [],
        clauses: [],
        conditions: [],
        products: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateUploadForm = (data: Partial<IUploadForm> = {}) =>
  UploadForm.$create(
    Object.assign(
      {
        kintin: null,
        submissions: [],
        signKey: null,
        name: null,
        validTill: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateGuardian = (data: Partial<IGuardian> = {}) =>
  Guardian.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateBeneficiary = (data: Partial<IBeneficiary> = {}) =>
  Beneficiary.$create(
    Object.assign(
      {
        person: null,
        ifPredeceased: null,
        classGiftType: null,
        reserveclassGiftType: null,
        reservePerson: null,
        forPerson: null,
        position: null,
        sharePercentage: null,
        shareFraction: null,
        notes: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateExecutor = (data: Partial<IExecutor> = {}) =>
  Executor.$create(
    Object.assign(
      {
        forPerson: null,
        person: null,
        isReserve: false,
        notes: null,
        professionalSupport: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateTrustee = (data: Partial<ITrustee> = {}) =>
  Trustee.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        isReserve: false,
        notes: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateAttorney = (data: Partial<IAttorney> = {}) =>
  Attorney.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        isReserve: false,
        notes: null,
        isCertProv: null,
        isHealthWealth: null,
        isPropFinance: null,
        sortOrder: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateAdviser = (data: Partial<IAdviser> = {}) =>
  Adviser.$create(
    Object.assign(
      {
        person: null,
        forPerson: null,
        defaultTrustIfa: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateProperty = (data: Partial<IProperty> = {}) =>
  Property.$create(
    Object.assign(
      {
        address: null,
        howOwned: null,
        ownership: null,
        type: null,
        kintin: null,
        titleDeed: null,
        price: null,
        value: null,
        lessMortgage: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateKintinAccess = (data: Partial<IKintinAccess> = {}) =>
  KintinAccess.$create(
    Object.assign(
      {
        accessType: "readonly",
        kintin: null,
        user: null,
        allowAccess: true,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        legal: null,
        assets: null,
        contacts: null,
        admin: null,
        care: null,
        death: null,
        misc: null,
        settings: null,
      },
      data,
    ),
  ).$persist();

export const CreateCreditNote = (data: Partial<ICreditNote> = {}) =>
  CreditNote.$create(
    Object.assign(
      {
        relatedOrder: null,
        relatedKintin: null,
        relatedTrustReg: null,
        issuedBy: null,
        relatedUser: null,
        relatedPerson: null,
        note: CreateNote().id,
        value: null,
        issuedAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();
export const CreateNotification = (data: Partial<INotification> = {}) =>
  Notification.$create(
    Object.assign(
      {
        user: null,
        note: null,
        status: null,
        statusChangedAt: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateEmailTemplate = (data: Partial<IEmailTemplate> = {}) =>
  EmailTemplate.$create(
    Object.assign(
      {
        name: null,
        subject: null,
        type: null,
        includeTo: null,
        preloadedFiles: [],
        requiredAttachments: [],
        emails: [],
        html: null,
        markdown: null,
        allowAttachments: null,
        strictAttachments: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreatePhysicalStorageFile = (
  data: Partial<IPhysicalStorageFile> = {},
) =>
  PhysicalStorageFile.$create(
    Object.assign(
      {
        file: null,
        subscription: null,
        logs: [],
        title: null,
        length: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreatePhysicalStorageFileLog = (
  data: Partial<IPhysicalStorageFileLog> = {},
) =>
  PhysicalStorageFileLog.$create(
    Object.assign(
      {
        logAction: null,
        file: null,
        createdBy: null,
        confirmedBy: null,
        completedAt: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateLead = (data: Partial<ILead> = {}) =>
  Lead.$create(
    Object.assign(
      {
        referral: CreateAccountReferral().$persist().id,
        assignedTo: null,
        ownedBy: null,
        referringKintin: null,
        status: null,
        tags: [],
        profile: CreateProfile().$persist().id,
        description: null,
        CommunicationNotes: [],
        notes: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        noteCount: 0,
      },
      data,
    ),
  ).$persist();

export const CreateUser = (data: Partial<IUser> = {}) =>
  User.$create(
    Object.assign(
      {
        notifications: null,
        profile: CreateProfile().$persist().id,
        credential: CreateCredential().$persist().id,
        brandedKinvaultMemberships: [],
        roles: [],
        acuityUserId: null,
        displayUserInPortal: null,
        lastLoggedIn: null,
        lastPasswordReset: null,
        issuedCreditNotes: [],
        receivedCreditNotes: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerContact = (
  data: Partial<IIntroducerContact> = {},
) =>
  IntroducerContact.$create(
    Object.assign(
      {
        assistantProfile: null,
        status: null,
        callStatus: null,
        newsLetter: false,
        remittanceType: null,
        profile: CreateProfile().$persist().id,
        tags: [],
        companies: [],
        cpds: [],
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerBillingContact = (
  data: Partial<IIntroducerBillingContact> = {},
) =>
  IntroducerBillingContact.$create(
    Object.assign(
      {
        introducerRemittanceType: null,
        agentRemittanceType: null,
        profile: CreateProfile().$persist().id,
        company: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerOutsource = (
  data: Partial<IIntroducerOutsource> = {},
) =>
  IntroducerOutsource.$create(
    Object.assign(
      {
        introducer: null,
        company: null,
        notes: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateKintin = (data: Partial<IKintin> = {}) =>
  Kintin.$create(
    Object.assign(
      {
        primaryPerson: CreatePerson().$persist().id,
        secondaryPerson: null,
        type: null,
        status: null,
        referral: CreateAccountReferral().$persist().id,
        sourceKintin: null,
        process: null,
        stage: null,
        creditNotes: [],
        willRevisions: [],
        friendlyName: null,
        ref: null,
        estatePlanners: [],
        legalAssistant: null,
        businessAssets: [],
        cashDebts: [],
        gifts: [],
        investments: [],
        lifeTimeGifts: [],
        otherAssets: [],
        pensions: [],
        properties: [],
        tags: [],
        orders: [],
        assignedTeams: [],
        assignedUsers: [],
        subscriptions: [],
        willReviews: [],
        policies: [],
        exclusions: [],
        signDocs: [],
        uploadForms: [],
        files: [],
        whyEol: null,
        callScript: null,
        permissions: [],
        notificationDate: null,
        statusUpdatedAt: null,
        nextActionAt: null,
        statusChangeReason: null,
        checks: [],
        checkFollowUps: [],
        checkNotes: [],
        people: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerCompany = (
  data: Partial<IIntroducerCompany> = {},
) =>
  IntroducerCompany.$create(
    Object.assign(
      {
        assignedTo: null,
        type: null,
        stage: null,
        status: null,
        seniorAdviser: null,
        introducedBy: null,
        kintinCount: 0,
        profile: CreateProfile().$persist().id,
        tags: [],
        contacts: [],
        billingContacts: [],
        contracts: [],
        referralCodes: [],
        referrals: [],
        network: null,
        kinvaultStatus: null,
        description: null,
        lastConversation: null,
        trustRegXeroContactId: null,
        notes: [],
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateCredential = (data: Partial<ICredential> = {}) =>
  Credential.$create(
    Object.assign(
      {
        password: null,
        username: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateEmailLog = (data: Partial<IEmailLog> = {}) =>
  EmailLog.$create(
    Object.assign(
      {
        status: null,
        data: null,
        preloadedFiles: [],
        attachments: [],
        template: null,
        cc: [],
        bcc: [],
        to: [],
        from: null,
        title: null,
        body: "",
        subject: null,
        sentAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateCommunicationNote = (
  data: Partial<ICommunicationNote> = {},
) =>
  CommunicationNote.$create(
    Object.assign(
      {
        communicationType: null,
        note: null,
        reference: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateThemeCssVar = (data: Partial<IThemeCssVar> = {}) =>
  ThemeCssVar.$create(
    Object.assign(
      {
        theme: null,
        value: null,
        key: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateThemeKinvault = (data: Partial<IThemeKinvault> = {}) =>
  ThemeKinvault.$create(
    Object.assign(
      {
        theme: null,
        navbarType: null,
        cssVars: [],
        footer: CreateThemeKinvaultFooter().$persist().id,
        header: CreateThemeKinvaultHeader().$persist().id,
        login: CreateThemeKinvaultLogin().$persist().id,
        favIcon: null,
        brand: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateThemeKinvaultFooter = (
  data: Partial<IThemeKinvaultFooter> = {},
) =>
  ThemeKinvaultFooter.$create(
    Object.assign(
      {
        class: null,
        type: null,
        logoClass: null,
        footerloginLogoClass: null,
        textLine1: null,
        textLine2: null,
        logo: null,
        backgroundColor: null,
        linkColour: null,
        twitter: null,
        facebook: null,
        linkedIn: null,
        youtube: null,
        footerloginLogo: null,
        footerStrap: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        hasBorderTop: false,
      },
      data,
    ),
  ).$persist();

export const CreateThemeKinvaultHeader = (
  data: Partial<IThemeKinvaultHeader> = {},
) =>
  ThemeKinvaultHeader.$create(
    Object.assign(
      {
        type: null,
        defaultLogoClass: null,
        loginLogoClass: null,
        defaultLogo: null,
        loginLogo: null,
        backgroundColour: null,
        linkColour: null,
        strap: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateThemeKinvaultLogin = (
  data: Partial<IThemeKinvaultLogin> = {},
) =>
  ThemeKinvaultLogin.$create(
    Object.assign(
      {
        screenImg: null,
        screenType: null,
        customScreenImage: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateEmailCampaign = (data: Partial<IEmailCampaign> = {}) =>
  EmailCampaign.$create(
    Object.assign(
      {
        id: Uuid.generate(),
        description: null,
        name: null,
        profiles: [],
        campaignLogs: [],
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateEmailCampaignLog = (data: Partial<IEmailCampaignLog> = {}) =>
  EmailCampaignLog.$create(
    Object.assign(
      {
        data: null,
        preloadedFiles: [],
        attachments: [],
        template: null,
        title: null,
        subject: null,
        scheduledToSendAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        emailLogs: [],
        campaign: null,
        namedAttachments: [],
        body: null,
      },
      data,
    ),
  ).$persist();

export const CreateBrandedKinvaultSubscriptionReferralCode = (
  data: Partial<IBrandedKinvaultSubscriptionReferralCode>,
) =>
  new BrandedKinvaultSubscriptionReferralCode(
    Object.assign(
      {
        referrals: [],
        organisation: null,
        code: null,
        name: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateDefaultBrandedKinvaultReferralCode = (
  data: Partial<IDefaultBrandedKinvaultReferralCode>,
) =>
  new DefaultBrandedKinvaultReferralCode(
    Object.assign(
      {
        referrals: [],
        organisation: null,
        code: null,
        name: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateWillRevision = (data: Partial<IWillRevision> = {}) =>
  WillRevision.$create(
    Object.assign(
      {
        ownedBy: null,
        createdBy: null,
        approvedBy: null,
        approvedAt: null,
        content: null,
        notes: null,
        hasBusinessTrust: false,
        hasEstateProtectionTrust: false,
        hasIipTrust: false,
        hasBusinessClause: false,
        kintin: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateBrandedKinvaultInvitation = (
  data: Partial<IBrandedKinvaultInvitation> = {},
) =>
  BrandedKinvaultInvitation.$create(
    Object.assign(
      {
        status: "notsent",
        introducerContact: null,
        referral: null,
        profile: CreateProfile().$persist().id,
        sentAt: null,
        completedAt: null,
        declinedAt: null,
        externalRef: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
        organisation: null,
      },
      data,
    ),
  ).$persist();

export const CreateBrandedKinvaultInviteReferral = (
  data: Partial<IBrandedKinvaultInviteReferral> = {},
) =>
  BrandedKinvaultInviteReferral.$create(
    Object.assign(
      {
        referralCode: null,
        invite: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateStatusLog = (data: Partial<IStatusLog> = {}) =>
  StatusLog.$create(
    Object.assign(
      {
        status: null,
        stage: null,
        kintin: null,
        note: null,
        nextActionAt: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateCompanyPartnerPortalPermissions = (
  data: Partial<ICompanyPartnerPortalPermissions> = {},
) =>
  CompanyPartnerPortalPermissions.$create(
    Object.assign(
      {
        company: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateContactPartnerPortalPermissions = (
  data: Partial<IContactPartnerPortalPermissions> = {},
) =>
  ContactPartnerPortalPermissions.$create(
    Object.assign(
      {
        company: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateIntroducerNetwork = (
  data: Partial<IIntroducerNetwork> = {},
) =>
  IntroducerNetwork.$create(
    Object.assign(
      {
        name: "",
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

export const CreateBrandedKinvaultPricingSchema = (
  data: Partial<IBrandedKinvaultPricingSchema> = {},
) =>
  new BrandedKinvaultPricingSchema(
    Object.assign(
      {
        users: null,
        organisations: [],
        startDate: null,
        endDate: null,
        isDefault: false,
        schema: null,
        id: Uuid.generate(),
        createdAt: new DateTime().formatMachine,
      },
      data,
    ),
  ).$persist();

<template>
  <Card title="SortRefer Reconcile">
    <p>Test</p>
    <DateRangeProcess :callback="sortReferReconcileCallback" />
  </Card>
</template>

<script lang="ts">
import { SettingsAcuityRoute } from "@/module/settings/page";
import DateRangeProcess from "@kinherit/framework/component.display/date-range-process";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsAcuityRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.settings)],
  components: {
    Card,
    DateRangeProcess,
  },
  data() {
    return {
      sortReferResult: [] as any[],
    };
  },
  methods: {
    async sortReferReconcileCallback(start: DateTime, end: DateTime) {
      // await window.Kernel.ActionBus.settings.sortReferReconcile({
      await this.$actionBus.SortReferReconcile({
        minDate: start.formatYMD,
        maxDate: end.formatYMD,
      });
    },
  },
});
</script>

// FileLog

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { FileLog } from "@kinherit/sdk/model/file-log";

export const UpdateFile = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/file-log/{fileLog}",
  method: "patch",
  parse: (target: FileLog) => ({
    body: {
      tab: target.$data.tab,
      fileName: target.$data.fileName,
      s3Location: target.$data.s3Location,
      fileExtension: target.$data.fileExtension,
      fileSize: target.$data.fileSize,
      notes: target.$data.notes,
      isRichText: target.$data.isRichText,
      email: target.$data.email,
      campaignLog: target.$data.campaignLog,
      kintin: target.$data.kintin,
      form: target.$data.form,
      type: target.$data.type,
      subType: target.$data.subType,
      businessAsset: target.$data.businessAsset,
      cashDebt: target.$data.cashDebt,
      debt: target.$data.debt,
      investment: target.$data.investment,
      otherAsset: target.$data.otherAsset,
      pension: target.$data.pension,
      policy: target.$data.policy,
      property: target.$data.property,
      knowledgeBaseDocument: target.$data.knowledgeBaseDocument,
      reminder: target.$data.reminder,
    },
    params: {
      kintin: target.$data.kintin!,
      fileLog: target,
    },
  }),
  after: async ({ message }) => {
    message.$persist();
  },
});

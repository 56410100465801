// AccountReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { AccountReferralCode } from "@kinherit/sdk/index";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const AddAccountReferralCode = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/company/referral/{introducerCompany}/code/referral-codes/{accountReferralCode}/add",
  method: "post",
  parse: (message: {
    introducerCompany: IntroducerCompany | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: message,
  }),
});

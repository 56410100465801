<template>
  <div :class="`${wrapperClasses} input-field`">
    <Field
      class="advanced-file-field"
      :label="label"
      :messages="computedMessages"
      :size="size"
      @click="$emit('focus')"
    >
      <Tabs
        :class="tabsClass"
        :size="size"
        :is-fullwidth="false"
        :config="tabs"
        :icon-right="
          iconRightOverride
            ? iconRightOverride
            : hasWriteAccess
              ? 'Plus'
              : undefined
        "
        :icon-left="iconLeft"
        v-model:tab="currentTab"
        @click:right="() => (rightIconClick ? rightIconClick() : addFileMenu())"
        @click:left="leftIconClick"
        is-boxed
      >
        <template
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          #[`slot-${index}`]
        >
          <DropDownMenu
            :key="`${computedValue.length}-${currentTab}`"
            class="is-shadowless"
            is-static
            show
            :options="
              computedValue
                ? computedValue.filter((f: any) =>
                    tabs[currentTab].ref
                      ? tabs[currentTab].ref === f.tab
                      : true,
                  )
                : []
            "
            :map-options="{
              label: fields.title,
              value: fields.tracking,
              line1: (f: any) =>
                `File type: ${f.isRichText ? 'Note' : f.fileExtension}`,
            }"
            :empty-message="placeholder ?? `- Empty -`"
            @update:value="([item]) => fileSelected(item)"
            :show-more-button="false"
          />
        </template>
      </Tabs>
    </Field>
  </div>
</template>

<script lang="ts">
import { CreateFile } from "@/config/model.config";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import { Field } from "@kinherit/framework/component.form/field";
import { DropDownMenu } from "@kinherit/framework/component.input/drop-down-menu";
import { FieldMixin } from "@kinherit/framework/component.mixin/field-mixin";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { ThemeColor } from "@kinherit/framework/theme/prop/color";
import {
  ThemeIconName,
  ThemeIconNameType,
} from "@kinherit/framework/theme/prop/icon";
import { FileLog, Kintin } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { UpdateFileForm } from "../form/update-file.form";

export default defineComponent({
  name: "FileVaultDocuments",
  components: { Field, Tabs, DropDownMenu },
  mixins: [FieldMixin(), KinvaultKintinDetailsMixin],
  props: {
    tabs: {
      type: Array as PropType<
        Array<{
          label: string;
          icon?: ThemeIconNameType;
          ref: FileLog["$data"]["tab"];
        }>
      >,
      default: () => [{ label: "Files" }],
    },
    value: {
      type: Array as () => Array<FileLog>,
      default: () => [],
    },
    type: {
      type: Object as PropType<FileLog["type"]>,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
    tabsClass: {
      type: String,
      default: "",
    },
    kintin: {
      type: Object as PropType<Kintin>,
      default: null,
    },
  },
  emits: {
    "update:value": (value: Array<FileLog>) => null !== value,
    save: (value: { fileLog: FileLog; uploadedFile: File | null }) =>
      null !== value,
    delete: (value: FileLog) => null !== value,
    focus: () => true,
    blur: () => true,
  },
  data: () => ({
    currentTab: 0,
    fields: {
      title: "fileName" as keyof FileLog,
      tracking: "id" as keyof FileLog,
    },
  }),
  computed: {
    isDisabled() {
      return ["disabled", "readonly"].includes(this.state as string);
    },
  },
  methods: {
    async fileSelected(file: FileLog): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      const items = [
        {
          title: "Edit",
          line1: "Update or replace this file",
          icon: StyleService.icon.edit.icon,
          action: this.editFile,
        },
        {
          title: "Delete",
          line1: "Delete the selected file",
          icon: StyleService.icon.delete.icon,
          action: this.deleteFile,
        },
      ];

      if (!file.isRichText) {
        items.unshift({
          title: "Preview",
          line1: "View the selected file",
          icon: StyleService.icon.file.icon,
          action: this.downloadFile,
        });
      }

      try {
        const option = await OpenContextMenu({
          items,
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        });

        await option.action(file);
      } catch (error) {
        this.$emit("blur");
      }
    },
    async deleteFile(file: FileLog): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      try {
        await OpenAlertDialog({
          dialog: {
            title: "Delete",
            message: `Are you sure you want to delete '${file.fileName}'?`,
          },
          button: {
            ok: {
              text: "Delete",
              color: ThemeColor.isDanger,
            },
            cancel: {
              color: ThemeColor.isPrimary,
            },
          },
        });
      } catch (e) {
        this.$emit("blur");
        return;
      }

      this.$emit("delete", file);

      this.$emit("blur");
    },
    async editFile(file: FileLog): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      if (file.fileExtension === "rich-text") {
        await this.createFileForm(file);
      } else {
        await this.uploadFileForm(file);
      }
    },
    async addFileMenu(): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      try {
        const option = await OpenContextMenu({
          items: [
            {
              title: "Create",
              line1: "Add a quick note to this section",
              icon: ThemeIconName.Plus,
              action: () => this.createFileForm(),
            },
            {
              title: "Upload",
              line1: "Upload a new file here",
              icon: ThemeIconName.Upload,
              action: () => this.uploadFileForm(),
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        });

        option.action();
      } catch (e) {
        this.$emit("blur");
      }
    },
    async createFileForm(file?: FileLog): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      if (undefined === file) {
        file = this.createFileModel(true);
      }

      try {
        const result = await UpdateFileForm(file).dialog({
          dialog: { title: "Create file" },
        });

        this.addFile(result);
      } catch (e) {
        file.$restore();
        throw e;
      }
    },
    async uploadFileForm(file?: FileLog): Promise<void> {
      if (this.isDisabled) {
        return;
      }

      if (undefined === file) {
        file = this.createFileModel();
      }

      try {
        const result = await UpdateFileForm(file).dialog({
          dialog: { title: "Upload file" },
        });

        this.addFile(result);
      } catch (e) {
        file.$restore();
        throw e;
      }
    },
    createFileModel(isNote = false): FileLog {
      return CreateFile({
        tab: this.tabs[this.currentTab].ref ?? "primary",
        type: this.type?.id,
        fileExtension: isNote ? "rich-text" : (null as any),
        fileSize: 0,
      });
    },
    addFile({
      file,
      uploadedFile,
    }: {
      file: FileLog;
      uploadedFile: File | null;
    }): void {
      if (this.isDisabled) {
        return;
      }

      const files: Array<FileLog> = this.computedValue ?? [];

      if (!files.pluck("id").includes(file.id)) {
        files.push(file);
      } else {
        const index = files.findIndex(({ id }) => {
          return id === file.id;
        });

        files[index] = file;
      }

      this.$emit("save", {
        fileLog: file,
        uploadedFile,
      });
      this.computedValue = files;
    },
    async downloadFile(file: FileLog): Promise<void> {
      const kintin = this.kintin;

      if (undefined === kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.file.download({
      //   file,
      //   kintin,
      // });
      await this.$actionBus.fileLog.DownloadFile({
        file,
        kintin,
      });
    },
  },
});
</script>

// Order, Kintin, AccountReferral, AccountReferralCode, Profile, OrderItem, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Order } from "@kinherit/sdk/model/order";

export const RecordOrder = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/order/{order}",
  method: "get",
  rootModel: Order,
  parse: (message: { kintin: Kintin | string; order: Order | string }) => ({
    params: {
      kintin: message.kintin,
      order: message.order,
    },
  }),
});

import { ChangeTokenPasswordHandler } from "@/module/auth/action/change-password/change-token-password.action";
import { UniqueUsernameAction } from "@/module/auth/action/unique-username.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ChangeMyPassword } from "./change-password/change-my-password.action";
import { RequestPasswordChange } from "./change-password/request-password-change.action";
import { CompleteMultiFactorAuthHandler } from "./multi-factor/complete-multi-factor-auth.action";
import { RequestMultiFactorAuthHandler } from "./multi-factor/request-multi-factor-auth.action";
import { CompleteSessionHandler } from "./session/complete-session.action";
import { LogoutSessionHandler } from "./session/logout-session.action";
import { ReadSession } from "./session/read-session.action";
import { RequestSessionHandler } from "./session/request-session.action";

export const Auth = DefineActionBus({
  name: "auth",
  actions: {
    multiFactor: DefineActionBus({
      name: "auth-multi-factor",
      actions: {
        RequestMultiFactorAuth: RequestMultiFactorAuthHandler,
        CompleteMultiFactorAuth: CompleteMultiFactorAuthHandler,
      },
    }),
    session: DefineActionBus({
      name: "auth-session",
      actions: {
        RequestSession: RequestSessionHandler,
        CompleteSession: CompleteSessionHandler,
        LogoutSession: LogoutSessionHandler,
        ReadSession: ReadSession,
      },
    }),
    changePassword: DefineActionBus({
      name: "auth-change-password",
      actions: {
        ChangeTokenPassword: ChangeTokenPasswordHandler,
        ChangeMyPassword,
        RequestPasswordChange,
      },
    }),
    UniqueUsername: UniqueUsernameAction,
  },
});

// PhysicalStorageFileLog

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Kintin, Subscription } from "@kinherit/sdk/index";
import { PhysicalStorageFileLog } from "@kinherit/sdk/model/physical-storage-file-log";

export const CreatePhysicalStorageFileLog = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvault/{kintin}/subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}/log",
  method: "post",
  parse: (message: {
    physicalStorageFileLog: PhysicalStorageFileLog;
    kintin: string | Kintin;
    subscription: string | Subscription;
  }) => ({
    body: {
      createdBy: message.physicalStorageFileLog.$data.createdBy,
      confirmedBy: message.physicalStorageFileLog.$data.confirmedBy,
      completedAt: message.physicalStorageFileLog.$data.completedAt,
      notes: message.physicalStorageFileLog.$data.notes,
      requestedAt: message.physicalStorageFileLog.$data.requestedAt,
      file: message.physicalStorageFileLog.$data.file,
      logAction: message.physicalStorageFileLog.$data.logAction,
      kintin: message.physicalStorageFileLog.$data.kintin,
    },
    params: {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.physicalStorageFileLog,
    },
  }),
  after: ({ message }) => {
    PhysicalStorageFileLog.$delete(message.physicalStorageFileLog);
  },
});

// Profile, EmailCampaign

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailCampaign, Profile } from "@kinherit/sdk/index";

export const AddFilteredProfiles = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/email-campaign/profile/{emailCampaign}/add-filtered-profiles",
  method: "post",
  rootModel: Profile,
  parse: (emailCampaign: EmailCampaign | string) => ({
    body: {},
    params: { emailCampaign },
  }),
});

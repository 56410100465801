import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinAccess } from "./create-kintin-access.action";
import { DeleteKintinAccess } from "./delete-kintin-access.action";
import { ReadKintinAccess } from "./read-kintin-access.action";
import { RecordKintinAccess } from "./record-kintin-access.action";
import { UpdateKintinAccess } from "./update-kintin-access.action";

export const Portal2KinvaultKintinAccess = DefineActionBus({
  name: "portal-2-kinvault-kintin-access",
  actions: {
    CreateKintinAccess,
    DeleteKintinAccess,
    ReadKintinAccess,
    UpdateKintinAccess,
    RecordKintinAccess,
  },
});

<template>
  <div class="downloadable-reports">
    <Card title="Custom Reports">
      <Form
        v-if="null !== $data.filters"
        is-borderless
        :config="$data.filters"
        @submit="load"
      />
      <div class="buttons">
        <Button @click="load">Get Report</Button>
      </div>
    </Card>
    <Card title="Time Since Last Lead">
      <p>
        For each referral code, the total number of leads, the date of the last
        lead, and the amount of days since the last lead. Includes who the
        assigned Sales Team member is.
      </p>
      <Button @click="getTimeSinceLastLeadReport">Get Report</Button>
    </Card>
  </div>
</template>

<script lang="ts">
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";
import { DownloadReportRoute } from ".";
import { ReadDownloadReportFilters } from "../form/read-download-report.form";

export default defineComponent({
  name: DownloadReportRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.reports)],
  components: {
    Card,
    Form,
    Button,
  },
  data: () => ({
    filters: ReadDownloadReportFilters(),
  }),
  methods: {
    async load() {
      // await window.Kernel.ActionBus.report.download(
      //   this.filters?.localData ?? {},
      // );
      await this.$actionBus.Download(this.filters?.localData ?? {});
    },
    async getTimeSinceLastLeadReport() {
      // await window.Kernel.ActionBus.report.timeSinceLead();
      await this.$actionBus.TimeSinceLead();
    },
  },
});
</script>

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Note, Person } from "@kinherit/sdk/index";

export const UpdateNote = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/officer/{owner}/notes/{note}",
  method: "patch",
  parse: (message: { officer: Person | string; note: Note }) => ({
    body: {
      name: message.note.$data.name,
      type: message.note.$data.type,
      completedAt: message.note.$data.completedAt,
      dueAt: message.note.$data.dueAt,
      notes: message.note.$data.notes,
      pinned: message.note.$data.pinned,
      kintin: message.note.$data.kintin,
      lead: message.note.$data.lead,
      introducerOutsource: message.note.$data.introducerOutsource,
      company: message.note.$data.company,
      contact: message.note.$data.contact,
      person: message.note.$data.person,
      isPublic: message.note.$data.isPublic,
    },
    params: { owner: message.officer, note: message.note },
  }),
});

// KnowledgeBaseCategory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const ReadKnowledgeBaseCategory = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/knowledge-base",
  method: "get",
  rootModel: KnowledgeBaseCategory,
  parse: () => ({}),
});

// Executor

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Executor } from "@kinherit/sdk/model/executor";

export const RecordExecutor = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/executor/{executor}",
  method: "get",
  rootModel: Executor,
  parse: (executor: Executor) => ({
    params: {
      executor,
      kintin: executor.$data.kintin,
    },
  }),
});

<template>
  <KnowledgeBaseWrapper>
    <template #content>
      <SidebarPage
        :key="key"
        class="knowledge-base-document"
        :name="knowledgeBaseDocument?.title"
        title="Knowledge Base Document"
        subtitle="Document Details"
        :has-context-menu="hasContextMenu"
        @context-menu="contextMenu"
        :tabs="tabs"
        v-model:tab="tab"
        :show-sidebar="false"
      >
        <template #default>
          <RouterChildView name="default">
            <KnowledgeBaseDocumentDetailsContent />
          </RouterChildView>
        </template>
      </SidebarPage>
    </template>
  </KnowledgeBaseWrapper>
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseDocumentDetailsWrapper",
  "route": "KnowledgeBaseDocumentDetails",
  "selector": ".knowledge-base-document",
  "imports": {
    "ReadKnowledgeBaseDocumentFormWrapper": "@/module/knowledge-base/form/read-knowledge-base-document.form.test",
    "UpdateKnowledgeBaseDocumentFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-document.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKnowledgeBaseDocumentFormWrapper"
    },
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Update", "Delete"]
    },
    "tabs": {
      "type": "tabs",
      "selector": ".tabs",
      "items": ["Content", "Edit Files"]
    },
    "updateDocumentForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateKnowledgeBaseDocumentFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import KnowledgeBaseWrapper from "@/module/knowledge-base/page/KnowledgeBaseWrapper.vue";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { KnowledgeBaseDocument } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import {
  KnowledgeBaseCategoryDetailsRoute,
  KnowledgeBaseDocumentDetailsParams,
  KnowledgeBaseDocumentDetailsRoute,
} from ".";
import { UpdateKnowledgeBaseDocumentForm } from "../form/update-knowledge-base-document.form";
import { KnowledgeBaseDocumentMixin } from "../mixin/knowledge-base-document.mixin";
import {
  KnowledgeBaseDocumentDetailsContentRoute,
  KnowledgeBaseDocumentDetailsFilesRoute,
} from "./document";
import KnowledgeBaseDocumentDetailsContent from "./document/KnowledgeBaseDocumentDetails.Content.vue";

export default defineComponent({
  name: KnowledgeBaseDocumentDetailsRoute,
  components: {
    SidebarPage,
    RouterChildView,
    KnowledgeBaseWrapper,
    KnowledgeBaseDocumentDetailsContent,
  },
  mixins: [AuthService.mixin(), KnowledgeBaseDocumentMixin()],
  async beforeRouteEnter(route) {
    const knowledgeBaseDocument = route.params.document as string;

    // await window.Kernel.ActionBus.knowledgeBase.document.record({
    await window.Kernel.ActionBus2.portal2.knowledgeBase.category.document.RecordKnowledgeBaseDocument(
      {
        message: knowledgeBaseDocument,
      },
    );
  },
  data: () => ({
    key: 0,
    tab: 0,
    tabs: [
      {
        label: "Content",
        route: {
          name: KnowledgeBaseDocumentDetailsContentRoute,
        },
      },
      {
        label: "Edit Files",
        route: { name: KnowledgeBaseDocumentDetailsFilesRoute },
      },
    ] as TabOptions[],
  }),
  computed: {
    $params(): KnowledgeBaseDocumentDetailsParams {
      return this.$route.params as KnowledgeBaseDocumentDetailsParams;
    },
    knowledgeBaseDocument(): KnowledgeBaseDocument | null {
      return KnowledgeBaseDocument.$findOne(this.$params.document);
    },
    hasContextMenu(): boolean {
      return (
        this.$auth.hasPermission("knowledge-base-document:write") ||
        this.$auth.hasPermission("knowledge-base-document:delete")
      );
    },
  },
  methods: {
    contextMenu(): void {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          titleField: "title",
          trackingField: "title",
          iconLeftField: "icon",
          line1Field: "line1",
          items: [
            {
              title: "Update",
              icon: StyleService.icon.edit.icon,
              line1: "Update this document",
              permission: "knowledge-base-document:write",
              action: this.updateDocument,
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this document",
              permission: "knowledge-base-document:delete",
              action: this.deleteDocument,
            },
          ],
        },
        path: "permission",
        filter: "all",
        callback: (item) => item.action(),
      });

      contextMenu();
    },
    async updateDocument(): Promise<void> {
      const knowledgeBaseDocument = this.knowledgeBaseDocument;
      if (!knowledgeBaseDocument) {
        return;
      }

      try {
        await UpdateKnowledgeBaseDocumentForm(knowledgeBaseDocument).dialog({
          dialog: {
            title: "Update Knowledge Base Document",
          },
        });
      } catch (error) {
        knowledgeBaseDocument.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.knowledgeBase.document.update({
      await this.$actionBus.UpdateKnowledgeBaseDocument(knowledgeBaseDocument);
      this.key++;
    },
    async deleteDocument(): Promise<void> {
      if (!this.knowledgeBaseDocument) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Knowledge Base Document",
          message: `Are you sure you want to delete this document?`,
        },
      });

      // await window.Kernel.ActionBus.knowledgeBase.document.delete({
      await this.$actionBus.DeleteKnowledgeBaseDocument(
        this.knowledgeBaseDocument,
      );

      this.$router.push({
        name: KnowledgeBaseCategoryDetailsRoute,
        params: {
          category: this.knowledgeBaseCategory?.id,
        },
      });
    },
  },
});
</script>

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateAttorney } from "./create-attorney.action";
import { DeleteAttorney } from "./delete-attorney.action";
import { ReadAttorney } from "./read-attorney.action";
import { RecordAttorney } from "./record-attorney.action";
import { UpdateAttorney } from "./update-attorney.action";

export const Portal2KinvaultAttorney = DefineActionBus({
  name: "portal-2-kinvault-attorney",
  actions: {
    CreateAttorney,
    DeleteAttorney,
    ReadAttorney,
    UpdateAttorney,
    RecordAttorney,
  },
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailCampaignLog } from "./create-email-campaign-log.action";
import { DeleteAttachment } from "./delete-attachment.action";
import { DeleteEmailCampaignLog } from "./delete-email-campaign-log.action";
import { ReadEmailCampaignLog } from "./read-email-campaign-log.action";
import { RecordEmailCampaignLog } from "./record-email-campaign-log.action";
import { UpdateEmailCampaignLog } from "./update-email-campaign-log.action";
import { UploadAttachment } from "./upload-attachment.action";

export const Portal2EmailCampaignLog = DefineActionBus({
  name: "portal-2-email-campaign-log",
  actions: {
    CreateEmailCampaignLog,
    DeleteEmailCampaignLog,
    RecordEmailCampaignLog,
    ReadEmailCampaignLog,
    UpdateEmailCampaignLog,
    UploadAttachment,
    DeleteAttachment,
  },
});

<template>
  <Card title="Acuity Appointment Reconcile">
    <p>
      For a given range, gets all appointments from acuity and updates the local
      store. Useful if things get out of sync.
    </p>
    <DateRangeProcess :callback="acuityReconcileCallback" />
  </Card>
  <Card title="Acuity Appointment Profile Reconcile">
    <p>
      For a given range, gets all appointments and try and populate profiles
      where empty.
    </p>
    <DateRangeProcess :callback="acuityReconcileProfilesCallback" />
  </Card>
  <Card title="Acuity Appointment Types">
    <p>For each appointment type in acuity, checks if it exists in options</p>
    <Button text="Reconcile Appointment Types" @click="acuityReconcileTypes" />
    <div v-if="acuityReconcileTypeResult.length">
      <table>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Active</th>
          <th>Matched ID</th>
          <th>Matched Group</th>
        </tr>
        <tr v-for="type in acuityReconcileTypeResult" :key="type.id">
          <td>{{ type.id }}</td>
          <td>{{ type.name }}</td>
          <td>
            <span
              :class="type.active ? 'has-text-success' : 'has-text-danger'"
              >{{ type.active }}</span
            >
          </td>
          <td>
            <span
              :class="type.matchedId ? 'has-text-success' : 'has-text-danger'"
              >{{ type.matchedId }}</span
            >
          </td>
          <td>
            <span
              :class="
                type.matchedGroup ? 'has-text-success' : 'has-text-danger'
              "
              >{{ type.matchedGroup ?? "None" }}</span
            >
          </td>
        </tr>
      </table>
    </div>
  </Card>
</template>

<script lang="ts">
import { SettingsAcuityRoute } from "@/module/settings/page";
import DateRangeProcess from "@kinherit/framework/component.display/date-range-process";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsAcuityRoute,
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.settings.acuity),
  ],
  components: {
    Card,
    Button,
    DateRangeProcess,
  },
  data() {
    return {
      acuityReconcileTypeResult: [] as any[],
    };
  },
  methods: {
    async acuityReconcileCallback(start: DateTime, end: DateTime) {
      // await window.Kernel.ActionBus.settings.acuityReconcile({
      await this.$actionBus.Reconcile({
        minDate: start.formatYMD,
        maxDate: end.formatYMD,
      });
    },
    async acuityReconcileProfilesCallback(start: DateTime, end: DateTime) {
      // await window.Kernel.ActionBus.settings.acuityReconcileProfiles({
      await this.$actionBus.ReconcileProfiles({
        minDate: start.formatYMD,
        maxDate: end.formatYMD,
      });
    },
    async acuityReconcileTypes() {
      // this.acuityReconcileTypeResult =
      //   await window.Kernel.ActionBus.settings.acuityReconcileTypes();
      this.acuityReconcileTypeResult = await this.$actionBus.ReconcileTypes();
    },
  },
});
</script>

// Address

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Address } from "@kinherit/sdk/model/address";

export const ReadAddress = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/address",
  method: "get",
  rootModel: Address,
  parse: () => ({}),
});

// BrandedKinvaultInvitation, AccountReferralCode, BrandedKinvaultInviteReferral, Profile, IntroducerContact, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/model/branded-kinvault-invitation";

export const ReadBrandedKinvaultInvitation = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/invitation",
  method: "get",
  rootModel: BrandedKinvaultInvitation,
  parse: () => ({}),
});

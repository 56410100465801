// Tag

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Tag } from "@kinherit/sdk/model/tag";

export const DeleteTag = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/tag/{tag}",
  method: "delete",
  parse: (tag: Tag | string) => ({
    params: {
      tag,
    },
  }),
  after: ({ message }) => {
    Tag.$delete(message);
  },
});

// BrandedKinvaultMembership

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultMembership } from "@kinherit/sdk/model/branded-kinvault-membership";

export const CreateBrandedKinvaultMembership = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/user/{user}/branded-kinvault-membership",
  method: "post",
  parse: (target: BrandedKinvaultMembership) => ({
    body: {
      allowAccess: target.$data.allowAccess,
      user: target.$data.user,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      user: target.user,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultMembership.$delete(message);
  },
});

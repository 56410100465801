// XeroContact

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { XeroContact } from "@kinherit/sdk/model/xero-contact";

export const ReadXeroContact = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/xero-contact",
  method: "get",
  rootModel: XeroContact,
  parse: () => ({}),
});

// BrandedKinvaultInvitation

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/model/branded-kinvault-invitation";

export const DeleteBrandedKinvaultInvitation = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/invitation/{brandedKinvaultInvitation}",
  method: "delete",
  parse: (brandedKinvaultInvitation: BrandedKinvaultInvitation | string) => ({
    params: {
      brandedKinvaultInvitation,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultInvitation.$delete(message);
  },
});

import { AddressSearch } from "@/action-bus/core/address-search.action";
import { SetXeroToken } from "@/action-bus/core/set-xero-token.action";
import { SyncXeroContacts } from "@/action-bus/core/sync-xero-contacts.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Clear } from "./cache/clear.action";
import { CoreEmailLog } from "./email-log";
import { SignUrl } from "./environment/sign-url.action";
import { Index } from "./exchange-rates/index.action";
import { CoreNote } from "./note";
import { CoreOption } from "./option";
import { CoreSelect } from "./select";

export const Core = DefineActionBus({
  name: "core",
  actions: {
    ClearCache: Clear,
    SignUrl,
    ExchangeRates: Index,
    options: CoreOption,
    select: CoreSelect,
    notes: CoreNote,
    emailLog: CoreEmailLog,
    SetXeroToken,
    SyncXeroContacts,
    AddressSearch,
  },
});

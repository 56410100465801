import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Note, Person } from "@kinherit/sdk/index";

export const DeleteNoteHandler = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/officer/{owner}/notes/{note}",
  method: "delete",
  parse: (message: { officer: Person | string; note: Note }) => ({
    params: { owner: message.officer, note: message.note },
  }),
  after: ({ message }) => {
    Note.$delete(message.note);
  },
});

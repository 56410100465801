import { Api, Order } from "@kinherit/sdk/index";

//
export const DownloadInvoice = async (message: {
  order: Order;
  isBusinessInvoice: boolean;
}) => {
  const { order, isBusinessInvoice } = message;
  const { kintin } = order;

  const primaryPerson = kintin?.primaryPerson;
  const secondaryPerson = kintin?.secondaryPerson;

  const surname = [
    primaryPerson?.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  const ref = kintin?.ref;

  await Api.resource("portal-2", "/v2/portal-2/order/{order}/invoice", {
    order,
  })
    .method("get")
    .params({
      isBusinessInvoice,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${ref} Order Invoice - ${surname}`,
    });

  return {};
};

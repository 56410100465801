// AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Lead } from "@kinherit/sdk/index";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";

export const ReadAccountReferral = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/lead/{lead}/account-referral",
  method: "get",
  rootModel: AccountReferral,
  parse: (lead: Lead) => ({
    params: {
      lead,
    },
  }),
});

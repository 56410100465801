import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateLead } from "./create-lead.action";
import { ReadLead } from "./read-lead.action";
import { UpdateAccountReferral } from "./update-account-referral.action";
import { UpdateLead } from "./update-lead.action";

export const Portal2Leads = DefineActionBus({
  name: "portal-2-leads",
  actions: {
    ReadLead,
    UpdateLead,
    CreateLead,
    UpdateAccountReferral,
  },
});

// BrandedKinvaultInvitation, BrandedKinvaultInviteReferral, Profile, EmailAddress

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Credential } from "@kinherit/sdk/index";
import { Uuid } from "@kinherit/ts-common/index";

export const CreateBrandedKinvaultInvitation = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault/invitation",
  method: "post",
  parse: (message: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    reference: null | string;
    referralCode: null | string;
    adviser: null | string;
    sendInvitationEmail: null | boolean;
    createdBy: Credential;
    brandedKinvaultOverride: null | string; // @todo
    brandedKinvault: string;
  }) => ({
    body: {
      invite: {
        profile: {
          firstName: message.firstName,
          lastName: message.lastName,
          emails: [{ email: message.emailAddress, primary: true }],
        },
        signUpToken: Uuid.generate(),
        status: "notsent" as const,
        brandedKinvault: message.brandedKinvault,
        createdBy: message.createdBy.id,
        introducerContact: message.adviser,
        externalRef: message.reference,
      },
      referralCode: message.referralCode,
    },
  }),
});

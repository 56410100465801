import { calculateStartEndDates } from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeAppointmentsBreakdownReportMessage {
  created: [number, number] | null;
  assignedUser?: string | null;
}

export interface ComputeAppointmentsBreakdownReportResponse {
  data: Array<{
    groupName: string;
    total: number;
    cancelled: number;
    appointmentTypes: Array<{
      id: string;
      total: number;
      cancelled: number;
      outcomes: Array<{
        id: string;
        count: number;
      }>;
      cancelledOutcomes: Array<{
        id: string;
        count: number;
      }>;
    }>;
  }>;
}
export const ComputeAppointmentsBreakdownReportHandler = async (
  message: ComputeAppointmentsBreakdownReportMessage,
): Promise<ComputeAppointmentsBreakdownReportResponse> => {
  const { created, assignedUser } = message || {};

  const { startDate, endDate } = calculateStartEndDates(
    "custom",
    created,
    null,
  );

  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/report/appointments/breakdown",
    {},
  )
    .method("get")
    .params({
      startDate,
      endDate,
      assignedUser: assignedUser ?? null,
    })

    .result();

  return response;
};

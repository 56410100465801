import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateGuardian } from "./create-guardian.action";
import { DeleteGuardian } from "./delete-guardian.action";
import { ReadGuardian } from "./read-guardian.action";
import { RecordGuardian } from "./record-guardian.action";
import { UpdateGuardian } from "./update-guardian.action";

export const Portal2KinvaultGuardian = DefineActionBus({
  name: "portal-2-kinvault-guardian",
  actions: {
    CreateGuardian,
    DeleteGuardian,
    ReadGuardian,
    UpdateGuardian,
    RecordGuardian,
  },
});

// User, Profile, Credential

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { User } from "@kinherit/sdk/model/user";

export const ReadUsers = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/users",
  method: "get",
  rootModel: User,
  parse: () => ({}),
});

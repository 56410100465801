// Check

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Check } from "@kinherit/sdk/model/check";

export const ReadCheck = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/check",
  method: "get",
  rootModel: Check,
  parse: () => ({}),
});

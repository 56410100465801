<template>
  <div class="introducer-company-partner-portal" :key="key">
    <PageHeader htag="h2" text="Partner Portal" />

    <CollapsableWarningWidget v-if="warnings.length" :items="warnings" />

    <DataListWidget
      v-if="introducerCompany"
      :details="companyPermissions"
      :direction="'is-horizontal'"
    >
      <template #above>
        <div class="level">
          <h5 class="left">Global Settings</h5>
          <Button
            v-if="hasWritePermission"
            class="create"
            size="is-normal"
            @click="$emit('update-company-partner-portal')"
            icon-left="Cog"
          >
            Settings
          </Button>
        </div>
      </template>
    </DataListWidget>

    <MasterListPage
      class="contacts-table"
      :columns="columns"
      :rows="rows"
      is-nested
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :row-classes="rowClasses"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.fullName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #name="{ row }">
        <a class="has-text-weight-bold">{{ row.profile.fullName }}</a>
        <Badge
          v-if="row.status.value === 'dormant'"
          text="Dormant"
          color="is-warning"
          size="is-small"
          isCompact
        />
        <Badge
          v-if="row.status.value === 'left'"
          text="Left Company"
          color="is-danger"
          size="is-small"
          isCompact
        />
        <Badge
          v-if="row.status.value === 'dead'"
          text="Deceased"
          color="is-purple"
          size="is-small"
          isCompact
        />
      </template>
      <template #actions="{ row }">
        <Button
          v-if="hasWritePermission && row.profile.emails.isNotEmpty()"
          @click="editContactPartnerPortalSettings(row)"
          icon-left="Cog"
          size="is-small"
        >
          Edit
        </Button>
        <Button
          v-if="
            hasWritePermission &&
            undefined !== row.partnerPortalPermissions?.first()
          "
          @click="deleteContactPartnerPortalSettings(row)"
          icon-left="Reset"
          size="is-small"
          aria-label="Reset Permissions"
        >
        </Button>
      </template>
      <template #credentials="{ row }">
        <span v-if="row.credential?.username">
          <strong>{{ row.credential?.username }}</strong>
          <small class="is-block">
            Last Login:
            <span v-if="row.credential?.lastLoggedIn?.formatDateTime">
              {{ row.credential?.lastLoggedIn?.formatDateTime }}</span
            >
            <span v-else>Never</span>
          </small>
        </span>
        <span v-else>
          <span v-if="row.profile.emails.isEmpty()"
            ><em>No Email Address</em></span
          >
          <span v-else>
            {{ row.profile.emails.first()?.email }}
          </span>
        </span>
      </template>
      <template #clientsRead="{ row }">
        <PermissionStatusBadge
          :status="row.partnerPortalPermissions?.first()?.clientsRead"
        />
      </template>
      <template #clientsCreate="{ row }">
        <PermissionStatusBadge
          :status="row.partnerPortalPermissions?.first()?.clientsCreate"
        />
      </template>
      <template #feesRead="{ row }">
        <PermissionStatusBadge
          :status="row.partnerPortalPermissions?.first()?.feesRead"
        />
      </template>
      <template #invitationsCreate="{ row }">
        <PermissionStatusBadge
          :status="row.partnerPortalPermissions?.first()?.invitationsCreate"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsPartnerPortalWrapper",
  "route": "IntroducerCompanyDetailsPartnerPortal",
  "selector": ".introducer-company-partner-portal",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { IIntroducerContact, IntroducerContact } from "@kinherit/sdk";

import { CreateContactPartnerPortalPermissions } from "@/config/model.config";
import { IntroducerContactPartnerPortalSettingsForm } from "@/module/introducer.company/form/introducer-contact-partner-portal-settings.form";
import { IntroducerCompanyDetailsMixin } from "@/module/introducer.company/mixin/introducer-company-details.mixin";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsPartnerPortalRoute } from ".";

import { DangerDialog } from "@/config/dialog.config";
import PermissionStatusBadge from "@/shared/component/partner-portal/PermissionStatusBadge.vue";
import CollapsableWarningWidget from "@/shared/component/widget/CollapsableWarningWidget.vue";
import Badge from "@kinherit/framework/component.display/badge";
import { Equal } from "@kinherit/orm/index";
export default defineComponent({
  name: IntroducerCompanyDetailsPartnerPortalRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    PageHeader,
    Button,
    DataListWidget,
    MasterListPage,
    PermissionStatusBadge,
    CollapsableWarningWidget,
    Badge,
  },
  data: () => ({
    key: 0,
    columns: [
      {
        title: "Name",
        sort: true,
        slot: "name",
      },
      {
        title: "Credentials",
        sort: true,
        slot: "credentials",
      },
      {
        title: "clientsRead",
        slot: "clientsRead",
      },
      {
        title: "clientsCreate",
        slot: "clientsCreate",
      },
      {
        title: "feesRead",
        slot: "feesRead",
      },
      {
        title: "invitationsCreate",
        slot: "invitationsCreate",
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerContact>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "profile.fullName" as keyof IIntroducerContact,
      direction: "asc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return AuthService.hasPermission("introducer-company:write");
    },
    companyPermissions(): Array<DataListWidgetDetail> {
      if (!this.introducerCompany) {
        return [];
      }

      return [
        {
          label: "Enabled",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.allowPartnerPortal
              ? "Yes"
              : "No",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.allowPartnerPortal
              ? "has-text-success"
              : "has-text-danger",
          },
        },
        {
          label: "Client Module",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableClients
              ? "Yes"
              : "No",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableClients
              ? "has-text-success"
              : "has-text-danger",
          },
        },
        {
          label: "View Order Products",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.viewOrderProducts
              ? "Yes"
              : "No",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.viewOrderProducts
              ? "has-text-success"
              : "has-text-danger",
          },
        },
        {
          label: "Fees Module",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableFees
              ? "Yes"
              : "No",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableFees
              ? "has-text-success"
              : "has-text-danger",
          },
        },
        {
          label: "Standalone Kinvault Module",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableStandaloneKinvault
              ? "Yes"
              : "No",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.enableStandaloneKinvault
              ? "has-text-success"
              : "has-text-danger",
          },
        },
      ];
    },
    warnings(): Array<string> {
      const warnings: Array<string> = [];
      if (!this.introducerCompany) {
        return [];
      }

      if (
        !this.introducerCompany.companyPartnerPortalPermissions
          ?.allowPartnerPortal
      ) {
        warnings.push("Partner Portal is disabled");
      }

      if (
        this.introducerCompany.companyPartnerPortalPermissions
          ?.allowPartnerPortal &&
        !(
          this.introducerCompany.companyPartnerPortalPermissions
            ?.enableClients ||
          this.introducerCompany.companyPartnerPortalPermissions?.enableFees
        )
      ) {
        warnings.push("Partner Portal is enabled but no modules are enabled");
      }
      return warnings;
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const { introducerContact, $pagination } =
        // await window.Kernel.ActionBus.introducerCompany.contacts.read({
        await this.$actionBus.contact.ReadIntroducerContact({
          query: {
            companies: {
              id: Equal(this.$params.introducerCompany),
            },
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = introducerContact;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light": ["left", "dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-purple-light": ["dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-warning-light": scope.row.status?.value === "dormant",
      };
    },
    async editContactPartnerPortalSettings(
      row: IntroducerContact,
    ): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }
      let permissions = null;
      let isCreating = false;
      if (row.partnerPortalPermissions.isEmpty()) {
        permissions = CreateContactPartnerPortalPermissions({});
        permissions.company = this.introducerCompany;
        permissions.contact = row;
        isCreating = true;
      } else {
        permissions = row.partnerPortalPermissions.first();
      }

      if (!permissions) {
        return;
      }
      const contactPartnerPortalPermissions =
        await IntroducerContactPartnerPortalSettingsForm(permissions)
          .dialog({
            dialog: {
              title: "Partner Portal Permissions",
            },
          })
          .catch((error) => {
            permissions.$restore({});
            throw error;
          });

      if (isCreating) {
        // await window.Kernel.ActionBus.introducerCompany.contacts.partnerPortalPermissions.create(
        //   {
        //     contactPartnerPortalPermissions,
        //   },
        // );
        await this.$actionBus.contact.partnerPortalPermissions.CreateIntroducerContactPartnerPortal(
          contactPartnerPortalPermissions,
        );
      } else {
        // await window.Kernel.ActionBus.introducerCompany.contacts.partnerPortalPermissions.update(
        //   {
        //     contactPartnerPortalPermissions,
        //   },
        // );
        await this.$actionBus.contact.partnerPortalPermissions.UpdateIntroducerContactPartnerPortal(
          contactPartnerPortalPermissions,
        );
      }
      this.key++;
    },
    async deleteContactPartnerPortalSettings(row: IntroducerContact) {
      if (!this.introducerCompany) {
        return;
      }
      const partnerPortalPermissions = row.partnerPortalPermissions.first();
      if (!partnerPortalPermissions) {
        return;
      }
      await DangerDialog({
        dialog: {
          title: `Reset Permissions?`,
          message: `Are you sure you want to reset the permissions for ${row.profile.fullName}?`,
        },
      });

      // await window.Kernel.ActionBus.introducerCompany.contacts.partnerPortalPermissions.delete(
      //   {
      //     contactPartnerPortalPermissions: partnerPortalPermissions,
      //   },
      // );
      await this.$actionBus.contact.partnerPortalPermissions.DeleteIntroducerContactPartnerPortal(
        partnerPortalPermissions,
      );
      this.key++;
    },
  },
});
</script>

// AccountReferral

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";

export const DeleteAccountReferral = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/lead/{lead}/account-referral/{accountReferral}",
  method: "delete",
  parse: (accountReferral: AccountReferral) => ({
    params: {
      accountReferral,
      lead: accountReferral.$data.lead!,
    },
  }),
  after: ({ message }) => {
    AccountReferral.$delete(message);
  },
});

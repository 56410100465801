// IntroducerFeePaymentItem, IntroducerFeePayment, IntroducerFeePaymentRun, IntroducerFeePaymentRun, IntroducerFeePayment

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/model/introducer-fee-payment-item";

export const ReadIntroducerCompanyFees = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/fees/{introducerCompany}/fees",
  method: "get",
  rootModel: IntroducerFeePaymentItem,
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});

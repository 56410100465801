import { Api } from "@kinherit/sdk/api";
import { Order } from "@kinherit/sdk/index";

// Order
export const PushOrderToXero = async (order: Order) => {
  await Api.resource("portal-2", "/v2/portal-2/order/{order}/push-to-xero", {
    order,
  })
    .method("put")
    .result();
};

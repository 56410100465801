<template>
  <div v-if="!loading" class="introducer-company-details-referral-form">
    <Card title="Referral Form" subtitle="Link and QR code" class="mt-4">
      <p v-if="referralCodes.length === 0">
        No referral codes available for this company.
      </p>
      <ReferralQRCode
        v-for="referralCode in referralCodes"
        :key="referralCode.code"
        :code="referralCode.code"
        :title="referralCode.name"
        :file-name="referralCode.code"
      />
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsReferralFormWrapper",
  "route": "IntroducerCompanyDetailsReferralForm",
  "selector": ".introducer-company-details-referral-form"
}
</cypress-wrapper>

<script lang="ts">
import ReferralQRCode from "@/shared/component/misc/ReferralQRCode.vue";
import Card from "@kinherit/framework/component.layout/card";
import { AccountReferralCode } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsReferralFormRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsReferralFormRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    Card,
    ReferralQRCode,
  },
  data: () => ({
    loading: false,
    referralCodes: Array<AccountReferralCode>(),
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      this.loading = true;
      // const { referralCodes } =
      //   await window.Kernel.ActionBus.introducerCompany.referralCodes.read(
      //     this.$params,
      //   );
      const { accountReferralCode } =
        await this.$actionBus.company.referral.ReadAccountReferralCodes({
          message: this.$params.introducerCompany,
          pagination: false,
        });

      this.referralCodes = accountReferralCode;
      this.loading = false;
    },
  },
});
</script>

<template>
  <div class="branded-kinvault-details-subscriptions">
    <Message
      v-if="!useBrandedKinvaultSubscriptions"
      title="Warning"
      :color="'is-warning'"
      size="is-small"
      class="mb-4 mt-4"
    >
      <b>Subscriptions are not enabled for this branded kinvault.</b>
      <br />
      <small>
        To enable subscriptions, update the branded kinvault settings
      </small>
    </Message>
    <MasterListPage
      v-if="brandedKinvault"
      :filters="$data.form"
      :columns="columns"
      :rows="rows"
      is-nested
      title="Subscriptions"
      subtitle="Manage subscriptions for this branded kinvault: subscriptions are where the end user has signed up as is paying via stripe"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template
        #subscription="{ row }: { row: BrandedKinvaultSubscriptionReferral }"
      >
        <b>{{ row.subscription?.kintinPrice?.title }}</b>
        <br />
        <small class="is-inline-block pt-1">
          {{ row.subscription?.kintinPrice?.price?.toFormattedString(true) }}
        </small>
      </template>
      <template #status="{ row }">
        <span
          v-if="row.subscription?.status?.value === 'new'"
          class="badge is-info"
        >
          New
        </span>
        <span
          v-else-if="row.subscription?.status?.value === 'active'"
          class="badge is-primary"
        >
          Active
        </span>
        <span
          v-else-if="row.subscription?.status?.value === 'complete'"
          class="badge is-success"
        >
          Complete
        </span>
        <span
          v-else-if="row.subscription?.status?.value === 'inprogress'"
          class="badge is-warning"
        >
          In Progress
        </span>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsSubscriptionsWrapper",
  "route": "BrandedKinvaultDetailsSubscriptions",
  "selector": ".branded-kinvault-details-subscriptions",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadSubscriptionReferralFormWrapper": "@/module/admin.branded-kinvault/form/read-subscription-referral.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadSubscriptionReferralFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadSubscriptionReferralForm } from "@/module/admin.branded-kinvault/form/read-subscription-referral.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  BrandedKinvaultSubscriptionReferral,
  IBrandedKinvaultSubscriptionReferral,
  QueryMask,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsSubscriptionsRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

import Message from "@kinherit/framework/component.display/message";
import { Between, Equal, IsNotNull, Like } from "@kinherit/orm/index";
export default defineComponent({
  name: BrandedKinvaultDetailsSubscriptionsRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: { MasterListPage, Message },
  data: () => ({
    form: ReadSubscriptionReferralForm(),
    columns: [
      {
        title: "Subscription",
        slot: "subscription",
      },
      {
        title: "Email",
        map: (row) => row.subscription?.email,
      },
      {
        title: "Status",
        slot: "status",
      },
      {
        title: "Referral code",
        map: (row) => row.referralCode?.code,
      },
      {
        title: "Created",
        map: (row) => row.subscription?.createdAt.formatYMD,
      },
      {
        title: "Cancelled",
        map: (row) => row.subscription?.cancelledAt?.formatYMD,
      },
    ] satisfies TableColumn<BrandedKinvaultSubscriptionReferral>[],
    rows: Array<BrandedKinvaultSubscriptionReferral>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultSubscriptionReferral,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    useBrandedKinvaultSubscriptions(): boolean {
      return this.brandedKinvault?.allowPublicRegistration ?? false;
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      // const { brandedKinvaultSubscriptionReferrals, pagination } =
      //   await window.Kernel.ActionBus.adminBrandedKinvault.subscriptionReferral.read(
      //     {
      //       ...this.form.localData,
      //       brandedKinvault: this.$params.brandedKinvault,
      //       sort: this.sort,
      //       pagination: this.pagination,
      //     },
      //   );
      const formData = this.form.localData;

      let query: QueryMask<typeof BrandedKinvaultSubscriptionReferral>;

      const shared = {
        referralCode: {
          brandedKinvault: Equal(this.brandedKinvault?.id),
        },
        createdAt: Between(formData.created),
        subscription: {
          id: IsNotNull(),
          status: {
            id: Equal(formData.status),
          },
        },
      };

      if (!formData.search) {
        query = {
          ...shared,
        };
      } else {
        query = [
          {
            ...shared,
            subscriptionIntent: {
              primaryProfile: {
                fullName: Like(formData.search),
              },
            },
          },
          {
            ...shared,
            subscriptionIntent: {
              primaryProfile: {
                emails: {
                  email: Like(formData.search),
                },
              },
            },
          },
          {
            ...shared,
            subscriptionIntent: {
              secondaryProfile: {
                fullName: Like(formData.search),
              },
            },
          },
          {
            ...shared,
            subscriptionIntent: {
              secondaryProfile: {
                emails: {
                  email: Like(formData.search),
                },
              },
            },
          },
        ];
      }

      const { brandedKinvaultSubscriptionReferral, $pagination } =
        await this.$actionBus.subscriptionReferral.ReadBrandedKinvaultSubscriptionReferral(
          {
            query,
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.rows = brandedKinvaultSubscriptionReferral;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    handleClick(
      BrandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral,
      event: MouseEvent,
    ): void {
      // @todo
      console.log(BrandedKinvaultSubscriptionReferral, event);
    },
  },
});
</script>

import { Api } from "@kinherit/sdk/api";
import { Kintin, KintinAccess } from "@kinherit/sdk/index";

// KintinAccess
export const RevokeAccess = async (message: { kintin: string | Kintin }) => {
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/status/revoke-assigned-users-access",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    kintinAccess: KintinAccess.$inflate(response.kintinAccess),
  };
};

// DefaultBrandedKinvaultReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const ReadDefaultBrandedKinvaultReferralCode = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/default-branded-kinvault-referral-code",
  method: "get",
  rootModel: DefaultBrandedKinvaultReferralCode,
  parse: () => ({}),
});

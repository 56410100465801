import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerCompanyPartnerPortal } from "./create-introducer-company-partner-portal.action";
import { ReadIntroducerCompanyPartnerPortal } from "./read-introducer-company-partner-portal.action";
import { UpdateIntroducerCompanyPartnerPortal } from "./update-introducer-company-partner-portal.action";

export const Portal2IntroducerCompanyPartnerPortalPermissions = DefineActionBus(
  {
    name: "portal-2-introducer-company-partner-portal-permissions",
    actions: {
      CreateIntroducerCompanyPartnerPortal,
      ReadIntroducerCompanyPartnerPortal,
      UpdateIntroducerCompanyPartnerPortal,
    },
  },
);

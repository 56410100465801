import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateAccountReferral } from "./create-account-referral.action";
import { DeleteAccountReferral } from "./delete-account-referral.action";
import { ReadAccountReferral } from "./read-account-referral.action";
import { RecordAccountReferral } from "./record-account-referral.action";
import { UpdateAccountReferral } from "./update-account-referral.action";

export const Portal2KinvaultAccountReferral = DefineActionBus({
  name: "portal-2-kinvault-account-referral",
  actions: {
    CreateAccountReferral,
    DeleteAccountReferral,
    ReadAccountReferral,
    UpdateAccountReferral,
    RecordAccountReferral,
  },
});

// Kintin, Profile, AccountReferral, AccountReferralCode, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadKintin = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/escalated",
  method: "get",
  rootModel: Kintin,
  parse: () => ({}),
});

// IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const ReadIntroducerCompany = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/introducer-company",
  method: "get",
  rootModel: IntroducerCompany,
  parse: () => ({}),
});

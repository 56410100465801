// Lead, Profile, EmailAddress, PhoneNumber, AccountReferral

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Lead } from "@kinherit/sdk/model/lead";

export const CreateLead = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/leads",
  method: "post",
  parse: (target: Lead) => ({
    body: {
      assignedTo: target.$data.assignedTo,
      description: target.$data.description,
      ownedBy: target.$data.ownedBy,
      status: target.$data.status,
      profile: {
        firstName: target.profile.$data.firstName,
        lastName: target.profile.$data.lastName,
        phoneNumbers: target.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
        })),
        emails: target.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
      },
      referral: {
        contact: target.referral?.contact?.$data.id,
        referralCode: target.referral?.referralCode?.$data.id,
        externalId: target.referral?.$data.externalId,
      },
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: {
        emails: true,
        phoneNumbers: true,
      },
      referral: true,
    });
  },
});

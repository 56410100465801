<template>
  <SidebarPage
    v-if="loaded"
    class="knowledge-base-wrapper"
    name="Knowledge Base"
    title="Knowledge Base"
    :has-context-menu="true"
    @context-menu="contextMenu"
    :show-sidebar="false"
  >
    <template #below>
      <Card v-if="topLevelCategories.length > 0" class="mt-2">
        <Button
          :text="`All`"
          size="is-small"
          is-rounded
          class="mr-1 mb-1"
          @click="handleAllClick($event)"
        />
        <Button
          v-for="(category, key) in topLevelCategories"
          :key="key"
          :text="`${category.title}`"
          size="is-small"
          is-rounded
          :class="[
            category.id === $route.params.topLevelCategory ? 'is-primary' : '',
            'mr-1',
            'mb-1',
            'top-level-category-button',
          ]"
          @click="handleSectionClick(category as KnowledgeBaseCategory, $event)"
        />
      </Card>
    </template>
    <template #default>
      <slot name="content"> </slot>
    </template>
  </SidebarPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseWrapper",
  "selector": ".knowledge-base-wrapper",
  "imports": {
    "UpdateKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-category.form.test"
  },
  "methods": {
    "buttons": {
      "type": "to-many",
      "selector": ".top-level-category-button"
    },
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Create Section", "Update Section", "Delete Section"]
    },
    "updateSectionForm": {
      "type": "to-one",
      "wrapper": "UpdateKnowledgeBaseCategoryFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateKnowledgeBaseCategory } from "@/config/model.config";
import { UpdateKnowledgeBaseCategoryForm } from "@/module/knowledge-base/form/update-knowledge-base-category.form";
import { KnowledgeBaseCategoryMixin } from "@/module/knowledge-base/mixin/knowledge-base-category.mixin";
import {
  KnowledgeBaseRoute,
  KnowledgeBaseTopLevelCategoryDetailsParams,
  KnowledgeBaseTopLevelCategoryDetailsRoute,
} from "@/module/knowledge-base/page";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { IsNull } from "@kinherit/orm/index";
import { KnowledgeBaseCategory } from "@kinherit/sdk/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KnowledgeBaseWrapper",
  components: { SidebarPage, Card, Button },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.knowledgeBase),
    KnowledgeBaseCategoryMixin(),
  ],
  data: () => ({
    loaded: false,
    topLevelCategories: Array<KnowledgeBaseCategory>(),
  }),
  async beforeMount() {
    await this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const data = await this.$actionBus.ReadKnowledgeBaseCategory({
        query: [
          {
            parentCategory: IsNull(),
          },
        ],
      });

      this.topLevelCategories = data.knowledgeBaseCategory.sort((a, b) => {
        const titleA = a.title || "";
        const titleB = b.title || "";
        return titleA.localeCompare(titleB);
      });

      this.loaded = true;
    },
    contextMenu(): void {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          titleField: "title",
          trackingField: "title",
          iconLeftField: "icon",
          line1Field: "line1",
          items: [
            {
              title: "Create Section",
              icon: StyleService.icon.add.icon,
              line1: "Create top level category",
              permission: "knowledge-base-category:write",
              action: this.addSection,
            },
            {
              title: "Update Section",
              icon: StyleService.icon.edit.icon,
              line1: "Update top level category",
              permission: "knowledge-base-category:write",
              action: this.updateSection,
            },
            {
              title: "Delete Section",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this top level category",
              permission: "knowledge-base-category:delete",
              action: this.deleteSection,
            },
          ],
        },
        path: "permission",
        filter: "all",
        callback: (item) => item.action(),
      });

      contextMenu();
    },
    handleAllClick(event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseRoute,
        },
        event.ctrlKey,
      );
    },
    handleSectionClick(
      category: KnowledgeBaseCategory,
      event: MouseEvent,
    ): void {
      const params: KnowledgeBaseTopLevelCategoryDetailsParams = {
        topLevelCategory: category.id,
      };

      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseTopLevelCategoryDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async addSection(): Promise<void> {
      const knowledgeBaseTopLevelCategory =
        await UpdateKnowledgeBaseCategoryForm({
          category: CreateKnowledgeBaseCategory(),
          isTopLevel: true,
        }).dialog({
          dialog: {
            title: "Create New Section",
          },
        });

      knowledgeBaseTopLevelCategory.$persist();

      await this.$actionBus.CreateKnowledgeBaseCategory(
        knowledgeBaseTopLevelCategory,
      );

      this.refresh();
    },
    async updateSection(): Promise<void> {
      if (!this.knowledgeBaseTopLevelCategory) {
        return;
      }

      try {
        await UpdateKnowledgeBaseCategoryForm({
          category: this.knowledgeBaseTopLevelCategory,
          isTopLevel: true,
        }).dialog({
          dialog: {
            title: "Update Section",
          },
        });
      } catch (error) {
        this.knowledgeBaseTopLevelCategory.$restore();
        throw error;
      }

      await this.$actionBus.category.UpdateKnowledgeBaseCategory(
        this.knowledgeBaseTopLevelCategory,
      );

      this.refresh();
    },
    async deleteSection(): Promise<void> {
      if (!this.knowledgeBaseTopLevelCategory) {
        return;
      }
      await DangerDialog({
        dialog: {
          title: "Delete Section",
          message: `Warning: This will recursively delete all sub categories in this section: Are you sure you want to delete the ${this.knowledgeBaseTopLevelCategory.title} section?`,
        },
      });

      await this.$actionBus.category.DeleteKnowledgeBaseCategory(
        this.knowledgeBaseTopLevelCategory,
      );

      this.$router.push({ name: KnowledgeBaseRoute });
    },
  },
});
</script>

// IntroducerFeePaymentItem, IntroducerFeePaymentRun, IntroducerFeePayment, Order, XeroContact
import { Api } from "@/service/api.service";

export interface CreateIntroducerFeePaymentsMessage {
  feeType: "introducerFee" | "agentFee" | "parentAgentFee";
  /** Introducer fee payment run id */
  introducerFeePaymentRun: string;
  /** Orders ids */
  orders: string[];
  /** Xero contact id */
  xeroContact: string;
}

export interface CreateIntroducerFeePaymentsResponse {}

export const PopulateIntroducerFeePaymentRun = async (
  message: CreateIntroducerFeePaymentsMessage,
): Promise<CreateIntroducerFeePaymentsResponse> => {
  await Api.resource(
    "portal-2",
    "/v2/portal-2/introducer/fees/payment-run/populate-payment-run",
  )
    .method("post")
    .body({
      feeType: message.feeType,
      introducerFeePaymentRun: message.introducerFeePaymentRun,
      orders: message.orders,
      xeroContact: message.xeroContact,
    })
    .result();

  return {};
};

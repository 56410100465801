// CallScript

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { CallScript } from "@kinherit/sdk/model/call-script";

export const CreateCallScript = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/call-script",
  method: "post",
  parse: (target: CallScript) => ({
    body: {
      person2Present: target.$data.person2Present,
      advSavingsValue: target.$data.advSavingsValue,
      advDebtValue: target.$data.advDebtValue,
      advCashValue: target.$data.advCashValue,
      advPolicyValue: target.$data.advPolicyValue,
      advPensionValue: target.$data.advPensionValue,
      advOtherAssetValue: target.$data.advOtherAssetValue,
      advBusinessAssetValue: target.$data.advBusinessAssetValue,
      advPotentialValue: target.$data.advPotentialValue,
      advPropsValue: target.$data.advPropsValue,
      requiresDeedOfSev: target.$data.requiresDeedOfSev,
      requiresPoa: target.$data.requiresPoa,
      requiresAssetProtection: target.$data.requiresAssetProtection,
      beneficiaryAge: target.$data.beneficiaryAge,
      mowGuardians: target.$data.mowGuardians,
      advGuardianNotes: target.$data.advGuardianNotes,
      advBeneficiaryNotes: target.$data.advBeneficiaryNotes,
      advExclusionNotes: target.$data.advExclusionNotes,
      advIfaNotes: target.$data.advIfaNotes,
      advGiftNotes: target.$data.advGiftNotes,
      advExecutorNotes: target.$data.advExecutorNotes,
      advTrusteenNotes: target.$data.advTrusteenNotes,
      advAttorneyenNotes: target.$data.advAttorneyenNotes,
      advHteStateBene: target.$data.advHteStateBene,
      advHteStateValue: target.$data.advHteStateValue,
      advR2RNotes: target.$data.advR2RNotes,
      advBusinessNotes: target.$data.advBusinessNotes,
      advIhteStateSitu: target.$data.advIhteStateSitu,
    },
    params: {
      kintin: target.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

// EmailCampaign, Profile

import { Api } from "@/service/api.service";
import { CpdSession, EmailCampaign } from "@kinherit/sdk";

export interface CreateEmailCampaignMessage {
  cpdSession: CpdSession;
  emailCampaign: EmailCampaign;
  include: "registrations" | "participants" | "both";
}

export interface CreateEmailCampaignResponse {
  emailCampaign: EmailCampaign;
}

export const CreateEmailCampaign = async (
  message: CreateEmailCampaignMessage,
): Promise<CreateEmailCampaignResponse> => {
  const request = Api.resource(
    "portal-2",
    "/v2/portal-2/cpd/{cpdSession}/email-campaign/{members}",
    {
      cpdSession: message.cpdSession,
      members: message.include,
    },
  )
    .method("post")

    .body({
      name: message.emailCampaign.name,
      description: message.emailCampaign.description,
    });

  const response = await request.result();

  message.emailCampaign.$delete({
    address: true,
  });

  message.emailCampaign.$delete();

  const emailCampaign = EmailCampaign.$inflate(response.emailCampaign).first();

  if (!emailCampaign) {
    throw new Error(`Failed to create email campaign`);
  }

  return {
    emailCampaign,
  };
};

<template>
  <Card title="Status" :subtitle="externalId">
    <Message
      v-if="caseData && milestonesFromSortReferData.length === 0"
      title="Warning"
      :color="'is-warning'"
      size="is-small"
      class="mb-4 mt-4"
    >
      <span class="is-block">
        <b>Unable to get Milestone Data</b>
        <br />
        <small> This may be an invalid SortRefer Case Reference </small>
      </span>
    </Message>
    <Button icon-left="Reset" class="mr-1" @click="getSortReferCase"
      >Manually Refresh
    </Button>
    <Button
      :is-disabled="!caseHandlerUserId"
      class="mr-1"
      @click="updateCaseHandler"
      >Update Case Handler</Button
    >
    <Button
      :is-disabled="!caseReference"
      class="mr-1"
      @click="updateCaseReference"
      >Update Case Reference</Button
    >
    <Button icon-left="Plus" @click="addSortReferNote">Add a Note </Button>
  </Card>
  <Card
    title="Milestones"
    subtitle="Update milestones manually by our account status"
  >
    <Button
      v-for="stage in kintinStageOptions"
      :key="stage.id"
      class="mr-2"
      @click="updateMilestoneByStage(stage.id)"
      >{{ stage.value }}</Button
    >
  </Card>
  <Card title="Milestones" subtitle="As completed on SortRefer">
    <p v-if="milestonesFromSortReferData.length === 0">
      No milestones have been completed
    </p>
    <ButtonListWidget :items="milestoneButtonList" />
  </Card>
  <Card title="Account Status" subtitle="Manually Update via Case Status">
    <ButtonListWidget :items="accountStatusButtonList" />
  </Card>
  <Card title="Lead Status" subtitle="Manually Update via Lead Status">
    <ButtonListWidget :items="leadStatusButtonList" />
  </Card>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import ButtonListWidget, {
  TButtonListWidgetItem,
} from "@/shared/component/widget/ButtonListWidget.vue";
import { CreateSortReferNoteForm } from "@/shared/form/external/sort-refer/create-sort-refer-note.form";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Option } from "@kinherit/sdk/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SortReferStatusPage",
  components: { Card, Button, ButtonListWidget, Message },
  props: {
    // i.e SortRefer Case Reference 781-134603-160522-WI
    externalId: {
      type: String,
      required: true,
    },
    // If lead, then lead.ownedBy, if kintin, then first estatePlanner
    caseHandlerUserId: {
      type: String,
      required: false,
    },
    // if kintiin, then ktref
    caseReference: {
      type: String,
      required: false,
    },
    //  kintin stageId
    newKintinStageId: {
      type: String,
      required: false,
    },
    // Either lead or kintin statusId
    newStatusId: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    completeStatusId: null as string | null,
    notnowStatusId: null as string | null,
    closedStatusId: null as string | null,
    leadDeadLeadStatusId: null as string | null,
    leadDuplicateStatusId: null as string | null,
    leadJunkStatusId: null as string | null,
    leadQualifiedOutStatusId: null as string | null,
    leadUCNoTelStatusId: null as string | null,
    leadUCWithTelStatusId: null as string | null,
    kintinStageOptions: [] as Option[],
    caseData: null,
  }),
  computed: {
    accountStatusButtonList() {
      return [
        {
          title: "Mark Account: Complete",
          subTitle:
            "Non reversable: All milestones need to be complete before this can be called",
          color: "is-success",
          icon: "Tick",
          onClick: () => this.updateStatus(this.completeStatusId!),
        },
        {
          title: "Mark Account: Not Now",
          color: "is-warning",
          icon: "Clock",
          subTitle:
            "Suspends case and adds Note: Not a good time for the client, follow up later",
          onClick: () => this.updateStatus(this.notnowStatusId!),
        },
        {
          title: "Mark Account: Closed",
          color: "is-danger",
          icon: "Trash",
          subTitle:
            "Cancels the case and adds Note: Client decided not to proceed",
          onClick: () => this.updateStatus(this.closedStatusId!),
        },
      ] as TButtonListWidgetItem[];
    },
    leadStatusButtonList() {
      return [
        {
          title: "Mark Lead: Dead Lead",
          subTitle: "Cancels the case and adds Note: Lost Opportunity",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadDeadLeadStatusId!),
        },
        {
          title: "Mark Lead: Duplicate",
          subTitle: "Declines the case and adds Note: Duplicate Contact",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadDuplicateStatusId!),
        },
        {
          title: "Mark Lead: Junk",
          subTitle: "Declinies the case and adds Note: Spam or Other test",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadJunkStatusId!),
        },
        {
          title: "Mark Lead: Qualified Out",
          subTitle:
            "Closes the case and adds Note: Client is not interested in our services",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadQualifiedOutStatusId!),
        },
        {
          title: "Mark Lead: UC No Tel",
          subTitle:
            "Closes the case and adds Note: Client was uncontactable after multiple attempts",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadUCNoTelStatusId!),
        },
        {
          title: "Mark Lead: UC With Tel",
          subTitle:
            "Closes the case and adds Note: Client was uncontactable after multiple attempts",
          color: "is-danger",
          icon: "Trash",
          onClick: () => this.updateStatus(this.leadUCWithTelStatusId!),
        },
      ] as TButtonListWidgetItem[];
    },
    milestonesFromSortReferData() {
      const sortReferMilestones = [
        {
          title: "Case Accepted",
          eventId: "619",
        },
        {
          title: "Client contacted and initial appointment booked",
          eventId: "620",
        },
        {
          title: "Letter of engagement sent to client",
          eventId: "621",
        },
        {
          title: "Appointment completed",
          eventId: "622",
        },
        {
          title: "Invoice given to the client and payment received",
          eventId: "623",
        },
        {
          title: "Draft will sent to the client",
          eventId: "624",
        },
        {
          title: "Draft will approved by client",
          eventId: "625",
        },
        {
          title: "Final will sent to client - Case completed",
          eventId: "626",
        },
      ];
      // @ts-ignore
      if (!this.caseData?.Instruction?.TrackingEvents) {
        return [];
      }

      // @ts-ignore
      return this.caseData.Instruction.TrackingEvents[0].TrackingEvent.map(
        (event: any) => ({
          title:
            sortReferMilestones.find(
              (milestone) => milestone.eventId === event.eventId[0],
            )?.title || "Unknown",
          eventId: event.eventId[0],
          status: event.status[0],
          date: event.date[0],
        }),
      );
    },
    milestoneButtonList() {
      return this.milestonesFromSortReferData.map(
        (milestone: {
          eventId: string;
          title: string;
          status: string;
          date: string;
        }) => {
          const color =
            milestone.status === "complete" ? "is-success" : "is-danger";
          const subTitle = milestone.date ? milestone.date : "Not Yet Complete";
          return {
            eventId: milestone.eventId,
            title: milestone.title,
            subTitle,
            color,
            icon: milestone.status === "complete" ? "Tick" : "Clock",
            onClick: () => this.updateMilestone(milestone.eventId),
          };
        },
      );
    },
  },
  mounted() {
    this.getSortReferCase();

    this.kintinStageOptions = Option.$findBy({
      group: "kintinStage",
    });

    const leadStatusOptions = Option.$findBy({
      group: "leadStatus",
    });

    const kintinStatusOptions = Option.$findBy({
      group: "kintinStatus",
    });

    this.completeStatusId = kintinStatusOptions.findByOrThrow(
      "value",
      "complete",
    )?.id;

    this.notnowStatusId = kintinStatusOptions.findByOrThrow(
      "value",
      "notnow",
    )?.id;

    this.closedStatusId = kintinStatusOptions.findByOrThrow(
      "value",
      "closed",
    )?.id;

    this.leadDeadLeadStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "deadlead",
    )?.id;

    this.leadDuplicateStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "duplicate",
    )?.id;

    this.leadJunkStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "junk",
    )?.id;

    this.leadQualifiedOutStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "qualifiedout",
    )?.id;

    this.leadUCNoTelStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "uc_no_tel",
    )?.id;

    this.leadUCWithTelStatusId = leadStatusOptions.findByOrThrow(
      "value",
      "uc_with_tel",
    )?.id;
  },
  methods: {
    async getSortReferCase() {
      // @ts-ignore
      this.caseData = await window.Kernel.ActionBus2.portal2.sortRefer.getCase({
        externalId: this.externalId,
      });
    },
    async updateCaseHandler() {
      if (!this.caseHandlerUserId) {
        console.error("caseHandlerUserId is required");
        return;
      }
      await window.Kernel.ActionBus2.portal2.sortRefer.updateCase({
        externalId: this.externalId,
        userId: this.caseHandlerUserId,
      });
    },
    async updateCaseReference() {
      if (!this.caseReference) {
        console.error("caseReference is required");
        return;
      }
      await window.Kernel.ActionBus2.portal2.sortRefer.updateCaseReference({
        externalId: this.externalId,
        ref: this.caseReference,
      });
    },
    async updateMilestoneByStage(kintinStageId?: string) {
      const kintinStage = kintinStageId || this.newKintinStageId;
      if (!kintinStage) {
        console.error("newKintinStage is required");
        return;
      }
      await window.Kernel.ActionBus2.portal2.sortRefer.updateMilestoneByStage({
        externalId: this.externalId,
        newKintinStageId: kintinStage,
      });
    },
    async updateMilestone(eventId: string) {
      await window.Kernel.ActionBus2.portal2.sortRefer.updateMilestone({
        externalId: this.externalId,
        eventId: eventId,
      });
    },
    async updateStatus(newStatusId?: string) {
      const statusId = newStatusId || this.newStatusId;

      if (!statusId) {
        console.error("newStatusId is required");
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Are you sure?",
          message: "This action update the status of the case on SortRefer",
        },
      });

      await window.Kernel.ActionBus2.portal2.sortRefer.updateStatus({
        externalId: this.externalId,
        newStatusId: statusId,
      });
    },
    async addSortReferNote() {
      // await window.Kernel.ActionBus2.portal2.sortRefer.addNote({
      //   externalId: this.externalId,
      // });
      const { note } = await CreateSortReferNoteForm().dialog({
        dialog: {
          title: "Add SortRefer Note",
        },
        button: {
          ok: {
            text: "Create Note",
          },
        },
      });

      if (note) {
        await window.Kernel.ActionBus2.portal2.sortRefer.createNote({
          externalId: this.externalId,
          note: note,
        });
      }
    },
  },
});
</script>

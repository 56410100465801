// IntroducerContract

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContract } from "@kinherit/sdk/model/introducer-contract";

export const RecordIntroducerContract = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contract/{introducerContract}",
  method: "get",
  rootModel: IntroducerContract,
  parse: (introducerContract: IntroducerContract | string) => ({
    params: {
      introducerContract,
    },
  }),
});

import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadAccountReferralCode } from "./read-account-referral-code.action";
import { ReadAddress } from "./read-address.action";
import { ReadAdviser } from "./read-adviser.action";
import { ReadBrandedKinvaultMembership } from "./read-branded-kinvault-membership.action";
import { ReadBrandedKinvaultSubscriptionReferralCode } from "./read-branded-kinvault-subscription-referral-code.action";
import { ReadBrandedKinvault } from "./read-branded-kinvault.action";
import { ReadCheck } from "./read-check.action";
import { ReadDefaultBrandedKinvaultReferralCode } from "./read-default-branded-kinvault-referral-code.action";
import { ReadEmailAddress } from "./read-email-address.action";
import { ReadEmailTemplate } from "./read-email-template.action";
import { ReadIntroducerCompany } from "./read-introducer-company.action";
import { ReadIntroducerContact } from "./read-introducer-contact.action";
import { ReadIntroducerContract } from "./read-introducer-contract.action";
import { ReadIntroducerFeePaymentRun } from "./read-introducer-fee-payment-run.action";
import { ReadIntroducerNetwork } from "./read-introducer-network.action";
import { ReadKintinAddress } from "./read-kintin-address.action";
import { ReadKintin } from "./read-kintin.action";
import { ReadKnowledgeBaseCategory } from "./read-knowledge-base-category.action";
import { ReadOrder } from "./read-order.action";
import { ReadPerson } from "./read-person.action";
import { ReadProduct } from "./read-product.action";
import { ReadProperty } from "./read-property.action";
import { ReadRole } from "./read-role.action";
import { ReadTag } from "./read-tag.action";
import { ReadUser } from "./read-user.action";
import { ReadWillRevision } from "./read-will-revision.action";
import { ReadXeroContact } from "./read-xero-contact.action";

export const CoreSelect = DefineActionBus({
  name: "core-select",
  actions: {
    ReadIntroducerFeePaymentRun,
    ReadXeroContact,
    ReadWillRevision,
    ReadPerson,
    ReadAddress,
    ReadKintinAddress,
    ReadUser,
    ReadAdviser,
    ReadKintin,
    ReadBrandedKinvault,
    ReadIntroducerCompany,
    ReadCheck,
    ReadRole,
    ReadTag,
    ReadIntroducerContract,
    ReadKnowledgeBaseCategory,
    ReadIntroducerContact,
    ReadProduct,
    ReadBrandedKinvaultMembership,
    ReadEmailTemplate,
    ReadEmailAddress,
    ReadProperty,
    ReadAccountReferralCode,
    readDefaultBrandedKinvaultReferralCode:
      ReadDefaultBrandedKinvaultReferralCode,
    readBrandedKinvaultSubscriptionReferralCode:
      ReadBrandedKinvaultSubscriptionReferralCode,
    ReadOrder,
    ReadIntroducerNetwork,
  },
});

<template>
  <div class="kintin-details-post-payment">
    <PageHeader htag="h2" text="Post Payment">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          text="Create Post Payment"
          class="create-post-payment"
          @click="$emit('create-post-payment')"
        />
      </template>
    </PageHeader>
    <Card>
      <TextField :value="signUrl" message="Post payment Sign App URL">
        <template #right>
          <Button
            icon-left="Copy"
            @click="copyUrl"
            aria-label="Copy Sign URL"
            tooltip="Copy Sign URL"
          />
        </template>
      </TextField>
    </Card>
    <MasterListPage
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      is-nested
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <!--template #expand="{ row }">
      <Button v-if="null !== row.signedAt" :text="`Toggle Signing Info`" />
    </template-->
      <template #details="{ row }">
        <span v-if="null === row.signedAt">
          <span class="has-text-danger">Not Signed</span>
        </span>
        <span v-else>
          <p class="mb-1"><b>ID:</b> {{ row.id }}</p>
          <p class="mb-1"><b>Signed on:</b> {{ row.signedAt?.formatDate }}</p>
          <p class="mb-1"><b>IP Address:</b> {{ row.ipAddress }}</p>
          <p><b>User Agent:</b> {{ row.userAgent }}</p>
        </span>
      </template>

      <template #status="{ row }">
        <span v-if="row.type.value === 'identitystripe'">
          <Badge
            v-if="row.data?.status === 'verified'"
            is-compact
            color="is-success"
            >Verified</Badge
          ><Badge
            v-if="row.data?.status !== 'verified'"
            is-compact
            color="is-warning"
            >{{ (row.data?.status as string)?.ucFirst() ?? "Pending" }}</Badge
          >
        </span>
        <span v-else>
          <Badge
            v-if="row.signedAt"
            is-compact
            color="is-success"
            text="Signed"
          />
          <Badge
            v-if="null === row.signedAt"
            is-compact
            color="is-warning"
            text="Pending"
          />
        </span>
      </template>
      <template #actions="{ row }">
        <Button
          v-if="row.type.value === 'customdisclaimer'"
          :icon-left="$style.icon.edit.icon"
          tooltip="View HTML"
          color="is-plain"
          @click="preview(row)"
        />
        <Button
          v-if="row.type.value === 'customdisclaimer'"
          :icon-left="$style.icon.download.icon"
          tooltip="Download"
          color="is-plain"
          @click="download(row)"
        />
        <Button
          v-if="row.type.value === 'customdisclaimer'"
          :icon-left="$style.icon.settings.icon"
          tooltip="Settings"
          color="is-plain"
          @click="settings(row)"
        />
        <Button
          v-if="row.type.value === 'contacts'"
          :icon-left="$style.icon.contact.icon"
          tooltip="Merge Contacts"
          is-outlined
          color="is-positive"
          @click="mergeContacts(row)"
          >Merge Data</Button
        >
        <Button
          v-if="hasWritePermission"
          :icon-left="$style.icon.delete.icon"
          class="delete-button mr-1"
          ariaLabel="Delete"
          color="is-plain"
          @click="deleteRow(row)"
        />
        <Button
          v-if="
            row.type.value === 'identitystripe' &&
            ['verified', 'requires_input'].includes(row.data?.status)
          "
          :icon-left="$style.icon.personTie.icon"
          tooltip="View Identity Result"
          color="is-plain"
          @click="goToVerificationSession(row)"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsPostPaymentWrapper",
  "route": "KinvaultKintinDetailsPostPayment",
  "selector": ".kintin-details-post-payment",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "createPostPayment": {
      "type": "context-menu",
      "selector": ".create-post-payment",
      "items": [
        "Contacts Signoff",
        "Disclaimer",
        "Identity Verification",
        "Direct Debit Request",
        "Document Creation Signoff"
      ]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { PostPaymentTableColumns } from "@/config/table-columns.config";
import { UpdateSignDocDisclaimerForm } from "@/module/kinvault.kintin/form/update-sign-doc-disclaimer.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Badge from "@kinherit/framework/component.display/badge";
import { Button } from "@kinherit/framework/component.input/button";
import { TextField } from "@kinherit/framework/component.input/text-field";
import Card from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
import { Equal } from "@kinherit/orm/index";
import { ISignDoc, SignDoc } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KinvaultKintinDetailsPostPaymentMergeContactsParams,
  KinvaultKintinDetailsPostPaymentMergeContactsRoute,
  KinvaultKintinDetailsPostPaymentRoute,
  KinvaultKintinDetailsPostPaymentVerificationSessionParams,
  KinvaultKintinDetailsPostPaymentVerificationSessionRoute,
} from ".";

export default defineComponent({
  name: KinvaultKintinDetailsPostPaymentRoute,
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin(), StyleService.mixin],
  components: {
    MasterListPage,
    Button,
    PageHeader,
    TextField,
    Card,
    Badge,
  },
  emits: ["create-post-payment"],
  data: () => ({
    columns: PostPaymentTableColumns,
    rows: Array<SignDoc>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ISignDoc,
      direction: "desc" as "asc" | "desc",
    },
    signUrl: "",
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("kintin:write");
    },
  },
  async mounted() {
    // await window.Kernel.ActionBus.core.environment.getSignAppUrl();
    const url = await window.Kernel.ActionBus2.core.SignUrl();

    this.signUrl = `${url}/login/${this.kintin?.ref}`;
  },
  methods: {
    copyUrl(): void {
      ClipBoardService.copyTextToClipBoard(this.signUrl, {
        wrapLinks: false,
        showSnackbar: true,
      });
    },
    async refresh(): Promise<void> {
      // await window.Kernel.ActionBus.kinvaultKintin.signDoc.read({
      const { signDoc, $pagination } =
        await this.$actionBus.signDoc.ReadSignDoc({
          message: this.$params.kintin,
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = signDoc;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    async handleClick(signDoc: SignDoc): Promise<void> {
      const item = await OpenContextMenu({
        items: [
          {
            title: "Preview",
            line1: "Open the disclaimer in a new tab",
            hide: signDoc.type.value !== "customdisclaimer",
            action: this.preview,
            icon: StyleService.icon.signFile.icon,
          },
          {
            title: "Download",
            line1: "Download the disclaimer",
            hide: signDoc.type.value !== "customdisclaimer",
            action: this.download,
            icon: StyleService.icon.download.icon,
          },
          {
            title: "Settings",
            line1: "View the disclaimers settings",
            hide: signDoc.type.value !== "customdisclaimer",
            action: this.settings,
            icon: StyleService.icon.edit.icon,
          },
          {
            title: "Merge Contacts",
            line1: "Merge the changes to the contact",
            hide: signDoc.type.value !== "contacts",
            action: this.mergeContacts,
            icon: StyleService.icon.contact.icon,
          },
          {
            title: "Delete",
            line1: "Delete this post payment",
            hide: !this.hasWritePermission,
            action: this.deleteRow,
            icon: StyleService.icon.delete.icon,
          },
        ],
        titleField: "title",
        trackingField: "tracking",
        hideField: "hide",
        iconLeftField: "icon",
        line1Field: "line1",
      });

      await item.action(signDoc);
    },
    async preview(signDoc: SignDoc): Promise<void> {
      // await window.Kernel.ActionBus.kinvaultKintin.signDoc.previewDisclaimer({
      //   signDoc,
      //   kintin: signDoc.kintin,
      // });
      await this.$actionBus.signDoc.PreviewDisclaimer({
        signDoc,
        kintin: signDoc.kintin,
      });
    },
    async mergeContacts(signDoc: SignDoc): Promise<void> {
      window.Kernel.visitRoute({
        name: KinvaultKintinDetailsPostPaymentMergeContactsRoute,
        params: {
          signDoc: signDoc.id,
        } as KinvaultKintinDetailsPostPaymentMergeContactsParams,
      });
    },
    async download(signDoc: SignDoc): Promise<void> {
      // await window.Kernel.ActionBus.kinvaultKintin.signDoc.downloadDisclaimer({
      await this.$actionBus.signDoc.DownloadDisclaimer({
        signDoc,
        kintin: signDoc.kintin,
      });
    },
    async deleteRow(signDoc: SignDoc): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Post Payment",
          message: "Are you sure you want to delete this post payment?",
        },
      });

      // await window.Kernel.ActionBus.kinvaultKintin.signDoc.delete({
      await this.$actionBus.signDoc.DeleteSignDoc({
        signDoc,
        kintin: signDoc.kintin,
      });

      this.refresh();
    },
    async settings(signDoc: SignDoc): Promise<void> {
      // await window.Kernel.ActionBus.core.select.product.read({
      const { product } =
        await window.Kernel.ActionBus2.core.select.ReadProduct({
          query: {
            type: Equal("joint"),
          },
        });

      const form = await UpdateSignDocDisclaimerForm(product, signDoc);
      form.options.state = "disabled";
      try {
        await form.dialog({
          dialog: {
            title: "Disclaimer Settings",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        signDoc.$restore();
        throw e;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.signDoc.update({
      //   signDoc,
      //   kintin: signDoc.kintin,
      // });
      await this.$actionBus.signDoc.UpdateSignDoc(signDoc);

      this.refresh();
    },
    async goToVerificationSession(signDoc: SignDoc): Promise<void> {
      window.Kernel.visitRoute({
        name: KinvaultKintinDetailsPostPaymentVerificationSessionRoute,
        params: {
          signDoc: signDoc.id,
        } as KinvaultKintinDetailsPostPaymentVerificationSessionParams,
      });
    },
  },
});
</script>

// User, Profile, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { User } from "@kinherit/sdk/model/user";

export const ReadUser = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/user",
  method: "get",
  rootModel: User,
  parse: () => ({}),
});

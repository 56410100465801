import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { RecordThemeKinvault } from "./record-theme-kinvault.action";
import { ReplaceTheme } from "./replace-theme.action";

export const Portal2AdminBrandedKinvaultTheme = DefineActionBus({
  name: "portal-2-admin-branded-kinvault-theme",
  actions: {
    RecordThemeKinvault,
    ReplaceTheme,
  },
});

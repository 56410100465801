<template>
  <PageHeader htag="h1" text="Introducer Fee Payment Runs">
    <template #buttons>
      <Button @click="create" text="Create" />
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="data"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    @row-clicked="clickHandler"
    click-to-expand
    v-model:value="selected"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #status="{ row }">
      <Badge
        v-if="row.status?.value === 'draft'"
        type="success"
        text="Draft"
        size="is-small"
        color="is-warning"
        is-rounded
      ></Badge>
      <Badge
        v-if="row.status?.value === 'awaitingApproval'"
        type="success"
        text="Awaiting Approval"
        size="is-small"
        color="is-info"
        is-rounded
      ></Badge>
      <Badge
        v-if="row.status?.value === 'approved'"
        type="success"
        text="Approved"
        size="is-small"
        color="is-teal"
        is-rounded
      ></Badge>
      <Badge
        v-if="row.status?.value === 'completed'"
        type="success"
        text="Completed"
        size="is-small"
        color="is-success"
        is-rounded
      ></Badge>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadIntroducerFeePaymentRunForm } from "@/module/introducer.fees/form/read-introducer-fee-payment-run.form";
import { StyleService } from "@/service/style.service";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePaymentRun,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerFeePaymentRunMasterListRoute } from ".";
import { UpdateIntroducerFeePaymentRunForm } from "../form/update-introducer-fee-payment-run.form";
import {
  IntroducerFeePaymentRunDetailsParams,
  IntroducerFeePaymentRunDetailsRoute,
} from "./details";

export default defineComponent({
  name: IntroducerFeePaymentRunMasterListRoute,
  components: { MasterListPage, PageHeader, Button, Badge },
  mixins: [
    StyleService.mixin,
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.introducer),
  ],
  data: () => ({
    filters: ReadIntroducerFeePaymentRunForm(),
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Fee Type",
        map: (row) =>
          ({
            introducerFee: "Introducer Fee",
            agentFee: "Agent Fee",
            parentAgentFee: "Parent Agent Fee",
          })[row.feeTypeId],
      },
      {
        title: "Status",
        slot: "status",
      },
      {
        title: "Created",
        map: (row) => row.createdAt?.formatDate,
      },
      {
        title: "Completed",
        map: (row) => row.completedAt?.formatDate ?? "N/A",
      },
      {
        title: "No. of Items",
        map: (row) => row.introducerFeePaymentsCount,
      },
    ] as TableColumn<IntroducerFeePaymentRun>[],
    data: [] as IntroducerFeePaymentRun[],
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerFeePaymentRun,
      direction: "desc" as "desc" | "asc",
    },
    selected: [] as IntroducerFeePaymentRun[],
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadIntroducerFeePaymentRunForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.introducerFees.paymentRun.read(
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );
      const data =
        await this.$actionBus.fees.paymentRun.ReadIntroducerFeePaymentRun({
          query: {
            name: Like(formData.name),
            createdAt: Between(formData.createdAt),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.data = data.introducerFeePaymentRun;

      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const form = UpdateIntroducerFeePaymentRunForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Introducer Fee Payment Run",
          },
        });

        // await window.Kernel.ActionBus.introducerFees.paymentRun.create({
        await this.$actionBus.fees.paymentRun.CreateIntroducerFeePaymentRun(
          form.localData,
        );

        await this.refresh(this.filters.localData);
      } catch {
        form.localData.$delete();
      }
    },
    async clickHandler(
      row: IntroducerFeePaymentRun,
      event: MouseEvent,
    ): Promise<void> {
      await window.Kernel.visitRoute(
        {
          name: IntroducerFeePaymentRunDetailsRoute,
          params: {
            introducerFeePaymentRun: row.id,
          } as IntroducerFeePaymentRunDetailsParams,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>

// KintinCheckFollowUp, Note, Kintin

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KintinCheckFollowUp } from "@kinherit/sdk/model/kintin-check-follow-up";

export const DeleteKintinCheckFollowUp = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvault/{kintin}/kintin-check-follow-up/{kintinCheckFollowUp}",
  method: "delete",
  parse: (kintinCheckFollowUp: KintinCheckFollowUp) => ({
    params: {
      kintinCheckFollowUp,
      kintin: kintinCheckFollowUp.$data.kintin,
    },
  }),
  after: ({ message }) => {
    KintinCheckFollowUp.$delete(message);
  },
});

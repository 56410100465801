import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadEmailLog } from "./read-email-log.action";
import { ReadFailedLoginAttempt } from "./read-failed-login-attempt.action";
import { RecordUser } from "./record-user.action";
import { ReadNotifications } from "@/action-bus/portal-2/admin/log/read-notifications.action";

export const Portal2AdminLog = DefineActionBus({
  name: "portal-2-admin-log",
  actions: {
    ReadFailedLoginAttempt,
    ReadEmailLog,
    RecordUser,
    ReadNotifications,
  },
});

// Adviser, Person, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Adviser } from "@kinherit/sdk/model/adviser";

export const ReadAdviser = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/adviser",
  method: "get",
  rootModel: Adviser,
  parse: () => ({}),
});

<template>
  <Card is-plain title="Create Authentication Method" class="user-create-2fa">
    <div v-if="false === verifying">
      <p>
        We recommend either <strong>Google Authenticator</strong> (<a
          title="Get Google Authenticator (iOS)"
          href="https://apps.apple.com/gb/app/google-authenticator/id388497605"
          >iOS</a
        >
        |
        <a
          title="Get Google Authenticator (Android)"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en_GB"
          >Android</a
        >) or <strong>Microsoft Authenticator</strong> (<a
          title="Get Microsoft Authenticator (iOS)"
          href="https://apps.apple.com/gb/app/microsoft-authenticator/id983156458"
          >iOS</a
        >
        |
        <a
          title="Get Microsoft Authenticator (Android)"
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator&amp;hl=en_GB"
          >Android</a
        >), but you can also add Email or SMS Text methods as a backup.
      </p>
      <hr class="is-dashed" />
      <Form is-borderless :config="$data.form" />
      <div class="buttons is-right">
        <Button text="Cancel" @click="cancel" class="mr-2" />
        <Button text="Next" @click="save" class="mr-2" />
      </div>
    </div>
    <div v-else class="has-text-centered">
      <p v-if="null !== otpUri">
        Scan the QR code with your authenticator app and enter the code below.
      </p>
      <QRCode v-if="null !== otpUri" :value="otpUri" />
      <PinField v-model:value="verificationCode" />
      <Button text="Verify" @click="verify" class="mt-4" />
    </div>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserCreate2FactorAuthWrapper",
  "route": "UserCreate2FactorAuth",
  "selector": ".user-create-2fa"
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { QRCode } from "@kinherit/framework/component.display/qr-code";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import { PinField } from "@kinherit/framework/component.input/pin-field";
import { Card } from "@kinherit/framework/component.layout/card";
import { MfAuthMethod } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common/index";
import { OtpService } from "@kinherit/ts-common/service/otp.service";
import { defineComponent } from "vue";
import {
  AdminUserDetailsCreate2FactorAuthRoute,
  AdminUserDetailsManage2FactorAuthRoute,
} from ".";
import { CreateAuthMethodForm } from "../../form/update-auth-method.form";
import { UserDetailsMixin } from "../../mixin/user-details.mixin";

export default defineComponent({
  name: AdminUserDetailsCreate2FactorAuthRoute,
  mixins: [UserDetailsMixin],
  components: { Card, QRCode, PinField, Button, Form },
  data: () => ({
    form: CreateAuthMethodForm(),
    otpUri: null as string | null,
    verifying: false,
    verificationCode: "",
    newMfAuthMethod: null as MfAuthMethod | null,
  }),
  methods: {
    cancel(): void {
      window.Kernel.visitRoute({
        name: AdminUserDetailsManage2FactorAuthRoute,
      });
    },
    async save(): Promise<void> {
      if (!this.form.isValid()) {
        this.form.options.showValidation = true;
        return;
      }

      const mfAuthMethod = this.$data.form.localData;

      if (mfAuthMethod.type === "totp") {
        await this.setupOtp(mfAuthMethod);
      } else {
        this.setKey();
        const newMfAuthMethod = await this.create();
        await this.sendCode(newMfAuthMethod);
        this.$data.newMfAuthMethod = newMfAuthMethod;
      }

      this.verifying = true;
    },
    async setupOtp(mfAuthMethod: MfAuthMethod): Promise<void> {
      const key = OtpService.generateKey();

      mfAuthMethod.key = key;
      mfAuthMethod.$persist();

      const user = this.user;

      if (!user) {
        throw new Error("No username found");
      }

      this.$data.otpUri = OtpService.generateUri({
        key,
        username: user.credential.username,
        issuer: "Kinherit Ltd.",
      });
    },
    async verify(): Promise<void> {
      const mfAuthMethod = this.newMfAuthMethod ?? this.$data.form.localData;
      let verified = false;

      if (mfAuthMethod.type === "totp") {
        verified = await OtpService.verify({
          key: mfAuthMethod.key,
          input: this.$data.verificationCode,
        });
      } else {
        verified = mfAuthMethod.key === this.$data.verificationCode;
      }

      if (!verified) {
        await DangerDialog({
          dialog: {
            title: "Invalid Code",
            message: "The code you entered is invalid.",
          },
        });
        return;
      }

      mfAuthMethod.setupCompleteAt = new DateTime();
      mfAuthMethod.$persist();

      if (this.$data.newMfAuthMethod) {
        await this.update(this.$data.newMfAuthMethod);
      } else {
        await this.create();
      }

      this.back();
    },
    back(): void {
      window.Kernel.visitRoute({
        name: AdminUserDetailsManage2FactorAuthRoute,
      });
    },
    setKey(): void {
      this.$data.form.localData.key = Uuid.numerical(6);
      this.$data.form.localData.$persist();
    },
    async create(): Promise<MfAuthMethod> {
      if (!this.user) {
        throw new Error("No user found");
      }

      this.$data.form.localData.credential = this.user.credential;
      const { mfAuthMethod } =
        // await window.Kernel.ActionBus.adminUser.mfAuthMethods.create({
        //   mfAuthMethod: this.$data.form.localData,
        // });
        await this.$actionBus.mfAuthMethods.CreateMfAuthMethod(
          this.$data.form.localData,
        );

      return mfAuthMethod.first("Failed to create authentication method");
    },
    async update(mfAuthMethod: MfAuthMethod): Promise<void> {
      // await window.Kernel.ActionBus.adminUser.mfAuthMethods.update({
      //   mfAuthMethod,
      // });
      await this.$actionBus.mfAuthMethods.UpdateMfAuthMethod(mfAuthMethod);
    },
    async sendCode(mfAuthMethod: MfAuthMethod): Promise<void> {
      // await window.Kernel.ActionBus.adminUser.mfAuthMethods.resend({
      //   mfAuthMethod,
      // });
      await this.$actionBus.mfAuthMethods.ResendMfAuthMethodCode(mfAuthMethod);
    },
    reset(): void {
      this.form.localData.$restore();
    },
  },
});
</script>

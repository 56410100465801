import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const ActionBus = DefineActionBus({
  name: "ActionBus",
  actions: {
    // adminAccountReferralCode: AdminAccountReferralCodeActionBus,
    // adminBrandedKinvault: AdminBrandedKinvaultActionBus,
    // adminCallLog: AdminCallLogActionBus,
    // adminEmailCampaign: AdminEmailCampaignActionBus,
    // adminEmailTemplate: AdminEmailTemplateActionBus,
    // adminLog: AdminLogActionBus,
    // adminTag: AdminTagActionBus,
    // adminUser: AdminUserActionBus,
    // appointment: AppointmentActionBus,
    // auth: AuthActionBus,
    // core: CoreActionBus,
    // cpd: CpdActionBus,
    // dashboard: DashboardActionBus,
    // external: ExternalActionBus,
    // introducerCompany: IntroducerCompanyActionBus,
    // introducerContact: IntroducerContactActionBus,
    // introducerContract: IntroducerContractActionBus,
    // introducerFeeKintin: IntroducerFeeKintinActionBus,
    // introducerFees: IntroducerFeesActionBus,
    // introducerNetwork: IntroducerNetworkActionBus,
    // introducerOutsource: IntroducerOutsourceActionBus,
    // introducerBillingContact: IntroducerBillingContactActionBus,
    // kinvaultKintin: KinvaultKintinActionBus,
    // kinvaultStorageRequest: KinvaultStorageRequestActionBus,
    // kinvaultSubscription: KinvaultSubscriptionActionBus,
    // kinvaultWillRevision: KinvaultWillRevisionActionBus,
    // kinvaultOfficer: KinvaultOfficerActionBus,
    // knowledgeBase: KnowledgeBaseActionBus,
    // lead: LeadActionBus,
    // order: OrderActionBus,
    // report: ReportActionBus,
    // settings: SettingsActionBus,
    // shared: SharedActionBus,
  },
});

// EmailAddress, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailAddress } from "@kinherit/sdk/model/email-address";

export const ReadEmailAddress = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/email-address",
  method: "get",
  rootModel: EmailAddress,
  parse: () => ({}),
});

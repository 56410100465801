// Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadKintin = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/kintin",
  method: "get",
  rootModel: Kintin,
  parse: () => ({}),
});

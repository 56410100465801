// BrandedKinvaultSubscriptionReferral

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk/model/branded-kinvault-subscription-referral";

export const UpdateBrandedKinvaultSubscriptionReferral = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/subscription-referral/{brandedKinvaultSubscriptionReferral}",
  method: "patch",
  parse: (target: BrandedKinvaultSubscriptionReferral) => ({
    body: {
      referralCode: target.$data.referralCode,
      subscriptionIntent: target.$data.subscriptionIntent,
      subscription: target.$data.subscription,
    },
    params: {
      brandedKinvaultSubscriptionReferral: target,
    },
  }),
});

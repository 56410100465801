// BrandedKinvaultMembership

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultMembership } from "@kinherit/sdk/model/branded-kinvault-membership";

export const RecordBrandedKinvaultMembership = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/user/{user}/branded-kinvault-membership/{brandedKinvaultMembership}",
  method: "get",
  rootModel: BrandedKinvaultMembership,
  parse: (brandedKinvaultMembership: BrandedKinvaultMembership) => ({
    params: {
      brandedKinvaultMembership,
      user: brandedKinvaultMembership.user,
    },
  }),
});

// IntroducerBillingContact, Profile, Address, PhoneNumber, EmailAddress, Website

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerBillingContact } from "@kinherit/sdk/model/introducer-billing-contact";

export const DeleteIntroducerBillingContact = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/billing-contacts/{introducerBillingContact}",
  method: "delete",
  parse: (introducerBillingContact: IntroducerBillingContact | string) => ({
    params: {
      introducerBillingContact,
    },
  }),
  after: ({ message }) => {
    IntroducerBillingContact.$delete(message);
  },
});

import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsSortReferBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsSortReferRoute = "LeadDetailsSortRefer";
const LeadDetailsSortReferUri = "/lead/:lead/sort-refer";

export type LeadDetailsSortReferParams = RouteParamObject<
  typeof LeadDetailsSortReferUri
>;

export const LeadDetailsSortReferStatusRoute = "LeadDetailsSortReferStatus";
export const LeadDetailsSortReferStatusUri = "/lead/:lead/sort-refer/status";

export type LeadDetailsSortReferStatusParams = RouteParamObject<
  typeof LeadDetailsSortReferStatusUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsSortReferRoute,
    path: LeadDetailsSortReferUri,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsSortRefer" */ "./LeadDetails.SortRefer.vue"
        ),
    },
    meta: {
      breadcrumbs: LeadDetailsSortReferBreadCrumb,
    },
    children: [
      {
        name: LeadDetailsSortReferStatusRoute,
        path: LeadDetailsSortReferStatusUri,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "LeadDetailsSortReferStatus" */ "./LeadDetails.SortRefer.Status.vue"
            ),
        },
        meta: {
          breadcrumbs: LeadDetailsSortReferBreadCrumb,
        },
      },
    ],
    redirect: { name: LeadDetailsSortReferStatusRoute },
  },
];

// BrandedKinvault, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";

export const ReadBrandedKinvault = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/branded-kinvault",
  method: "get",
  rootModel: BrandedKinvault,
  parse: () => ({}),
});

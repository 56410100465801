// CpdSession, Address, Website

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CpdSession } from "@kinherit/sdk/model/cpd-session";

export const ReadCpdSessions = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/cpds",
  method: "get",
  rootModel: CpdSession,
  parse: () => ({}),
});

import { Api } from "@kinherit/sdk/api";
import { EmailCampaign } from "@kinherit/sdk/index";

// EmailCampaign, Profile
export const UploadMembersCsv = async (message: {
  emailCampaign: EmailCampaign;
  amendments: {
    profile: string;
    emailAddress: string;
  }[];
  file: File;
}) => {
  const { file } = message;

  await Api.resource(
    "portal-2",
    "/v2/portal-2/email-campaign/{emailCampaign}/members/csv/upload",
    {
      emailCampaign: message.emailCampaign,
    },
  )
    .method("put")

    .files({
      amendments: JSON.stringify(message.amendments),
      uploadedFile: file,
    })
    .result();

  return {};
};

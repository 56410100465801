// ThemeKinvault, ThemeKinvaultFooter, ThemeKinvaultHeader, ThemeKinvaultLogin, ThemeCssVar

import { Api } from "@/service/api.service";
import { BrandedKinvault, ThemeCssVar, ThemeKinvault } from "@kinherit/sdk";

export interface ReplaceThemeKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
  themeKinvault: ThemeKinvault;
}

export const ReplaceTheme = async (message: ReplaceThemeKinvaultMessage) => {
  await Api.resource(
    "portal-2",
    "/v2/portal-2/admin/branded-kinvault/{brandedKinvault}/theme",
    {
      brandedKinvault: message.brandedKinvault,
    },
  )
    .method("put")
    .body({
      brandedKinvault:
        "string" === typeof message.brandedKinvault
          ? message.brandedKinvault
          : message.brandedKinvault.$id,
      brand: message.themeKinvault.$data.brand,
      favIcon: message.themeKinvault.$data.favIcon,
      login: {
        screenImg: message.themeKinvault.login.$data.screenImg,
        customScreenImage: message.themeKinvault.login.$data.customScreenImage,
      },
      header: {
        type: message.themeKinvault.header.$data.type,
        defaultLogo: message.themeKinvault.header.$data.defaultLogo,
        defaultLogoClass: message.themeKinvault.header.$data.defaultLogoClass,
        loginLogo: message.themeKinvault.header.$data.loginLogo,
        loginLogoClass: message.themeKinvault.header.$data.loginLogoClass,
        strap: message.themeKinvault.header.$data.strap,
      },
      footer: {
        class: message.themeKinvault.footer.$data.class,
        leftLogoClass: message.themeKinvault.footer.$data.leftLogoClass,
        leftLogo: message.themeKinvault.footer.$data.leftLogo,
        rightLogoClass: message.themeKinvault.footer.$data.rightLogoClass,
        rightLogo: message.themeKinvault.footer.$data.rightLogo,
        textLine1: message.themeKinvault.footer.$data.textLine1,
        textLine2: message.themeKinvault.footer.$data.textLine2,
        twitter: message.themeKinvault.footer.$data.twitter,
        facebook: message.themeKinvault.footer.$data.facebook,
        linkedIn: message.themeKinvault.footer.$data.linkedIn,
        youtube: message.themeKinvault.footer.$data.youtube,
      },
      cssVars: message.themeKinvault.cssVars.map((cssVar) => ({
        key: cssVar.$data.key,
        value: cssVar.$data.value,
      })),
    })
    .result();

  message.themeKinvault.$restore({
    footer: true,
    header: true,
    login: true,
    cssVars: true,
  });

  ThemeCssVar.$delete(message.themeKinvault.cssVars.pluck("$id"));

  return await window.Kernel.ActionBus2.portal2.admin.brandedKinvault.theme.RecordThemeKinvault(
    {
      message: {
        brandedKinvault: message.brandedKinvault,
        themeKinvault: message.themeKinvault,
      },
    },
  );
};

// Person, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Person } from "@kinherit/sdk/model/person";

export const ReadPerson = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/person",
  method: "get",
  rootModel: Person,
  parse: () => ({}),
});

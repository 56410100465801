import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { AddFilteredProfiles } from "./add-filtered-profiles.action";
import { AddProfile } from "./add-profile.action";
import { ReadProfiles } from "./read-profiles.action";
import { RemoveProfile } from "./remove-profile.action";

export const Portal2EmailCampaignProfile = DefineActionBus({
  name: "portal-2-email-campaign-profile",
  actions: {
    AddFilteredProfiles,
    ReadProfiles,
    AddProfile,
    RemoveProfile,
  },
});

// Order

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Order } from "@kinherit/sdk/model/order";

export const ReadOrder = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/order",
  method: "get",
  rootModel: Order,
  parse: () => ({}),
});

// DefaultBrandedKinvaultReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const CreateDefaultBrandedKinvaultReferralCode = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/branded-kinvault/default-branded-kinvault-referral-code",
  method: "post",
  parse: (target: DefaultBrandedKinvaultReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      brandedKinvault: target.$data.brandedKinvault,
    },
  }),
  after: ({ message }) => {
    DefaultBrandedKinvaultReferralCode.$delete(message);
  },
});

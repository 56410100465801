// MfAuthMethod

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Credential } from "@kinherit/sdk/model/credential";
import { MfAuthMethod } from "@kinherit/sdk/model/mf-auth-method";

export const DeleteMfAuthMethod = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/admin/user/{user}/mf-auth-methods/{credential}/mf-auth-methods/{mfAuthMethod}",
  method: "delete",
  parse: (message: {
    credential: Credential;
    mfAuthMethod: MfAuthMethod | string;
  }) => ({
    params: {
      credential: message.credential,
      mfAuthMethod: message.mfAuthMethod,
      user: message.credential.user!,
    },
  }),
  after: ({ message }) => {
    MfAuthMethod.$delete(message.mfAuthMethod);
  },
});

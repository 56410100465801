<template>
  <div style="display: contents" class="kintin-details-process">
    <PageHeader htag="h2" text="Process" />
    <Card title="Cover & Closing Letters">
      <div class="columns">
        <div class="column">
          <Button
            @click="downloadPackingSlip"
            text="Create Packing Slip and Cover Letters"
            icon-left="PDF"
            is-fullwidth
            class="mr-3 mt-1 has-text-left"
          /><Button
            @click="createRoyalMailOrder"
            text="Create Royal Mail Order"
            icon-left="Cog"
            is-fullwidth
            class="mt-1 has-text-left"
            has-text-left
          />
        </div>
        <div class="column">
          <Button
            @click="downloadStageEClosingLetter"
            text="Download Stage E Closing Letter"
            icon-left="PDF"
            is-fullwidth
            class="mr-3 mt-1 has-text-left"
          />
          <Button
            @click="downloadStageFClosingLetter"
            text="Download Stage F Closing Letter"
            icon-left="PDF"
            is-fullwidth
            class="mr-3 mt-1 has-text-left"
          />
          <Button
            @click="downloadLetterOfAuthority"
            text="Create Letter of Authority"
            icon-left="PDF"
            is-fullwidth
            class="mr-3 mt-1 has-text-left"
          />
        </div>
      </div>
    </Card>
    <Card title="Prefilled LPA Forms">
      <CollapsableWarningWidget
        v-if="potentialLPAWarnings.length"
        :items="potentialLPAWarnings"
        class="mb-5"
      />
      <p>
        <strong>For {{ kintin?.primaryPerson?.profile.fullName }}</strong>
      </p>
      <div class="columns">
        <div class="column">
          <Button
            text="Create LP1F Finance PDF"
            icon-left="PDF"
            is-fullwidth
            class="has-text-left"
            @click="downloadLpaFinancial(kintin?.primaryPerson as Person)"
          />
          <p>
            Main: {{ numberOfAttorneys.primary.finance.primary }} Reserve:
            {{ numberOfAttorneys.primary.finance.reserve }}
          </p>
        </div>
        <div class="column">
          <Button
            text="Create LP1H Health PDF"
            icon-left="PDF"
            is-fullwidth
            class="has-text-left"
            @click="downloadLpaHealth(kintin?.primaryPerson as Person)"
          />
          <p>
            Main: {{ numberOfAttorneys.primary.health.primary }} Reserve:
            {{ numberOfAttorneys.primary.health.reserve }}
          </p>
        </div>
      </div>

      <template v-if="kintin?.type.value === 'joint'">
        <p>
          <strong>For {{ kintin?.secondaryPerson?.profile.fullName }}</strong>
        </p>
        <div class="columns">
          <div class="column">
            <Button
              text="Create LP1F Finance PDF"
              icon-left="PDF"
              is-fullwidth
              class="has-text-left"
              @click="downloadLpaFinancial(kintin.secondaryPerson as Person)"
            />
            <p>
              Main: {{ numberOfAttorneys.secondary.finance.primary }} Reserve:
              {{ numberOfAttorneys.secondary.finance.reserve }}
            </p>
          </div>
          <div class="column">
            <Button
              text="Create LP1H Health PDF"
              icon-left="PDF"
              is-fullwidth
              class="has-text-left"
              @click="downloadLpaHealth(kintin.secondaryPerson as Person)"
            />
            <p>
              Main: {{ numberOfAttorneys.secondary.health.primary }} Reserve:
              {{ numberOfAttorneys.secondary.health.reserve }}
            </p>
          </div>
        </div>
      </template>
    </Card>
    <Card title="DOS">
      <div v-if="noticeOfSeverances.isNotEmpty()">
        <div v-for="property in noticeOfSeverances" :key="property.id">
          <p>
            <strong>{{ property.address?.summary }}</strong>
          </p>
          <div class="columns">
            <div class="column">
              <Button
                :key="`lr-dos-form-${property.id}`"
                :text="'LR Severance Form (pdf)'"
                icon-left="PDF"
                is-fullwidth
                class="has-text-left"
                @click="downloadDOSLRSeveranceForm(property)"
              />
            </div>
            <div class="column">
              <Button
                :key="`notice-of-severance-${property.id}`"
                :text="'Notice of Severance (pdf)'"
                icon-left="PDF"
                is-fullwidth
                class="has-text-left"
                @click="downloadDOSNoticeOfSeverance(property)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>No Deed of Severance(s) available.</p>
        <p class="help">
          To have a Deed of Severance generated, please add a Title Deed to the
          relevant properties.
        </p>
      </div>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsProcessWrapper",
  "route": "KintinDetailsProcess",
  "selector": ".kintin-details-process"
}
</cypress-wrapper>

<script lang="ts">
import { CreateLetterOfAuthorityForm } from "@/module/kinvault.kintin/form/create-letter-of-authority.form";
import { CreatePackingSlipForm } from "@/module/kinvault.kintin/form/create-packing-slip.form";
import { CreateRoyalMailOrderForm } from "@/module/kinvault.kintin/form/create-royal-mail-order.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import CollapsableWarningWidget from "@/shared/component/widget/CollapsableWarningWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { Attorney, Person, Property } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsProcessRoute } from ".";

export default defineComponent({
  name: KintinDetailsProcessRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    Card,
    PageHeader,
    Button,
    CollapsableWarningWidget,
  },
  data: () => ({
    renderKey: 0,
  }),
  mounted() {
    this.renderKey = Math.random();
  },
  computed: {
    noticeOfSeverances(): Array<Property> {
      return (
        this.kintin?.properties?.filter(
          (property) => null !== property.titleDeed,
        ) ?? []
      );
    },
    numberOfAttorneys() {
      const primaryAttorneys = this.getAppointedAttorneys("primaryPerson");
      const secondaryAttorneys = this.getAppointedAttorneys("secondaryPerson");
      return {
        primary: {
          health: {
            total: primaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth,
            ).length,
            primary: primaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth && !attorney.isReserve,
            ).length,
            reserve: primaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth && attorney.isReserve,
            ).length,
          },
          finance: {
            total: primaryAttorneys.filter((attorney) => attorney.isPropFinance)
              .length,
            primary: primaryAttorneys.filter(
              (attorney) => attorney.isPropFinance && !attorney.isReserve,
            ).length,
            reserve: primaryAttorneys.filter(
              (attorney) => attorney.isPropFinance && attorney.isReserve,
            ).length,
          },
        },
        secondary: {
          health: {
            total: secondaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth,
            ).length,
            primary: secondaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth && !attorney.isReserve,
            ).length,
            reserve: secondaryAttorneys.filter(
              (attorney) => attorney.isHealthWealth && attorney.isReserve,
            ).length,
          },
          finance: {
            total: secondaryAttorneys.filter(
              (attorney) => attorney.isPropFinance,
            ).length,
            primary: secondaryAttorneys.filter(
              (attorney) => attorney.isPropFinance && !attorney.isReserve,
            ).length,
            reserve: secondaryAttorneys.filter(
              (attorney) => attorney.isPropFinance && attorney.isReserve,
            ).length,
          },
        },
      };
    },
    potentialLPAWarnings() {
      const primary = this.kintin?.primaryPerson;
      const secondary = this.kintin?.secondaryPerson;
      const warnings = [];
      // @TODO Add checks for certificate provider address etc
      if (!primary?.dateOfBirth) {
        warnings.push("Primary Account Holder: Date of Birth is required");
      }
      if (!primary?.profile?.addresses?.length) {
        warnings.push("Primary Account Holder: An address is required");
      }
      if (!primary?.profile?.emails?.length) {
        warnings.push(
          "Primary Account Holder: An email address is recommended",
        );
      }
      if (!this.primaryAttorneyCheckFinance.hasCertificateProvider) {
        warnings.push("Primary Account Holder: No Certificate Provider");
      }
      if (!this.primaryAttorneyCheckFinance.hasAtLeastOnePrimaryAttorney) {
        warnings.push(
          "Primary Account Holder: At least one attorney should be appointed for Property & Finance LPA",
        );
      }
      if (!this.primaryAttorneyCheckHealth.hasAtLeastOnePrimaryAttorney) {
        warnings.push(
          "Primary Account Holder: At least one attorney should be appointed for Health & Welfare LPA",
        );
      }
      if (this.kintin?.type.value === "joint") {
        if (!secondary?.dateOfBirth) {
          warnings.push("Secondary Account Holder: Date of Birth is required");
        }
        if (!secondary?.profile?.addresses?.length) {
          warnings.push("Secondary Account Holder: An address is required");
        }
        if (!secondary?.profile?.emails?.length) {
          warnings.push(
            "Secondary Account Holder: An email address is recommended",
          );
        }
        if (!this.secondaryAttorneyCheckFinance.hasCertificateProvider) {
          warnings.push("Secondary Account Holder: No Certificate Provider");
        }
        if (!this.secondaryAttorneyCheckFinance.hasAtLeastOnePrimaryAttorney) {
          warnings.push(
            "Secondary Account Holder: At least one attorney should be appointed for Property & Finance LPA",
          );
        }
        if (!this.secondaryAttorneyCheckHealth.hasAtLeastOnePrimaryAttorney) {
          warnings.push(
            "Secondary Account Holder: At least one attorney should be appointed for Health & Welfare LPA",
          );
        }
      }
      return warnings;
    },
    primaryAttorneyCheckFinance() {
      const attorneys = this.getAppointedAttorneys("primaryPerson");
      const hasCertificateProvider = this.hasCertificate(attorneys);
      const hasAtLeastOnePrimaryAttorney = this.hasPrimaryAttorney(
        attorneys,
        "isPropFinance",
      );
      return { hasCertificateProvider, hasAtLeastOnePrimaryAttorney };
    },
    primaryAttorneyCheckHealth() {
      const attorneys = this.getAppointedAttorneys("primaryPerson");
      const hasAtLeastOnePrimaryAttorney = this.hasPrimaryAttorney(
        attorneys,
        "isHealthWealth",
      );
      return { hasAtLeastOnePrimaryAttorney };
    },
    secondaryAttorneyCheckFinance() {
      const attorneys = this.getAppointedAttorneys("secondaryPerson");
      const hasCertificateProvider = this.hasCertificate(attorneys);
      const hasAtLeastOnePrimaryAttorney = this.hasPrimaryAttorney(
        attorneys,
        "isPropFinance",
      );
      return { hasCertificateProvider, hasAtLeastOnePrimaryAttorney };
    },
    secondaryAttorneyCheckHealth() {
      const attorneys = this.getAppointedAttorneys("secondaryPerson");
      const hasAtLeastOnePrimaryAttorney = this.hasPrimaryAttorney(
        attorneys,
        "isHealthWealth",
      );
      return { hasAtLeastOnePrimaryAttorney };
    },
  },
  methods: {
    getAppointedAttorneys(
      personType: "primaryPerson" | "secondaryPerson",
    ): Attorney[] {
      const person = this.kintin && this.kintin[personType];
      return person?.appointedAttorney ?? [];
    },

    hasCertificate(attorneys: Attorney[]): boolean {
      return attorneys.filter((attorney) => attorney.isCertProv).length === 1;
    },

    hasPrimaryAttorney(
      attorneys: Attorney[],
      typeCheck: "isPropFinance" | "isHealthWealth",
    ): boolean {
      return (
        attorneys.filter(
          (attorney) =>
            !attorney.isCertProv && !attorney.isReserve && attorney[typeCheck],
        ).length >= 1
      );
    },

    async downloadPackingSlip() {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const data = await CreatePackingSlipForm(kintin).dialog({
        dialog: {
          title: "Document Options",
        },
      });

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadPackingSlip({
      //   ...data,
      //   kintin,
      // });
      await this.$actionBus.process.FillPackingSlip({
        ...data,
        kintin,
      });
    },
    async downloadDOSLRSeveranceForm(property: Property): Promise<void> {
      const user = this.kintin?.estatePlanners.first();
      const kintin = this.kintin;

      if (!user || !kintin) {
        return;
      }

      // await window.Kernel.ActionBus.adminUser.record({
      //   user,
      // });
      await window.Kernel.ActionBus2.portal2.admin.user.RecordUser({
        message: user,
      });

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadLRSeverance({
      //   kintin,
      //   user,
      //   property,
      // });
      await this.$actionBus.process.FillLRSeveranceForm({
        kintin,
        user,
        property,
      });
    },
    async downloadDOSNoticeOfSeverance(property: Property): Promise<void> {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadNoticeOfSeverance(
      //   {
      //     kintin,
      //     property,
      //   },
      // );

      await this.$actionBus.process.FillNoticeOfSeverance({
        kintin,
        property,
      });
    },
    async downloadLpaFinancial(person: Person) {
      const user =
        this.kintin?.legalAssistants.first() ??
        this.kintin?.estatePlanners.first();
      const kintin = this.kintin;
      if (!user || !kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadLpaFinancial(
      //   {
      //     kintin,
      //     person,
      //     user,
      //   },
      // );
      await this.$actionBus.process.FillLpaFinancial({
        kintin,
        person,
        user,
      });
    },
    async downloadLpaHealth(person: Person) {
      const user =
        this.kintin?.legalAssistants.first() ??
        this.kintin?.estatePlanners.first();
      const kintin = this.kintin;
      if (!user || !kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadLpaHealth({
      //   kintin,
      //   person,
      //   user,
      // });
      await this.$actionBus.process.FillLpaHealth({
        kintin,
        person,
        user,
      });
    },
    async downloadStageEClosingLetter() {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadStageEClosingLetter(
      //   {
      //     kintin,
      //   },
      // );
      await this.$actionBus.process.FillStageEClosingLetter({
        kintin,
      });
    },
    async downloadStageFClosingLetter() {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadStageFClosingLetter(
      //   {
      //     kintin,
      //   },
      // );
      await this.$actionBus.process.FillStageFClosingLetter({
        kintin,
      });
    },
    async downloadLetterOfAuthority() {
      const kintin = this.kintin;

      if (!kintin) {
        return;
      }

      const { person, name, companyName, address, relationship } =
        await CreateLetterOfAuthorityForm({
          kintin,
        }).dialog({
          dialog: {
            title: `Create Letter of Authority`,
          },
        });

      // await window.Kernel.ActionBus.kinvaultKintin.process.downloadLetterOfAuthority(
      //   {
      //     kintin,
      //     person: person as Person,
      //     name: name as string,
      //     companyName: companyName as string,
      //     address: address as string,
      //     relationship: relationship as string,
      //   },
      // );
      await this.$actionBus.process.FillLetterOfAuthority({
        kintin,
        person: person as Person,
        name: name as string,
        companyName: companyName as string,
        address: address as string,
        relationship: relationship as string,
      });
    },
    async createRoyalMailOrder() {
      const kintin = this.kintin;

      const profile = kintin?.primaryPerson?.profile;

      if (!kintin || !profile) {
        return;
      }

      const { address } = await CreateRoyalMailOrderForm(
        profile,
        kintin,
      ).dialog({
        dialog: {
          title: `Create Royal Mail Order`,
        },
      });

      if (!address) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.process.createRoyalMailOrder(
      //   {
      //     kintin,
      //     address,
      //   },
      // );
      await this.$actionBus.process.CreateRoyalMailOrder({
        kintin,
        address,
      });
    },
  },
});
</script>

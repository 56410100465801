import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateAdviser } from "./create-adviser.action";
import { DeleteAdviser } from "./delete-adviser.action";
import { ReadAdviser } from "./read-adviser.action";
import { RecordAdviser } from "./record-adviser.action";
import { UpdateAdviser } from "./update-adviser.action";

export const Portal2KinvaultAdviser = DefineActionBus({
  name: "portal-2-kinvault-adviser",
  actions: {
    CreateAdviser,
    DeleteAdviser,
    ReadAdviser,
    UpdateAdviser,
    RecordAdviser,
  },
});

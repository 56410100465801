// IntroducerContact, Profile, IntroducerCompany, EmailAddress, ContactPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const ReadIntroducerContact = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact",
  method: "get",
  rootModel: IntroducerContact,
  parse: () => ({}),
});

// User, Profile, PhoneNumber, EmailAddress, Address, Website, BrandedKinvaultMembership, BrandedKinvault, KintinAccess, Kintin, Credential

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { User } from "@kinherit/sdk/model/user";

export const RecordUser = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/user/{user}",
  method: "get",
  rootModel: User,
  parse: (message: { kintin: Kintin | string; user: User | string }) => ({
    params: {
      kintin: message.kintin,
      user: message.user,
    },
  }),
});

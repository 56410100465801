// OrderItem

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { OrderItem } from "@kinherit/sdk/index";

export const CreateOrderItem = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/order/{order}/order-items",
  method: "post",
  parse: (target: OrderItem) => ({
    body: {
      order: target.$data.order,
      price: target.$data.price,
      product: target.$data.product,
      quantity: target.$data.quantity,
      recurring: target.$data.recurring,
      requiresDd: target.$data.requiresDd,
      text: target.$data.text,
      value: target.$data.value,
      vat: target.$data.vat,
    },
    params: {
      order: target.order,
      kintin: target.order.kintin?.id as string,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});

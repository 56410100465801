// KnowledgeBaseDocument

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const DeleteKnowledgeBaseDocument = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/knowledge-base/category/document/{knowledgeBaseDocument}",
  method: "delete",
  parse: (knowledgeBaseDocument: KnowledgeBaseDocument | string) => ({
    params: {
      knowledgeBaseDocument,
    },
  }),
  after: ({ message }) => {
    KnowledgeBaseDocument.$delete(message);
  },
});

import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

export const CreateEmailNamedAttachmentHandler = async (message: {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Record<string, File>;
}): Promise<{}> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal-2/introducer/company/{owner}/email-log/{emailLog}/named-attachment",
    [IntroducerContact.$name]:
      "/v2/portal-2/introducer/contact/{owner}/email-log/{emailLog}/named-attachment",
    [Kintin.$name]:
      "/v2/portal-2/kinvault/{owner}/email-log/{emailLog}/named-attachment",
    [Lead.$name]:
      "/v2/portal-2/lead/{owner}/email-log/{emailLog}/named-attachment",
    [Person.$name]:
      "/v2/portal-2/officer/{owner}/email-log/{emailLog}/named-attachment",
    [BrandedKinvault.$name]:
      "/v2/portal-2/admin/branded-kinvault/{owner}/email-log/{emailLog}/named-attachment",
  } as const;

  const route = routes[message.owner.$name];

  for (const name in message.files) {
    const file = message.files[name];

    await Api.resource("portal-2", route, {
      owner: message.owner,
      emailLog: message.emailLog,
    })
      .method("put")

      .files({
        uploadedFile: file,
        attachmentName: name,
      })
      .result();
  }

  return {};
};

// Trustee

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Trustee } from "@kinherit/sdk/model/trustee";

export const UpdateTrustee = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/trustee/{trustee}",
  method: "patch",
  parse: (target: Trustee) => ({
    body: {
      isReserve: target.$data.isReserve,
      notes: target.$data.notes,
      sortOrder: target.$data.sortOrder,
      attainmentAge: target.$data.attainmentAge,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      trustee: target,
      kintin: target.$data.kintin,
    },
  }),
});

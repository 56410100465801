import { IntroducerContactField } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormButton } from "@kinherit/framework/component.input/button";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { CpdSessionRegistration, IntroducerContact } from "@kinherit/sdk";
import { reactive } from "vue";
import { ReadCpdSessionRegistrationMatchesResponse } from "../action/cpd-session-registration/read-cpd-session-registration-matches.action";

export const UpdateCpdSessionRegistrationMatchesForm = async (
  registration: CpdSessionRegistration,
) => {
  const localData = reactive({
    potentialMatches: null as null | Omit<
      ReadCpdSessionRegistrationMatchesResponse,
      "pagination"
    >,
    pagination: {
      currentPage: 0,
      lastPage: 0,
      count: 0,
      perPage: 10,
    },
    tab: 0,
  });

  const query = async () => {
    // const response = await window.Kernel.ActionBus.cpd.register.matches.read({
    const response =
      await window.Kernel.ActionBus2.portal2.cpd.ReadIntroducerContacts({
        cpdSessionRegistation: registration,
        sort: {
          by: "firstName",
        },
        pagination: {
          perPage: localData.pagination.perPage,
          currentPage: localData.pagination.currentPage,
        },
      });

    localData.potentialMatches = {
      profiles: response.profiles,
      phoneNumbers: response.phoneNumbers,
      emailAddresses: response.emailAddresses,
      introducerContacts: response.introducerContacts,
    };

    localData.pagination = {
      ...localData.pagination,
      ...response.pagination,
    };
  };

  return defineForm({
    name: "update-cpd-session-registration-matches-form",
    data: () => ({
      match: null as IntroducerContact | null,
    }),
    validate: (data) => data.match !== null,
    beforeMount: () => query(),
    formAreas: (data) => [
      defineFormArea({
        name: "update-cpd-session-registration-matches-form-area",
        data,
        template: GridLayout([["potentialMatches"], ["manualSearch"]]),
        components: () => ({
          potentialMatches: [
            FormMessage({
              props: {
                title: `Potential Matches for ${registration.profile.firstName} ${registration.profile.lastName}`,
                text: `The following introducers match the name, email address and/or phone number of the registrant.`,
                color: "is-info",
                size: "is-small",
              },
            }),
            FormTable({
              props: {
                isAlignedMiddle: true,
                columns: [
                  {
                    title: "Name",
                    map: ({ profile }: IntroducerContact) =>
                      `${profile.firstName} ${profile.lastName}`,
                  },
                  {
                    title: "Company",
                    map: ({ profile }: IntroducerContact) =>
                      `${profile.introducerContact?.companies
                        .map((c) => c.profile.organisationName)
                        .join(", ")}`,
                  },
                  {
                    title: "Email",
                    map: ({ profile }: IntroducerContact) =>
                      profile.emails.map((email) => email.email).join(", "),
                  },
                  {
                    title: "Phone",
                    map: ({ profile }: IntroducerContact) =>
                      profile.phoneNumbers
                        .map((phoneNumber) => phoneNumber.tel)
                        .join(", "),
                  },
                  {
                    slot: "actions",
                    class: "is-narrow",
                  },
                ],
                rows: () =>
                  localData.potentialMatches?.introducerContacts || [],
                rowClasses:
                  (data) =>
                  ({
                    row,
                  }: {
                    index: number;
                    row: unknown;
                    isChecked: boolean;
                    isExpanded: boolean;
                  }) => ({
                    "has-background-success":
                      data.match?.id === (row as IntroducerContact).id,
                    "has-text-white":
                      data.match?.id === (row as IntroducerContact).id,
                  }),
                reference: "table",
              },
              emits: {
                "update:page": (page: number) => {
                  localData.pagination.currentPage = page;
                  query();
                },
                "update:perPage": (perPage: number) => {
                  localData.pagination.perPage = perPage;
                  query();
                },
              },
              slots: {
                actions: [
                  FormButton({
                    props: {
                      text: (data, controls) => {
                        return data.match?.id ===
                          (controls.templateScope.row as IntroducerContact).id
                          ? "Cancel"
                          : "Select";
                      },
                      color: (data, controls) => {
                        return data.match?.id ===
                          (controls.templateScope.row as IntroducerContact).id
                          ? "is-danger"
                          : "is-positive";
                      },
                      size: "is-small",
                      reference: "toggleMatch",
                    },
                    emits: {
                      click: (value: unknown, data, controls) => {
                        if (
                          data.match?.id ===
                          (controls.templateScope.row as IntroducerContact).id
                        ) {
                          data.match = null;
                        } else {
                          data.match = controls.templateScope.row;
                        }
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          manualSearch: [
            FormMessage({
              props: {
                title: `Manual search for ${registration.profile.firstName} ${registration.profile.lastName}`,
                text: `Search for the introducer below.`,
                color: "is-info",
                class: "mb-4",
                size: "is-small",
              },
            }),
            IntroducerContactField({
              props: {
                vModel: "match",
              },
            }),
          ],
        }),
      }),
    ],
  });
};

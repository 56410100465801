<template>
  <div v-if="knowledgeBaseCategory" class="knowledge-base-category-details">
    <KnowledgeBaseWrapper>
      <template #content>
        <KnowledgeBaseDocumentMasterList
          v-if="knowledgeBaseTopLevelCategory"
          :key="refreshKey"
          :category-id="knowledgeBaseCategory?.id"
          :parent-category-id="knowledgeBaseTopLevelCategory?.id"
          :has-context-menu="hasWritePermission"
          :has-create-button="hasWritePermission"
          @context-menu="contextMenu"
          @create="createDocument"
        >
          <template #buttons>
            <Button
              v-if="hasWritePermission"
              class="create-document"
              size="is-normal"
              @click="createDocument"
              icon-left="Plus"
              tooltip="Add Document"
              is-plain
            />
            <Button
              v-if="hasWritePermission"
              class="update-category"
              size="is-normal"
              @click="updateCategory"
              icon-left="Edit"
              tooltip="Update Category"
              is-plain
            />
            <Button
              v-if="hasWritePermission"
              class="delete-category"
              size="is-normal"
              @click="deleteCategory"
              icon-left="Trash"
              tooltip="Delete Category"
              is-plain
            />
          </template> </KnowledgeBaseDocumentMasterList
      ></template>
    </KnowledgeBaseWrapper>
  </div>
  <PageNotFound v-else />
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseCategoryDetailsWrapper",
  "route": "KnowledgeBaseCategoryDetails",
  "selector": ".knowledge-base-category-details",
  "extends": {
    "name": "KnowledgeBaseDocumentMasterListWrapper",
    "path": "@/module/knowledge-base/component/KnowledgeBaseDocumentMasterList.test"
  },
  "imports": {
    "UpdateKnowledgeBaseDocumentFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-document.form.test",
    "UpdateKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-category.form.test"
  },
  "methods": {
    "deleteCategory": {
      "type": "click",
      "selector": ".delete-category"
    },
    "updateCategory": {
      "type": "click",
      "selector": ".update-category"
    },
    "documentForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateKnowledgeBaseDocumentFormWrapper"
    },
    "createDocument": {
      "type": "click",
      "selector": ".create-document"
    },
    "categoryForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateKnowledgeBaseCategoryFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateKnowledgeBaseDocument } from "@/config/model.config";
import KnowledgeBaseDocumentMasterList from "@/module/knowledge-base/component/KnowledgeBaseDocumentMasterList.vue";
import KnowledgeBaseWrapper from "@/module/knowledge-base/page/KnowledgeBaseWrapper.vue";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Button } from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";
import { KnowledgeBaseCategoryDetailsRoute, KnowledgeBaseRoute } from ".";
import { UpdateKnowledgeBaseCategoryForm } from "../form/update-knowledge-base-category.form";
import { UpdateKnowledgeBaseDocumentForm } from "../form/update-knowledge-base-document.form";
import { KnowledgeBaseCategoryMixin } from "../mixin/knowledge-base-category.mixin";

export default defineComponent({
  name: KnowledgeBaseCategoryDetailsRoute,
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.knowledgeBase.category,
    ),
    KnowledgeBaseCategoryMixin(),
    StyleService.mixin,
  ],
  components: {
    PageNotFound,
    KnowledgeBaseWrapper,
    KnowledgeBaseDocumentMasterList,
    Button,
  },
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("knowledge-base-category:write");
    },
  },
  data: () => ({
    refreshKey: 0,
  }),
  methods: {
    contextMenu(): void {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          titleField: "title",
          trackingField: "title",
          iconLeftField: "icon",
          line1Field: "line1",
          items: [
            {
              title: "Update",
              icon: StyleService.icon.edit.icon,
              line1: "Update this category",
              permission: "knowledge-base-category:write",
              action: this.updateCategory,
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this category",
              permission: "knowledge-base-category:delete",
              action: this.deleteCategory,
            },
          ],
        },
        path: "permission",
        filter: "all",
        callback: (item) => item.action(),
      });

      contextMenu();
    },
    async updateCategory(): Promise<void> {
      const knowledgeBaseCategory = this.knowledgeBaseCategory;
      if (!knowledgeBaseCategory) {
        return;
      }

      try {
        await UpdateKnowledgeBaseCategoryForm({
          category: knowledgeBaseCategory,
          isTopLevel: false,
        }).dialog({
          dialog: {
            title: "Update Knowledge Base Category",
          },
        });
      } catch (error) {
        knowledgeBaseCategory.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.knowledgeBase.category.update({
      await this.$actionBus.UpdateKnowledgeBaseCategory(knowledgeBaseCategory);
    },
    async deleteCategory(): Promise<void> {
      if (!this.knowledgeBaseCategory) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Knowledge Base Category",
          message: `Are you sure you want to delete this category?`,
        },
      });

      // await window.Kernel.ActionBus.knowledgeBase.category.delete({
      await this.$actionBus.DeleteKnowledgeBaseCategory(
        this.knowledgeBaseCategory,
      );

      this.$router.push({ name: KnowledgeBaseRoute });
    },
    async createDocument(): Promise<void> {
      if (!this.knowledgeBaseCategory) {
        return;
      }

      const document = CreateKnowledgeBaseDocument();
      document.addCategories(this.knowledgeBaseCategory);

      const knowledgeBaseDocument = await UpdateKnowledgeBaseDocumentForm(
        document,
      ).dialog({
        dialog: {
          title: "Create Knowledge Base Document",
        },
      });

      // await window.Kernel.ActionBus.knowledgeBase.document.create({
      await this.$actionBus.CreateKnowledgeBaseDocument(knowledgeBaseDocument);

      this.refreshKey++;
    },
  },
});
</script>

// Beneficiary

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/index";
import { Beneficiary } from "@kinherit/sdk/model/beneficiary";

export const ReadBeneficiary = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvault/{kintin}/beneficiary",
  method: "get",
  rootModel: Beneficiary,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});
